<template>
  <div class="consult">
    <div class="header">
      <div class="header_bg">
        <img src="../assets/img/header_bg1.png" alt="" />
      </div>
      <div class="header_logo" @click="goHome"></div>
      <div class="header_user_info">
        <div class="header_user_img">
          <img :src="userInfo.avatar" />
        </div>
        <div class="header_user_name">HI，{{ userInfo.nickname }}</div>
        <div class="header_user_center" @click="userCenterClick">个人中心</div>
        <div class="header_user_line">|</div>
        <div class="header_user_exit" @click="logoutClick">退出登录</div>
      </div>
      <div class="header_option">
        <span @click="headerOptionClick(-1)">首页</span>
        <span @click="headerOptionClick(0)">量表测评</span>
        <span @click="headerOptionClick(1)">心理咨询</span>
        <span @click="headerOptionClick(2)">名师课程</span>
        <span @click="headerOptionClick(3)">在线商城</span>
        <span @click="headerOptionClick(4)">共享咨询室</span>
        <span @click="headerOptionClick(5)">员工关爱方案</span>
      </div>
    </div>
    <div class="consult_bg">
      <img src="../assets/img/consult_hedaer.png" />
    </div>

    <div style="width: 1200px; margin: 0 auto">
      <div class="accredit_list">
        <div v-for="item in accreditList" :key="item.accredit_id">
          <div v-if="item.status == 1">
            <div class="accredit_item">
              <div class="accredit_title">{{ item.accredit_subject }}</div>
              <div class="accredit_sub">
                咨询地点：{{ item.accredit_locale }}
              </div>
              <div class="accredit_sub">
                驻场时间：{{ item.accredit_date }} 截止预约：{{
                  item.subscribe_end_date
                }}
              </div>
            </div>
          </div>
          <div class="accredit_btn" @click="accreditClick(item)">我要预约</div>
        </div>
      </div>
      <!-- 筛选 -->
      <div class="filtrate_wp">
        <el-dropdown @command="handlesortCommand">
          <span class="el-dropdown-link">
            {{ sortString }}<i class="el-icon-arrow-down el-icon--right"></i>
            <el-divider
              style="margin-left: 10px"
              direction="vertical"
            ></el-divider>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="nomore">默认排序</el-dropdown-item>
              <el-dropdown-item command="number">预约最多</el-dropdown-item>
              <el-dropdown-item command="price">低价优先</el-dropdown-item>
              <el-dropdown-item command="evaluate">评价降序</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <el-dropdown @command="handleadeptCommand">
          <span class="el-dropdown-link">
            {{ adeptString }}<i class="el-icon-arrow-down el-icon--right"></i>
            <el-divider
              style="margin-left: 10px"
              direction="vertical"
            ></el-divider>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="0">默认</el-dropdown-item>
              <el-dropdown-item command="1">职场困扰</el-dropdown-item>
              <el-dropdown-item command="2">情绪困扰</el-dropdown-item>
              <el-dropdown-item command="3">婚姻恋爱</el-dropdown-item>
              <el-dropdown-item command="4">家庭困扰</el-dropdown-item>
              <el-dropdown-item command="5">亲子教育</el-dropdown-item>
              <el-dropdown-item command="6">儿童青少年</el-dropdown-item>
              <el-dropdown-item command="7">人际关系</el-dropdown-item>
              <el-dropdown-item command="8">个人成长</el-dropdown-item>
              <el-dropdown-item command="9">心理健康</el-dropdown-item>
              <el-dropdown-item command="10">测评解读</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <el-dropdown @command="handlePriceCommand">
          <span class="el-dropdown-link">
            {{ priceString }}<i class="el-icon-arrow-down el-icon--right"></i>
            <el-divider
              style="margin-left: 10px"
              direction="vertical"
            ></el-divider>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="0">默认</el-dropdown-item>
              <el-dropdown-item command="1">300以下</el-dropdown-item>
              <el-dropdown-item command="2">300-599</el-dropdown-item>
              <el-dropdown-item command="3">600-999</el-dropdown-item>
              <el-dropdown-item command="4">1000+</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <el-input
          v-model="params.keyword"
          class="input-search"
          placeholder="输入咨询师名称"
        />
        <div class="search-button" @click="handleSearch">搜索</div>

        <!-- <span>
          <el-check-tag
            @change="consultantTypeClick"
            :checked="params.consultant_type == '1'"
            >员工关爱服务</el-check-tag
          >
        </span> -->
      </div>
      <div>
        <ul class="consult_list">
          <li
            class="consult_list_item"
            v-for="(i, index) in consultList"
            :key="i.id"
            @click="consultItemClick(i.id)"
          >
            <div class="consult_list_item_top">
              <div class="consult_list_item_left">
                <el-image
                  class="consult_list_item_img"
                  :src="i.user.avatar"
                  fit="cover"
                ></el-image>

                <div class="consult_list_item_btn">预约咨询</div>
                <div
                  v-if="i.province.length > 0"
                  class="consult_list_item_offline"
                >
                  线下预约：{{ i.province }}
                </div>
              </div>
              <div class="consult_list_item_right">
                <div class="consult_list_item_name_w">
                  <div class="consult_list_item_name">
                    {{ i.consultant_name }}
                  </div>
                  <div
                    class="consult_list_item_att"
                    @click.stop="fllowClick(i, index)"
                  >
                    {{ isFollow(i.follow_status) }}
                  </div>
                </div>

                <div class="consult_list_item_introduce">
                  <div>{{ i.consultant_title }}</div>
                  <div>{{ i.consultant_message }}</div>
                </div>
                <div class="consult_list_item_price">
                  咨询费用：¥{{ i.consult_price }}&nbsp;&nbsp;&nbsp;&nbsp;50分钟
                </div>
              </div>
            </div>
            <div class="consult_list_item_bottom">
              <div class="consult_list_item_strong_title">擅长类别：</div>
              <div class="consult_list_item_strong_detail">
                {{ i.adept_category.toString() }}
              </div>
            </div>
          </li>
        </ul>
        <p v-if="loading" class="load_footer">加载中...</p>
        <p v-if="noMore" class="load_footer">没有更多了</p>
      </div>
    </div>
    <copy-footer class="footer" />
  </div>
</template>
<script>
import CopyFooter from "../components/footer/CopyFooter.vue";
import {
  consultant_list,
  consultant_follow,
  consultant_unfollow,
  accredit_consult_activity_list,
} from "../network/views-request";

export default {
  name: "consult",
  components: { CopyFooter },
  props: {},
  data() {
    return {
      userInfo: {},
      params: {
        index: 1,
        count: 6,
        sort: "nomore",
        order: "desc",
        adept_category: "0", //擅长类别
        keyword: "", //搜索关键字
      },
      price: "0", //价格
      consultList: [], //咨询师列表
      loading: false,
      allCount: 1000,
      accreditList: [], //驻场列表
    };
  },
  computed: {
    noMore() {
      return this.allCount <= this.consultList.length;
    },
    disabled() {
      return this.loading || this.noMore;
    },
    isFollow() {
      return (status) => {
        return status ? "已关注" : "+关注专家";
      };
    },
    sortString() {
      if (this.params.sort == "nomore") {
        return "默认排序";
      } else if (this.params.sort == "number") {
        return "预约最多";
      } else if (this.params.sort == "price") {
        return "低价优先";
      } else if (this.params.sort == "evaluate") {
        return "评价降序";
      }
    },
    priceString() {
      if (this.price == "0") {
        return "价格区间";
      } else if (this.price == "1") {
        return "300以下";
      } else if (this.price == "2") {
        return "300-599";
      } else if (this.price == "3") {
        return "600-999";
      } else if (this.price == "4") {
        return "1000+";
      }
    },
    adeptString() {
      if (this.params.adept_category == "0") {
        return "擅长类别";
      } else if (this.params.adept_category == "1") {
        return "职场困扰";
      } else if (this.params.adept_category == "2") {
        return "情绪困扰";
      } else if (this.params.adept_category == "3") {
        return "婚姻恋爱";
      } else if (this.params.adept_category == "4") {
        return "家庭困扰";
      } else if (this.params.adept_category == "5") {
        return "亲子教育";
      } else if (this.params.adept_category == "6") {
        return "儿童青少年";
      } else if (this.params.adept_category == "7") {
        return "人际关系";
      } else if (this.params.adept_category == "8") {
        return "个人成长";
      } else if (this.params.adept_category == "9") {
        return "心理健康";
      } else if (this.params.adept_category == "10") {
        return "测评解读";
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.getMore); //监听滚动
  },
  created() {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    if (info) {
      this.userInfo = info;
    }
    this.getList();
    this.getAccreditList();
  },
  methods: {
    //退出登录
    logoutClick() {
      localStorage.clear();
      this.$router.push({ path: "/" });
    },
    //个人中心
    userCenterClick() {
      this.$router.push({ path: "/usercenter" });
    },
    //回主页
    goHome() {
      this.$router.push({ path: "/" });
    },
    headerOptionClick(index) {
      if (index == 0) {
        this.$router.push({ path: "/survey" });
      } else if (index == 1) {
        this.$router.push({ path: "/consult" });
      } else if (index == 2) {
        this.$router.push({ path: "/course" });
      } else if (index == 3) {
        this.$router.push({ path: "/shopping" });
      } else if (index == 4) {
        this.$router.push({ path: "/room" });
      } else if (index == 5) {
        this.$router.push({ path: "/concern" });
      } else {
        this.goHome();
      }
    },
    //点击排序
    handlesortCommand(command) {
      this.params.sort = command;
      this.consultList = [];
      this.params.index = 1;
      this.getList();
    },
    //选中筛选类别
    handleadeptCommand(command) {
      this.params.adept_category = command;
      this.consultList = [];
      this.params.index = 1;
      this.getList();
    },
    //选中筛选价格范围
    handlePriceCommand(command) {
      this.price = command;
      this.consultList = [];
      this.params.index = 1;
      this.getList();
    },
    //点击员工关爱
    consultantTypeClick() {
      if (this.params.consultant_type == "0") {
        this.params.consultant_type = "1";
      } else {
        this.params.consultant_type = "0";
      }
      this.consultList = [];
      this.params.index = 1;
      this.getList();
    },
    //搜索
    handleSearch() {
      if (this.params.keyword.length == 0) {
        this.$message.error("请输入搜索内容");
        return;
      }
      this.consultList = [];
      this.params.index = 1;
      this.getList();
      this.params.keyword = "";
    },
    //点击驻场我要预约
    accreditClick(item) {
      if (item.status == 1) {
        this.$router.push({
          path: "/consultaccreditdetail",
          query: { accredit_id: item.accredit_id },
        });
      } else {
        this.$message.error("报名已截止");
      }
    },
    //关注
    fllowClick(item, index) {
      if (item.follow_status) {
        this.$confirm("确定要取消关注该专家吗?", "提示", {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning",
        }).then(() => {
          this.gounFllows(item, index);
        });
      } else {
        this.goFllows(item, index);
      }
    },

    //关注
    goFllows(item, index) {
      consultant_follow({
        consultant_id: item.id,
      }).then((res) => {
        if (res.code == 200) {
          let consulter = this.consultList[index];
          consulter.follow_status = 1;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //取消关注
    gounFllows(item, index) {
      consultant_unfollow({
        consultant_id: item.id,
      }).then((res) => {
        if (res.code == 200) {
          let consulter = this.consultList[index];
          consulter.follow_status = 0;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //点击咨询师
    consultItemClick(id) {
      this.$router.push({ path: "/consultdetail", query: { id } });
    },
    //驻场列表
    getAccreditList() {
      accredit_consult_activity_list().then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.accreditList = this.accreditList.concat(res.data.list);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //咨询列表
    getList() {
      this.loading = true;
      let params = {
        index: this.params.index,
        count: this.params.count,
      };
      if (this.params.sort != "nomore") {
        params.sort = this.params.sort;
        params.order = "desc";

        if (this.params.sort == "price") {
          params.order = "asc";
        }
      }
      if (this.params.adept_category != "0") {
        params.adept_category = this.params.adept_category;
      }
      if (this.params.consultant_type != "0") {
        params.consultant_type = this.params.consultant_type;
      }
      if (this.price == "1") {
        params.price = JSON.stringify([0, 299]);
      } else if (this.price == "2") {
        params.price = JSON.stringify([300, 599]);
      } else if (this.price == "3") {
        params.price = JSON.stringify([600, 999]);
      } else if (this.price == "4") {
        params.price = JSON.stringify([1000, 10000]);
      }

      if (this.params.keyword.length > 0) {
        params.keyword = this.params.keyword;
      }
      consultant_list(params).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.allCount = res.data.all_count;
          this.consultList = this.consultList.concat(res.data.list);
        } else {
          this.$message.error(res.message);
        }
        this.loading = false;
      });
    },

    getMore() {
      let clientHeight = document.documentElement.clientHeight; //获取html的可视高度
      let scrollTop = document.documentElement.scrollTop; //获取html的滚动高度
      let scrollHeight = document.documentElement.scrollHeight; //获取文档的实际高度
      //判断如果html的可视高度加上滚动高度等于文档的实际高度的话,就关闭开关,否则会造成数据混乱
      if (clientHeight + scrollTop + 100 >= scrollHeight) {
        this.load();
      }
    },
    load() {
      if (this.noMore == true) {
        return;
      }
      if (this.loading == false) {
        this.params.index += 1;
        this.getList();
      }
    },
  },
};
</script>
<style scoped>
.header {
  position: relative;
}
.header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
}
.header_user_info {
  position: absolute;
  display: flex;
  align-items: center;
  right: 20px;
  top: 15px;
}
.header_user_img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #fff;
  overflow: hidden;
}
.header_user_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header_user_name {
  margin-left: 10px;
  font-size: 15px;
  color: #fff;
}
.header_user_center {
  margin-left: 15px;
  font-size: 15px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.header_user_line {
  margin: 0 15px;
  font-size: 15px;
  color: #fff;
}
.header_user_exit {
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}

.header_option {
  position: absolute;
  right: 0px;
  bottom: 15px;
}
.header_option span {
  padding: 0 20px;
  border-right: 1px solid #fff;
  font-size: 17px;
  flex-wrap: 600;
  color: #fff;
  cursor: pointer;
}
.header_option span:nth-child(6) {
  border-right: 0px solid #fff;
}
.input-search {
  width: 200px;
}
.search-button {
  margin-left: 10px;
  padding: 5px 10px;
  font-size: 12px;
  color: #fff;
  border-radius: 5px;
  background-color: var(--color-main);
  cursor: pointer;
}

.consult_list {
  margin-top: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.consult_list_item {
  margin: 30px;
  padding: 30px;
  width: 45%;
  min-width: 500px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #cecece;
  cursor: pointer;
}
.consult_list_item_top {
  display: flex;
  padding-bottom: 20px;
  border-bottom: 1px dashed var(--color-main);
}
.consult_list_item_left {
  position: relative;
  display: flex;
  flex-direction: column;
}
.consult_list_item_img {
  width: 250px;
  height: 200px;
  border: 1px solid #d6d6d6;
  border-bottom: 0px solid #000;
}
.consult_list_item_btn {
  padding: 5px 0;
  background-color: var(--color-main);
  font-size: 15px;
  color: #fff;
  text-align: center;
}
.consult_list_item_offline {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 20px;
  width: 120px;
  height: 20px;
  font-size: 12px;
  text-align: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.4);
}
.consult_list_item_right {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}
.consult_list_item_name_w {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
.consult_list_item_name {
  font-size: 19px;
  color: #000;
}
.consult_list_item_att {
  font-size: 15px;
  font-weight: 500;
  color: var(--color-main);
  cursor: pointer;
}
.consult_list_item_introduce {
  margin-top: 5px;
  height: 158px;
  font-size: 13px;
  color: #606266;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8;
  white-space: pre-wrap;
  overflow: hidden;
}
.consult_list_item_price {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 15px;
  color: #ce5b39;
}

.consult_list_item_bottom {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}
.consult_list_item_strong_title {
  font-size: 17px;
  font-weight: 600;
}
.consult_list_item_strong_detail {
  margin-top: 10px;
  font-size: 14px;
  color: #606266;
}
.load_footer {
  font-size: 14px;
  text-align: center;
  color: #909399;
}
.accredit_list {
  margin-top: 50px;
  padding: 0 30px;
}
.accredit_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  background-color: var(--color-main);
}
.accredit_title {
  font-size: 18px;
  font-weight: 700;
}
.accredit_sub {
  font-size: 14px;
}
.accredit_btn {
  width: 100px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 15px;
  font-size: 15px;
  font-weight: 700;
  color: var(--color-main);
  background-color: #fff;
  cursor: pointer;
}
.filtrate_wp {
  display: flex;
  align-items: center;
  margin: 50px 20px 0px;
  padding: 20px 15px;
  border-radius: 2px;
  background-color: #ededed;
}
.footer {
  margin-top: 100px;
}
</style>