<template>
  <div class="consult">
    <div class="header">
      <div class="header_bg">
        <img src="../assets/img/header_bg1.png" alt="" />
      </div>
      <div class="header_logo" @click="goHome"></div>
      <div class="header_user_info">
        <div class="header_user_img">
          <img :src="userInfo.avatar" />
        </div>
        <div class="header_user_name">HI，{{ userInfo.nickname }}</div>
        <div class="header_user_center" @click="userCenterClick">个人中心</div>
        <div class="header_user_line">|</div>
        <div class="header_user_exit" @click="logoutClick">退出登录</div>
      </div>
      <div class="header_option">
        <span @click="headerOptionClick(-1)">首页</span>
        <span @click="headerOptionClick(0)">量表测评</span>
        <span @click="headerOptionClick(1)">心理咨询</span>
        <span @click="headerOptionClick(2)">名师课程</span>
        <span @click="headerOptionClick(3)">在线商城</span>
        <span @click="headerOptionClick(4)">共享咨询室</span>
        <span @click="headerOptionClick(5)">员工关爱方案</span>
      </div>
    </div>
    <div class="consult_title">我的关注</div>

    <div style="width: 1200px; margin: 0 auto; min-height: 500px">
      <div>
        <ul
          class="consult_list"
          v-infinite-scroll="load"
          :infinite-scroll-disabled="disabled"
          style="overflow: auto"
        >
          <li
            class="consult_list_item"
            v-for="(i, index) in consultList"
            :key="i.id"
            @click="consultItemClick(i.id)"
          >
            <div class="consult_list_item_top">
              <div class="consult_list_item_left">
                <el-image
                  class="consult_list_item_img"
                  :src="i.user.avatar"
                  fit="cover"
                ></el-image>
                <div class="consult_list_item_btn">预约咨询</div>
              </div>
              <div class="consult_list_item_right">
                <div class="consult_list_item_name_w">
                  <div class="consult_list_item_name">
                    {{ i.consultant_name }}
                  </div>
                  <div
                    class="consult_list_item_att"
                    @click.stop="fllowClick(i, index)"
                  >
                    {{ isFollow(i.follow_status) }}
                  </div>
                </div>

                <div class="consult_list_item_introduce">
                  {{ i.professional_certificate }}
                </div>
                <div class="consult_list_item_price">
                  咨询费用：¥{{ i.consult_price }}&nbsp;&nbsp;&nbsp;&nbsp;50分钟
                </div>
              </div>
            </div>
            <div class="consult_list_item_bottom">
              <div class="consult_list_item_strong_title">擅长类别：</div>
              <div class="consult_list_item_strong_detail">
                {{ i.adept_category.toString() }}
              </div>
            </div>
          </li>
        </ul>
        <p v-if="loading" class="load_footer">加载中...</p>
        <p v-if="noMore" class="load_footer">没有更多了</p>
      </div>
    </div>
    <copy-footer class="footer" />
  </div>
</template>
<script>
import CopyFooter from "../components/footer/CopyFooter.vue";
import {
  consultant_follow_list,
  consultant_follow,
  consultant_unfollow,
} from "../network/views-request";

export default {
  name: "consult",
  components: { CopyFooter },
  props: {},
  data() {
    return {
      userInfo: {},
      params: {
        index: 1,
        count: 20,
      },
      consultList: [],
      loading: false,
      allCount: 1000,
    };
  },
  computed: {
    noMore() {
      return this.allCount <= this.consultList.length;
    },
    disabled() {
      return this.loading || this.noMore;
    },
    isFollow() {
      return (status) => {
        return status ? "已关注" : "+关注专家";
      };
    },
  },
  created() {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    if (info) {
      this.userInfo = info;
    }
    // this.getList();
  },
  methods: {
    //退出登录
    logoutClick() {
      localStorage.clear();
      this.$router.push({ path: "/" });
    },
    //个人中心
    userCenterClick() {
      this.$router.push({ path: "/usercenter" });
    },
    //回主页
    goHome() {
      this.$router.push({ path: "/" });
    },
    headerOptionClick(index) {
      if (index == 0) {
        this.$router.push({ path: "/survey" });
      } else if (index == 1) {
        this.$router.push({ path: "/consult" });
      } else if (index == 2) {
        this.$router.push({ path: "/course" });
      } else if (index == 3) {
        this.$router.push({ path: "/shopping" });
      } else if (index == 4) {
        this.$router.push({ path: "/room" });
      } else if (index == 5) {
        this.$router.push({ path: "/concern" });
      } else {
        this.goHome();
      }
    },
    //关注
    fllowClick(item, index) {
      if (item.follow_status) {
        this.$confirm("确定要取消关注该专家吗?", "提示", {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning",
        }).then(() => {
          this.gounFllows(item, index);
        });
      } else {
        this.goFllows(item, index);
      }
    },
    //关注
    goFllows(item, index) {
      consultant_follow({
        consultant_id: item.id,
      }).then((res) => {
        if (res.code == 200) {
          let consulter = this.consultList[index];
          consulter.follow_status = 1;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //取消关注
    gounFllows(item, index) {
      consultant_unfollow({
        consultant_id: item.id,
      }).then((res) => {
        if (res.code == 200) {
          let consulter = this.consultList[index];
          consulter.follow_status = 0;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //点击咨询师
    consultItemClick(id) {
      this.$router.push({ path: "/consultdetail", query: { id } });
      // this.$router.push({ path: "/shopping" });
    },
    //咨询列表
    getList() {
      this.loading = true;
      consultant_follow_list(this.params).then((res) => {
        if (res.code == 200) {
          this.allCount = res.data.all_count;
          this.consultList = this.consultList.concat(res.data.list);
        } else {
          this.$message.error(res.message);
        }
        this.loading = false;
      });
    },
    load() {
      this.getList();
    },
  },
};
</script>
<style scoped>
.header {
  position: relative;
}
.header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
}
.header_user_info {
  position: absolute;
  display: flex;
  align-items: center;
  right: 20px;
  top: 15px;
}
.header_user_img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #fff;
  overflow: hidden;
}
.header_user_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header_user_name {
  margin-left: 10px;
  font-size: 15px;
  color: #fff;
}
.header_user_center {
  margin-left: 15px;
  font-size: 15px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.header_user_line {
  margin: 0 15px;
  font-size: 15px;
  color: #fff;
}
.header_user_exit {
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}

.header_option {
  position: absolute;
  right: 0px;
  bottom: 15px;
}
.header_option span {
  padding: 0 20px;
  border-right: 1px solid #fff;
  font-size: 17px;
  flex-wrap: 600;
  color: #fff;
  cursor: pointer;
}
.header_option span:nth-child(6) {
  border-right: 0px solid #fff;
}
.consult_title {
  margin-top: 50px;
  text-align: center;
  font-size: 19px;
  font-weight: 600;
}

.consult_list {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.consult_list_item {
  margin: 30px;
  padding: 30px;
  width: 45%;
  min-width: 500px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #cecece;
}
.consult_list_item_top {
  display: flex;
  padding-bottom: 20px;
  border-bottom: 1px dashed var(--color-main);
}
.consult_list_item_left {
  display: flex;
  flex-direction: column;
}
.consult_list_item_img {
  width: 250px;
  height: 200px;
  border: 1px solid #d6d6d6;
  border-bottom: 0px solid #000;
}
.consult_list_item_btn {
  padding: 5px 0;
  background-color: var(--color-main);
  font-size: 15px;
  color: #fff;
  text-align: center;
}
.consult_list_item_right {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.consult_list_item_name_w {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
.consult_list_item_name {
  font-size: 19px;
  color: #000;
}
.consult_list_item_att {
  font-size: 15px;
  font-weight: 500;
  color: var(--color-main);
  cursor: pointer;
}
.consult_list_item_introduce {
  margin-top: 5px;
  height: 150px;
  font-size: 13px;
  color: #606266;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8;
  overflow: hidden;
}
.consult_list_item_price {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 15px;
  color: #ce5b39;
}

.consult_list_item_bottom {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}
.consult_list_item_strong_title {
  font-size: 17px;
  font-weight: 600;
}
.consult_list_item_strong_detail {
  margin-top: 10px;
  font-size: 14px;
  color: #606266;
}
.load_footer {
  font-size: 14px;
  text-align: center;
  color: #909399;
}

.footer {
  margin-top: 100px;
}
</style>