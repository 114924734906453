<template>
  <div class="course">
    <div class="header">
      <div class="header_bg">
        <img src="../assets/img/header_bg1.png" alt="" />
      </div>
      <div class="header_logo" @click="goHome"></div>
      <div class="header_user_info">
        <div class="header_user_img">
          <img :src="userInfo.avatar" />
        </div>
        <div class="header_user_name">HI，{{ userInfo.nickname }}</div>
        <div class="header_user_center" @click="userCenterClick">个人中心</div>
        <div class="header_user_line">|</div>
        <div class="header_user_exit" @click="logoutClick">退出登录</div>
      </div>
      <div class="header_option">
        <span @click="headerOptionClick(-1)">首页</span>
        <span @click="headerOptionClick(0)">量表测评</span>
        <span @click="headerOptionClick(1)">心理咨询</span>
        <span @click="headerOptionClick(2)">名师课程</span>
        <span @click="headerOptionClick(3)">在线商城</span>
        <span @click="headerOptionClick(4)">共享咨询室</span>
        <span @click="headerOptionClick(5)">员工关爱方案</span>
      </div>
    </div>
    <div class="course_bg">
      <img src="../assets/img/course_header.png" />
    </div>
    <div style="width: 1200px; margin: 0 auto; min-height: 500px">
      <!-- 筛选 -->
      <div class="filtrate_wp">
        <el-dropdown @command="handlesortCommand">
          <span class="el-dropdown-link">
            {{ sortString }}<i class="el-icon-arrow-down el-icon--right"></i>
            <el-divider
              style="margin-left: 10px"
              direction="vertical"
            ></el-divider>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="nomore">默认排序</el-dropdown-item>
              <el-dropdown-item command="number">销量最多</el-dropdown-item>
              <el-dropdown-item command="price">低价优先</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <el-dropdown @command="handleVideoTypeCommand">
          <span class="el-dropdown-link">
            {{ videoTypeString
            }}<i class="el-icon-arrow-down el-icon--right"></i>
            <el-divider
              style="margin-left: 10px"
              direction="vertical"
            ></el-divider>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="1">录播课程</el-dropdown-item>
              <el-dropdown-item command="3">直播回放</el-dropdown-item>
              <el-dropdown-item command="2">线上直播</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <el-dropdown @command="handleVideoClassCommand">
          <span class="el-dropdown-link">
            {{ videoClassString
            }}<i class="el-icon-arrow-down el-icon--right"></i>
            <el-divider
              style="margin-left: 10px"
              direction="vertical"
            ></el-divider>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="0">默认分类</el-dropdown-item>
              <el-dropdown-item command="1">个人健康</el-dropdown-item>
              <el-dropdown-item command="2">情绪压力</el-dropdown-item>
              <el-dropdown-item command="3">个人成长</el-dropdown-item>
              <el-dropdown-item command="4">人格探索</el-dropdown-item>
              <el-dropdown-item command="5">亲密关系</el-dropdown-item>
              <el-dropdown-item command="6">家庭关系</el-dropdown-item>
              <el-dropdown-item command="7">亲子教育</el-dropdown-item>
              <el-dropdown-item command="8"
                >职场人际与职场沟通</el-dropdown-item
              >
              <el-dropdown-item command="9">管理技能</el-dropdown-item>
              <el-dropdown-item command="10">其他</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <el-input
          v-model="params.keyword"
          class="input-search"
          placeholder="输入课程名称"
        />
        <div class="search-button" @click="handleSearch">搜索</div>
      </div>
      <div>
        <ul class="course_list">
          <li
            class="course_list_item"
            v-for="i in courseList"
            :key="i.id"
            @click="itemClick(i.id)"
          >
            <el-image
              class="item_image"
              :src="i.cover_url"
              fit="cover"
            ></el-image>
            <el-image
              class="item_image_play"
              :src="require('../assets/img/course_play.png')"
            ></el-image>
            <div class="item_text_wp">
              <div class="item_title">{{ i.video_title }}</div>
              <div class="item_price_wp">
                <div class="item_price">价格：￥{{ i.price }}元</div>
                <div class="item_play">
                  {{ i.need_buy ? "立即购买" : "播放视频" }}
                </div>
              </div>
            </div>
          </li>
        </ul>
        <p v-if="loading" class="load_footer">加载中...</p>
        <p v-if="noMore" class="load_footer">没有更多了</p>
      </div>
    </div>

    <copy-footer class="footer" />
  </div>
</template>
<script>
import { stringify } from "qs";
import CopyFooter from "../components/footer/CopyFooter.vue";
import { video_list } from "../network/views-request";

export default {
  name: "course",
  components: { CopyFooter },
  props: {},
  data() {
    return {
      userInfo: {},
      params: {
        sort: "nomore",
        video_type: "1",
        video_classify: "0",
        keyword: "",
        index: 1,
        count: 6,
      },
      courseList: [],
      loading: false,
      allCount: 1000,
    };
  },
  computed: {
    sortString() {
      if (this.params.sort == "nomore") {
        return "默认排序";
      } else if (this.params.sort == "number") {
        return "销量最多";
      } else if (this.params.sort == "price") {
        return "低价优先";
      }
    },
    videoTypeString() {
      if (this.params.video_type == "1") {
        return "录播课程";
      } else if (this.params.video_type == "3") {
        return "直播回放";
      } else if (this.params.video_type == "2") {
        return "线上直播";
      }
    },
    videoClassString() {
      if (this.params.video_classify == "0") {
        return "默认分类";
      } else if (this.params.video_classify == "1") {
        return "个人健康";
      } else if (this.params.video_classify == "2") {
        return "情绪压力";
      } else if (this.params.video_classify == "3") {
        return "个人成长";
      } else if (this.params.video_classify == "4") {
        return "人格探索";
      } else if (this.params.video_classify == "5") {
        return "亲密关系";
      } else if (this.params.video_classify == "6") {
        return "家庭关系";
      } else if (this.params.video_classify == "7") {
        return "亲子教育";
      } else if (this.params.video_classify == "8") {
        return "职场人际与职场沟通";
      } else if (this.params.video_classify == "9") {
        return "管理技能";
      } else if (this.params.video_classify == "10") {
        return "其他";
      }
    },
    noMore() {
      return this.allCount <= this.courseList.length;
    },
    disabled() {
      return this.loading || this.noMore;
    },
  },
  created() {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    if (info) {
      this.userInfo = info;
    }
    this.getList();
  },
  mounted() {
    window.addEventListener("scroll", this.getMore); //监听滚动
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.getMore);
  },
  methods: {
    //退出登录
    logoutClick() {
      localStorage.clear();
      this.$router.push({ path: "/" });
    },
    //个人中心
    userCenterClick() {
      this.$router.push({ path: "/usercenter" });
    },
    goHome() {
      this.$router.push({ path: "/" });
    },
    headerOptionClick(index) {
      if (index == 0) {
        this.$router.push({ path: "/survey" });
      } else if (index == 1) {
        this.$router.push({ path: "/consult" });
      } else if (index == 2) {
        this.$router.push({ path: "/course" });
      } else if (index == 3) {
        this.$router.push({ path: "/shopping" });
      } else if (index == 4) {
        this.$router.push({ path: "/room" });
      } else if (index == 5) {
        this.$router.push({ path: "/concern" });
      } else {
        this.goHome();
      }
    },
    //点击排序
    handlesortCommand(command) {
      this.params.sort = command;
      this.courseList = [];
      this.params.index = 1;
      this.getList();
    },
    //选中类别直播录播
    handleVideoTypeCommand(command) {
      this.params.video_type = command;
      this.courseList = [];
      this.params.index = 1;
      this.getList();
    },
    //选中课程分类
    handleVideoClassCommand(command) {
      console.log(command);
      this.params.video_classify = command;
      this.courseList = [];
      this.params.index = 1;
      this.getList();
    },
    //搜索
    handleSearch() {
      if (this.params.keyword.length == 0) {
        this.$message.error("请输入搜索内容");
        return;
      }
      this.courseList = [];
      this.params.index = 1;
      this.getList();
      this.params.keyword = "";
    },
    //点击课程
    itemClick(id) {
      this.$router.push({ path: "/coursedetail", query: { item_id: id } });
    },
    //咨询列表
    getList() {
      this.loading = true;
      let params = JSON.parse(JSON.stringify(this.params));
      params.video_classify = JSON.stringify([this.params.video_type]);
      video_list(this.params).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.allCount = res.data.all_count;
          this.courseList = this.courseList.concat(res.data.list);
        } else {
          this.$message.error(res.message);
        }
        this.loading = false;
      });
    },
    getMore() {
      let clientHeight = document.documentElement.clientHeight; //获取html的可视高度
      let scrollTop = document.documentElement.scrollTop; //获取html的滚动高度
      let scrollHeight = document.documentElement.scrollHeight; //获取文档的实际高度
      //判断如果html的可视高度加上滚动高度等于文档的实际高度的话,就关闭开关,否则会造成数据混乱
      if (clientHeight + scrollTop + 100 >= scrollHeight) {
        this.load();
      }
    },
    load() {
      if (this.noMore == true) {
        return;
      }
      if (this.loading == false) {
        this.params.index += 1;
        this.getList();
      }
    },
  },
};
</script>
<style scoped>
.header {
  position: relative;
}
.header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
}
.header_user_info {
  position: absolute;
  display: flex;
  align-items: center;
  right: 20px;
  top: 15px;
}
.header_user_img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #fff;
  overflow: hidden;
}
.header_user_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header_user_name {
  margin-left: 10px;
  font-size: 15px;
  color: #fff;
}
.header_user_center {
  margin-left: 15px;
  font-size: 15px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.header_user_line {
  margin: 0 15px;
  font-size: 15px;
  color: #fff;
}
.header_user_exit {
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}

.header_option {
  position: absolute;
  right: 0px;
  bottom: 15px;
}
.header_option span {
  padding: 0 20px;
  border-right: 1px solid #fff;
  font-size: 17px;
  flex-wrap: 600;
  color: #fff;
  cursor: pointer;
}
.header_option span:nth-child(6) {
  border-right: 0px solid #fff;
}
.filtrate_wp {
  display: flex;
  align-items: center;
  margin: 50px 20px 0px;
  padding: 20px 15px;
  border-radius: 2px;
  background-color: #ededed;
}
.input-search {
  width: 200px;
}
.search-button {
  margin-left: 10px;
  padding: 5px 10px;
  font-size: 12px;
  color: #fff;
  border-radius: 5px;
  background-color: var(--color-main);
  cursor: pointer;
}
.course_list {
  margin-top: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}
.course_list_item {
  margin-top: 40px;
  margin-left: 50px;
  position: relative;
  width: 335px;
  height: 215px;
  list-style-type: none;
  cursor: pointer;
}
.play_text {
  position: absolute;
  left: 0;
  top: 0;
  padding: 2px 35px;
  color: #fff;
  font-size: 13px;
  background-color: rgba(255, 255, 255, 0.5);
}
.item_image_play {
  position: absolute;
  top: 40%;
  left: 50%;
  margin-top: -30px;
  margin-left: -30px;
  width: 60px;
  height: 60px;
}
.item_image {
  position: absolute;
  left: 0;
  top: 0;
  width: 335px;
  height: 200px;
  vertical-align: top;
}
.item_text_wp {
  position: absolute;
  left: 0;
  top: 140px;
  padding: 10px;
  width: 100%;
  background-color: #e4efef;
}
.item_title {
  font-size: 15px;
}
.item_price_wp {
  display: flex;
  justify-content: space-between;
}
.item_price {
  font-size: 15px;
}
.item_play {
  padding: 5px 25px;
  font-size: 14px;
  text-align: center;
  border-radius: 3px;
  background-color: var(--color-main);
  color: #fff;
}
.load_footer {
  font-size: 14px;
  text-align: center;
  color: #909399;
}

.footer {
  margin-top: 100px;
}
</style>