<template>
  <div class="consult">
    <div class="header">
      <div class="header_bg">
        <img src="../assets/img/header_bg1.png" alt="" />
      </div>
      <div class="header_logo" @click="goHome"></div>
      <div class="header_user_info">
        <div class="header_user_img">
          <img :src="userInfo.avatar" />
        </div>
        <div class="header_user_name">HI，{{ userInfo.nickname }}</div>
        <div class="header_user_center" @click="userCenterClick">个人中心</div>
        <div class="header_user_line">|</div>
        <div class="header_user_exit" @click="logoutClick">退出登录</div>
      </div>
      <div class="header_option">
       <span @click="headerOptionClick(-1)">首页</span>
        <span @click="headerOptionClick(0)">量表测评</span>
        <span @click="headerOptionClick(1)">心理咨询</span>
        <span @click="headerOptionClick(2)">名师课程</span>
        <span @click="headerOptionClick(3)">在线商城</span>
        <span @click="headerOptionClick(4)">共享咨询室</span>
        <span @click="headerOptionClick(5)">员工关爱方案</span>
      </div>
    </div>
    <div style="width: 1200px; margin: 50px auto; min-height: 500px">
      <el-tabs
        v-model="params.order_status"
        type="card"
        @tab-click="handleClick"
      >
        <el-tab-pane :label="stayPayCount" name="0"></el-tab-pane>
        <el-tab-pane :label="stayAssessCount" name="1"></el-tab-pane>
        <el-tab-pane :label="completeAssessCount" name="2"></el-tab-pane>
        <el-tab-pane label="全部" name="1000"> </el-tab-pane>
      </el-tabs>
      <div>
        <ul
          class="consult_list"
          :infinite-scroll-disabled="disabled"
          style="overflow: auto"
        >
          <li
            class="consult_list_item"
            v-for="i in surveyList"
            :key="i.order_sn"
          >
            <!--订单头-->
            <div class="item_order_sn_bg">
              <span class="item_order_sn">订单号：{{ i.order_sn }}</span>
<!--              <span class="item_status">{{ i.order_status_msg }}</span>-->
              <div class="item_goods_num">量表数量：{{ i.item.length }}</div>
              <div class="item_goods_total">价格 ：<span>￥{{ i.goods_amount }}</span></div>
              <el-button
                  size="mini"
                  type="primary"
                  @click="lookoverOrder(i.order_sn)"
              >查看订单</el-button
              >
              <el-button
                  v-if="i.order_status == '0'"
                  size="mini"
                  type="danger"
                  @click="goToPay(i.order_sn)"
              >重新支付</el-button
              >
            </div>
            <!--订单量表内容-->
            <div class="item_avatar_bg" v-for="items in i.item">
              <el-image
                class="item_avatar_image"
                :src="i.item && i.img_url"
                fit="cover"
                @click="consultItemClick(items.item_id)"
              ></el-image>
              <span style="color: #a98148;width: 300px;" v-if="i.gauge_source === 1">{{ items.order_status === 0 ? '未付款' : items.order_status === 1 ? '待评测' : items.order_status === 2 ? '查看测评报告' : '' }}</span>
              <span style="color: #a98148;width: 300px;" v-if="i.gauge_source === 2">{{ items.order_status === 0 ? '未开始' : items.order_status === 1 ? '进行中' : items.order_status === 2 ? '已完成' : items.order_status === 3 ? '生成报告' : '' }}</span>
              <div
                class="item_goods_info"
                @click="consultItemClick(items.item_id)"
              >
                <div class="item_goods_name" v-if="i.gauge_source === 1">量表 ：{{ items.goods_name }}</div>
                <div class="item_goods_name" v-if="i.gauge_source === 2">量表 ：{{ items.survey_name }}</div>
              </div>

              <div class="item_btn_bg">

                <el-button
                    v-if="items.order_status === 1 || items.order_status === 0"
                  size="mini"
                  type="danger"
                  @click="beganReview(i)"
                  >开始评测</el-button
                >

                <el-button
                    v-if="items.order_status === 2"
                  size="mini"
                  type="success"
                  @click="checkReport(i)"
                  >查看报告</el-button
                >
                <el-button
                    v-if="items.order_status === 3"
                    size="mini"
                    type="success"
                    @click="checkReport(i)"
                >查看报告</el-button
                >
              </div>
            </div>
          </li>





<!--团测-->
          <li
              class="consult_list_item"
              v-for="(item,index) in regimentList"
          >
            <div class="item_order_sn_bg">
              <span class="item_order_sn">团测列表</span>
              <!--              <span class="item_status">{{ i.order_status_msg }}</span>-->
              <div class="item_goods_num" style="width: 50%">产品名称：{{ item.goods_name }}</div>
              <div class="item_goods_num">量表数量：{{ item.anxin_link.length }}</div>
            </div>
            <div class="item_avatar_bg" v-for="items in item.anxin_link">
              <div
                  class="item_goods_info"
              >
                <div class="item_goods_name" >量表 ：{{ items.survey_name }}</div>
                <!--                <div class="item_goods_name" v-if="i.gauge_source === 2">量表 ：{{ items.survey_name }}</div>-->
              </div>
<!--              <el-image-->
<!--                  class="item_avatar_image"-->
<!--                  :src="i.item && i.img_url"-->
<!--                  fit="cover"-->
<!--                  @click="consultItemClick(items.item_id)"-->
<!--              ></el-image>-->
<!--              <span style="color: #a98148" v-if="i.gauge_source === 1">{{ items.order_status === 0 ? '未付款' : items.order_status === 1 ? '待评测' : items.order_status === 2 ? '查看测评报告' : '' }}</span>-->
              <span style="color: #a98148" >{{ items.order_status === 0 ? '未开始' : items.order_status === 1 ? '进行中' : items.order_status === 2 ? '已完成' : items.order_status === 3 ? '生成报告' : '' }}</span>


              <div class="item_btn_bg">

                <el-button
                    v-if="items.order_status === 1 || items.order_status === 0"
                    size="mini"
                    type="danger"
                    @click="beganRevieww(items)"
                >开始评测</el-button
                >
                <el-button
                    v-if="items.order_status === 2"
                    size="mini"
                    type="success"
                    @click="checkReports"
                >查看报告</el-button
                >
                <el-button
                  v-if="items.order_status === 3"
                  size="mini"
                  type="success"
                  @click="checkReports"
              >查看报告</el-button
              >
              </div>
            </div>
          </li>
        </ul>
        <p v-if="loading" class="load_footer">加载中...</p>
        <p v-if="noMore" class="load_footer">没有更多了</p>
      </div>
    </div>

    <copy-footer class="footer" />
    <!-- 重新支付二维码 -->
    <el-dialog title="扫码重新支付" v-model="codeDialogFormVisible" center>
      <div class="qrcode" ref="qrCodeUrl"></div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="codeDialogFormVisible = false"
            >完 成</el-button
          >
        </span>
      </template>
    </el-dialog>
    <!-- 安心量表 -->
    <el-dialog title="量表列表" v-model="anxinListVisible">
      <el-table :data="anxin_link">
        <el-table-column
          property="survey_name"
          label="量表名称"
          width="300"
        ></el-table-column>
        <el-table-column label="状态" width="200">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <span>{{ scope.row.state == 0 ? "未评测" : "已评测" }}</span>
            </div>
          </template></el-table-column
        >
        <el-table-column label="操作" fixed="right">
          <template #default="scope">
            <el-button link @click="handleReview(scope.$index, scope.row)"
              >立即评测</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 安心量表结果 -->
    <el-dialog title="量表列表" v-model="anxinResultVisible">
      <el-table :data="anxin_result">
        <el-table-column
          property="survey_name"
          label="量表名称"
          width="300"
        ></el-table-column>
        <el-table-column label="状态" width="200"> 已测评 </el-table-column>
        <el-table-column label="操作" fixed="right">
          <template #default="scope">
            <el-button link @click="handleResult(scope.$index, scope.row)"
              >查看报告</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import CopyFooter from "../components/footer/CopyFooter.vue";
import { gauge_orders, gauge_post_re_payment } from "../network/views-request";
import QRCode from "qrcodejs2";
export default {
  name: "consult",
  components: { CopyFooter },
  props: {},
  data() {
    return {
      userInfo: {},
      params: {
        index: 0,
        count: 20,
        order_status: "1", //订单状态 0:未付款 1:未测评 2:已测评
      },
      surveyList: [],
      // 团测
      regimentList:[],
      loading: false,
      allCount: 1000,
      stay_pay_count: 0, //待付款订单数
      stay_assess_count: 0, //待评测订单数
      complete_assess_count: 0, //已评测订单数

      codeDialogFormVisible: false, //重新支付弹窗
      code_url: "", //支付二维码

      anxinListVisible: false, //安心量表显示
      anxin_link: [], //选中的安心量表
      anxinResultVisible: false, //安心量表显示
      anxin_result: [], //选中的安心量表结果
      userInfo:null
    };
  },
  computed: {
    noMore() {
      return this.allCount <= this.surveyList.length;
    },
    disabled() {
      return this.loading || this.noMore;
    },
    stayPayCount() {
      return "待付款（" + this.stay_pay_count + ")";
    },
    stayAssessCount() {
      return "未评测（" + this.stay_assess_count + ")";
    },
    completeAssessCount() {
      return "已评测（" + this.complete_assess_count + ")";
    },
    //开始评测
    beganReviewShow() {
      return (item) => {
        return item.assess_able && item.item.gauge_source;
      };
    },
    //评测报告
    checkReportShow() {
      return (item) => {
        return item.report_able && item.item.gauge_source;
      };
    },
  },
  created() {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    if (info) {
      this.userInfo = info;
      this.getList();
    }
  },
  methods: {
    //退出登录
    logoutClick() {
      localStorage.clear();
      this.$router.push({ path: "/" });
    },
    //个人中心
    userCenterClick() {
      this.$router.push({ path: "/usercenter" });
    },
    //回主页
    goHome() {
      this.$router.push({ path: "/" });
    },
    headerOptionClick(index) {
      if (index == 0) {
        this.$router.push({ path: "/survey" });
      } else if (index == 1) {
        this.$router.push({ path: "/consult" });
      } else if (index == 2) {
        this.$router.push({ path: "/course" });
      } else if (index == 3) {
        this.$router.push({ path: "/shopping" });
      } else if (index == 4) {
        this.$router.push({ path: "/room" });
      } else if (index == 5) {
        this.$router.push({ path: "/concern" });
      } else {
        this.goHome();
      }
    },
    //点击分类
    handleClick(tab, event) {
      this.params.index = 1;
      this.surveyList = [];
      this.getList();
    },
    //查看订单
    lookoverOrder(order_sn) {
      this.$router.push({ path: "/surveyorderdetail", query: { order_sn } });
    },
    //量表订单列表
    getList() {
      this.loading = true;
      gauge_orders(this.params).then((res) => {
        console.log(res.data.list,56)
        if (res.code == 200) {
          this.allCount = res.data.all_count;
          this.stay_pay_count = res.data.stay_pay_count;
          this.stay_assess_count = res.data.stay_assess_count;
          this.complete_assess_count = res.data.complete_assess_count;
          this.surveyList = this.surveyList.concat(res.data.list);
          this.regimentList = res.data.maps;
        } else {
          // this.$message.error(res.message);
        }
        this.loading = false;
      });
    },
    load() {
      this.params.index += 1;
      this.getList();
    },
    //点击订单
    consultItemClick(item_id) {
      this.$router.push({ path: "/surveydetail", query: { id: item_id } });
    },
    //重新支付
    goToPay(order_sn) {
      this.codeDialogFormVisible = true;
      let data = {
        order_sn,
      };
      gauge_post_re_payment(data).then((res) => {
        if (res.code == 200) {
          this.code_url = res.data.code_url;
          this.creatQrCode(res.data.code_url);
        } else {
          // this.$message.error(res.message);
        }
      });
    },
    //生成二维码
    creatQrCode(code_url) {
      let dom = this.$refs.qrCodeUrl;
      while (dom.firstChild) {
        dom.removeChild(dom.firstChild);
      }
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: code_url, // 需要转换为二维码的内容
        width: 150,
        height: 150,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
    //开始评测
    beganReview(i) {
      console.log(i,222)
      // 从 localStorage 获取数据
      const userInfoString = localStorage.getItem("userInfos");

      // 将获取到的字符串解析为对象
      if (userInfoString) {
        this.userInfo = JSON.parse(userInfoString);
      }
      console.log(this.userInfo,1233)
      if(this.userInfo.birthday && this.userInfo.gender){
        if (i.gauge_source == "1") {
          //京师量表
          this.$router.push({
            path: "/surveyreview",
            query: { order_sn: i.order_sn },
          });
        } else if (i.gauge_source == "2") {
          //安定量表
          this.anxin_link = i.anxin_link;
          console.log(this.anxin_link,6767)
          this.anxinListVisible = true;
        }
      }else{
        this.$message.error('请到个人中心填写性别以及出生日期');
        this.$router.push({ path: "/usercenter" });
      }


    },
    beganRevieww(i) {
      console.log(i,12)
      // 从 localStorage 获取数据
      const userInfoString = localStorage.getItem("userInfos");

      // 将获取到的字符串解析为对象
      if (userInfoString) {
        this.userInfo = JSON.parse(userInfoString);
      }

      if(this.userInfo.birthday && this.userInfo.gender){
        this.anxin_link = i.link;
        window.open(this.anxin_link, "_blank");
        // if (i.gauge_source == "1") {
        //   //京师量表
        //   this.$router.push({
        //     path: "/surveyreview",
        //     query: { order_sn: i.order_sn },
        //   });
        // } else if (i.gauge_source == "2") {
        //   //安定量表
        //   this.anxin_link = i;
        //   window.open(this.anxin_link, "_blank");
        //   console.log(this.anxin_link,6767)
        //   this.anxinListVisible = true;
        // }
      }else{
        this.$message.error('请到个人中心填写性别以及出生日期');
        this.$router.push({ path: "/usercenter" });
      }
    },

    //安心量表立即评测
    handleReview(index, row) {
      this.anxinListVisible = false;
      if (row.link) {
        window.open(row.link, "_blank");
      }
    },
    //查看评测报告
    checkReport(i) {
      console.log(i,1)
      if (i.gauge_source == "1") {
        //京师量表
        this.$router.push({
          path: "/surveyreport",
          query: { order_sn: i.order_sn },
        });
      } else if (i.gauge_source == "2") {
        //安定量表
        this.$message.error('请到小程序查看报告');
      }
    },
    checkReports(i) {
        //安定量表
        this.$message.error('请到小程序查看报告');
    },
    //安心量表评测报告
    handleResult(index, row) {
      this.anxinResultVisible = false;
      if (row.result) {
        window.open(row.result, "_blank");
      }
    },
  },
};
</script>
<style scoped>
.item_goods_name{
  width: 90%;
  height: 100%;
}
.header {
  position: relative;
}
.header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
}
.header_user_info {
  position: absolute;
  display: flex;
  align-items: center;
  right: 20px;
  top: 15px;
}
.header_user_img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #fff;
  overflow: hidden;
}
.header_user_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header_user_name {
  margin-left: 10px;
  font-size: 15px;
  color: #fff;
}
.header_user_center {
  margin-left: 15px;
  font-size: 15px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.header_user_line {
  margin: 0 15px;
  font-size: 15px;
  color: #fff;
}
.header_user_exit {
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}

.header_option {
  position: absolute;
  right: 0px;
  bottom: 15px;
}
.header_option span {
  padding: 0 20px;
  border-right: 1px solid #fff;
  font-size: 17px;
  flex-wrap: 600;
  color: #fff;
  cursor: pointer;
}
.header_option span:nth-child(6) {
  border-right: 0px solid #fff;
}
.consult_title {
  margin-top: 50px;
  text-align: center;
  font-size: 19px;
  font-weight: 600;
}
.consult_list_item {
  border: 1px solid #ebeef5;
  margin-bottom: 20px;
}
.item_order_sn_bg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: rgb(206, 228, 238);
}
.item_order_sn {
  font-size: 15px;
}
.item_order_time {
  margin-left: 20px;
  font-size: 15px;
  color: #646566;
}
.item_status {
  margin-left: 20px;
  font-size: 15px;
  color: rgb(214, 24, 24);
}
.item_avatar_bg {
  margin: 20px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.item_avatar_image {
  width: 80px;
  border-radius: 5px;
  cursor: pointer;
}
.item_goods_info {
  margin-left: 20px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  font-size: 15px;
  color: #646566;
}
.item_goods_info :hover {
  color: rgb(214, 24, 24);
}

.item_goods_num {
  width: 120px;
  margin-left: 50px;
  font-size: 15px;
}
.item_goods_total {
  flex: 1;
  margin-left: 100px;
  font-size: 17px;
  color: #333333;
  text-align: left;
}
.item_goods_total span{
  color: red;
}
.load_footer {
  font-size: 14px;
  text-align: center;
  color: #909399;
}
.qrcode {
  width: 150px;
  margin: 0 auto;
}
.footer {
  margin-top: 100px;
}
</style>
