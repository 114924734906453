<template>
  <div class="shop">
    <div class="header">
      <div class="header_bg">
        <img src="../assets/img/header_bg1.png" alt="" />
      </div>
      <div class="header_logo" @click="goHome"></div>
      <div class="header_user_info">
        <div class="header_user_img">
          <img :src="userInfo.avatar" />
        </div>
        <div class="header_user_name">HI，{{ userInfo.nickname }}</div>
        <div class="header_user_center" @click="userCenterClick">个人中心</div>
        <div class="header_user_line">|</div>
        <div class="header_user_exit" @click="logoutClick">退出登录</div>
      </div>
      <div class="header_option">
        <span @click="headerOptionClick(-1)">首页</span>
        <span @click="headerOptionClick(0)">量表测评</span>
        <span @click="headerOptionClick(1)">心理咨询</span>
        <span @click="headerOptionClick(2)">名师课程</span>
        <span @click="headerOptionClick(3)">在线商城</span>
        <span @click="headerOptionClick(4)">共享咨询室</span>
        <span @click="headerOptionClick(5)">员工关爱方案</span>
      </div>
    </div>
    <div style="width: 1200px; min-height: 500px; margin: 0 auto">
      <div class="banner" style="margin-top: 30px">
        <el-carousel
          v-if="detailInfo.room_profile"
          trigger="click"
          :autoplay="false"
          height="500px"
        >
          <el-carousel-item
            v-for="item in detailInfo.room_profile"
            :key="item.url"
          >
            <div v-if="item.type == 'image'">
              <el-image
                :src="item.url"
                style="width: 100%; height: 100%"
                fit="cover"
              />
            </div>
            <div v-if="item.type == 'video'" style="width: 100%; height: 100%">
              <video
                ref="video"
                id="video"
                :src="videolist.src"
                :autoplay="videolist.autoplay"
                :controls="videolist.controls"
                :controlslist="videolist.controlslist"
                :webkit-playsinline="true"
                style="width: 100%; height: 100%"
              ></video>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="room_title">{{ detailInfo.room_name }}</div>
      <div class="room_address">
        咨询地址：{{
          detailInfo.province +
          detailInfo.city +
          detailInfo.district +
          detailInfo.detail_address
        }}
      </div>
      <el-tabs v-model="tabActiveName" type="card" style="margin-top: 30px">
        <el-tab-pane label="咨询室简介" name="info">
          <div class="room_details" v-html="detailInfo.room_detail"></div>
        </el-tab-pane>
        <el-tab-pane label="预约" name="appointment">
          <div class="appointment_wp">
            <div class="appointment_price">
              <span class="appointment_price_text"
                >价格：￥{{ detailInfo.price }}</span
              >
              <span class="appointment_can_dot">●</span>
              <span class="appointment_can">可预约</span>
              <span class="appointment_no_dot">●</span>
              <span class="appointment_no">不可预约</span>
            </div>
          </div>
          <div class="appointment_tab_online">
            <div
              class="appointment_item"
              v-for="item in appointment_time_list"
              :key="item.date"
            >
              <div class="appointment_item_title">{{ item.date }}</div>
              <div class="appointment_item_list">
                <div
                  class="appointment_item_list_item"
                  v-for="item1 in item.time"
                  :key="item1.consult_start_time"
                  :class="
                    item1.status == '1'
                      ? 'appointment_item_can'
                      : 'appointment_item_not'
                  "
                  @click="appointmentClick(item1)"
                >
                  {{ item1.start_time }}-{{ item1.end_time }}
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <copy-footer class="footer" />
  </div>
</template>
<script>
import CopyFooter from "../components/footer/CopyFooter.vue";
import { room_detail, room_appointment_times } from "../network/views-request";

export default {
  name: "shop",
  components: { CopyFooter },
  props: {},
  data() {
    return {
      userInfo: {},
      detailInfo: {},
      appointment_time_list: [],
      videoPlayer: null,
      tabActiveName: "info",
      videolist: {
        autoplay: false, // 自动播放
        controls: "controls", //操作
        controlslist: "nodownload  noplaybackrate",
        src: "",
        /*
        1、nodownload: 不要下载按钮
        2、nofullscreen: 不要全屏按钮
        3、noremoteplayback: 不要远程回放
        4、disablePictureInPicture 不要画中画按钮
        5、noplaybackrate 不要播放速度按钮
        */
      },
    };
  },
  computed: {},
  created() {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    if (info) {
      this.userInfo = info;
    }
    this.getDetail();
    this.getAppointmentTime();
  },
  methods: {
    //退出登录
    logoutClick() {
      localStorage.clear();
      this.$router.push({ path: "/" });
    },
    //个人中心
    userCenterClick() {
      this.$router.push({ path: "/usercenter" });
    },
    //回主页
    goHome() {
      this.$router.push({ path: "/" });
    },
    headerOptionClick(index) {
      if (index == 0) {
        this.$router.push({ path: "/survey" });
      } else if (index == 1) {
        this.$router.push({ path: "/consult" });
      } else if (index == 2) {
        this.$router.push({ path: "/course" });
      } else if (index == 3) {
        this.$router.push({ path: "/shopping" });
      } else if (index == 4) {
        this.$router.push({ path: "/room" });
      } else if (index == 5) {
        this.$router.push({ path: "/concern" });
      } else {
        this.goHome();
      }
    },
    //点击预约
    appointmentClick(item) {
      if (item.status == "1") {
        console.log(this.userInfo.consultant_verify);
        if (this.userInfo.consultant_verify == "0") {
          //需要咨询室认证
          this.$router.push({
            path: "/consultantverify",
            query: { room_id: this.$route.query.id },
          });
        } else {
          //可预约
          let info = {};
          info.room_id = this.$route.query.id;
          info.consult_start_time = item.consult_start_time;
          info.consult_end_time = item.consult_end_time;
          this.$router.push({
            path: "/roomplaceorder",
            query: info,
          });
        }
      } else {
        this.$message.error("该时间段不可预约");
      }
    },
    //获取详情
    getDetail() {
      const params = { room_id: this.$route.query.id };
      room_detail(params).then((res) => {
        if (res.code == 200) {
          this.detailInfo = res.data;
          res.data.room_profile.forEach((item) => {
            if (item.type == "video") {
              this.videolist.src = item.url;
            }
          });
          this.$nextTick(() => {
            this.initVideoplayer();
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //咨询室 - 咨询室预约时间段
    getAppointmentTime() {
      room_appointment_times({
        room_id: this.$route.query.id,
      }).then((res) => {
        if (res.code == 200) {
          this.appointment_time_list = res.data.appointment_time_list;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    initVideoplayer() {
      if (!this.videoPlayer && this.$refs.video) {
        this.videoPlayer = this.$refs.video;

        // 视频进度改变 -- 播放即触发
        this.videoPlayer.addEventListener("timeupdate", (e) => {
          // console.log(this.videoPlayer.currentTime,'currentTime') //当前播放进度
          // console.log(this.videoPlayer.duration,'duration') //当前播放进度
          sessionStorage.setItem("currentTime", this.videoPlayer.currentTime);
          sessionStorage.setItem("duration", this.videoPlayer.duration);
        });

        //视频暂停 --  点击暂停（退出也会触发并记录时间）
        this.videoPlayer.addEventListener("pause", (e) => {
          // this.fileTimelengthRecord(this.videoPlayer.currentTime,this.videoPlayer.duration)
        });

        // // 进度拖动
        // this.videoPlayer.addEventListener('seeking',(e) => {
        //   console.log(this.videoPlayer.currentTime,'seeking进行拖动')
        // })

        // // 进度拖动（进度条拖动会先 pause --> seeked--->play）
        // this.videoPlayer.addEventListener('seeked',(e) => {
        //   console.log(this.videoPlayer.currentTime,'seeked进行拖动')
        // })

        // 视频播放 -- 点击播放
        this.videoPlayer.addEventListener("play", (e) => {});

        // 视频播放 -- 播放结束
        this.videoPlayer.addEventListener("ended", (e) => {
          console.log(this.videoPlayer.currentTime, "ended 播放完成");
        });

        //   // 禁止快进
        // let sym
        // setInterval(() => {
        //     let time = this.videoPlayer.currentTime
        //     if(time-sym>1){
        //         this.videoPlayer.currentTime=sym
        //     }
        //     sym=this.videoPlayer.currentTime
        // },500)
      }
    },
  },
};
</script>
<style scoped>
.header {
  position: relative;
}
.header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
}
.header_user_info {
  position: absolute;
  display: flex;
  align-items: center;
  right: 20px;
  top: 15px;
}
.header_user_img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #fff;
  overflow: hidden;
}
.header_user_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header_user_name {
  margin-left: 10px;
  font-size: 15px;
  color: #fff;
}
.header_user_center {
  margin-left: 15px;
  font-size: 15px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.header_user_line {
  margin: 0 15px;
  font-size: 15px;
  color: #fff;
}
.header_user_exit {
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}

.header_option {
  position: absolute;
  right: 0px;
  bottom: 15px;
}
.header_option span {
  padding: 0 20px;
  border-right: 1px solid #fff;
  font-size: 17px;
  flex-wrap: 600;
  color: #fff;
  cursor: pointer;
}
.header_option span:nth-child(7) {
  border-right: 0px solid #fff;
}
.banner {
  background-color: #f5f5f5;
}
.room_title {
  margin-top: 20px;
  font-size: 17px;
  font-weight: 700;
  color: #333333;
}
.room_price {
  margin-top: 10px;
  font-size: 15px;
  color: red;
}
.room_address {
  margin-top: 10px;
  font-size: 15px;
}
.room_details {
  margin-top: 10px;
}
.appointment_wp {
  padding: 20px 0;
  font-size: 15px;
}
.appointment_can_dot {
  margin-left: 50px;
  color: var(--color-main);
}
.appointment_can {
  margin-left: 10px;
}
.appointment_can_blue_dot {
  margin-left: 10px;
  color: #4389cb;
}
.appointment_no_dot {
  margin-left: 50px;
  color: #bebebe;
}
.appointment_no {
  margin-left: 10px;
}
.appointment_btn {
  margin-top: 30px;
}
.appointment_btn_down {
  padding: 10px 40px;
  font-size: 15px;
  background-color: #4389cb;
  color: #fff;
}
.appointment_btn_up {
  padding: 10px 40px;
  font-size: 15px;
  background-color: var(--color-main);
  color: #fff;
}
.appointment_tab_online {
  padding: 40px 20px;
  border: 1px solid #d5d5d5;
  background-color: #f8f8f8;
}
.appointment_tab_off {
  padding: 40px 20px;
  border: 1px solid #d5d5d5;
  background-color: #f8f8f8;
}
.appointment_item {
  margin-bottom: 30px;
}
.appointment_item_list {
  padding: 15px 20px;
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}
.appointment_item_list_item {
  margin-bottom: 15px;
  margin-left: 15px;
  width: 160px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  cursor: pointer;
}
.appointment_item_can {
  background-color: var(--color-main);
}
.appointment_item_off_can {
  background-color: #4389cb;
}
.appointment_item_not {
  background-color: #bebebe;
}
.footer {
  margin-top: 100px;
}
</style> 