<template>
  <div class="consult">
    <div class="header">
      <div class="header_bg">
        <img src="../assets/img/header_bg1.png" alt="" />
      </div>
      <div class="header_logo" @click="goHome"></div>
      <div class="header_user_info">
        <div class="header_user_img">
          <img :src="userInfo.avatar" />
        </div>
        <div class="header_user_name">HI，{{ userInfo.nickname }}</div>
        <div class="header_user_center" @click="userCenterClick">个人中心</div>
        <div class="header_user_line">|</div>
        <div class="header_user_exit" @click="logoutClick">退出登录</div>
      </div>
      <div class="header_option">
        <span @click="headerOptionClick(-1)">首页</span>
        <span @click="headerOptionClick(0)">量表测评</span>
        <span @click="headerOptionClick(1)">心理咨询</span>
        <span @click="headerOptionClick(2)">名师课程</span>
        <span @click="headerOptionClick(3)">在线商城</span>
        <span @click="headerOptionClick(4)">共享咨询室</span>
        <span @click="headerOptionClick(5)">员工关爱方案</span>
      </div>
    </div>
    <div style="width: 1200px; margin: 50px auto; min-height: 500px">
      <div>
        <ul class="consult_list">
          <li class="consult_list_item" v-for="i in records" :key="i.order_sn">
            <div class="item_order_sn_bg">
              <span class="item_order_sn">订单号：{{ i.order_sn }}</span>
            </div>
            <div class="item_order_info">
              <div class="item_order_info_text">
                充值心币：{{ i.heart_coin }}
              </div>
              <div class="item_order_info_text">
                赠送心币：{{ i.give_heart_coin }}
              </div>
              <div class="item_order_info_text">
                总共心币：{{ i.total_heart_coin }}
              </div>
              <div class="item_order_info_text">
                支付方式：{{ payType(i.pay_type) }}
              </div>
              <div class="item_order_info_text">
                支付金额：{{ i.pay_amount }}
              </div>
              <div class="item_order_info_text item_order_info_text_no_border">
                支付时间：{{ i.pay_time }}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <copy-footer class="footer" />
  </div>
</template>
<script>
import CopyFooter from "../components/footer/CopyFooter.vue";
import { recharge_orders } from "../network/views-request";

export default {
  name: "message",
  components: { CopyFooter },
  props: {},
  data() {
    return {
      userInfo: {},
      records: [],
      page: 1,
    };
  },
  computed: {
    payType() {
      return (status) => {
        if (status == "1") {
          return "微信支付";
        } else if (status == "2") {
          return "支付宝支付";
        } else if (status == "3") {
          return "员工关爱服务";
        } else if (status == "4") {
          return "心币支付";
        } else if (status == "5") {
          return "企业心币支付";
        }
      };
    },
  },
  created() {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    if (info) {
      this.userInfo = info;
    }
    this.getRechargeRecords();
  },
  mounted() {
    window.addEventListener("scroll", this.getMore); //监听滚动
  },
  destroyed() {
    window.removeEventListener("scroll", this.getMore);
  },
  methods: {
    //退出登录
    logoutClick() {
      localStorage.clear();
      this.$router.push({ path: "/" });
    },
    //个人中心
    userCenterClick() {
      this.$router.push({ path: "/usercenter" });
    },
    //回主页
    goHome() {
      this.$router.push({ path: "/" });
    },
    headerOptionClick(index) {
      if (index == 0) {
        this.$router.push({ path: "/survey" });
      } else if (index == 1) {
        this.$router.push({ path: "/consult" });
      } else if (index == 2) {
        this.$router.push({ path: "/course" });
      } else if (index == 3) {
        this.$router.push({ path: "/shopping" });
      } else if (index == 4) {
        this.$router.push({ path: "/room" });
      } else if (index == 5) {
        this.$router.push({ path: "/concern" });
      } else {
        this.goHome();
      }
    },
    //充值记录
    getRechargeRecords() {
      recharge_orders({ index: this.page, count: "20" }).then((res) => {
        if (res.code == 200) {
          let list = res.data.list;
          this.records = [...this.records, ...list];
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //滑动到底部
    getMore() {
      let clientHeight = document.documentElement.clientHeight; //获取html的可视高度
      let scrollTop = document.documentElement.scrollTop; //获取html的滚动高度
      let scrollHeight = document.documentElement.scrollHeight; //获取文档的实际高度
      //判断如果html的可视高度加上滚动高度等于文档的实际高度的话,就关闭开关,否则会造成数据混乱
      if (clientHeight + scrollTop >= scrollHeight) {
        this.load();
      }
    },
    //加载更多
    load() {
      this.page += 1;
      this.getRechargeRecords();
    },
  },
};
</script>
<style scoped>
.header {
  position: relative;
}
.header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
}
.header_user_info {
  position: absolute;
  display: flex;
  align-items: center;
  right: 20px;
  top: 15px;
}
.header_user_img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #fff;
  overflow: hidden;
}
.header_user_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header_user_name {
  margin-left: 10px;
  font-size: 15px;
  color: #fff;
}
.header_user_center {
  margin-left: 15px;
  font-size: 15px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.header_user_line {
  margin: 0 15px;
  font-size: 15px;
  color: #fff;
}
.header_user_exit {
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}

.header_option {
  position: absolute;
  right: 0px;
  bottom: 15px;
}
.header_option span {
  padding: 0 20px;
  border-right: 1px solid #fff;
  font-size: 17px;
  flex-wrap: 600;
  color: #fff;
  cursor: pointer;
}
.header_option span:nth-child(6) {
  border-right: 0px solid #fff;
}
.consult_list_item {
  border: 1px solid #ebeef5;
  margin-bottom: 20px;
}
.item_order_sn_bg {
  padding: 10px;
  background-color: rgb(206, 228, 238);
}
.item_order_sn {
  font-size: 15px;
}
.item_order_info {
  display: flex;
}
.item_order_info_text {
  padding: 20px;
  border-right: 1px solid #ebeef5;
  margin-right: 20px;
  font-size: 15px;
}
.item_order_info_text_no_border {
  border-right: 0px solid #ebeef5;
}
</style>