<template>
  <div class="survey">
    <div class="header">
      <div class="header_bg">
        <img src="../assets/img/header_bg1.png" alt="" />
      </div>
      <div class="header_logo" @click="goHome"></div>
      <div class="header_user_info">
        <div class="header_user_img">
          <img :src="userInfo.avatar" />
        </div>
        <div class="header_user_name">HI，{{ userInfo.nickname }}</div>
        <div class="header_user_center" @click="userCenterClick">个人中心</div>
        <div class="header_user_line">|</div>
        <div class="header_user_exit" @click="logoutClick">退出登录</div>
      </div>
      <div class="header_option">
        <span @click="headerOptionClick(-1)">首页</span>
        <span @click="headerOptionClick(0)">量表测评</span>
        <span @click="headerOptionClick(1)">心理咨询</span>
        <span @click="headerOptionClick(2)">名师课程</span>
        <span @click="headerOptionClick(3)">在线商城</span>
        <span @click="headerOptionClick(4)">共享咨询室</span>
        <span @click="headerOptionClick(5)">员工关爱方案</span>
      </div>
    </div>

    <div style="width: 1200px; margin: 50px auto; min-height: 500px">
      <div class="goods_info">
        <el-image
          class="goods_banner"
          :src="detailInfo.item && detailInfo.item.img_url"
          fit="cover"
        ></el-image>
        <div class="goods_right">
          <div class="goods_title">
            {{ detailInfo.item && detailInfo.item.goods_name }}
          </div>
          <div class="goods_price_info">
            <div class="goods_price_num">商品数量：1</div>
            <div class="goods_price_text">
              <span class="goods_price_title">小计:</span>
              <span class="goods_price_price"
                >￥{{ detailInfo.item && detailInfo.item.price }}元</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="coupon">
        <div class="coupon_title">
          优惠券<span class="coupon_title_num"
            >（{{ coupons.length }}张可用）</span
          >
        </div>
        <div class="coupon_choose" @click="couponChoose">
          {{
            chooseCoupon == null
              ? "不使用优惠券"
              : "- " + chooseCoupon.coupon_discount_str
          }}
          >
        </div>
      </div>
      <ul class="coupon_list" v-if="couponListShow">
        <li class="coupon_list_item">
          <div class="coupon_list_item_wrap0">
            <div class="coupon_time_wrap">
              <div class="coupon_time_text">不使用优惠券</div>
            </div>
            <div class="coupon_use_wrap" @click="gotoUseClick(null)">选择</div>
          </div>
        </li>
        <li class="coupon_list_item" v-for="i in coupons" :key="i.coupon_id">
          <div class="coupon_list_item_wrap4" v-if="i.type == 4">
            <div class="coupon_scope">
              {{ i.coupon_type == "1" ? "全品类" : "部分商品适用" }}
            </div>
            <div class="coupon_monry_wrap">
              <div class="coupon_money4">兑换券</div>
              <div class="coupon_all_money">无门槛</div>
            </div>
            <div class="coupon_time_wrap">
              <div class="coupon_time_text">{{ i.coupon_title }}</div>
              <div class="coupon_time_date4">
                有效期至{{ i.coupon_end_time }}
              </div>
            </div>
            <div class="coupon_use_wrap" @click="gotoUseClick(i)">选择</div>
          </div>
          <div class="coupon_list_item_wrap5" v-if="i.type == 5">
            <div class="coupon_scope">
              {{ i.coupon_type == "1" ? "全品类" : "部分商品适用" }}
            </div>
            <div class="coupon_monry_wrap">
              <div class="coupon_money">
                {{ i.coupon_discount_rate / 10
                }}<span class="coupon_money_symbol">折</span>
              </div>
              <div class="coupon_all_money">无门槛</div>
            </div>
            <div class="coupon_time_wrap">
              <div class="coupon_time_text">{{ i.coupon_title }}</div>
              <div class="coupon_time_date5">
                有效期至{{ i.coupon_end_time }}
              </div>
            </div>
            <div class="coupon_use_wrap" @click="gotoUseClick(i)">选择</div>
          </div>
          <div class="coupon_list_item_wrap6" v-if="i.type == 6">
            <div class="coupon_scope">
              {{ i.coupon_type == "1" ? "全品类" : "部分商品适用" }}
            </div>
            <div class="coupon_monry_wrap">
              <div class="coupon_money">
                <span class="coupon_money_symbol">￥</span
                >{{ i.coupon_discount }}
              </div>
              <div class="coupon_all_money">
                满{{ i.coupon_min_order_amount }}可用
              </div>
            </div>
            <div class="coupon_time_wrap">
              <div class="coupon_time_text">{{ i.coupon_title }}</div>
              <div class="coupon_time_date6">
                有效期至{{ i.coupon_end_time }}
              </div>
            </div>
            <div class="coupon_use_wrap" @click="gotoUseClick(i)">选择</div>
          </div>
        </li>
      </ul>
      <div class="pay_way" v-if="payWayShow">
        <div class="pay_item" @click="projectChange(1, true)">
          <div
            class="pay_select"
            :class="pay_type == 1 ? 'pay_select_on' : ''"
          ></div>
          <el-image
            class="pay_icon"
            :src="require('../assets/img/pay_wx_icon.png')"
          ></el-image>
          <div class="pay_way_title">微信支付</div>
        </div>
        <div
          class="pay_item"
          :class="!wallet.heart_coin_useage ? 'pay_disable' : ''"
          @click="projectChange(4, wallet.heart_coin_useage)"
        >
          <div
            class="pay_select"
            :class="pay_type == 4 ? 'pay_select_on' : ''"
          ></div>
          <el-image
            class="pay_icon"
            :src="require('../assets/img/pay_xin_icon.png')"
          ></el-image>
          <div class="pay_way_title_wp">
            <div class="pay_way_title">心币支付</div>
            <div class="pay_way_balance">剩余心币：{{ wallet.heart_coin }}</div>
          </div>
        </div>
        <div
          class="pay_item"
          v-for="item in project_list"
          :key="item.project_id"
          :class="!item.heart_coin_useage ? 'pay_disable' : ''"
          @click="projectChange(item.project_id, item.heart_coin_useage)"
        >
          <div
            class="pay_select"
            :class="pay_type == item.project_id ? 'pay_select_on' : ''"
          ></div>
          <el-image
            class="pay_icon"
            :src="require('../assets/img/pay_xin_icon.png')"
          ></el-image>
          <div class="pay_way_title_wp">
            <div class="pay_way_title">{{ item.project_name }}</div>
            <div class="pay_way_balance">
              剩余心币：{{ item.remain_heart_coin }}
            </div>
          </div>
        </div>
      </div>
      <div class="goods_price_bg">
        <div class="goods_total_text">商品总额:</div>
        <div class="goods_total_info">
          <div class="goods_total_price">￥{{ goodsTotalPrice }}元</div>
          <div class="goods_postal">(*虚拟商品一经售出概不退款)</div>
        </div>
        <div class="goods_buy" @click="placeOrder">立即购买</div>
      </div>
    </div>

    <copy-footer class="footer" />
    <!-- 支付二维码 -->
    <el-dialog title="微信扫码支付" v-model="codeDialogFormVisible" center>
      <div class="qrcode" ref="qrCodeUrl"></div>
      <div style="text-align: center; margin-top: 20px">
        扫码成功后点击完成查看订单
      </div>

      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="wchatPayComplete">完 成</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import CopyFooter from "../components/footer/CopyFooter.vue";
import {
  gauge_preview_post_item_order,
  gauge_post_item_order,
} from "../network/views-request";
import QRCode from "qrcodejs2";

export default {
  name: "Survey",
  components: { CopyFooter },
  props: {},
  data() {
    return {
      userInfo: {},
      detailInfo: {},
      pay_type: 0,
      wallet: {}, //心币
      project_list: [], //支持企业心币的项目列表
      codeDialogFormVisible: false, //微信支付弹窗
      code_url: "", //支付二维码
      order_sn: "", //微信支付完成跳转订单号
      coupons: [], //优惠券
      couponListShow: "", //显示优惠券列表
      chooseCoupon: null, //选择的优惠券
    };
  },
  computed: {
    goodsTotalPrice() {
      if (this.chooseCoupon == null) {
        return this.detailInfo.item && this.detailInfo.item.price;
      } else {
        return (
          this.detailInfo.item.price - this.chooseCoupon.coupon_discount_str
        );
      }
    },
    payWayShow() {
      if (self.chooseCoupon == null) return true;
      if (self.chooseCoupon.pay_type == 4) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    if (info) {
      this.userInfo = info;
    }
    this.getDetail();
  },
  methods: {
    //退出登录
    logoutClick() {
      localStorage.clear();
      this.$router.push({ path: "/" });
    },
    //个人中心
    userCenterClick() {
      this.$router.push({ path: "/usercenter" });
    },
    //回主页
    goHome() {
      this.$router.push({ path: "/" });
    },
    headerOptionClick(index) {
      if (index == 0) {
        this.$router.push({ path: "/survey" });
      } else if (index == 1) {
        this.$router.push({ path: "/consult" });
      } else if (index == 2) {
        this.$router.push({ path: "/course" });
      } else if (index == 3) {
        this.$router.push({ path: "/shopping" });
      } else if (index == 4) {
        this.$router.push({ path: "/room" });
      } else if (index == 5) {
        this.$router.push({ path: "/concern" });
      } else {
        this.goHome();
      }
    },
    //改变企业心币支付项目
    projectChange(pay_type, disable) {
      if (disable) {
        this.pay_type = pay_type;
      }
    },
    //选择优惠券
    couponChoose() {
      if (this.coupons.length == 0) {
        this.$message.warning("无可用优惠券");
        return;
      }
      this.couponListShow = !this.couponListShow;
    },
    //点击优惠券
    gotoUseClick(item) {
      this.couponListShow = false;
      this.chooseCoupon = item;
    },
    //获取详情
    getDetail() {
      const params = { item_id: this.$route.query.id, quantity: "1" };
      gauge_preview_post_item_order(params).then((res) => {
        if (res.code == 200) {
          this.detailInfo = res.data;
          this.wallet = res.data.wallet;
          this.project_list = res.data.project_list;
          this.coupons = res.data.coupons;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //下单
    placeOrder() {
      if (this.chooseCoupon) {
        if (this.chooseCoupon.type != 4 && this.pay_type == 0) {
          this.$message.error("请选择支付方式");
          return;
        }
      } else {
        if (this.pay_type == 0) {
          this.$message.error("请选择支付方式");
          return;
        }
      }

      const params = {
        item_id: this.$route.query.id,
        quantity: "1",
        pay_type: this.pay_type,
      };
      if (this.pay_type.length > 2) {
        //企业心币支付
        params.pay_type = 5;
        params.project_id = this.pay_type;
      }
      //兑换券pay_type=6
      if (this.chooseCoupon) {
        params.coupon_id = this.chooseCoupon.coupon_id;
        if (this.chooseCoupon.type == 4) {
          params.pay_type = 6;
        }
      }

      gauge_post_item_order(params).then((res) => {
        if (res.code == 200) {
          if (
            this.chooseCoupon &&
            this.chooseCoupon.type == 4 &&
            params.pay_type == 6
          ) {
            this.$message.success("购买成功");
            this.$router.push({
              path: "/surveyorderdetail",
              query: { order_sn: res.data.order_sn },
            });
            return;
          }
          if (this.pay_type == 1) {
            //微信支付
            this.codeDialogFormVisible = true;
            //解决二维码refs找不到的问题
            setTimeout(() => {
              this.code_url = res.data.code_url;
              this.order_sn = res.data.order_sn;
              this.creatQrCode(res.data.code_url);
            }, 500);
          } else {
            this.$message.success("购买成功");
            this.$router.push({
              path: "/surveyorderdetail",
              query: { order_sn: res.data.order_sn },
            });
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //微信支付扫码点完成
    wchatPayComplete() {
      this.codeDialogFormVisible = false;
      this.$router.push({
        path: "/surveyorderdetail",
        query: { order_sn: this.order_sn },
      });
    },
    //生成二维码
    creatQrCode(code_url) {
      let dom = this.$refs.qrCodeUrl;
      console.log(this.$refs);

      while (dom.firstChild) {
        dom.removeChild(dom.firstChild);
      }
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: code_url, // 需要转换为二维码的内容
        width: 150,
        height: 150,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
  },
};
</script>
<style scoped>
.header {
  position: relative;
}
.header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
}
.header_user_info {
  position: absolute;
  display: flex;
  align-items: center;
  right: 20px;
  top: 15px;
}
.header_user_img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #fff;
  overflow: hidden;
}
.header_user_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header_user_name {
  margin-left: 10px;
  font-size: 15px;
  color: #fff;
}
.header_user_center {
  margin-left: 15px;
  font-size: 15px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.header_user_line {
  margin: 0 15px;
  font-size: 15px;
  color: #fff;
}
.header_user_exit {
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}

.header_option {
  position: absolute;
  right: 0px;
  bottom: 15px;
}
.header_option span {
  padding: 0 20px;
  border-right: 1px solid #fff;
  font-size: 17px;
  flex-wrap: 600;
  color: #fff;
  cursor: pointer;
}
.header_option span:nth-child(6) {
  border-right: 0px solid #fff;
}

.goods_info {
  display: flex;
  padding: 20px;
  background-color: #f8f8f8;
}
.goods_banner {
  width: 200px;
}
.goods_right {
  flex: 1;
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.goods_title {
  font-size: 17px;
  font-weight: 600;
}
.goods_price_num {
  margin-bottom: 15px;
  font-size: 15px;
  color: #646363;
  text-align: right;
}
.goods_price_text {
  text-align: right;
}
.goods_price_title {
  font-size: 15px;
  color: #646363;
}
.goods_price_price {
  font-size: 18px;
  margin-left: 30px;
  color: #ef0c26;
}

.goods_price_bg {
  margin: 30px 0 200px 0;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
}
.goods_total_text {
  font-size: 15px;
}
.goods_total_info {
  margin-left: 15px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.goods_total_price {
  font-size: 20px;
  font-weight: 600;
  color: #ef0c26;
}
.goods_postal {
  margin-left: 10px;
  font-size: 12px;
  color: #646363;
}
.goods_buy {
  width: 120px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 15px;
  color: #fff;
  background-color: var(--color-main);
  cursor: pointer;
}
.pay_type {
  margin-top: 20px;
  padding: 15px;
  background-color: #f5f5f5;
}
.pay_title {
  font-size: 17px;
}
.pay_ch {
  margin: 10px 0;
}
.qrcode {
  width: 150px;
  margin: 0 auto;
}
.pay_way {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
}
.pay_way :nth-child(3n) {
  margin-right: 0px;
}
.pay_item {
  margin-bottom: 20px;
  margin-right: 60px;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  background-color: #f5f5f5;
  width: 360px;
  cursor: pointer;
  opacity: 1;
}
.pay_disable {
  cursor: not-allowed;
  opacity: 0.4;
}
.pay_select {
  margin-left: 15px;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  border: 1px solid #aaaaaa;
  background-color: #fff;
}
.pay_select_on {
  background-color: var(--color-main);
}
.pay_icon {
  margin-left: 15px;
  margin-right: 15px;
  width: 35px;
  height: 35px;
}
.pay_way_title_wp {
  display: flex;
  flex-direction: column;
}
.pay_way_title {
  font-size: 15px;
}
.pay_way_balance {
  font-size: 14px;
  color: #ef0c26;
}
.footer {
  margin-top: 100px;
}

.coupon {
  margin-top: 20px;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  background-color: #f5f5f5;
}
.coupon_title_num {
  font-size: 14px;
  color: #aaa;
}
.coupon_choose {
  color: #646363;
}

.coupon_list {
  display: flex;
  flex-wrap: wrap;
  background-color: #f5f5f5;
}
.coupon_list_item {
  margin: 20px;
  border-radius: 15px;
  overflow: hidden;
}
.coupon_list_item_wrap6 {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 320px;
  height: 100px;
  background-color: rgb(238, 189, 128);
}
.coupon_list_item_wrap5 {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 320px;
  height: 100px;
  background-color: rgb(150, 195, 245);
}
.coupon_list_item_wrap4 {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 320px;
  height: 100px;
  background-color: rgb(152, 243, 228);
}
.coupon_list_item_wrap0 {
  padding-left: 15px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 320px;
  height: 100px;
  background-color: rgba(233, 212, 212, 0.9);
}
.coupon_scope {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 10px;
  font-size: 10px;
  color: #fff;
  background-color: var(--color-main);
}
.coupon_monry_wrap {
  margin: 0 30px 0 10px;
}
.coupon_money_symbol {
  font-size: 15px;
  font-weight: bold;
  color: rgb(240, 28, 28);
}
.coupon_money4 {
  font-size: 10px;
  font-weight: bold;
  color: rgb(240, 28, 28);
}
.coupon_money {
  font-size: 30px;
  font-weight: bold;
  color: rgb(240, 28, 28);
}
.coupon_all_money {
  font-size: 10px;
  color: rgb(240, 28, 28);
}
.coupon_time_wrap {
  flex: 1;
}
.coupon_time_text {
  font-size: 15px;
  font-weight: bold;
  color: #232323;
}
.coupon_time_date4 {
  font-size: 12px;
  color: rgb(36, 156, 142);
}
.coupon_time_date5 {
  font-size: 12px;
  color: rgb(102, 131, 223);
}
.coupon_time_date6 {
  font-size: 12px;
  color: rgb(171, 105, 25);
}
.coupon_use_wrap {
  margin-right: 10px;
  width: 60px;
  height: 30px;
  line-height: 30px;
  border-radius: 20px;
  font-size: 10px;
  text-align: center;
  background-color: rgb(242, 48, 48);
  color: #fff;
}
</style>