<template>
  <div class="consult">
    <div class="header">
      <div class="header_bg">
        <img src="../assets/img/header_bg1.png" alt="" />
      </div>
      <div class="header_logo" @click="goHome"></div>
      <div class="header_user_info">
        <div class="header_user_img">
          <img :src="userInfo.avatar" />
        </div>
        <div class="header_user_name">HI，{{ userInfo.nickname }}</div>
        <div class="header_user_center" @click="userCenterClick">个人中心</div>
        <div class="header_user_line">|</div>
        <div class="header_user_exit" @click="logoutClick">退出登录</div>
      </div>
      <div class="header_option">
        <span @click="headerOptionClick(-1)">首页</span>
        <span @click="headerOptionClick(0)">量表测评</span>
        <span @click="headerOptionClick(1)">心理咨询</span>
        <span @click="headerOptionClick(2)">名师课程</span>
        <span @click="headerOptionClick(3)">在线商城</span>
        <span @click="headerOptionClick(4)">共享咨询室</span>
        <span @click="headerOptionClick(5)">员工关爱方案</span>
      </div>
    </div>
    <div style="width: 1200px; margin: 50px auto; min-height: 500px">
      <el-tabs
        v-model="params.order_status"
        type="card"
        @tab-click="handleClick"
      >
        <el-tab-pane label="待付款" name="0"></el-tab-pane>
        <el-tab-pane label="我的课程" name="1"></el-tab-pane>
        <el-tab-pane label="已过期" name="2"></el-tab-pane>
        <el-tab-pane label="全部" name="1000"> </el-tab-pane>
      </el-tabs>
      <div>
        <ul
          class="consult_list"
          v-infinite-scroll="load"
          :infinite-scroll-disabled="disabled"
          style="overflow: auto"
        >
          <li
            class="consult_list_item"
            v-for="i in videoList"
            :key="i.order_sn"
          >
            <div class="item_order_sn_bg">
              <span class="item_order_sn">订单号：{{ i.order_sn }}</span>
              <span class="item_status">{{ i.order_status_msg }}</span>
            </div>
            <div class="item_avatar_bg">
              <el-image
                class="item_avatar_image"
                :src="i.item && i.item.cover_url"
                fit="cover"
                @click="consultItemClick(i.item.item_id)"
              ></el-image>
              <div
                class="item_goods_info"
                @click="consultItemClick(i.item.item_id)"
              >
                <div class="item_goods_name">{{ i.item.video_title }}</div>
                <div class="item_goods_price">￥{{ i.item.price }}</div>
              </div>
              <div class="item_goods_project">
                项目名称：{{ i.item.project_name }}
                <span class="item_video_status">{{
                  videoTypeText(i.item.video_type)
                }}</span>
              </div>
              <div class="item_goods_num">商品数量：{{ i.item.quantity }}</div>
              <div class="item_goods_total">￥{{ i.goods_amount }}</div>
              <div class="item_btn_bg">
                <el-button
                  size="mini"
                  type="primary"
                  @click="lookoverOrder(i.order_sn)"
                  >查看订单</el-button
                >
                <el-button
                  v-if="i.order_status == '0'"
                  size="mini"
                  type="danger"
                  @click="goToPay(i.order_sn)"
                  >重新支付</el-button
                >
                <el-button
                  v-if="i.order_status == '0'"
                  size="mini"
                  type="info"
                  @click="cancelOrderClick(i.order_sn)"
                  >取消订单</el-button
                >
              </div>
            </div>
          </li>
        </ul>
        <p v-if="loading" class="load_footer">加载中...</p>
        <p v-if="noMore" class="load_footer">没有更多了</p>
      </div>
    </div>

    <copy-footer class="footer" />
    <!-- 重新支付二维码 -->
    <el-dialog title="扫码重新支付" v-model="codeDialogFormVisible" center>
      <div class="qrcode" ref="qrCodeUrl"></div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="codeDialogFormVisible = false"
            >完 成</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import CopyFooter from "../components/footer/CopyFooter.vue";
import {
  video_orders,
  video_order_post_re_payment,
  video_post_cancel_order,
} from "../network/views-request";
import QRCode from "qrcodejs2";
export default {
  name: "consult",
  components: { CopyFooter },
  props: {},
  data() {
    return {
      userInfo: {},
      params: {
        index: 0,
        count: 20,
        order_status: "1", //待付款，我的课程，已过期，全部
      },
      videoList: [],
      loading: false,
      allCount: 1000,
      play_count: 0, //点播订单数
      live_count: 0, //直播订单数

      codeDialogFormVisible: false, //重新支付弹窗
      code_url: "", //支付二维码
    };
  },
  computed: {
    noMore() {
      return this.allCount <= this.videoList.length;
    },
    disabled() {
      return this.loading || this.noMore;
    },
    videoCount() {
      return "录播（" + this.play_count + ")";
    },
    liveCount() {
      return "直播（" + this.live_count + ")";
    },
    videoTypeText() {
      return (type) => {
        if (type == "1") {
          return "(录播课程)";
        } else if (type == "2") {
          return "(线上直播)";
        } else if (type == "3") {
          return "(直播回放)";
        }
      };
    },
  },
  created() {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    if (info) {
      this.userInfo = info;
    }
    this.getList();
  },
  methods: {
    //退出登录
    logoutClick() {
      localStorage.clear();
      this.$router.push({ path: "/" });
    },
    //个人中心
    userCenterClick() {
      this.$router.push({ path: "/usercenter" });
    },
    //回主页
    goHome() {
      this.$router.push({ path: "/" });
    },
    headerOptionClick(index) {
      if (index == 0) {
        this.$router.push({ path: "/survey" });
      } else if (index == 1) {
        this.$router.push({ path: "/consult" });
      } else if (index == 2) {
        this.$router.push({ path: "/course" });
      } else if (index == 3) {
        this.$router.push({ path: "/shopping" });
      } else if (index == 4) {
        this.$router.push({ path: "/room" });
      } else if (index == 5) {
        this.$router.push({ path: "/concern" });
      } else {
        this.goHome();
      }
    },
    //点击分类
    handleClick(tab, event) {
      this.params.index = 1;
      this.videoList = [];
      this.getList();
    },
    //查看订单
    lookoverOrder(order_sn) {
      this.$router.push({ path: "/courseorderdetail", query: { order_sn } });
    },
    //订单列表
    getList() {
      this.loading = true;
      let params = {};
      if (this.params.order_status == "1000") {
        params.index = this.params.index;
        params.count = this.params.count;
      } else {
        params = this.params;
      }
      video_orders(params).then((res) => {
        if (res.code == 200) {
          this.allCount = res.data.all_count;
          this.play_count = res.data.play_count;
          this.live_count = res.data.live_count;
          this.videoList = this.videoList.concat(res.data.list);
        } else {
          this.$message.error(res.message);
        }
        this.loading = false;
      });
    },
    load() {
      this.params.index += 1;
      this.getList();
    },
    //点击订单
    consultItemClick(item_id) {
      this.$router.push({ path: "/coursedetail", query: { item_id: item_id } });
    },
    //取消订单
    cancelOrderClick(order_sn) {
      video_post_cancel_order({ order_sn }).then((res) => {
        if (res.code == 200) {
          this.$message.success("取消成功");
          this.params.index = 1;
          this.videoList = [];
          this.getList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //重新支付
    goToPay(order_sn) {
      this.codeDialogFormVisible = true;
      let data = {
        order_sn,
      };
      video_order_post_re_payment(data).then((res) => {
        if (res.code == 200) {
          this.code_url = res.data.code_url;
          this.creatQrCode(res.data.code_url);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //生成二维码
    creatQrCode(code_url) {
      let dom = this.$refs.qrCodeUrl;
      while (dom.firstChild) {
        dom.removeChild(dom.firstChild);
      }
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: code_url, // 需要转换为二维码的内容
        width: 150,
        height: 150,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
  },
};
</script>
<style scoped>
.header {
  position: relative;
}
.header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
}
.header_user_info {
  position: absolute;
  display: flex;
  align-items: center;
  right: 20px;
  top: 15px;
}
.header_user_img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #fff;
  overflow: hidden;
}
.header_user_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header_user_name {
  margin-left: 10px;
  font-size: 15px;
  color: #fff;
}
.header_user_center {
  margin-left: 15px;
  font-size: 15px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.header_user_line {
  margin: 0 15px;
  font-size: 15px;
  color: #fff;
}
.header_user_exit {
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}

.header_option {
  position: absolute;
  right: 0px;
  bottom: 15px;
}
.header_option span {
  padding: 0 20px;
  border-right: 1px solid #fff;
  font-size: 17px;
  flex-wrap: 600;
  color: #fff;
  cursor: pointer;
}
.header_option span:nth-child(6) {
  border-right: 0px solid #fff;
}
.consult_title {
  margin-top: 50px;
  text-align: center;
  font-size: 19px;
  font-weight: 600;
}
.consult_list_item {
  border: 1px solid #ebeef5;
  margin-bottom: 20px;
}
.item_order_sn_bg {
  padding: 10px;
  background-color: rgb(206, 228, 238);
}
.item_order_sn {
  font-size: 15px;
}
.item_order_time {
  margin-left: 20px;
  font-size: 15px;
  color: #646566;
}
.item_status {
  margin-left: 20px;
  font-size: 15px;
  color: rgb(214, 24, 24);
}
.item_avatar_bg {
  margin: 20px 10px;
  display: flex;
  align-items: center;
}
.item_avatar_image {
  width: 80px;
  border-radius: 5px;
  cursor: pointer;
}
.item_goods_info {
  margin-left: 20px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  font-size: 15px;
  color: #646566;
}
.item_goods_info :hover {
  color: rgb(214, 24, 24);
}
.item_goods_project {
  width: 300px;
  font-size: 15px;
  color: #646566;
}
.item_goods_num {
  margin-left: 50px;
  width: 120px;
  font-size: 15px;
}
.item_video_status {
  font-size: 15px;
  color: rgb(214, 24, 24);
}
.item_goods_total {
  flex: 1;
  margin-left: 100px;
  font-size: 17px;
  color: rgb(235, 46, 46);
  text-align: left;
}

.load_footer {
  font-size: 14px;
  text-align: center;
  color: #909399;
}
.qrcode {
  width: 150px;
  margin: 0 auto;
}
.footer {
  margin-top: 100px;
}
</style>