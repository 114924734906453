<template>
  <div class="consultdetail">
    <div class="header">
      <div class="header_bg">
        <img src="../assets/img/header_bg1.png" alt="" />
      </div>
      <div class="header_logo" @click="goHome"></div>
      <div class="header_user_info">
        <div class="header_user_img">
          <img :src="userInfo && userInfo.avatar" />
        </div>
        <div class="header_user_name">HI，{{ userInfo.nickname }}</div>
        <div class="header_user_center" @click="userCenterClick">个人中心</div>
        <div class="header_user_line">|</div>
        <div class="header_user_exit" @click="logoutClick">退出登录</div>
      </div>
      <div class="header_option">
        <span @click="headerOptionClick(-1)">首页</span>
        <span @click="headerOptionClick(0)">量表测评</span>
        <span @click="headerOptionClick(1)">心理咨询</span>
        <span @click="headerOptionClick(2)">名师课程</span>
        <span @click="headerOptionClick(3)">在线商城</span>
        <span @click="headerOptionClick(4)">共享咨询室</span>
        <span @click="headerOptionClick(5)">员工关爱方案</span>
      </div>
    </div>
    <div style="width: 1200px; margin: 0 auto; min-height: 500px">
      <div class="gather_bg">
        <div class="gather_title">初次咨询信息收集表</div>
        <div class="gather_message">
          您还需要回答几个问题，即可完成第一次咨询预约（带*号为必填项）。
        </div>
        <div class="gather_message">
          下列咨询前采集信息仅供咨询人员筹备咨询使用，请您按真实情况填写，以便我们给您更好的安排。
        </div>
        <div class="gather_message">
          心理咨询遵循保密原则，我们承诺为您的私人信息严格保密。
        </div>
        <el-form
          class="form"
          ref="form"
          :model="form"
          label-width="300px"
          label-position="left"
        >
          <el-form-item label="您的称呼" :rules="[{ required: true }]">
            <el-input
              v-model="form.real_name"
              placeholder="请尽量使用真实姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="您的职业" :rules="[{ required: true }]">
            <el-input v-model="form.job" placeholder="输入您的职业"></el-input>
          </el-form-item>
          <el-form-item
            label="您想要咨询的问题类型（可多选）"
            :rules="[{ required: true }]"
          >
            <el-checkbox-group v-model="form.problem_type">
              <el-checkbox label="职场困扰"></el-checkbox>
              <el-checkbox label="情绪困扰"></el-checkbox>
              <el-checkbox label="婚姻恋爱"></el-checkbox>
              <el-checkbox label="家庭困扰"></el-checkbox>
              <el-checkbox label="亲子关系"></el-checkbox>
              <el-checkbox label="儿童青少年"></el-checkbox>
              <el-checkbox label="人际关系"></el-checkbox>
              <el-checkbox label="个人成长"></el-checkbox>
              <el-checkbox label="身心健康"></el-checkbox>
              <el-checkbox label="测评解读"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item
            label="您现在是否有医院心理科或精神科确诊的就诊记录，或历史就诊记录 "
            :rules="[{ required: true }]"
          >
            <el-radio-group v-model="form.whether_go_hospital">
              <el-radio label="1">是</el-radio>
              <el-radio label="2">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="您是否有反复发作的身体疾病？或近期出现身体上的不适?"
            :rules="[{ required: true }]"
          >
            <el-radio-group
              v-model="form.whether_have_recurrent_physical_diseases"
            >
              <el-radio label="1">是</el-radio>
              <el-radio label="2">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="您的家族是否有遗传病史？"
            :rules="[{ required: true }]"
          >
            <el-radio-group v-model="form.whether_have_genetic_history">
              <el-radio label="1">是</el-radio>
              <el-radio label="2">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="之前是否做过心理咨询？"
            :rules="[{ required: true }]"
          >
            <el-radio-group v-model="form.done_consult">
              <el-radio label="1">是</el-radio>
              <el-radio label="2">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="您想让咨询师了解的">
            <el-input
              type="textarea"
              v-model="form.know_information"
              placeholder="如问题起始及持续时间、问题产生的影响、对咨询师的期待等"
            ></el-input>
          </el-form-item>
          <el-form-item label="紧急联系人">
            <el-input
              v-model="form.emergency_contact_name"
              placeholder="输入紧急联系人"
            ></el-input>
          </el-form-item>
          <el-form-item label="紧急联系方式">
            <el-input
              v-model.number="form.emergency_contact_mobile"
              placeholder="输入紧急联系方式"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="submit" @click="postItemOrder">提交表单</div>
    </div>
    <copy-footer class="footer" />
  </div>
</template>
<script>
import CopyFooter from "../components/footer/CopyFooter.vue";
import { consult_information_collection } from "../network/views-request";

export default {
  name: "consultDetail",
  components: { CopyFooter },
  props: {},
  data() {
    return {
      userInfo: {},
      form: {
        real_name: "",
        job: "",
        problem_type: [], //问题类型 多个用,隔开
        whether_go_hospital: "", //是否去医院您现在是否有医院心理科或精神科确诊的就诊记录，或历史就诊记录
        whether_have_recurrent_physical_diseases: "", //是否有反复发作的身体疾病？或近期出现身体上的不适
        whether_have_genetic_history: "", //是否有遗传病史
        done_consult: "", //是否做过心理咨询
        emergency_contact_name: "", //紧急联系人
        emergency_contact_mobile: "", //紧急联系电话
        know_information: "", //咨询师提前知晓的信息
      },
    };
  },
  computed: {},
  created() {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    if (info) {
      this.userInfo = info;
    }
  },
  methods: {
    //退出登录
    logoutClick() {
      localStorage.clear();
      this.$router.push({ path: "/" });
    },
    //个人中心
    userCenterClick() {
      this.$router.push({ path: "/usercenter" });
    },
    //回主页
    goHome() {
      this.$router.push({ path: "/home" });
    },
    headerOptionClick(index) {
      if (index == 0) {
        this.$router.push({ path: "/survey" });
      } else if (index == 1) {
        this.$router.push({ path: "/consult" });
      } else if (index == 2) {
        this.$router.push({ path: "/course" });
      } else if (index == 3) {
        this.$router.push({ path: "/shopping" });
      } else if (index == 4) {
        this.$router.push({ path: "/room" });
      } else if (index == 5) {
        this.$router.push({ path: "/concern" });
      } else {
        this.goHome();
      }
    },

    //提交表单
    postItemOrder() {
      if (this.form.real_name.length == 0) {
        this.$message.error("请填写您的称呼");
        return;
      }
      if (this.form.job.length == 0) {
        this.$message.error("请填写您的职业");
        return;
      }
      if (this.form.problem_type.length == 0) {
        this.$message.error("请选择您想要咨询的问题类型");
        return;
      }
      if (!this.form.whether_have_recurrent_physical_diseases) {
        this.$message.error("是否有反复发作的身体疾病 ");
        return;
      }
      if (!this.form.whether_have_genetic_history) {
        this.$message.error("请选择是否有遗传病史 ");
        return;
      }
      if (!this.form.done_consult) {
        this.$message.error("是否做过心理咨询 ");
        return;
      }
      let form = JSON.parse(JSON.stringify(this.form));
      form.problem_type = form.problem_type.join(",");
      consult_information_collection({
        consult_information_collection: form,
        order_sn: this.$route.query.order_sn,
      }).then((res) => {
        if (res.code == 200) {
          this.$router.push({
            path: "/consultorderdetail",
            query: { order_sn: this.$route.query.order_sn },
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>
<style scoped>
.header {
  position: relative;
}
.header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
}
.header_user_info {
  position: absolute;
  display: flex;
  align-items: center;
  right: 20px;
  top: 15px;
}
.header_user_img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #fff;
  overflow: hidden;
}
.header_user_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header_user_name {
  margin-left: 10px;
  font-size: 15px;
  color: #fff;
}
.header_user_center {
  margin-left: 15px;
  font-size: 15px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.header_user_line {
  margin: 0 15px;
  font-size: 15px;
  color: #fff;
}
.header_user_exit {
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}

.header_option {
  position: absolute;
  right: 0px;
  bottom: 15px;
}
.header_option span {
  padding: 0 20px;
  border-right: 1px solid #fff;
  font-size: 17px;
  flex-wrap: 600;
  color: #fff;
  cursor: pointer;
}
.header_option span:nth-child(6) {
  border-right: 0px solid #fff;
}
.title {
  margin-top: 30px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}
.consult_info {
  margin-top: 40px;
  padding: 20px;
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
}
.avatar_image {
  width: 100%;
  height: 180px;
  width: 180px;
  vertical-align: top;
}
.name_info {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: #707070;
}
.consult_price {
  padding: 20px;
  text-align: center;
  font-size: 20px;
  color: rgb(235, 31, 31);
  background-color: #f5f5f5;
}
.consult_price_title {
  color: #707070;
  font-size: 16px;
  font-weight: 600;
}
.submit {
  margin: 50px auto;
  width: 150px;
  height: 40px;
  font-size: 15px;
  line-height: 40px;
  text-align: center;
  border-radius: 3px;
  color: #fff;
  background-color: var(--color-main);
}
.gather_bg {
  margin-top: 20px;
  background-color: #f5f5f5;
  padding: 15px;
}
.gather_title {
  font-size: 17px;
  text-align: center;
  font-weight: 600;
  color: rgb(235, 31, 31);
}
.gather_message {
  font-size: 14px;
  text-align: center;
  color: rgb(235, 31, 31);
}

.form {
  margin: 20px auto;
  width: 600px;
}
.qrcode {
  width: 150px;
  margin: 0 auto;
}
.pay_way {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
}
.pay_way :nth-child(3n) {
  margin-right: 0px;
}
.pay_item {
  margin-bottom: 20px;
  margin-right: 60px;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  background-color: #f5f5f5;
  width: 360px;
  cursor: pointer;
  opacity: 1;
}
.pay_disable {
  cursor: not-allowed;
  opacity: 0.4;
}
.pay_select {
  margin-left: 15px;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  border: 1px solid #aaaaaa;
  background-color: #fff;
}
.pay_select_on {
  background-color: var(--color-main);
}
.pay_icon {
  margin-left: 15px;
  margin-right: 15px;
  width: 35px;
  height: 35px;
}
.pay_way_title_wp {
  display: flex;
  flex-direction: column;
}
.pay_way_title {
  font-size: 15px;
}
.pay_way_balance {
  font-size: 14px;
  color: #ef0c26;
}
.footer {
  margin-top: 100px;
}
:deep(.el-radio__input.is-checked .el-radio__inner) {
  background-color: var(--color-main);
  border-color: var(--color-main);
}
:deep(.el-radio__input.is-checked + .el-radio__label) {
  color: var(--color-main);
}
:deep(.el-checkbox__input.is-checked
    .el-checkbox__inner, .el-checkbox__input.is-indeterminate
    .el-checkbox__inner) {
  background-color: var(--color-main);
  border-color: var(--color-main);
}
:deep(.el-checkbox__input.is-checked + .el-checkbox__label) {
  color: var(--color-main);
}
</style>