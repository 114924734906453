<template>
  <div class="consult">
    <div class="header">
      <div class="header_bg">
        <img src="../assets/img/header_bg1.png" alt="" />
      </div>
      <div class="header_logo" @click="goHome"></div>
      <div class="header_user_info">
        <div class="header_user_img">
          <img :src="userInfo.avatar" />
        </div>
        <div class="header_user_name">HI，{{ userInfo.nickname }}</div>
        <div class="header_user_center" @click="userCenterClick">个人中心</div>
        <div class="header_user_line">|</div>
        <div class="header_user_exit" @click="logoutClick">退出登录</div>
      </div>
      <div class="header_option">
        <span @click="headerOptionClick(-1)">首页</span>
        <span @click="headerOptionClick(0)">量表测评</span>
        <span @click="headerOptionClick(1)">心理咨询</span>
        <span @click="headerOptionClick(2)">名师课程</span>
        <span @click="headerOptionClick(3)">在线商城</span>
        <span @click="headerOptionClick(4)">共享咨询室</span>
        <span @click="headerOptionClick(5)">员工关爱方案</span>
      </div>
    </div>
    <div class="consult_title">{{ projectDetail.project_name }} 项目明细</div>

    <div style="width: 1200px; margin: 0 auto">
      <div
        class="pro_bg"
        v-if="
          projectDetail.group_survey_gauges &&
          projectDetail.group_survey_gauges.length > 0
        "
      >
        <div class="project_title">量表评测</div>
        <el-table
          :data="projectDetail.group_survey_gauges"
          border
          style="width: 100%"
        >
          <el-table-column prop="goods_name" label="名称"> </el-table-column>
          <el-table-column fixed="right" label="操作" width="200">
            <template #default="scope">
              <el-button @click="reviewClick(scope.row)" type="text">{{
                reviewString(scope.row.assess_able)
              }}</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div
        class="pro_bg"
        v-if="
          projectDetail.group_survey_videos &&
          projectDetail.group_survey_videos.length > 0
        "
      >
        <div class="project_title">心理课程</div>
        <el-table
          :data="projectDetail.group_survey_videos"
          border
          style="width: 100%"
        >
          <el-table-column prop="video_title" label="名称"> </el-table-column>
          <el-table-column fixed="right" label="操作" width="200">
            <template #default="scope">
              <el-button @click="livePlayClick(scope.row)" type="text"
                >播放观看</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pro_bg" v-if="mentality > 0">
        <div class="project_title">心理咨询</div>
        <el-table :data="mentality" border style="width: 100%">
          <el-table-column prop="name" label="次数"> </el-table-column>
          <el-table-column fixed="right" label="操作" width="200">
            <template #default="scope">
              <el-button @click="mentalityItemClick(scope.row)" type="text"
                >预约咨询</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pro_bg">
        <div class="project_title">咨询列表</div>
        <div>
          <ul class="consult_list">
            <li
              class="consult_list_item"
              v-for="(i, index) in projectDetail.group_survey_consultants"
              :key="i.id"
              @click="consultItemClick(i.id)"
            >
              <div class="consult_list_item_top">
                <div class="consult_list_item_left">
                  <el-image
                    class="consult_list_item_img"
                    :src="i.user.avatar"
                    fit="cover"
                  ></el-image>
                  <div class="consult_list_item_btn">预约咨询</div>
                </div>
                <div class="consult_list_item_right">
                  <div class="consult_list_item_name_w">
                    <div class="consult_list_item_name">
                      {{ i.consultant_name }}
                    </div>
                    <div
                      class="consult_list_item_att"
                      @click.stop="fllowClick(i, index)"
                    >
                      {{ isFollow(i.follow_status) }}
                    </div>
                  </div>

                  <div class="consult_list_item_introduce">
                    <div>{{ i.consultant_title }}</div>
                    <div>{{ i.consultant_message }}</div>
                  </div>
                  <div class="consult_list_item_price">
                    咨询费用：¥{{
                      i.consult_price
                    }}&nbsp;&nbsp;&nbsp;&nbsp;50分钟
                  </div>
                </div>
              </div>
              <div class="consult_list_item_bottom">
                <div class="consult_list_item_strong_title">擅长类别：</div>
                <div class="consult_list_item_strong_detail">
                  {{ i.adept_category.toString() }}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <copy-footer class="footer" />
  </div>
</template>
<script>
import CopyFooter from "../components/footer/CopyFooter.vue";
import {
  project_detail,
  consultant_follow,
  consultant_unfollow,
} from "../network/views-request";

export default {
  name: "consult",
  components: { CopyFooter },
  props: {},
  data() {
    return {
      userInfo: {},
      projectDetail: {},
      mentality: [], //心理咨询
      stationed: [], //驻场
    };
  },
  computed: {
    isFollow() {
      return (status) => {
        return status ? "已关注" : "+关注专家";
      };
    },
    reviewString() {
      return (isReview) => {
        return isReview ? "开始评测" : "查看报告";
      };
    },
    adeptString() {
      if (this.params.adept_category == "0") {
        return "擅长类别";
      } else if (this.params.adept_category == "1") {
        return "情感";
      } else if (this.params.adept_category == "2") {
        return "青少年";
      } else if (this.params.adept_category == "3") {
        return "焦虑抑郁";
      } else if (this.params.adept_category == "4") {
        return "婚姻";
      } else if (this.params.adept_category == "5") {
        return "职场";
      }
    },
  },
  created() {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    if (info) {
      this.userInfo = info;
    }
    this.getProjectDetail();
  },
  methods: {
    //退出登录
    logoutClick() {
      localStorage.clear();
      this.$router.push({ path: "/" });
    },
    //个人中心
    userCenterClick() {
      this.$router.push({ path: "/usercenter" });
    },
    //回主页
    goHome() {
      this.$router.push({ path: "/" });
    },
    headerOptionClick(index) {
      if (index == 0) {
        this.$router.push({ path: "/survey" });
      } else if (index == 1) {
        this.$router.push({ path: "/consult" });
      } else if (index == 2) {
        this.$router.push({ path: "/course" });
      } else if (index == 3) {
        this.$router.push({ path: "/shopping" });
      } else if (index == 4) {
        this.$router.push({ path: "/room" });
      } else if (index == 5) {
        this.$router.push({ path: "/concern" });
      } else {
        this.goHome();
      }
    },
    //开始评测
    reviewClick(item) {
      if (item.assess_able) {
        this.$router.push({
          path: "/surveyreview",
          query: { order_sn: item.order_sn },
        });
      }
      if (item.report_able) {
        this.$router.push({
          path: "/surveyreport",
          query: { order_sn: item.order_sn },
        });
      }
    },
    //获取项目列表
    getProjectDetail() {
      project_detail({ project_id: this.$route.query.project_id }).then(
        (res) => {
          if (res.code == 200) {
            this.projectDetail = res.data;
            let mentalityItem = {
              name: res.data.single_counseling_max,
            };
            this.mentality.push(mentalityItem);
          } else {
            this.$message.error(res.message);
          }
        }
      );
    },
    //关注
    fllowClick(item, index) {
      console.log(item);
      if (item.follow_status) {
        this.$confirm("确定要取消关注该专家吗?", "提示", {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning",
        }).then(() => {
          this.gounFllows(item, index);
        });
      } else {
        this.goFllows(item, index);
      }
    },

    //关注
    goFllows(item, index) {
      consultant_follow({
        consultant_id: item.id,
      }).then((res) => {
        if (res.code == 200) {
          let consulter = this.projectDetail.group_survey_consultants[index];
          consulter.follow_status = 1;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //取消关注
    gounFllows(item, index) {
      consultant_unfollow({
        consultant_id: item.id,
      }).then((res) => {
        if (res.code == 200) {
          let consulter = this.projectDetail.group_survey_consultants[index];
          consulter.follow_status = 0;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //点击心理咨询
    mentalityItemClick() {
      this.$router.push({ path: "/consult" });
    },
    //点击咨询师
    consultItemClick(id) {
      this.$router.push({ path: "/consultdetail", query: { id } });
    },
    //点击播放
    livePlayClick(item) {
      this.$router.push({
        path: "/coursedetail",
        query: { item_id: item.item_id },
      });
    },
  },
};
</script>
<style scoped>
.header {
  position: relative;
}
.header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
}
.header_user_info {
  position: absolute;
  display: flex;
  align-items: center;
  right: 20px;
  top: 15px;
}
.header_user_img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #fff;
  overflow: hidden;
}
.header_user_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header_user_name {
  margin-left: 10px;
  font-size: 15px;
  color: #fff;
}
.header_user_center {
  margin-left: 15px;
  font-size: 15px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.header_user_line {
  margin: 0 15px;
  font-size: 15px;
  color: #fff;
}
.header_user_exit {
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}

.header_option {
  position: absolute;
  right: 0px;
  bottom: 15px;
}
.header_option span {
  padding: 0 20px;
  border-right: 1px solid #fff;
  font-size: 17px;
  flex-wrap: 600;
  color: #fff;
  cursor: pointer;
}
.header_option span:nth-child(6) {
  border-right: 0px solid #fff;
}
.consult_title {
  margin-top: 30px;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
}
.table {
  margin-top: 40px;
}
.project_title {
  margin: 0px auto;
  padding-bottom: 10px;
  font-size: 17px;
  text-align: center;
  color: var(--color-main);
}
.pro_bg {
  margin-top: 20px;
  padding: 10px;
  background-color: #f5f5f5;
}
.consult_list {
  margin-top: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #fff;
}
.consult_list_item {
  margin: 30px;
  padding: 30px;
  width: 520px;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #cecece;
}
.consult_list_item_top {
  display: flex;
  padding-bottom: 20px;
  border-bottom: 1px dashed var(--color-main);
}
.consult_list_item_left {
  display: flex;
  flex-direction: column;
}
.consult_list_item_img {
  width: 250px;
  height: 200px;
  border: 1px solid #d6d6d6;
  border-bottom: 0px solid #000;
}
.consult_list_item_btn {
  padding: 5px 0;
  background-color: var(--color-main);
  font-size: 15px;
  color: #fff;
  text-align: center;
}
.consult_list_item_right {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}
.consult_list_item_name_w {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
.consult_list_item_name {
  font-size: 19px;
  color: #000;
}
.consult_list_item_att {
  font-size: 15px;
  font-weight: 500;
  color: var(--color-main);
  cursor: pointer;
}
.consult_list_item_introduce {
  margin-top: 10px;
  height: 150px;
  font-size: 13px;
  color: #606266;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8;
  overflow: hidden;
}
.consult_list_item_price {
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 14px;
  color: #ce5b39;
}

.consult_list_item_bottom {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}
.consult_list_item_strong_title {
  font-size: 17px;
  font-weight: 600;
}
.consult_list_item_strong_detail {
  margin-top: 10px;
  font-size: 14px;
  color: #606266;
}
.footer {
  margin-top: 100px;
}
</style>