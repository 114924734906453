<template>
  <div class="consult">
    <div class="header">
      <div class="header_bg">
        <img src="../assets/img/header_bg1.png" alt="" />
      </div>
      <div class="header_logo" @click="goHome"></div>
      <div class="header_user_info">
        <div class="header_user_img">
          <img :src="userInfo.avatar" />
        </div>
        <div class="header_user_name">HI，{{ userInfo.nickname }}</div>
        <div class="header_user_center" @click="userCenterClick">个人中心</div>
        <div class="header_user_line">|</div>
        <div class="header_user_exit" @click="logoutClick">退出登录</div>
      </div>
      <div class="header_option">
        <span @click="headerOptionClick(-1)">首页</span>
        <span @click="headerOptionClick(0)">量表测评</span>
        <span @click="headerOptionClick(1)">心理咨询</span>
        <span @click="headerOptionClick(2)">名师课程</span>
        <span @click="headerOptionClick(3)">在线商城</span>
        <span @click="headerOptionClick(4)">共享咨询室</span>
        <span @click="headerOptionClick(5)">员工关爱方案</span>
      </div>
    </div>
    <div style="width: 1200px; margin: 50px auto; min-height: 500px">
      <el-tabs v-model="params.status" type="card" @tab-click="handleClick">
        <el-tab-pane label="未使用" name="1"></el-tab-pane>
        <el-tab-pane label="已失效" name="2"></el-tab-pane>
      </el-tabs>
      <el-tag
        style="margin-right: 10px"
        :type="params.type == null ? '' : 'info'"
        @click="couponTypeClick(null)"
        >全部({{ allCount }})</el-tag
      >
      <el-tag
        style="margin-right: 10px"
        :type="params.type == 4 ? '' : 'info'"
        @click="couponTypeClick(4)"
        >兑换券({{ coupon_type_1_count }})</el-tag
      >
      <el-tag
        style="margin-right: 10px"
        :type="params.type == 5 ? '' : 'info'"
        @click="couponTypeClick(5)"
        >折扣券({{ coupon_type_2_count }})</el-tag
      >
      <el-tag
        style="margin-right: 10px"
        :type="params.type == 6 ? '' : 'info'"
        @click="couponTypeClick(6)"
        >代金券({{ coupon_type_3_count }})</el-tag
      >
      <div>
        <ul class="coupon_list">
          <li
            class="coupon_list_item"
            v-for="i in cunponsList"
            :key="i.coupon_id"
          >
            <div class="coupon_list_item_wrap4" v-if="i.type == 4">
              <div class="coupon_scope">
                {{ i.coupon_type == "1" ? "全品类" : "部分商品适用" }}
              </div>
              <div class="coupon_monry_wrap">
                <div class="coupon_money4">兑换券</div>
                <div class="coupon_all_money">无门槛</div>
              </div>
              <div class="coupon_time_wrap">
                <div class="coupon_time_text">{{ i.coupon_title }}</div>
                <div class="coupon_time_date4">
                  有效期至{{ i.coupon_end_time }}
                </div>
              </div>
              <div class="coupon_use_wrap" @click="gotoUseClick(i.coupon_id)">
                去使用
              </div>
            </div>
            <div class="coupon_list_item_wrap5" v-if="i.type == 5">
              <div class="coupon_scope">
                {{ i.coupon_type == "1" ? "全品类" : "部分商品适用" }}
              </div>
              <div class="coupon_monry_wrap">
                <div class="coupon_money">
                  {{ i.coupon_discount_rate / 10
                  }}<span class="coupon_money_symbol">折</span>
                </div>
                <div class="coupon_all_money">无门槛</div>
              </div>
              <div class="coupon_time_wrap">
                <div class="coupon_time_text">{{ i.coupon_title }}</div>
                <div class="coupon_time_date5">
                  有效期至{{ i.coupon_end_time }}
                </div>
              </div>
              <div class="coupon_use_wrap" @click="gotoUseClick(i.coupon_id)">
                去使用
              </div>
            </div>
            <div class="coupon_list_item_wrap6" v-if="i.type == 6">
              <div class="coupon_scope">
                {{ i.coupon_type == "1" ? "全品类" : "部分商品适用" }}
              </div>
              <div class="coupon_monry_wrap">
                <div class="coupon_money">
                  <span class="coupon_money_symbol">￥</span
                  >{{ i.coupon_discount }}
                </div>
                <div class="coupon_all_money">
                  满{{ i.coupon_min_order_amount }}可用
                </div>
              </div>
              <div class="coupon_time_wrap">
                <div class="coupon_time_text">{{ i.coupon_title }}</div>
                <div class="coupon_time_date6">
                  有效期至{{ i.coupon_end_time }}
                </div>
              </div>
              <div class="coupon_use_wrap" @click="gotoUseClick(i.coupon_id)">
                去使用
              </div>
            </div>
          </li>
        </ul>
        <p v-if="loading" class="load_footer">加载中...</p>
        <p v-if="noMore" class="load_footer">没有更多了</p>
      </div>
    </div>
    <copy-footer class="footer" />
  </div>
</template>
<script>
import CopyFooter from "../components/footer/CopyFooter.vue";
import { users_coupons, users_coupon_items } from "../network/views-request";
export default {
  name: "consult",
  components: { CopyFooter },
  props: {},
  data() {
    return {
      userInfo: {},
      params: {
        index: 0,
        count: 20,
        status: "1", //位置 1：未使用 2：已失效
        type: null, //4:兑换券 5:折扣券 6:代金券 不传表示全部
      },
      cunponsList: [],
      loading: false,
      allCount: 0,
      coupon_type_1_count: 0, //兑换券张数
      coupon_type_2_count: 0, //折扣券张数
      coupon_type_3_count: 0, //代金券张数
    };
  },
  computed: {
    noMore() {
      return this.allCount <= this.cunponsList.length;
    },
    disabled() {
      return this.loading || this.noMore;
    },
    coupon0Count() {
      return "全部（" + this.allCount + ")";
    },
    coupon1Count() {
      return "兑换券（" + this.coupon_type_1_count + ")";
    },
    coupon2Count() {
      return "折扣券（" + this.coupon_type_2_count + ")";
    },
    coupon2Count() {
      return "代金券（" + this.coupon_type_3_count + ")";
    },
  },
  created() {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    if (info) {
      this.userInfo = info;
      this.getList();
    }
  },
  methods: {
    //退出登录
    logoutClick() {
      localStorage.clear();
      this.$router.push({ path: "/" });
    },
    //个人中心
    userCenterClick() {
      this.$router.push({ path: "/usercenter" });
    },
    //回主页
    goHome() {
      this.$router.push({ path: "/" });
    },
    headerOptionClick(index) {
      if (index == 0) {
        this.$router.push({ path: "/survey" });
      } else if (index == 1) {
        this.$router.push({ path: "/consult" });
      } else if (index == 2) {
        this.$router.push({ path: "/course" });
      } else if (index == 3) {
        this.$router.push({ path: "/shopping" });
      } else if (index == 4) {
        this.$router.push({ path: "/room" });
      } else if (index == 5) {
        this.$router.push({ path: "/concern" });
      } else {
        this.goHome();
      }
    },
    //点击分类
    handleClick(tab, event) {
      this.params.index = 1;
      this.params.type = null;
      this.cunponsList = [];
      this.getList();
    },
    //点击兑换券种类
    couponTypeClick(type) {
      this.params.type = type;
      this.cunponsList = [];
      this.getList();
    },
    //查看订单
    lookoverOrder(order_sn) {
      this.$router.push({ path: "/surveyorderdetail", query: { order_sn } });
    },
    //
    gotoUseClick(coupon_id) {
      this.$router.push({ path: "/coupongoods", query: { coupon_id } });
    },
    //优惠券列表
    getList() {
      this.loading = true;
      if (this.params.type == null) {
        delete this.params.type;
      }
      users_coupons(this.params).then((res) => {
        if (res.code == 200) {
          this.allCount = res.data.all_count;
          this.coupon_type_1_count = res.data.coupon_type_1_count;
          this.coupon_type_2_count = res.data.coupon_type_2_count;
          this.coupon_type_3_count = res.data.coupon_type_3_count;
          this.cunponsList = this.cunponsList.concat(res.data.list);
        } else {
          this.$message.error(res.message);
        }
        this.loading = false;
      });
    },
  },
};
</script>
<style scoped>
.header {
  position: relative;
}
.header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
}
.header_user_info {
  position: absolute;
  display: flex;
  align-items: center;
  right: 20px;
  top: 15px;
}
.header_user_img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #fff;
  overflow: hidden;
}
.header_user_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header_user_name {
  margin-left: 10px;
  font-size: 15px;
  color: #fff;
}
.header_user_center {
  margin-left: 15px;
  font-size: 15px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.header_user_line {
  margin: 0 15px;
  font-size: 15px;
  color: #fff;
}
.header_user_exit {
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}

.header_option {
  position: absolute;
  right: 0px;
  bottom: 15px;
}
.header_option span {
  padding: 0 20px;
  border-right: 1px solid #fff;
  font-size: 17px;
  flex-wrap: 600;
  color: #fff;
  cursor: pointer;
}
.header_option span:nth-child(6) {
  border-right: 0px solid #fff;
}
.consult_title {
  margin-top: 50px;
  text-align: center;
  font-size: 19px;
  font-weight: 600;
}
.coupon_list {
  display: flex;
  flex-wrap: wrap;
}
.coupon_list_item {
  margin: 20px;
  border-radius: 15px;
  overflow: hidden;
}
.coupon_list_item_wrap6 {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 360px;
  height: 100px;
  background-color: rgb(238, 189, 128);
}
.coupon_list_item_wrap5 {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 360px;
  height: 100px;
  background-color: rgb(150, 195, 245);
}
.coupon_list_item_wrap4 {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 360px;
  height: 100px;
  background-color: rgb(152, 243, 228);
}
.coupon_scope {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 10px;
  font-size: 10px;
  color: #fff;
  background-color: var(--color-main);
}
.coupon_monry_wrap {
  margin: 0 30px 0 10px;
}
.coupon_money_symbol {
  font-size: 15px;
  font-weight: bold;
  color: rgb(240, 28, 28);
}
.coupon_money4 {
  font-size: 10px;
  font-weight: bold;
  color: rgb(240, 28, 28);
}
.coupon_money {
  font-size: 30px;
  font-weight: bold;
  color: rgb(240, 28, 28);
}
.coupon_all_money {
  font-size: 10px;
  color: rgb(240, 28, 28);
}
.coupon_time_wrap {
  flex: 1;
}
.coupon_time_text {
  font-size: 15px;
  font-weight: bold;
  color: #232323;
}
.coupon_time_date4 {
  font-size: 12px;
  color: rgb(36, 156, 142);
}
.coupon_time_date5 {
  font-size: 12px;
  color: rgb(102, 131, 223);
}
.coupon_time_date6 {
  font-size: 12px;
  color: rgb(171, 105, 25);
}
.coupon_use_wrap {
  margin-right: 10px;
  width: 60px;
  height: 30px;
  line-height: 30px;
  border-radius: 20px;
  font-size: 10px;
  text-align: center;
  background-color: rgb(242, 48, 48);
  color: #fff;
}

.load_footer {
  font-size: 14px;
  text-align: center;
  color: #909399;
}
.qrcode {
  width: 150px;
  margin: 0 auto;
}
.footer {
  margin-top: 100px;
}
</style>