<template>
  <div class="consult">
    <div class="header">
      <div class="header_bg">
        <img src="../assets/img/header_bg1.png" alt="" />
      </div>
      <div class="header_logo" @click="goHome"></div>
      <div class="header_user_info">
        <div class="header_user_img">
          <img :src="userInfo.avatar" />
        </div>
        <div class="header_user_name">HI，{{ userInfo.nickname }}</div>
        <div class="header_user_center" @click="userCenterClick">个人中心</div>
        <div class="header_user_line">|</div>
        <div class="header_user_exit" @click="logoutClick">退出登录</div>
      </div>
      <div class="header_option">
        <span @click="headerOptionClick(-1)">首页</span>
        <span @click="headerOptionClick(0)">量表测评</span>
        <span @click="headerOptionClick(1)">心理咨询</span>
        <span @click="headerOptionClick(2)">名师课程</span>
        <span @click="headerOptionClick(3)">在线商城</span>
        <span @click="headerOptionClick(4)">共享咨询室</span>
        <span @click="headerOptionClick(5)">员工关爱方案</span>
      </div>
    </div>

    <div style="width: 1200px; margin: 0 auto">
      <div
        v-if="
          !(
            accreditInfo.accredit_consult_order &&
            accreditInfo.accredit_consult_order.order_sn
          )
        "
      >
        <div class="accredit_info">
          <div class="accredit_title">
            驻场主题：
            <span class="accredit_content">
              {{ accreditInfo.accredit_subject }}
            </span>
          </div>

          <div class="accredit_title">
            咨询地点：
            <span class="accredit_content">
              {{ accreditInfo.accredit_locale }}
            </span>
          </div>

          <div class="accredit_title">
            驻场时间：
            <span class="accredit_content">
              {{ accreditInfo.accredit_date }}
            </span>
          </div>

          <div class="accredit_title">
            截止预约：
            <span class="accredit_content">
              {{ accreditInfo.subscribe_end_date }}
            </span>
          </div>

          <div class="accredit_title">
            活动介绍：
            <span class="accredit_content">
              {{ accreditInfo.accredit_desc }}
            </span>
          </div>

          <div class="accredit_title">
            注意事项：
            <span class="accredit_content">
              选择下方咨询师以及要预约的时间，截止日期前可随时取消订单，截止日期后无法取消订单。
            </span>
            <span class="accredit_content"
              >驻场咨询当天，请提前10分钟到场。</span
            >
          </div>
        </div>
        <el-divider class="divider" content-position="center"
          >驻场咨询师</el-divider
        >
        <div
          class="consult_item"
          v-for="item in consultList"
          :key="item.consultant_name"
        >
          <div class="consult_info">
            <div class="avatar_wp">
              <el-image
                class="avatar_image"
                :src="item.user && item.user.avatar"
                fit="cover"
              ></el-image>
            </div>
            <div class="name_info">
              <div class="name_wp">
                <div class="name">
                  {{ item.consultant_name }}
                </div>
              </div>
              <div class="introduce">
                {{ item.professional_certificate }}
              </div>
            </div>
          </div>
          <div v-show="item.isUnfold" class="show_content">
            <div class="show_content_title">咨询师寄语</div>
            <div class="show_content_desc">
              {{ item.consultant_message }}
            </div>
            <div class="show_content_title">擅长领域</div>
            <div
              class="show_content_desc"
              v-for="adept in item.adept_category"
              :key="adept.name"
            >
              【{{ adept.name }}】 {{ adept.children }}
            </div>

            <div class="show_content_title">从业资质</div>
            <div class="show_content_desc">
              {{ item.professional_certificate }}
            </div>
          </div>
          <div class="unfold" @click="unfoldClick(item)">
            {{ item.isUnfold ? "-收起更多专家介绍" : "+展开更多专家介绍" }}
          </div>
          <div class="appointment_wp">
            <div class="appointment_price">
              <span class="appointment_can_dot">●</span>
              <span class="appointment_can">可预约</span>
              <span class="appointment_no_dot">●</span>
              <span class="appointment_no">不可预约</span>
            </div>
          </div>
          <div class="appointment_tab_online">
            <div
              class="appointment_item_list_item"
              v-for="item1 in item.appointment_times"
              :key="item1.consult_start_time"
              :class="
                item1.status == '0'
                  ? 'appointment_item_can'
                  : 'appointment_item_not'
              "
              @click="appointmentClick(item, item1)"
            >
              {{ item1.start_time }}-{{ item1.end_time }}
            </div>
          </div>
        </div>
      </div>
      <div class="consult_success" v-else>
        <div class="consult_success_title">预约成功</div>
        <div class="consult_success_avatar_bg">
          <el-image
            class="consult_success_avatar_image"
            :src="accreditInfo.accredit_consult_order.consultant.user.avatar"
            fit="cover"
          ></el-image>
          <div class="consult_success_text_bg">
            <div>预约类型：驻场预约</div>
            <div>咨询地点：{{ accreditInfo.accredit_locale }}</div>
            <div>
              预约专家：{{
                accreditInfo.accredit_consult_order.consultant.consultant_name
              }}
            </div>
            <div>
              预约时间：{{
                accreditInfo.accredit_consult_order.consult_start_time
              }}
              - {{ accreditInfo.accredit_consult_order.consult_end_time }}
            </div>
            <div>
              预约时长：{{ accreditInfo.accredit_consult_order.duration }}
            </div>
          </div>
        </div>
        <el-divider></el-divider>

        <div class="consult_success_message">
          <div>温馨提示</div>
          <div class="consult_success_message_text">
            您的预约已成功，请在驻场当天提前10分钟到场
          </div>
          <div class="consult_success_message_text">
            您的预约信息可在【我的咨询】里查看，如果遇到任何问题请点击屏幕右侧联系客服
          </div>
          <div style="margin-top: 20px">
            <el-button
              style="width: 120px"
              type="primary"
              @click="lookoverClick"
              >查看咨询订单</el-button
            >
            <el-button
              style="margin-left: 20px; width: 120px"
              type="primary"
              @click="cancelConsultClick"
              >取消咨询</el-button
            >
          </div>
          <el-divider></el-divider>

          <el-steps style="margin-top: 50px" :space="250" :active="3">
            <el-step title="预约成功"></el-step>
            <el-step title="驻场当天完成咨询服务"></el-step>
            <el-step title="提交咨询反馈"></el-step>
          </el-steps>
        </div>
      </div>
    </div>
    <copy-footer class="footer" />
    <!-- 取消预约弹窗 -->
    <el-dialog title="取消咨询" v-model="cancelDialogFormVisible">
      <el-form :model="cancelForm" style="padding: 15px">
        <el-form-item label="取消咨询原因">
          <el-input
            v-model="cancelForm.consult_cancel_reason"
            placeholder="请输入取消原因"
            autocomplete="off"
            type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancelDialogFormVisible = false">否</el-button>
          <el-button type="primary" @click="cancelDialogFormVisibleClick"
            >是</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import CopyFooter from "../components/footer/CopyFooter.vue";
import {
  accredit_consult_activity_detail,
  accredit_consult_activity_consultant_list,
  accredit_consult_order_post_cancel_order,
} from "../network/views-request";

export default {
  name: "consult",
  components: { CopyFooter },
  props: {},
  data() {
    return {
      userInfo: {},
      accreditInfo: {
        accredit_subject: "",
        accredit_locale: "",
        accredit_date: "",
        subscribe_end_date: "",
        accredit_desc: "",
      },
      cancelDialogFormVisible: false, //取消咨询弹窗
      cancelForm: {
        //取消咨询表单
        consult_cancel_reason: "",
      },
      consultList: [], //咨询师列表
    };
  },
  computed: {},
  created() {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    if (info) {
      this.userInfo = info;
    }
    this.getAccreditInfo();
    this.getConsultantList();
  },
  methods: {
    //退出登录
    logoutClick() {
      localStorage.clear();
      this.$router.push({ path: "/" });
    },
    //个人中心
    userCenterClick() {
      this.$router.push({ path: "/usercenter" });
    },
    //回主页
    goHome() {
      this.$router.push({ path: "/" });
    },
    headerOptionClick(index) {
      if (index == 0) {
        this.$router.push({ path: "/survey" });
      } else if (index == 1) {
        this.$router.push({ path: "/consult" });
      } else if (index == 2) {
        this.$router.push({ path: "/course" });
      } else if (index == 3) {
        this.$router.push({ path: "/shopping" });
      } else if (index == 4) {
        this.$router.push({ path: "/room" });
      } else if (index == 5) {
        this.$router.push({ path: "/concern" });
      } else {
        this.goHome();
      }
    },

    //点击展开更多
    unfoldClick(item) {
      item.isUnfold = !item.isUnfold;
    },
    //点击预约
    appointmentClick(item, item1) {
      if (item1.status == "0") {
        let info = {};
        info.consultant_id = item.consultant_id;
        info.consult_start_time = item1.consult_start_time;
        info.consult_end_time = item1.consult_end_time;
        info.accredit_id = this.accreditInfo.accredit_id;
        //可预约
        this.$router.push({
          path: "/consultaccreditplaceorder",
          query: info,
        });
      } else {
        this.$message.error("该时间段不可预约");
      }
    },
    //查看订单
    lookoverClick() {
      const order_sn = this.accreditInfo.accredit_consult_order.order_sn;
      this.$router.push({
        path: "/consultaccreditorderdetail",
        query: { order_sn },
      });
    },
    //取消咨询
    cancelConsultClick() {
      this.cancelDialogFormVisible = true;
    },
    //取消咨询确认
    cancelDialogFormVisibleClick() {
      this.cancelDialogFormVisible = false;

      if (this.cancelForm.consult_cancel_reason.length == 0) {
        this.$message.error("请输入取消原因");
        return;
      }
      let data = {
        order_sn: this.accreditInfo.accredit_consult_order.order_sn,
        consult_cancel_reason: this.cancelForm.consult_cancel_reason,
      };
      accredit_consult_order_post_cancel_order(data).then((res) => {
        if (res.code == 200) {
          this.cancelForm.consult_cancel_reason = "";
          this.$message.success("取消成功");
          this.getAccreditInfo();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //驻场详情
    getAccreditInfo() {
      accredit_consult_activity_detail({
        accredit_id: this.$route.query.accredit_id,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.accreditInfo = res.data;
          console.log(this.accreditInfo);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //驻场咨询师列表
    getConsultantList() {
      accredit_consult_activity_consultant_list({
        accredit_id: this.$route.query.accredit_id,
      }).then((res) => {
        if (res.code == 200) {
          this.consultList = res.data.list;
          //报名满后,提示约满
          for (let i = 0; i < this.consultList.length; i++) {
            let item = this.consultList[i];
            for (let j = 0; j < item.appointment_times.length; j++) {
              let item1 = item.appointment_times[j];
              if (
                i == this.consultList.length - 1 &&
                j == item.appointment_times.length - 1 &&
                item1.status == 0
              ) {
                this.$message({
                  message: "可选时间已约满，如需候补请联系分诊台",
                  duration: 5000,
                  type: "error",
                });
              } else {
                return;
              }
            }
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>
<style scoped>
.header {
  position: relative;
}
.header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
}
.header_user_info {
  position: absolute;
  display: flex;
  align-items: center;
  right: 20px;
  top: 15px;
}
.header_user_img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #fff;
  overflow: hidden;
}
.header_user_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header_user_name {
  margin-left: 10px;
  font-size: 15px;
  color: #fff;
}
.header_user_center {
  margin-left: 15px;
  font-size: 15px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.header_user_line {
  margin: 0 15px;
  font-size: 15px;
  color: #fff;
}
.header_user_exit {
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}

.header_option {
  position: absolute;
  right: 0px;
  bottom: 15px;
}
.header_option span {
  padding: 0 20px;
  border-right: 1px solid #fff;
  font-size: 17px;
  flex-wrap: 600;
  color: #fff;
  cursor: pointer;
}
.header_option span:nth-child(6) {
  border-right: 0px solid #fff;
}
.accredit_info {
  margin-top: 30px;
  padding: 10px 10px 20px 10px;
  background-color: #f5f5f5;
}
.accredit_title {
  margin-top: 20px;
  font-size: 17px;
  font-weight: 700;
  color: var(--color-main);
}
.accredit_content {
  font-size: 15px;
  color: #232323;
}
.divider {
  margin: 50px 0;
}
.consult_item {
  margin: 20px 0px;
  padding: 20px;
  border: 1px solid #bebebe;
}
.consult_info {
  margin-top: 50px;
  display: flex;
}
.avatar_wp {
  width: 230px;
  border: 1px solid #cecece;
}
.avatar_image {
  width: 100%;
  height: 150px;
  vertical-align: top;
}

.name_info {
  margin-left: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.name_wp {
  display: flex;
  justify-content: space-between;
}
.name {
  font-size: 19px;
  color: #000;
}
.fllow {
  font-size: 15px;
  font-weight: 500;
  color: var(--color-main);
}
.introduce {
  margin-top: 10px;
  flex: 1;
  color: #606266;
}
.price {
  font-size: 15px;
  color: #ce5b39;
}
.show_content {
  margin-top: 30px;
}
.show_content_title {
  margin-top: 50px;
  font-size: 19px;
  font-weight: 700;
}
.show_content_desc {
  margin-top: 20px;
  font-size: 14px;
  color: #606266;
}
.unfold {
  margin-top: 50px;
  font-size: 14px;
  text-align: center;
}
.appointment_wp {
  margin-top: 40px;
  border-top: 1px solid #bebebe;
  padding: 30px 0;
  font-size: 15px;
}
.appointment_can_dot {
  margin-left: 50px;
  color: var(--color-main);
}
.appointment_can {
  margin-left: 10px;
}

.appointment_no_dot {
  margin-left: 50px;
  color: #bebebe;
}
.appointment_no {
  margin-left: 10px;
}
.appointment_btn {
  margin-top: 30px;
}
.appointment_btn_down {
  padding: 10px 40px;
  font-size: 15px;
  background-color: #4389cb;
  color: #fff;
}
.appointment_btn_up {
  padding: 10px 40px;
  font-size: 15px;
  background-color: var(--color-main);
  color: #fff;
}
.appointment_tab_online {
  display: flex;
  padding: 40px 20px;
  border: 1px solid #d5d5d5;
  background-color: #f8f8f8;
}
.appointment_tab_off {
  padding: 40px 20px;
  border: 1px solid #d5d5d5;
  background-color: #f8f8f8;
}
.appointment_item {
  margin-bottom: 30px;
}
.appointment_item_list {
  padding: 15px 20px;
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}
.appointment_item_list_item {
  margin-bottom: 15px;
  margin-left: 15px;
  width: 160px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  cursor: pointer;
}
.appointment_item_can {
  background-color: var(--color-main);
}
.appointment_item_off_can {
  background-color: #4389cb;
}
.appointment_item_not {
  background-color: #bebebe;
}
.consult_success {
  margin-top: 40px;
}
.consult_success_title {
  font-size: 17px;
  font-weight: 700;
  text-align: center;
  color: var(--color-main);
}
.consult_success_avatar_bg {
  display: flex;
  margin-top: 30px;
}
.consult_success_avatar_image {
  width: 200px;
  height: 200px;
}
.consult_success_text_bg {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 15px;
}
.consult_success_message {
  margin-top: 30px;
  font-size: 15px;
  font-weight: 600;
}
.consult_success_message_text {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #646566;
}
.footer {
  margin-top: 100px;
}
:deep(.el-divider__text) {
  font-size: 16px;
  color: var(--color-main);
}
:deep(.el-button--primary) {
  background-color: var(--color-main);
  border-color: var(--color-main);
}
:deep(.el-step__head.is-finish) {
  color: var(--color-main);
}
:deep(.el-step__title.is-finish) {
  color: var(--color-main);
}
:deep(.el-step__icon.is-text) {
  border-color: var(--color-main);
}
</style>