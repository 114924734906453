<template>
  <div class="register">
    <div class="header">
      <div class="header_bg">
        <img src="../assets/img/header_bg1.png" alt="" />
      </div>
      <div class="header_logo" @click="goHome"></div>
    </div>
    <div class="title">护心镜隐私权政策</div>
    <div class="content">
      <h5 style="margin-bottom: 10px">生效日期：2021年7月13日</h5>
      <h5 style="margin-bottom: 10px">更新日期：2021年7月13日</h5>
      <div class="detailtext">
        【护心镜】（以下简称“我们”）深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，我们承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。如果您不同意本隐私权政策的任何内容，您应立即停止使用护心镜服务。当您使用护心镜提供的任一服务时，即表示您已同意我们按照本隐私权政策来合法使用和保护您的个人信息。我们可能会不时依据法律法规或业务调整对本隐私政策进行修订，您需理解，只有在您确认并同意变更后的《护心镜隐私权政策》，我们才会依据变更后的隐私政策储存您的个人信息；您有权拒绝同意变更后的隐私政策，但请您知悉，一旦您拒绝同意变更后的隐私政策，可能导致您不能或者不能继续完整使用护心镜的相关服务和功能，或者无法达到我们拟达到的服务效果。
      </div>
      <div class="detailtext">
        请在使用我们的产品（或服务）前，仔细阅读并了解本《隐私权政策》。
      </div>
      <h4>本《隐私权政策》将帮助您了解以下内容：</h4>
      <h5>一、关于我们</h5>
      <h5>二、我们如何收集和使用您的个人信息</h5>
      <h5>三、我们如何使用 Cookie 和同类技术</h5>
      <h5>四、我们如何保护您的个人信息</h5>
      <h5>五、您的权利</h5>
      <h5>六、第三方服务</h5>
      <h5>七、本隐私权政策如何更新</h5>
      <h5>八、如何联系我们</h5>
      <h4>一、关于我们</h4>

      <div class="detailtext">
        应用名称为“护心镜心理咨询平台”（以下称“护心镜”），由北京京欣医疗科技有限公司（以下称“京欣医疗”）提供软件开发、产品运营和服务。
      </div>
      <h4>二、我们如何收集和使用您的个人信息</h4>
      <div class="detailtext">
        个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。
      </div>
      <div class="detailtext">
        我们仅会出于本政策所述的以下目的，收集和使用您的个人信息：
      </div>
      <div class="detailtext">（一）为您提供网上预约咨询服务</div>
      <div class="detailtext">1、业务功能一：注册成为用户</div>
      <div class="detailtext">
        为完成创建账号，您需提供以下信息：手机号或电子邮箱地址、创建的用户名和密码。
      </div>
      <div class="detailtext">
        在注册过程中，如果您提供以下额外信息，将有助于我们给您提供更好的服务和体验：您的姓名、性别、年龄、学历。但如果您不提供这些信息，将不会影响使用本服务的基本功能。
      </div>
      <div class="detailtext">2、业务功能二：完成预约咨询。</div>
      <div class="detailtext">
        为完成预约咨询，您需提供以下信息：您的过往精神科相关经历、紧急联系人及电话。
      </div>
      <div class="detailtext">
        在预约咨询过程中，如果您提供以下额外信息，将有助于我们给您提供更好的服务和体验：您的婚姻状态、是否有孩子、咨询问题细节及对咨询的期待。但如果您不提供这些信息，将不会影响使用本服务的基本功能。
      </div>
      <div class="detailtext">
        您提供的上述信息，仅限您申请的咨询师可查看，且有责任进行保密；如有法律纠纷等特殊情况，可能会被依法使用，以保障您的合法权益。
        您提供的上述信息，将在您使用本服务期间持续授权我们使用。
      </div>
      <div class="detailtext">3、图片、文件</div>
      <div class="detailtext">
        当您在使用编辑头像（照片浏览）、联系分诊（发送图片）功能时，我们会收集您主动提供的上述图片、文件内容信息，同时我们还将为便于您发送图片和文件而经过您的同意后访问您的手机存储权限。同时，您也可以随时通过您的设备系统设置相关功能设置页面开启/取消该权限。您开启该权限即视为您授权我们可以访问、获取、收集、使用您的该等个人信息；当您取消该授权后，我们将不再收集该信息，也无法再为您提供上述与之对应的服务；但除非您依照法律的规定删除了您的个人信息，否则您的取消行为不会影响我们基于您之前的授权进行的对您个人信息的处理、存储。
      </div>
      <div class="detailtext">4. 拍摄照片、实时音视频</div>
      <div class="detailtext">
        当您使用设备相机拍摄照片（编辑头像、发送图片）时，我们将访问您的相机相关权限。如您拒绝提供该权限，仅会使您无法使用该功能，但并不影响您正常使用产品与/或服务的其他功能。同时，您也可以随时通过您的设备系统设置相关功能设置页面开启/取消该权限。您开启该权限即视为您授权我们可以访问、获取、收集、使用您的该等个人信息；当您取消该授权后，我们将不再收集该信息，也无法再为您提供上述与之对应的服务；但除非您依照法律的规定删除了您的个人信息，否则您的取消行为不会影响我们基于您之前的授权进行的您个人信息的处理、存储。
      </div>
      <div class="detailtext">
        （二）开展内部数据分析和研究，第三方SDK统计服务，改善我们的产品或服务【注：示例】
      </div>
      <div class="detailtext">
        我们收集数据是根据您与我们的互动和您所做出的选择，包括您的隐私设置以及您使用的产品和功能。我们收集的数据可能包括SDK/API/JS代码版本、浏览器、互联网服务提供商、IP地址、平台、时间戳、应用标识符、应用程序版本、应用分发渠道、独立设备标识符、iOS广告标识符（IDFA)、安卓广告主标识符、网卡（MAC）地址、国际移动设备识别码（IMEI）、设备型号、终端制造厂商、终端设备操作系统版本、会话启动/停止时间、语言所在地、时区和网络状态（WiFi等）、硬盘、CPU和电池使用情况等。
      </div>
      <div class="detailtext">
        当我们要将信息用于本策略未载明的其它用途时，会事先征求您的同意。
        当我们要将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。
      </div>
      <h4>三、我们如何使用 Cookie 和同类技术</h4>
      <div class="detailtext">（一）Cookie</div>
      <div class="detailtext">
        为确保网站正常运转，我们会在您的计算机或移动设备上存储名为 Cookie
        的小数据文件。Cookie 通常包含标识符、站点名称以及一些号码和字符。借助于
        Cookie，网站能够存储您的偏好或购物篮内的商品等数据。
      </div>
      <div class="detailtext">
        我们不会将 Cookie
        用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除
        Cookie。您可以清除计算机上保存的所有 Cookie，大部分网络浏览器都设有阻止
        Cookie
        的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。如需详细了解如何更改浏览器设置，请访问以下链接：Internet
        Explorer、Google Chrome、Mozilla Firefox、Safari 和 Opera。
      </div>
      <div class="detailtext">（二）网站信标和像素标签</div>
      <div class="detailtext">
        除 Cookie
        外，我们还会在网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至我们网站内容的点击
        URL。如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。
      </div>
      <h4>四、我们如何保护您的个人信息</h4>
      <div class="detailtext">（三）Do Not Track（请勿追踪）</div>
      <div class="detailtext">
        （一）我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如，在您的浏览器与“服务”之间交换数据（如信用卡信息）时受
        SSL 加密保护；我们同时对我们网站提供 https
        安全浏览方式；我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
      </div>
      <div class="detailtext">
        （二）我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。
      </div>
      <div class="detailtext">
        （三）互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他我们用户的交流方式并未加密，我们强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，协助我们保证您的账号安全。
      </div>
      <div class="detailtext">
        （四）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
      </div>
      <div class="detailtext">
        （五）在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。
      </div>
      <div class="detailtext">
        同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
      </div>
      <h4>五、您的权利</h4>
      <div class="detailtext">
        按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：
      </div>
      <div class="detailtext">（一）访问您的个人信息</div>
      <div class="detailtext">
         您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问：
      </div>
      <div class="detailtext">
        账户信息——如果您希望访问或编辑您的账户中的个人资料信息和支付信息、更改您的密码、添加安全信息或绑定第三方账号等，您可以通过访问【我的】或【个人中心】执行此类操作。
      </div>
      <div class="detailtext">
        如果您无法通过上述链接访问这些个人信息，您可以发送电子邮件至【public@jingxin-bj.com】。我们将在30天内回复您的访问请求。
      </div>
      <div class="detailtext">（二）更正您的个人信息</div>
      <div class="detailtext">
        当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正。您可以通过“（一）访问您的个人信息”中罗列的方式提出更正申请。
        如果您无法通过上述链接更正这些个人信息，您可以发送电子邮件至【public@jingxin-bj.com】。我们将在30天内回复您的更正请求。
      </div>
      <div class="detailtext">（三）删除您的个人信息</div>
      <div class="detailtext">
        在以下情形中，您可以向我们提出删除个人信息的请求：
      </div>
      <div class="detailtext">1、如果我们处理个人信息的行为违反法律法规；</div>
      <div class="detailtext">
        2、如果我们收集、使用您的个人信息，却未征得您的同意；
      </div>
      <div class="detailtext">
        3、如果我们处理个人信息的行为违反了与您的约定；
      </div>
      <div class="detailtext">4、如果您注销了护心镜账号时；</div>
      <div class="detailtext">5、如果我们终止服务及运营时；</div>
      <div class="detailtext">
        若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。
      </div>
      <div class="detailtext">
        当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。
      </div>
      <div class="detailtext">（四）改变您授权同意的范围</div>
      <div class="detailtext">
        每个业务功能需要一些基本的个人信息才能得以完成（见本策略“第一部分”）。对于额外收集的个人信息的收集和使用，您可以随时给予或收回您的授权同意。
      </div>
      <div class="detailtext">
        您可以通过“（一）访问您的个人信息”中罗列的方式自行操作。
      </div>
      <div class="detailtext">
        当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。
      </div>
      <div class="detailtext">（五）您有权注销您的账号</div>
      <div class="detailtext">
        1、您可以通过客服或通过其他我们公示的方式申请注销您的账号。账号注销的处理期限为5个工作日。
      </div>
      <div class="detailtext">
        2、当您注销账号后，您将无法再以该账号登录和使用我们的产品与服务；且该账号在护心镜及旗下的其他产品与服务使用期间已产生的但未消耗完毕的权益及未来的逾期利益等全部权益将被清除；该账号下的内容、信息、数据、记录等将会被删除或匿名化处理（但法律法规另有规定或监管部门另有要求的除外，如依据《中华人民共和国网络安全法》规定，您的网络操作日志将至少保留六个月的时间）；护心镜账号注销完成后，将无法恢复。
      </div>
      <div class="detailtext">
        如果您在处置您的个人信息时有任何疑问，您可以通过本隐私权政策第八条“联系我们”中公示的联系方式与我们沟通解决。
      </div>
      <div class="detailtext">（六）约束信息系统自动决策</div>
      <div class="detailtext">
        在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将提供适当的救济方式。
      </div>
      <div class="detailtext">（七）响应您的上述请求</div>
      <div class="detailtext">
        为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。我们将在三十天内做出答复。
      </div>
      <div class="detailtext">
        对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
      </div>
      <div class="detailtext">
        在以下情形中，按照法律法规要求，我们将无法响应您的请求：
      </div>
      <div class="detailtext">1、与国家安全、国防安全有关的；</div>
      <div class="detailtext">
        2、与公共安全、公共卫生、重大公共利益有关的；
      </div>
      <div class="detailtext">3、与犯罪侦查、起诉和审判等有关的；</div>
      <div class="detailtext">
        4、有充分证据表明您存在主观恶意或滥用权利的；
      </div>
      <div class="detailtext">
        5、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。
      </div>
      <div class="detailtext">
        6、个人信息是您自行向社会公开或这是从合法公开渠道（如合法的新闻报道、政府信息公开等渠道）中收集到的；
      </div>
      <div class="detailtext">
        7、根据与您签订和履行相关协议或者其他书面文件所必需的；
      </div>
      <div class="detailtext">8、法律法规等规定的其他情形。</div>
      <h4>六、本隐私权政策如何更新</h4>
      <div class="detailtext">
        我们可能适时会对本隐私权政策进行调整或变更，本隐私权政策的任何更新将以标注更新时间的方式公布在我们网站上，除法律法规或监管规定另有强制性规定外，经调整或变更的内容一经通知或公布后的7日后生效。如您在隐私权政策调整或变更后继续使用我们提供的任一服务或访问我们相关网站的，我们相信这代表您已充分阅读、理解并接受修改后的隐私权政策并受其约束。
      </div>
      <h4>八、如何联系我们</h4>
      <div class="detailtext">
        如果您对本隐私权政策有任何疑问、意见或建议，通过以下方式与我们联系：
      </div>
      <div class="detailtext">邮箱：public@jingxin-bj.com</div>
      <div class="detailtext">一般情况下，我们将在三十天内回复。</div>
    </div>
    <copy-footer class="footer" />
  </div>
</template>
<script>
import CopyFooter from "../components/footer/CopyFooter.vue";

export default {
  name: "Survey",
  components: { CopyFooter },
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {
    ////回主页
    goHome() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>
<style scoped>
.header {
  position: relative;
}
.title {
  margin: 20px 0;
  font-size: 17px;
  text-align: center;
}
.header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
}
.content {
  margin: 0 auto;
  width: 1200px;
}
h4 {
  margin-top: 20px;
}
.detailtext {
  text-indent: 1.5em;
  margin: 5px 0px;
  font-size: 14px;
  color: #606266;
}
.footer {
  margin-top: 100px;
}
</style>