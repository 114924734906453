<template>
  <div class="shop">
    <div class="header">
      <div class="header_bg">
        <img src="../assets/img/header_bg1.png" alt="" />
      </div>
      <div class="header_logo" @click="goHome"></div>
      <div class="header_user_info">
        <div class="header_user_img">
          <img :src="userInfo.avatar" />
        </div>
        <div class="header_user_name">HI，{{ userInfo.nickname }}</div>
        <div class="header_user_center" @click="userCenterClick">个人中心</div>
        <div class="header_user_line">|</div>
        <div class="header_user_exit" @click="logoutClick">退出登录</div>
      </div>
      <div class="header_option">
        <span @click="headerOptionClick(-1)">首页</span>
        <span @click="headerOptionClick(0)">量表测评</span>
        <span @click="headerOptionClick(1)">心理咨询</span>
        <span @click="headerOptionClick(2)">名师课程</span>
        <span @click="headerOptionClick(3)">在线商城</span>
        <span @click="headerOptionClick(4)">共享咨询室</span>
        <span @click="headerOptionClick(5)">员工关爱方案</span>
      </div>
    </div>
    <div style="width: 600px; min-height: 500px; margin: 0 auto">
      <div class="title">咨询师认证</div>
      <div class="sub_title">预约咨询室需要进行咨询师认证</div>
      <div v-if="params.status == '1'" class="title_status">认证资料审核中</div>
      <div v-if="params.status == '3'" class="title_status">
        认证未通过，请重新提交
      </div>
      <div v-if="params.status == '3'" class="title_status">
        拒绝原因：{{ params.refuse_reason }}
      </div>

      <div class="option">
        <span style="color: #ff0000">* </span>
        <span class="option_title">姓名</span>
        <el-input
          style="margin-top: 5px"
          v-model="params.consultant_name"
          :disabled="params.status == '1' || params.status == '2'"
          placeholder="姓名"
        />
      </div>
      <div class="option">
        <span style="color: #ff0000">* </span>
        <span class="option_title">手机号</span>
        <el-input
          style="margin-top: 5px"
          v-model="params.consultant_mobile"
          placeholder="手机号"
          :disabled="params.status == '1' || params.status == '2'"
        />
      </div>
      <div class="option" v-if="params.status == '0'">
        <span style="color: #ff0000">* </span>
        <span class="option_title">验证码</span>
        <div style="display: flex; justify-content: center">
          <el-input
            style="margin-top: 5px"
            v-model="params.code"
            placeholder="验证码"
          />
          <el-button
            style="margin-top: 5px"
            type="primary"
            @click="getNumberCode"
            :disabled="sendCodeDisabled"
            >{{ sendCodeTxt }}</el-button
          >
        </div>
      </div>
      <div class="option">
        <span style="color: #ff0000">* </span>
        <span class="option_title">身份证照片（正反面）</span>
        <div class="consultant_verify_idcard_wp">
          <el-upload
            action="https://api.jingxin-inc.com/interface/auth/upload"
            :show-file-list="false"
            :on-success="handleIdcardFrontSuccess"
            :disabled="params.status == '1' || params.status == '2'"
          >
            <div class="consultant_verify_idcard">
              <el-image
                fit="contain"
                :src="
                  params.id_card_copy ||
                  require('../assets/img/consultant_verify_idcard_front.png')
                "
                class="consultant_verify_idcard_front"
              />
              <img
                v-if="params.status == '0' || params.status == '3'"
                src="../assets/img/consultant_verify_photo.png"
                class="consultant_verify_photo"
              />
            </div>
          </el-upload>
          <el-upload
            action="https://api.jingxin-inc.com/interface/auth/upload"
            :show-file-list="false"
            :on-success="handleIdcardRearSuccess"
            :disabled="params.status == '1' || params.status == '2'"
          >
            <div class="consultant_verify_idcard">
              <el-image
                fit="contain"
                :src="
                  params.id_card_national ||
                  require('../assets/img/consultant_verify_idcard_rear.png')
                "
                class="consultant_verify_idcard_front"
              />
              <img
                v-if="params.status == '0' || params.status == '3'"
                src="../assets/img/consultant_verify_photo.png"
                class="consultant_verify_photo"
              />
            </div>
          </el-upload>
        </div>
      </div>
      <div class="option">
        <span style="color: #ff0000">* </span>
        <span class="option_title">专业资格证书</span>
        <div class="consultant_verify_idcard_wp">
          <el-upload
            action="https://api.jingxin-inc.com/interface/auth/upload"
            :show-file-list="false"
            :on-success="handleCertificateSuccess"
            :disabled="params.status == '1' || params.status == '2'"
          >
            <div class="consultant_verify_idcard">
              <el-image
                fit="contain"
                :src="
                  params.professional_qualification_certificate ||
                  require('../assets/img/consultant_verify_cer.png')
                "
                class="consultant_verify_idcard_front"
              />
              <img
                v-if="params.status == '0' || params.status == '3'"
                src="../assets/img/consultant_verify_photo.png"
                class="consultant_verify_photo"
              />
            </div>
          </el-upload>
        </div>
      </div>

      <div style="margin-top: 50px; color: #606266; font-size: 15px">
        如已入驻京欣平台，请勾选，便于快速审核。
      </div>
      <el-checkbox
        v-model="consultantType"
        label="1"
        size="large"
        :disabled="params.status == '1' || params.status == '2'"
        @change="consultantTypeChange"
        >我是京欣平台入驻咨询师</el-checkbox
      >
      <div
        v-if="params.status == '0' || params.status == '3'"
        class="submit"
        @click="submitClick"
      >
        提交
      </div>
    </div>

    <copy-footer class="footer" />
  </div>
</template>
<script>
import CopyFooter from "../components/footer/CopyFooter.vue";
import {
  consultant_verify_detail,
  consultant_verify_send_sms,
  consultant_verify_submit,
} from "../network/views-request";

export default {
  name: "shop",
  components: { CopyFooter },
  props: {},
  data() {
    return {
      userInfo: {},
      params: {},
      consultantType: false,
      sendCodeDisabled: false,
      time: 60,
      sendCodeTxt: "获取验证码",
    };
  },
  computed: {},
  created() {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    if (info) {
      this.userInfo = info;
    }
    this.getDetail();
  },
  methods: {
    consultantTypeChange() {
      if (this.consultantType == true) {
        this.params.consultant_type = "1";
      } else {
        this.params.consultant_type = "2";
      }
    },
    //退出登录
    logoutClick() {
      localStorage.clear();
      this.$router.push({ path: "/" });
    },
    //个人中心
    userCenterClick() {
      this.$router.push({ path: "/usercenter" });
    },
    //回主页
    goHome() {
      this.$router.push({ path: "/" });
    },
    headerOptionClick(index) {
      if (index == 0) {
        this.$router.push({ path: "/survey" });
      } else if (index == 1) {
        this.$router.push({ path: "/consult" });
      } else if (index == 2) {
        this.$router.push({ path: "/course" });
      } else if (index == 3) {
        this.$router.push({ path: "/shopping" });
      } else if (index == 4) {
        this.$router.push({ path: "/room" });
      } else if (index == 5) {
        this.$router.push({ path: "/concern" });
      } else {
        this.goHome();
      }
    },
    //提交
    submitClick() {
      console.log(this.params);
      if (!this.params.consultant_name) {
        this.$message.error("请输入姓名");
        return;
      }
      if (!this.params.consultant_mobile) {
        this.$message.error("请输入手机号");
        return;
      }
      if (!this.params.code && this.params.status == "0") {
        this.$message.error("请输入验证码");
        return;
      }
      if (!this.params.id_card_copy) {
        this.$message.error("请选择身份证正面照");
        return;
      }
      if (!this.params.id_card_national) {
        this.$message.error("请选择身份证反面照");
        return;
      }
      if (!this.params.id_card_national) {
        this.$message.error("请选择专业资格证书照");
        return;
      }
      consultant_verify_submit(this.params).then((res) => {
        if (res.code == 200) {
          this.getDetail();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //获取验证码
    getNumberCode() {
      if (this.params.consultant_mobile.length == 0) {
        this.$message.error("请输入手机号");
        return;
      }
      const params = { mobile: this.params.consultant_mobile };
      consultant_verify_send_sms(params).then((res) => {
        if (res.code == 200) {
          this.time = 60;
          this.timer();
        } else {
          this.$message.error(res.message);
        }
      });
    },

    //获取详情
    getDetail() {
      const params = { room_id: this.$route.query.id };
      consultant_verify_detail(params).then((res) => {
        if (res.code == 200) {
          this.params = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //身份证正面
    handleIdcardFrontSuccess(e) {
      let img_url = e.data.img_url;
      this.params.id_card_copy = img_url;
    },
    //身份证正面
    handleIdcardRearSuccess(e) {
      let img_url = e.data.img_url;
      this.params.id_card_national = img_url;
    },
    //身份证正面
    handleCertificateSuccess(e) {
      let img_url = e.data.img_url;
      this.params.professional_qualification_certificate = img_url;
    },
    //发送手机验证码倒计时
    timer() {
      if (this.time > 0) {
        this.sendCodeDisabled = true;
        this.time--;
        this.sendCodeTxt = this.time + "秒";
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.sendCodeTxt = "发送验证码";
        this.sendCodeDisabled = false;
      }
    },
  },
};
</script>
<style scoped>
.header {
  position: relative;
}
.header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
}
.header_user_info {
  position: absolute;
  display: flex;
  align-items: center;
  right: 20px;
  top: 15px;
}
.header_user_img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #fff;
  overflow: hidden;
}
.header_user_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header_user_name {
  margin-left: 10px;
  font-size: 15px;
  color: #fff;
}
.header_user_center {
  margin-left: 15px;
  font-size: 15px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.header_user_line {
  margin: 0 15px;
  font-size: 15px;
  color: #fff;
}
.header_user_exit {
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}

.header_option {
  position: absolute;
  right: 0px;
  bottom: 15px;
}
.header_option span {
  padding: 0 20px;
  border-right: 1px solid #fff;
  font-size: 17px;
  flex-wrap: 600;
  color: #fff;
  cursor: pointer;
}
.header_option span:nth-child(7) {
  border-right: 0px solid #fff;
}
.title {
  margin-top: 30px;
  font-size: 20px;
  text-align: center;
  font-weight: 700;
}
.sub_title {
  margin-top: 10px;
  font-size: 13px;
  color: #333333;
  text-align: center;
}
.title_status {
  margin-top: 10px;
  font-size: 15px;
  text-align: center;
  color: red;
}
.option {
  margin-top: 30px;
}
.option_title {
  font-size: 16px;
  font-weight: 700;
}
.consultant_verify_idcard_wp {
  display: flex;
}
.consultant_verify_idcard {
  margin-right: 50px;
  position: relative;
  width: 200px;
  height: 150px;
}
.consultant_verify_idcard_front {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 10px;
  width: 100%;
  height: 100%;
}
.consultant_verify_photo {
  position: absolute;
  top: 55px;
  left: 80px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.5);
}

.submit {
  margin: 40px 30px;
  padding: 10px 150px;
  background-color: var(--color-main);
  color: #fff;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}
.footer {
  margin-top: 100px;
}
</style> 