<template>
  <div class="register">
    <div class="header">
      <div class="header_bg">
        <img src="../assets/img/header_bg1.png" alt="" />
      </div>
      <div class="header_logo" @click="goHome"></div>
    </div>
    <div class="title">护心镜用户服务协议</div>
    <div class="content">
      <h5 style="margin-bottom: 10px">欢迎您使用护心镜。</h5>
      <h4>协议说明</h4>
      <div class="detailtext">
        本护心镜用户服务使用协议（下称“本协议”）由用户与北京京欣医疗科技有限公司（下称“京欣医疗”）共同缔结，是用户注册护心镜账号并使用京欣医疗服务时使用的通用条款。您在申请注册流程中点击同意本协议之前，请您务必审慎阅读、充分理解各条款内容。如您未满18周岁，或以其他形式属于限制民事行为能力人，请在监护人或法定代理人的陪同下阅读本协议。
      </div>
      <h4>协议生效</h4>
      <div class="detailtext">
        当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，即表示您已充分阅读、理解并接受本协议的全部内容，并与京欣医疗达成一致，成为护心镜在线平台用户。您的注册行为将被认为是对本协议全部条款无保留的接受和遵守。
      </div>
      <h4>定义</h4>
      <div class="detailtext">
        护心镜：指北京京欣医疗科技有限公司所运营的护心镜网络平台、小程序平台以及微信服务号（以及其他由京欣医疗运营的任何网站）。
        用户：指阅读并同意本协议内容，经过护心镜注册流程成为护心镜在线各项服务使用者的个人，以下亦称为“您”。
      </div>
      <h4>一、注册</h4>
      <div class="detailtext">
        1、京欣医疗提供用户注册。您的帐号和密码由您自行保管；您应当对以您的帐号进行的所有活动和事件负法律责任。请用户妥善保管自己的账号和密码，加强密码安全性，谨防账号泄漏或被盗。因用户账号被泄漏或被盗而造成的任何损失，京欣医疗不承担补偿责任。
      </div>
      <div class="detailtext">
        2、您注册时，在账号名称、头像和简介等注册信息中不得出现违法和不良信息，否则京欣医疗有权拒绝提供服务，并删除该账号。您应对注册获得的京欣医疗账号项下的一切行为承担全部责任，不得侵犯包括但不限于京欣医疗在内的任何主体的合法权益。
      </div>
      <div class="detailtext">
        3、您理解并同意，京欣医疗有权在法律允许的最大范围内视情况决定收回账号使用权，无需另行通知用户亦无需征得用户同意。
      </div>
      <h4>二、服务</h4>
      <div class="detailtext">
        1、京欣医疗不断创新以向其用户提供最优体验，您认知并同意京欣医疗提供的服务的形式和本质可不经事先通知您而不时变换，京欣医疗保留随时变更、中断或终止部分或全部网络服务的权利。如果用户不同意或者不接受京欣医疗相关软件的修改、替代、升级，请直接拒绝、停止、取消适用行为，否则视为用户同意并接受京欣医疗相关软件的修改、替代、升级，同时该同意并接受的行为仍受本协议约束。
      </div>
      <div class="detailtext">
        2、京欣医疗在提供服务时，可能会对部分服务收取一定费用，京欣医疗将会对收费服务给予明确的提示。
      </div>
      <div class="detailtext">
        3、您认知并同意，如果京欣医疗禁用对您的帐户的访问权，则您可能被阻止获得服务、您的帐户资料或包含在您帐户中的任何文件或其他内容。
      </div>
      <div class="detailtext">
        4、特别提醒：由于京欣医疗产品和服务的特点，本协议为统一适用的一般性用户服务条款
        。针对京欣医疗的某些特别产品/服务，京欣医疗还将制定特定用户服务协议，以便更具体地与您约定服务内容、服务规则等内容，您应在充分阅读并同意特定用户服务协议的全部内容后再适用该特定产品/服务。
      </div>
      <h4>三、用户的权利和责任</h4>
      <div class="detailtext">
        1、在您完成护心镜账户注册后，意味着您已获得该账户的使用权。
      </div>
      <div class="detailtext">
        2、您应妥善保管您的账户和密码，通过您的账户和密码操作或实施的行为，将视为您本人的行为，由您本人承担相应的责任和后果。您不得以任何形式转让或授权他人使用自己的护心镜账号，亦不得盗用他人帐号。如果您未保管好自己的帐号和密码而对您、京欣医疗或第三方造成的损害，您应立即通知京欣医疗并自行承担相关法律后果。
      </div>
      <div class="detailtext">
        3、您须遵守国家关于互联网信息发布的相关法律法规，您对自己在护心镜上发布的信息及行为承担责任，您不得发布违法信息，不得恶意评价其他用户、不得对平台内其他用户进行恶意骚扰、侮辱、人身攻击等行为。如您因违反上述规则，导致相应法律后果的发生，您将以自己的名义独立承担所有法律责任，以及接受京欣医疗对您采取的相应措施。
      </div>
      <div class="detailtext">
        4、您同意独自就您在使用服务时创作、传送或展示的任何内容以及您做出该等行为的后果（包括京欣医疗可能遭受的任何损失或损害）承担责任（京欣医疗不对您或任何第三方承担责任）。
      </div>

      <div class="detailtext">
        5、您通过京欣医疗向咨询师购买心理咨询服务时，需要在护心镜完成整个咨询服务，不得未经护心镜预约私下与咨询师达成交易。否则，因此与咨询师发生任何纠纷，您与咨询师自行承担一切后果，京欣医疗不负任何责任。
      </div>
      <h4>四、京欣医疗的权利和责任</h4>
      <div class="detailtext">
        1、京欣医疗有义务在现有技术上维护整个网络平台的正常运行，并努力提升和改进技术，增强用户使用服务的体验。对用户在注册使用服务中所遇到的与服务或注册有关的问题及反映的情况，京欣医疗有义务及时作出回应。
      </div>
      <div class="detailtext">
        2、京欣医疗没有义务对所有用户的注册数据、所有的活动行为以及与之有关的其它事项进行审查，但如存在下列情况，京欣医疗有权根据不同情况选择保留或删除相关信息或继续、停止对该用户提供服务，并追究相关法律责任：
      </div>
      <div class="detailtext">
        ①您或其它第三方通知京欣医疗，认为某个具体用户、具体行为、具体事项可能存在重大问题；
      </div>
      <div class="detailtext">
        ②您或其它第三方向京欣医疗告知网络平台上有违法或不当行为的，京欣医疗以普通非专业人员的知识水平标准对相关内容进行判别，可以明显认为这些内容或行为具有违法或不当性质的。
      </div>
      <div class="detailtext">
        3、京欣医疗为用户和心理咨询师提供商务辅助服务，搭建心理咨询的合作桥梁。用户可通过护心镜心理平台了解心理咨询师公开对外展示的信息，并使用平台进行心理咨询服务的预约等。当且仅当用户在护心镜心理平台付费预约及续约的心理咨询服务中，如用户与心理咨询师产生任何纠纷，京欣医疗有权但无义务介入调解，即，京欣医疗有权但无义务提供信息沟通及相应调处方案，但该调处结果无法律效力，由纠纷双方（用户与心理咨询师）自愿作出，京欣医疗不对调处承担法律责任。
      </div>
      <div class="detailtext">
        4、如用户和咨询师未通过京欣医疗进行心理咨询预约及费用支付，由此产生的任何纠纷属于您与咨询师的私人纠纷，京欣医疗不承担任何责任。如京欣医疗发现您和咨询师存在私下交易的行为，可即时采取注销账户并拒绝提供服务措施，如给京欣医疗及其相关方造成损失的，您和咨询师还应承担相应赔偿责任。
      </div>
      <div class="detailtext">
        5、京欣医疗有权对用户的注册数据及活动行为进行查阅，发现注册数据或活动行为中存在任何问题或怀疑，均有权向用户发出询问及要求改正的通知或者直接作出删除等处理。
      </div>
      <div class="detailtext">
        6、京欣医疗致力于为用户提供文明健康、规范有序的服务，用户应遵守中华人民共和国相关法律法规，(如果用户是中华人民共和国境外的使用者，还应遵守所属国家或地区的法律法规)，用户将自行承担用户所发布的信息内容的责任。如因用户的不当行为造成恶劣影响、损害他人合法权利等行为，京欣医疗有权对用户采取“相应措施”。
      </div>
      <h4>五、服务变更、中断或终止</h4>
      <div class="detailtext">1、软件更新</div>
      <div class="detailtext">
        ①为了改善用户体验、完善服务内容，京欣医疗将不断努力开发新的服务，并为用户不时提供软件更新（这些更新可能会采取软件替换、修改、功能强化、版本升级等形式）。
      </div>
      <div class="detailtext">
        ②为了保证本软件及服务的安全性和功能的一致性，京欣医疗有权不经向用户特别通知而对软件进行更新，或者对软件的部分功能效果进行改变或限制。本软件新版本发布后，旧版本的软件可能无法使用。京欣医疗不保证旧版本软件继续可用及相应的客户服务，请用户随时核对并下载最新版本。
      </div>
      <div class="detailtext">
        ③为了软件的正常运行，本软件手机应用需要定期或不定期地对软件进行停机维护，因此类情况而造成的正常服务中断、停止，用户应该予以理解，本公司应尽力避免服务中断、停止或将中断、停止时间限制在最短时间内。因系统维护或升级的需要而需暂停网络服务，京欣医疗将尽可能事先进行通告。
      </div>
      <div class="detailtext">2、服务中断或终止</div>
      <div class="detailtext">
        如发生下列任何一种情形，京欣医疗有权解除本协议，并终止您的全部服务：
      </div>
      <div class="detailtext">
        ①您违反国家有关法律法规或护心镜管理规定，侵害他人合法权益的；
      </div>
      <div class="detailtext">
        ②您因在护心镜上的不当行为被行政或司法机构拘留或起诉；
      </div>
      <div class="detailtext">
        ③您盗用他人账户、发布违禁信息、骗取他人财物的；
      </div>
      <div class="detailtext">④您传播虚假信息，歪曲事实，经查证属实的；</div>
      <div class="detailtext">⑤您骚扰、侮辱平台其他用户，造成恶劣影响的；</div>
      <div class="detailtext">⑥其它京欣医疗认为需要终止服务的情况。</div>
      <div class="detailtext">
        除前款所述情形外，京欣医疗保留在不事先通知您的情况下随时中断或终止部分或全部网络服务的权利，对于服务的中断或终止而造成您的损失的，京欣医疗不承担任何责任。
      </div>
      <div class="detailtext">
        3、服务发生变更、中断、终止后，京欣医疗仍有以下权利：
      </div>
      <div class="detailtext">
        ①京欣医疗有权保留您的注册数据及以前的行为记录；
      </div>
      <div class="detailtext">
        ②如您在服务变更、中断、终止前，在护心镜心理平台上存在违法行为或违反条款的行为，京欣医疗仍可行使本服务条款所规定的权利。
      </div>
      <h4>六、隐私声明</h4>
      <div class="detailtext">1、适用范围</div>
      <div class="detailtext">
        ①您注册护心镜时，根据网站要求提供的个人信息；
      </div>
      <div class="detailtext">
        ②您使用护心镜时，上传的非公开的申请单资料等文字、图片、数据信息。
      </div>
      <div class="detailtext">
        ③京欣医疗通过合法途径从其他途径取得的您的个人资料。
      </div>
      <div class="detailtext">④您认为不宜公开的其他内容。</div>
      <div class="detailtext">2、信息保密</div>
      <div class="detailtext">
        保护您的隐私是京欣医疗的一项基本政策，京欣医疗保证不对外公开或向第三方提供您的注册资料及您在使用网络服务时存储的非公开内容，但下列情况除外：事先获得您的明确授权；根据有关的法律法规要求；按照相关政府主管部门的要求；为维护社会公众的利益；为维护京欣医疗的合法权益。
      </div>
      <div class="detailtext">3、信息使用</div>
      <div class="detailtext">
        ①为服务用户的目的，京欣医疗可能通过使用您的个人信息向您提供服务，包括但不限于向您发出活动和服务信息等。
      </div>
      <div class="detailtext">
        ②您知悉并认可：京欣医疗可能会与第三方合作向用户提供相关的网络服务，在此情况下，如该第三方同意承担与本网站同等的保护用户隐私的责任，则京欣医疗有权将用户的注册资料等提供给该第三方。另外，在不透露单个用户隐私资料的前提下，京欣医疗有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。
      </div>
      <div class="detailtext">
        4、我们可能会不时依据法律法规或业务调整对隐私政策进行修订。
      </div>
      <h4>七、有限责任</h4>
      <div class="detailtext">
        不论在何种情况下，京欣医疗均不对由于Internet连接故障，电脑，通讯或其他系统的故障，电力故障，罢工，劳动争议，暴乱，起义，骚乱，生产力或生产资料不足，火灾，洪水，风暴，爆炸，不可抗力，战争，政府行为，国际、国内法院的命令或第三方的不作为而造成的不能服务或延迟服务承担责任。
      </div>
      <h4>八、违约责任</h4>
      <div class="detailtext">
        1、如果京欣医疗发现您有下列任一行为的，有权根据相应本服务的公约或守则的规定，采取“相应措施”：包括但不限于对您账号的冻结、终止、删除；您在此承诺京欣医疗有权作出上述行为，并承诺不就上述行为要求京欣医疗做任何补偿或退费：
      </div>
      <div class="detailtext">①您提供虚假注册信息；</div>
      <div class="detailtext">② 您违反本协议中规定的使用规则；</div>
      <div class="detailtext">
        ③通过非法手段、不正当手段或其他不公平的手段使用京欣医疗的产品和服务或参与本软件活动；
      </div>
      <div class="detailtext">④有损害本软件正当利益的行为；</div>
      <div class="detailtext">⑤有严重损害其他用户的行为；</div>
      <div class="detailtext">
        ⑥有违反中华人民共和国的法律、法规的行为或言论；
      </div>
      <div class="detailtext">
        ⑦有违背社会风俗、社会道德和互联网一般道德和礼仪的行为；
      </div>
      <div class="detailtext">
        ⑧其他妨碍本软件提供产品和服务或京欣医疗认为的严重不当行为；
        同一用户若有任一账号存在上述任一行为的，京欣医疗有权对该用户下的所有账号予以制裁，包括但不限于冻结账号、删除账号、终止服务等。京欣医疗因上述原因删除用户注册的账号后即不再对用户承担任何义务和责任。
      </div>
      <div class="detailtext">
        2、
        您同意保障和维护本软件及其他用户的利益，如因您违反有关法律、法规或本协议项下的任何条款而给本软件或任何其他第三人造成损失，您同意承担由此造成的损害赔偿责任。
      </div>
      <div class="detailtext">
        本协议中所述的“相应措施”包括但不限于以下一项或多项：
      </div>
      <div class="detailtext">① 更改、删除或屏蔽相关内容；</div>
      <div class="detailtext">② 警告违规账号、账号禁言；</div>
      <div class="detailtext">
        ③ 冻结用户账户资金，用于弥补用户给合作方及其关联公司、他人造成的损失；
      </div>
      <div class="detailtext">④ 变更、限制或禁止违规账号部分或全部功能；</div>
      <div class="detailtext">
        ⑤ 暂停、限制或终止用户使用京欣医疗服务的权利、注销用户账号等；
      </div>
      <div class="detailtext">⑥ 向有关监管部门或国家机关报告；</div>
      <div class="detailtext">⑦ 其他受损失的第三人认为合理的措施。</div>
      <h4>九、服务条款修改</h4>
      <div class="detailtext">
        1、京欣医疗有权根据服务情况变更、终止护心镜管理规定的各项条款，京欣医疗将会通过适当方式向您提示修改内容。
      </div>
      <div class="detailtext">
        2、
        如果您不同意护心镜管理规定的修改，有权停止使用网络服务。如果您继续使用网络服务，则视为您接受京欣医疗对服务条款相关条款所做的修改。
      </div>
      <h4>十、争议解决</h4>
      <div class="detailtext">
        1、本协议之解释与适用，以及与本协议有关的争议，均应依照中华人民共和国法律予以处理，并提交北京仲裁委员会裁决。
      </div>
      <div class="detailtext">
        2、如本协议的任何条款被视作无效或无法执行，则上述条款可被分离，其余部份则仍具有法律效力。
      </div>
      <h4>十一、通知送达</h4>
      <div class="detailtext">
        1、本协议项下京欣医疗对于您所有的通知均可通过网页公告、电子邮件、手机短信、微信消息或常规的信件传送等方式进行；该等通知于发送之日视为已送达给您。
      </div>
      <div class="detailtext">
        2、您对于京欣医疗的通知应当通过京欣医疗对外正式公布的通信地址、传真号码、电子邮件地址等联系信息进行送达。
      </div>
      <h4>十二、其他规定</h4>
      <div class="detailtext">
        1、本服务条款构成您与京欣医疗对服务条款之约定事项及其他有关事宜的完整协议，除服务条款规定的之外，未赋予服务条款各方其他权利。
      </div>
      <div class="detailtext">
        2、本协议的生效、履行、解释及争议的解决均适用中华人民共和国法律。本条款因与中华人民共和国现行法律相抵触导致部分无效，不影响其他部分的效力。协议各方同意，解决争议时，应以您同意的最新版用户协议为准。
      </div>
      <div class="detailtext">
        3、本服务条款中的标题仅为方便而设，在解释本条款时应被忽略。
      </div>
    </div>
    <copy-footer class="footer" />
  </div>
</template>
<script>
import CopyFooter from "../components/footer/CopyFooter.vue";

export default {
  name: "Survey",
  components: { CopyFooter },
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {
    ////回主页
    goHome() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>
<style scoped>
.header {
  position: relative;
}
.title {
  margin: 20px 0;
  font-size: 17px;
  text-align: center;
}
.header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
}
.content {
  margin: 0 auto;
  width: 1200px;
}
h4 {
  margin-top: 20px;
}
.detailtext {
  text-indent: 1.5em;
  margin: 5px 0px;
  font-size: 14px;
  color: #606266;
}
.footer {
  margin-top: 100px;
}
</style>