<template>
  <div class="shop">
    <div class="header">
      <div class="header_bg">
        <img src="../assets/img/header_bg1.png" alt="" />
      </div>
      <div class="header_logo" @click="goHome"></div>
      <div class="header_user_info">
        <div class="header_user_img">
          <img :src="userInfo.avatar" />
        </div>
        <div class="header_user_name">HI，{{ userInfo.nickname }}</div>
        <div class="header_user_center" @click="userCenterClick">个人中心</div>
        <div class="header_user_line">|</div>
        <div class="header_user_exit" @click="logoutClick">退出登录</div>
      </div>
      <div class="header_option">
        <span @click="headerOptionClick(-1)">首页</span>
        <span @click="headerOptionClick(0)">量表测评</span>
        <span @click="headerOptionClick(1)">心理咨询</span>
        <span @click="headerOptionClick(2)">名师课程</span>
        <span @click="headerOptionClick(3)">在线商城</span>
        <span @click="headerOptionClick(4)">共享咨询室</span>
        <span @click="headerOptionClick(5)">员工关爱方案</span>
      </div>
    </div>
    <div class="shop_bg">
      <img src="../assets/img/shop_header.png" />
    </div>
    <div style="width: 1200px; margin: 0 auto">
      <div class="all_order" @click="allOrderClick">
        <div class="all_order_text">全部订单</div>
        <el-image
          class="all_order_icon"
          :src="require('../assets/img/all_order_icon.png')"
        ></el-image>
      </div>
      <div>
        <ul
          class="shop_list"
          v-infinite-scroll="load"
          infinite-scroll-disabled="disabled"
          style="overflow: auto"
        >
          <li
            class="shop_list_item"
            v-for="i in shopList"
            :key="i.id"
            @click="shopItemClick(i.id)"
          >
            <el-image class="item_image" :src="i.banner" fit="cover"></el-image>

            <div class="item_text_wp">
              <div class="item_title">{{ i.goods_name }}</div>
              <div class="item_price_wp">
                <div class="item_price">
                  价格：
                  <span style="color: #ef0c26">￥{{ i.price }}元</span>
                </div>
                <div class="item_play">立即购买</div>
              </div>
            </div>
          </li>
        </ul>
        <p v-if="loading" class="load_footer">加载中...</p>
        <p v-if="noMore" class="load_footer">没有更多了</p>
      </div>
    </div>
    <copy-footer class="footer" />
  </div>
</template>
<script>
import CopyFooter from "../components/footer/CopyFooter.vue";
import { item_list } from "../network/views-request";

export default {
  name: "shop",
  components: { CopyFooter },
  props: {},
  data() {
    return {
      userInfo: {},
      params: {
        index: 1,
        count: 20,
      },
      shopList: [],
      loading: false,
      allCount: 1000,
    };
  },
  computed: {
    noMore() {
      return this.allCount <= this.shopList.length;
    },
    disabled() {
      return this.loading || this.noMore;
    },
  },
  created() {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    if (info) {
      this.userInfo = info;
    }
    this.getList();
  },
  methods: {
    //退出登录
    logoutClick() {
      localStorage.clear();
      this.$router.push({ path: "/" });
    },
    //个人中心
    userCenterClick() {
      this.$router.push({ path: "/usercenter" });
    },
    //回主页
    goHome() {
      this.$router.push({ path: "/" });
    },
    headerOptionClick(index) {
      if (index == 0) {
        this.$router.push({ path: "/survey" });
      } else if (index == 1) {
        this.$router.push({ path: "/consult" });
      } else if (index == 2) {
        this.$router.push({ path: "/course" });
      } else if (index == 3) {
        this.$router.push({ path: "/shopping" });
      } else if (index == 4) {
        this.$router.push({ path: "/room" });
      } else if (index == 5) {
        this.$router.push({ path: "/concern" });
      } else {
        this.goHome();
      }
    },
    //查看订单
    allOrderClick() {
      this.$router.push({ path: "/shoppingorderlist" });
    },
    //点击商品
    shopItemClick(id) {
      this.$router.push({ path: "/shoppingdetail", query: { id } });
    },
    //商城商品列表
    getList() {
      this.loading = true;
      item_list(this.params).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.allCount = res.data.all_count;

          this.shopList = this.shopList.concat(res.data.list);
        } else {
          this.$message.error(res.message);
        }
        this.loading = false;
      });
    },
    load() {
      this.getList();
    },
  },
};
</script>
<style scoped>
.header {
  position: relative;
}
.header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
}
.header_user_info {
  position: absolute;
  display: flex;
  align-items: center;
  right: 20px;
  top: 15px;
}
.header_user_img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #fff;
  overflow: hidden;
}
.header_user_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header_user_name {
  margin-left: 10px;
  font-size: 15px;
  color: #fff;
}
.header_user_center {
  margin-left: 15px;
  font-size: 15px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.header_user_line {
  margin: 0 15px;
  font-size: 15px;
  color: #fff;
}
.header_user_exit {
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}

.header_option {
  position: absolute;
  right: 0px;
  bottom: 15px;
}
.header_option span {
  padding: 0 20px;
  border-right: 1px solid #fff;
  font-size: 17px;
  flex-wrap: 600;
  color: #fff;
  cursor: pointer;
}
.header_option span:nth-child(6) {
  border-right: 0px solid #fff;
}
.all_order {
  position: relative;
  height: 80px;
  margin: 40px 50px 0px 50px;
  background-color: #ececec;
  cursor: pointer;
}
.all_order_icon {
  position: absolute;
  top: 25px;
  left: 30px;
  width: 40px;
}
.all_order_text {
  display: inline-block;
  position: absolute;
  top: 30px;
  left: 50px;
  width: 120px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background-color: #fff;
  border-radius: 15px;
  font-size: 15px;
}
.shop_list {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}
.shop_list_item {
  margin-left: 70px;
  margin-bottom: 30px;
  width: 300px;
  height: 400px;
  list-style-type: none;
}
.item_image {
  width: 300px;
  height: 300px;
  vertical-align: top;
}
.item_text_wp {
  padding: 10px;
  background-color: #e4efef;
  height: 100px;
}
.item_title {
  font-size: 15px;
  min-height: 40px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.item_price_wp {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
}
.item_price {
  margin-top: 5px;
  font-size: 15px;
}
.item_play {
  padding: 5px 25px;
  font-size: 14px;
  text-align: center;
  background-color: var(--color-main);
  color: #fff;
  cursor: pointer;
}
.load_footer {
  margin-top: 30px;
  font-size: 14px;
  text-align: center;
  color: #909399;
}

.footer {
  margin-top: 100px;
}
</style>