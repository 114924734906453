
<template>
  <div class="shop">
    <div class="header">
      <div class="header_bg">
        <img src="../assets/img/header_bg1.png" alt="" />
      </div>
      <div class="header_logo" @click="goHome"></div>
      <div class="header_user_info">
        <div class="header_user_img">
          <img :src="userInfo.avatar" />
        </div>
        <div class="header_user_name">HI，{{ userInfo.nickname }}</div>
        <div class="header_user_center" @click="userCenterClick">个人中心</div>
        <div class="header_user_line">|</div>
        <div class="header_user_exit" @click="logoutClick">退出登录</div>
      </div>
      <div class="header_option">
        <span @click="headerOptionClick(-1)">首页</span>
        <span @click="headerOptionClick(0)">量表测评</span>
        <span @click="headerOptionClick(1)">心理咨询</span>
        <span @click="headerOptionClick(2)">名师课程</span>
        <span @click="headerOptionClick(3)">在线商城</span>
        <span @click="headerOptionClick(4)">共享咨询室</span>
        <span @click="headerOptionClick(5)">员工关爱方案</span>
      </div>
    </div>
    <div style="width: 1200px; margin: 50px auto; min-height: 500px">
      <!-- 购买课程 -->
      <div v-if="detailInfo.need_buy" class="buy_bg">
        <el-image
          class="cover_img"
          :src="detailInfo.cover_url"
          fit="cover"
        ></el-image>
        <div class="img_shade"></div>
        <el-button class="img_buy_btn" type="primary" @click="goBuy"
          >购买</el-button
        >
      </div>
      <!-- 视频播放器 -->
      <div v-else>
        <div v-if="detailInfo.video_type == '2'" class="buy_bg">
          <el-image
            class="cover_img"
            :src="detailInfo.cover_url"
            fit="cover"
          ></el-image>
          <div class="img_shade"></div>
          <el-button class="img_buy_btn" type="primary" @click="goWatch"
            >观看直播</el-button
          >
        </div>
        <video
          v-else
          id="player-container-id"
          preload="auto"
          width="1200"
          height="600"
          playsinline
          webkit-playsinline
          x5-playsinline
        ></video>
      </div>

      <div class="video_title_bg">
        <div class="video_title">{{ detailInfo.video_title }}</div>
        <div class="video_desc">{{ detailInfo.video_desc }}</div>
      </div>
      <el-tabs
        v-model="tabActiveName"
        class="demo-tabs"
        type="card"
        style="margin-top: 30px"
      >
        <el-tab-pane label="视频简介" name="first">
          <div class="video_info" v-html="detailInfo.video_detail"></div>
        </el-tab-pane>
        <el-tab-pane label="课程目录" name="second">
          <div
            class="video_catalogue——item"
            :class="{
              'video_catalogue——item-selete': index == playerItemIndex,
            }"
            v-for="(item, index) in detailInfo.video_vods"
            :key="item.video_title"
            @click="videoItemClick(item, index)"
          >
            {{ item.video_title }}
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <copy-footer class="footer" />
    <el-dialog v-model="perfectVisible" title="提示" width="30%">
      <span>需要完善您的信息，确保年龄、性别都已填写，否则影响后续评测</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="perfectVisible = false">取消</el-button>
          <el-button type="primary" @click="pushToUserCenter">填写</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import CopyFooter from "../components/footer/CopyFooter.vue";
import {
  users_me,
  video_detail,
  video_journal,
} from "../network/views-request";
import TencentPlayer from "../components/TencentPlayer.vue";

export default {
  name: "shop",
  components: { CopyFooter, TencentPlayer },
  props: {},
  data() {
    return {
      userInfo: {},
      tabActiveName: "first",
      detailInfo: {},
      options: {
        fileID: "",
        appID: "",
      },
      player: null,
      playerItemIndex: 0,
      perfectVisible: false,
    };
  },
  computed: {},
  created() {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    if (info) {
      this.userInfo = info;
    }
    this.playerItemIndex = localStorage.getItem("playerItemIndex");
    if (!this.playerItemIndex) this.playerItemIndex = 0;
    this.getUserInfo();
    this.getDetail();
  },
  beforeUnmount() {
    localStorage.setItem("playerItemIndex", 0);
    if (this.player) this.player.dispose();
  },
  methods: {
    //退出登录
    logoutClick() {
      localStorage.clear();
      this.$router.push({ path: "/" });
    },
    //个人中心
    userCenterClick() {
      this.$router.push({ path: "/usercenter" });
    },
    //回主页
    goHome() {
      this.$router.push({ path: "/" });
    },
    headerOptionClick(index) {
      
      if (index == 0) {
        this.$router.push({ path: "/survey" });
      } else if (index == 1) {
        this.$router.push({ path: "/consult" });
      } else if (index == 2) {
        this.$router.push({ path: "/course" });
      } else if (index == 3) {
        this.$router.push({ path: "/shopping" });
      } else if (index == 4) {
        this.$router.push({ path: "/room" });
      } else if (index == 5) {
        this.$router.push({ path: "/concern" });
      } else {
        this.goHome();
      }
    },
    //获取个人信息
    getUserInfo() {
      users_me().then((res) => {
        if (res.code == 200) {
          this.userInfo = res.data;
        }
      });
    },
    //购买
    goBuy() {
      if (this.userInfo.age && this.userInfo.gender) {
        this.$router.push({
          path: "/courseplaceorder",
          query: { id: this.detailInfo.id },
        });
      } else {
        this.perfectVisible = true;
      }
    },
    //观看直播
    goWatch() {
      window.open(this.detailInfo.h5_page_url, "_blank");
    },
    //跳转
    pushToUserCenter() {
      this.$router.push({
        path: "/usercenter",
        query: { id: this.detailInfo.id },
      });
    },
    //获取详情
    getDetail() {
      const params = { item_id: this.$route.query.item_id };
      video_detail(params).then((res) => {
        if (res.code == 200) {
          this.detailInfo = res.data;
          if (this.detailInfo.video_vods.length > 0) {
            this.options =
              this.detailInfo.video_vods[this.playerItemIndex].play_info;
            this.initPlayer();
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //初始化播放器
    initPlayer() {
      var events = [
        "loadstart",
        "suspend",
        "abort",
        "error",
        "emptied",
        "stalled",
        "loadedmetadata",
        "loadeddata",
        "canplay",
        "canplaythrough",
        "playing",
        "waiting",
        "seeking",
        "seeked",
        "ended",
        "durationchange",
        "timeupdate",
        "progress",
        "play",
        "pause",
        "ratechange",
        "resize",
        "volumechange",
      ];
      setTimeout(() => {
        if (!this.player) {
          this.options.autoplay = true;
          console.log("初始化视频+", this.options);
          this.player = TCPlayer("player-container-id", this.options);
        } else {
          console.log("加载视频+", this.options);
          this.player.loadVideoByID(this.options);
        }
      });
    },
    //点击目录
    videoItemClick(item, index) {
      if (this.detailInfo.need_buy) {
        this.$message.error("请先购买");
      } else {
        if (this.options != item.play_info) {
          this.options = item.play_info;
          this.playerItemIndex = index;
          //保存所选目录
          localStorage.setItem("playerItemIndex", index);
          document.documentElement.scrollTop = 0;
          location.reload();
        }
      }
    },
    //点击播放按钮
    onPlay() {
      const params = {
        item_id: this.$route.query.item_id,
        video_id: this.video_id,
        action: "play",
      };
      video_journal(params).then((res) => {});
    },
  },
};
</script>
<style scoped>
.header {
  position: relative;
}
.header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
}
.header_user_info {
  position: absolute;
  display: flex;
  align-items: center;
  right: 20px;
  top: 15px;
}
.header_user_img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #fff;
  overflow: hidden;
}
.header_user_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header_user_name {
  margin-left: 10px;
  font-size: 15px;
  color: #fff;
}
.header_user_center {
  margin-left: 15px;
  font-size: 15px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.header_user_line {
  margin: 0 15px;
  font-size: 15px;
  color: #fff;
}
.header_user_exit {
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}

.header_option {
  position: absolute;
  right: 0px;
  bottom: 15px;
}
.header_option span {
  padding: 0 20px;
  border-right: 1px solid #fff;
  font-size: 17px;
  flex-wrap: 600;
  color: #fff;
  cursor: pointer;
}
.header_option span:nth-child(6) {
  border-right: 0px solid #fff;
}

.video {
  margin: 15px 0;
  width: 1200px;
  height: 600px;
}
.video_title_bg {
  background-color: #f5f5f5;
  padding: 20px;
}
.video_info_bg {
  margin-top: 30px;
  background-color: #f5f5f5;
  padding: 20px;
}
.video_title {
  font-size: 16px;
  font-weight: 600;
}
.video_desc {
  font-size: 15px;
  color: #707070;
}
.video_detail_title {
  font-size: 16px;
  font-weight: 600;
}
.video_info {
  margin-top: 10px;
  font-size: 15px;
  color: #707070;
}
.buy_bg {
  position: relative;
  width: 100%;
  height: 650px;
  overflow: hidden;
  background-color: #f5f5f5;
}
.cover_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 650px;
}
.img_shade {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 650px;
  background-color: rgba(0, 0, 0, 0.4);
}
.img_buy_btn {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 120px;
  transform: translate(-50%, -50%);
}
.footer {
  margin-top: 100px;
}
.video_catalogue——item {
  margin-top: 15px;
  padding: 10px;
  font-size: 13px;
  background-color: rgb(212, 243, 240);
  color: #707070;
  cursor: pointer;
}
.video_catalogue——item-selete {
  font-size: 17px;
  color: var(--color-main);
  font-weight: 700;
}
:deep(.video-js .vjs-big-play-button) {
  width: 0;
  height: 0;
  top: 270px;
  left: 570px;
}
:deep(.video-js) {
  width: 1200px;
  height: 600px;
}
</style>