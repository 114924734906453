<template>
  <div class="survey">
    <div class="header">
      <div class="header_bg">
        <img src="../assets/img/header_bg1.png" alt="" />
      </div>
      <div class="header_logo" @click="goHome"></div>
      <div class="header_user_info">
        <div class="header_user_img">
          <img :src="userInfo.avatar" />
        </div>
        <div class="header_user_name">HI，{{ userInfo.nickname }}</div>
        <div class="header_user_center" @click="userCenterClick">个人中心</div>
        <div class="header_user_line">|</div>
        <div class="header_user_exit" @click="logoutClick">退出登录</div>
      </div>
      <div class="header_option">
        <span @click="headerOptionClick(-1)">首页</span>
        <span @click="headerOptionClick(0)">量表测评</span>
        <span @click="headerOptionClick(1)">心理咨询</span>
        <span @click="headerOptionClick(2)">名师课程</span>
        <span @click="headerOptionClick(3)">在线商城</span>
        <span @click="headerOptionClick(4)">共享咨询室</span>
        <span @click="headerOptionClick(5)">员工关爱方案</span>
      </div>
    </div>

    <div style="width: 1200px; margin: 50px auto">
      <img :src="detailInfo.banner" />
      <!-- <div class="nil_line"></div> -->
      <div class="title_bg">
        <div class="goods_name">商品：{{ detailInfo.goods_name }}</div>
        <div class="goods_desc">{{ detailInfo.goods_desc }}</div>
      </div>
      <div class="desc_bg">
        <div class="goods_name">商品详情</div>
        <div class="desc_text" v-html="detailInfo.goods_detail"></div>
      </div>
      <div class="goods_price_bg">
        <div class="goods_price_info">
          <div class="goods_price">￥{{ detailInfo.price }}元</div>
          <div class="goods_postal">(*虚拟商品一经售出概不退款)</div>
        </div>

        <div class="goods_buy" @click="placeOrder">立即购买</div>
      </div>
    </div>

    <copy-footer class="footer" />
  </div>
</template>
<script>
import CopyFooter from "../components/footer/CopyFooter.vue";
import { gauge_detail } from "../network/views-request";

export default {
  name: "Survey",
  components: { CopyFooter },
  props: {},
  data() {
    return {
      userInfo: {},
      detailInfo: {},
    };
  },
  computed: {},
  created() {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    if (info) {
      this.userInfo = info;
    }
    this.getDetail();
  },
  methods: {
    //退出登录
    logoutClick() {
      localStorage.clear();
      this.$router.push({ path: "/" });
    },
    //个人中心
    userCenterClick() {
      this.$router.push({ path: "/usercenter" });
    },
    //回主页
    goHome() {
      this.$router.push({ path: "/" });
    },
    headerOptionClick(index) {
      if (index == 0) {
        this.$router.push({ path: "/survey" });
      } else if (index == 1) {
        this.$router.push({ path: "/consult" });
      } else if (index == 2) {
        this.$router.push({ path: "/course" });
      } else if (index == 3) {
        this.$router.push({ path: "/shopping" });
      } else if (index == 4) {
        this.$router.push({ path: "/room" });
      } else if (index == 5) {
        this.$router.push({ path: "/concern" });
      } else {
        this.goHome();
      }
    },
    //立即购买
    placeOrder() {
      this.$router.push({
        path: "/surveyplaceorders",
        query: { id: this.$route.query.id },
      });
    },
    //获取详情
    getDetail() {
      const params = { item_id: this.$route.query.id };
      gauge_detail(params).then((res) => {
        if (res.code == 200) {
          this.detailInfo = res.data;
        } else {
          this.$message.error(res.message);
        }
        this.loading = false;
      });
    },
  },
};
</script>
<style scoped>
.header {
  position: relative;
}
.header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
}
.header_user_info {
  position: absolute;
  display: flex;
  align-items: center;
  right: 20px;
  top: 15px;
}
.header_user_img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #fff;
  overflow: hidden;
}
.header_user_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header_user_name {
  margin-left: 10px;
  font-size: 15px;
  color: #fff;
}
.header_user_center {
  margin-left: 15px;
  font-size: 15px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.header_user_line {
  margin: 0 15px;
  font-size: 15px;
  color: #fff;
}
.header_user_exit {
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}

.header_option {
  position: absolute;
  right: 0px;
  bottom: 15px;
}
.header_option span {
  padding: 0 20px;
  border-right: 1px solid #fff;
  font-size: 17px;
  flex-wrap: 600;
  color: #fff;
  cursor: pointer;
}
.header_option span:nth-child(6) {
  border-right: 0px solid #fff;
}
.nil_line {
  height: 50px;
  background-color: #ededed;
}
.title_bg {
  padding: 30px;
  border-bottom: 1px dotted #707070;
}
.goods_name {
  font-size: 17px;
}
.goods_desc {
  margin-top: 10px;
  font-size: 14px;
}
.desc_bg {
  padding: 30px;
}
.desc_text {
  margin-top: 10px;
  font-size: 14px;
  white-space: pre-line;
}
.goods_price_bg {
  margin-top: 50px;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
}
.goods_price_info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.goods_price {
  font-size: 20px;
  font-weight: 600;
  color: #ef0c26;
}
.goods_postal {
  margin-left: 10px;
  font-size: 12px;
  color: #646363;
}
.goods_buy {
  width: 120px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 15px;
  color: #fff;
  background-color: var(--color-main);
}
.footer {
  margin-top: 100px;
}
</style>