<template>
  <div class="consultdetail">
    <div class="header">
      <div class="header_bg">
        <img src="../assets/img/header_bg1.png" alt="" />
      </div>
      <div class="header_logo" @click="goHome"></div>
      <div class="header_user_info">
        <div class="header_user_img">
          <img :src="userInfo && userInfo.avatar" />
        </div>
        <div class="header_user_name">HI，{{ userInfo.nickname }}</div>
        <div class="header_user_center" @click="userCenterClick">个人中心</div>
        <div class="header_user_line">|</div>
        <div class="header_user_exit" @click="logoutClick">退出登录</div>
      </div>
      <div class="header_option">
        <span @click="headerOptionClick(-1)">首页</span>
        <span @click="headerOptionClick(0)">量表测评</span>
        <span @click="headerOptionClick(1)">心理咨询</span>
        <span @click="headerOptionClick(2)">名师课程</span>
        <span @click="headerOptionClick(3)">在线商城</span>
        <span @click="headerOptionClick(4)">共享咨询室</span>
        <span @click="headerOptionClick(5)">员工关爱方案</span>
      </div>
    </div>
    <div style="width: 1200px; margin: 0 auto; min-height: 500px">
      <div class="title">预约信息</div>
      <div class="consult_info">
        <el-image
          class="avatar_image"
          :src="consultInfo.user && consultInfo.user.avatar"
          fit="cover"
        ></el-image>
        <div class="name_info">
          <div class="consult_type">
            预约类型:{{
              consultInfo.consult_type == "1" ? "线上预约" : "线下预约"
            }}
          </div>
          <div class="consult_name">
            预约专家：{{ consultInfo && consultInfo.consultant_name }}
          </div>
          <div class="consult_date">
            预约时间：{{
              consultInfo.consult_start_time +
              "-" +
              consultInfo.consult_end_time
            }}
          </div>
          <div class="consult_time">合计时长：50分钟</div>
        </div>
      </div>
      <div class="consult_price">
        <div>
          <span class="consult_price_title">需支付咨询费</span> ￥{{
            consultInfo.pay_amount
          }}
        </div>
        <div style="font-size: 13px; color: #707070">
          (*咨询开始前24小时内概不退款)
        </div>
      </div>
      <div class="coupon">
        <div class="coupon_title">
          优惠券<span class="coupon_title_num"
            >（{{ coupons.length }}张可用）</span
          >
        </div>
        <div class="coupon_choose" @click="couponChoose">
          {{
            chooseCoupon == null
              ? "不使用优惠券"
              : "- " + chooseCoupon.coupon_discount_str
          }}
          >
        </div>
      </div>
      <ul class="coupon_list" v-if="couponListShow">
        <li class="coupon_list_item">
          <div class="coupon_list_item_wrap0">
            <div class="coupon_time_wrap">
              <div class="coupon_time_text">不使用优惠券</div>
            </div>
            <div class="coupon_use_wrap" @click="gotoUseClick(null)">选择</div>
          </div>
        </li>
        <li class="coupon_list_item" v-for="i in coupons" :key="i.coupon_id">
          <div class="coupon_list_item_wrap4" v-if="i.type == 4">
            <div class="coupon_scope">
              {{ i.coupon_type == "1" ? "全品类" : "部分商品适用" }}
            </div>
            <div class="coupon_monry_wrap">
              <div class="coupon_money4">兑换券</div>
              <div class="coupon_all_money">无门槛</div>
            </div>
            <div class="coupon_time_wrap">
              <div class="coupon_time_text">{{ i.coupon_title }}</div>
              <div class="coupon_time_date4">
                有效期至{{ i.coupon_end_time }}
              </div>
            </div>
            <div class="coupon_use_wrap" @click="gotoUseClick(i)">选择</div>
          </div>
          <div class="coupon_list_item_wrap5" v-if="i.type == 5">
            <div class="coupon_scope">
              {{ i.coupon_type == "1" ? "全品类" : "部分商品适用" }}
            </div>
            <div class="coupon_monry_wrap">
              <div class="coupon_money">
                {{ i.coupon_discount_rate / 10
                }}<span class="coupon_money_symbol">折</span>
              </div>
              <div class="coupon_all_money">无门槛</div>
            </div>
            <div class="coupon_time_wrap">
              <div class="coupon_time_text">{{ i.coupon_title }}</div>
              <div class="coupon_time_date5">
                有效期至{{ i.coupon_end_time }}
              </div>
            </div>
            <div class="coupon_use_wrap" @click="gotoUseClick(i)">选择</div>
          </div>
          <div class="coupon_list_item_wrap6" v-if="i.type == 6">
            <div class="coupon_scope">
              {{ i.coupon_type == "1" ? "全品类" : "部分商品适用" }}
            </div>
            <div class="coupon_monry_wrap">
              <div class="coupon_money">
                <span class="coupon_money_symbol">￥</span
                >{{ i.coupon_discount }}
              </div>
              <div class="coupon_all_money">
                满{{ i.coupon_min_order_amount }}可用
              </div>
            </div>
            <div class="coupon_time_wrap">
              <div class="coupon_time_text">{{ i.coupon_title }}</div>
              <div class="coupon_time_date6">
                有效期至{{ i.coupon_end_time }}
              </div>
            </div>
            <div class="coupon_use_wrap" @click="gotoUseClick(i)">选择</div>
          </div>
        </li>
      </ul>
      <div class="pay_way">
        <div class="pay_item" @click="projectChange(1, true)">
          <div
            class="pay_select"
            :class="pay_type == 1 ? 'pay_select_on' : ''"
          ></div>
          <el-image
            class="pay_icon"
            :src="require('../assets/img/pay_wx_icon.png')"
          ></el-image>
          <div class="pay_way_title">微信支付</div>
        </div>

        <div
          class="pay_item"
          :class="!wallet.heart_coin_useage ? 'pay_disable' : ''"
          @click="projectChange(4, wallet.heart_coin_useage)"
        >
          <div
            class="pay_select"
            :class="pay_type == 4 ? 'pay_select_on' : ''"
          ></div>
          <el-image
            class="pay_icon"
            :src="require('../assets/img/pay_xin_icon.png')"
          ></el-image>
          <div class="pay_way_title_wp">
            <div class="pay_way_title">心币支付</div>
            <div class="pay_way_balance">剩余心币：{{ wallet.heart_coin }}</div>
          </div>
        </div>
        <!-- 企业心币支付 -->
        <div
          class="pay_item"
          v-for="item in project_list"
          :key="item.project_id"
          :class="
            !item.heart_coin_useage && !item.consult_count_useage
              ? 'pay_disable'
              : ''
          "
          @click="projectChange(item.project_id, item)"
        >
          <div
            class="pay_select"
            :class="project_id == item.project_id ? 'pay_select_on' : ''"
          ></div>
          <el-image
            class="pay_icon"
            :src="require('../assets/img/pay_xin_icon.png')"
          ></el-image>
          <div class="pay_way_title_wp">
            <div class="pay_way_title">{{ item.project_name }}</div>
            <div v-if="item.consult_count_useage" class="pay_way_balance">
              剩余咨询次数：{{ item.remain_consult_count }}
            </div>
            <div v-else class="pay_way_balance">
              剩余心币：{{ item.remain_heart_coin }}
            </div>
          </div>
        </div>
      </div>
      <div class="goods_price_bg">
        <div class="goods_total_text">你需要支付:</div>
        <div class="goods_total_info">
          <div class="goods_total_price">￥{{ goodsTotalPrice }}</div>
          <div class="goods_postal">(*咨询开始前24小时内概不退款)</div>
        </div>
        <div class="submit" @click="postItemOrder">立即支付</div>
      </div>
    </div>
    <copy-footer class="footer" />
    <!-- 支付二维码 -->
    <el-dialog title="微信扫码支付" v-model="codeDialogFormVisible" center>
      <div class="qrcode" ref="qrCodeUrl"></div>
      <div style="text-align: center; margin-top: 20px">
        扫码成功后点击完成查看订单
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="wchatPayComplete">完 成</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 隐私协议弹出框 -->
    <el-dialog
      v-model="centerDialogVisible"
      title="同意咨询保密协议"
      width="30%"
      center
    >
      <span
        >欢迎使用京欣预约咨询系统，在您访问京欣咨询预约系统、使用京欣咨询预约系统服务前，您需要通过点击同意的方式在线签署心理咨询知情同意书。请您务必仔细阅读、充分理解服务中的条款内容后再点击同意（特别是已加粗或下划线方式标注的条款，因为这些条款可能会明确您应履行的义务或对您的权利有所限定）</span
      >
      <div>
        我已阅读并同意<span style="color: #15bdbb" @click="pricacyPolicyClick"
          >心理咨询知情同意书</span
        >
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="centerDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="agreeeClick">同意</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import CopyFooter from "../components/footer/CopyFooter.vue";
import {
  preview_post_item_order,
  post_item_order,
} from "../network/views-request";
import QRCode from "qrcodejs2";

export default {
  name: "consultDetail",
  components: { CopyFooter },
  props: {},
  data() {
    return {
      userInfo: {},
      consultInfo: {},
      wallet: {}, //心币
      pay_type: "", //支付方式 1:微信 2:支付宝 3:团测 4:心币 5:企业心币 7：项目次数
      centerDialogVisible: false, //隐私协议
      codeDialogFormVisible: false, //微信支付弹窗
      code_url: "", //支付二维码
      order_sn: "", //微信支付完成跳转订单号
      project_list: [], //支持企业心币的项目列表
      project_id: "", //选中的企业心币的项目
      form: {
        done_consult: "", //是否做过心理咨询
        whether_go_hospital: "", //是否去医院 1:是 2:否
        whether_prescribe: "", //是否开药 1:是 2:否
        whether_medication: "", //是否服药 1.正在服用 2.曾用过 3.没吃过
        whether_go_hospital_other: "", //曾去心理科或精神科看过其他问题 1:是 2:否
        emergency_contact_name: "", //紧急联系人
        emergency_contact_mobile: "", //紧急联系电话
        know_information: "", //咨询师提前知晓的信息
        problem_type: [], //问题类型 多个用,隔开
      },
      coupons: [], //优惠券
      couponListShow: "", //显示优惠券列表
      chooseCoupon: null, //选择的优惠券
    };
  },
  computed: {
    goodsTotalPrice() {
      if (this.chooseCoupon == null) {
        return this.consultInfo && this.consultInfo.pay_amount;
      } else {
        return (
          this.consultInfo.pay_amount - this.chooseCoupon.coupon_discount_str
        );
      }
    },
    payWayShow() {
      if (self.chooseCoupon == null) return true;
      if (self.chooseCoupon.pay_type == 4) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    if (info) {
      this.userInfo = info;
    }
    this.getPreviewOrder();
  },
  methods: {
    //退出登录
    logoutClick() {
      localStorage.clear();
      this.$router.push({ path: "/" });
    },
    //个人中心
    userCenterClick() {
      this.$router.push({ path: "/usercenter" });
    },
    //回主页
    goHome() {
      this.$router.push({ path: "/home" });
    },
    headerOptionClick(index) {
      if (index == 0) {
        this.$router.push({ path: "/survey" });
      } else if (index == 1) {
        this.$router.push({ path: "/consult" });
      } else if (index == 2) {
        this.$router.push({ path: "/course" });
      } else if (index == 3) {
        this.$router.push({ path: "/shopping" });
      } else if (index == 4) {
        this.$router.push({ path: "/room" });
      } else if (index == 5) {
        this.$router.push({ path: "/concern" });
      } else {
        this.goHome();
      }
    },
    //改变企业心币支付项目
    projectChange(pay_type, disable) {
      //是bool类型，普通类型支付，不是则是项目支付
      //企业心币支付pay_type:5  项目次数支付pay_type:7
      let isBool = typeof disable;
      if (isBool == "boolean" && disable) {
        this.pay_type = pay_type;
        this.project_id = "";
      } else {
        if (disable.heart_coin_useage) {
          this.pay_type = 5;
          this.project_id = disable.project_id;
        } else if (disable.consult_count_useage) {
          this.pay_type = 7;
          this.project_id = disable.project_id;
        }
      }
    },
    //选择优惠券
    couponChoose() {
      if (this.coupons.length == 0) {
        this.$message.warning("无可用优惠券");
        return;
      }
      this.couponListShow = !this.couponListShow;
    },
    //点击优惠券
    gotoUseClick(item) {
      this.couponListShow = false;
      this.chooseCoupon = item;
    },
    //咨询订单 - 咨询下单预览
    getPreviewOrder() {
      let data = this.$route.query;
      console.log(data);
      preview_post_item_order(data).then((res) => {
        if (res.code == 200) {
          this.consultInfo = res.data.item;
          this.wallet = res.data.wallet;
          this.project_list = res.data.project_list;
          this.coupons = res.data.coupons;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //隐私政策
    pricacyPolicyClick() {
      this.$router.push({ path: "/informedConsent" });
    },
    //点击同意隐私协议
    agreeeClick() {
      this.centerDialogVisible = false;

      if (this.chooseCoupon) {
        if (this.chooseCoupon.type != 4 && this.pay_type == 0) {
          this.$message.error("请选择支付方式");
          return;
        }
      } else {
        if (this.pay_type == 0) {
          this.$message.error("请选择支付方式");
          return;
        }
      }
      let data = {
        consultant_id: this.$route.query.consultant_id,
        consult_type: this.$route.query.consult_type,
        consult_start_time: this.$route.query.consult_start_time,
        consult_end_time: this.$route.query.consult_end_time,
        pay_type: this.pay_type,
      };

      if (this.pay_type.length > 2) {
        data.pay_type = 5;
        data.project_id = this.pay_type;
      }

      //兑换券pay_type=6
      if (this.chooseCoupon) {
        data.coupon_id = this.chooseCoupon.coupon_id;
        if (this.chooseCoupon.type == 4) {
          data.pay_type = 6;
        }
      }
      post_item_order(data).then((res) => {
        if (res.code == 200) {
          if (
            this.chooseCoupon &&
            this.chooseCoupon.type == 4 &&
            data.pay_type == 6
          ) {
            this.$message.success("预约成功");
            this.skipDetailOrForm(res.data.order_sn);
            return;
          }
          if (this.pay_type == 1) {
            //微信支付
            this.codeDialogFormVisible = true;
            //解决二维码refs找不到的问题
            setTimeout(() => {
              this.code_url = res.data.code_url;
              this.order_sn = res.data.order_sn;
              this.creatQrCode(res.data.code_url);
            }, 500);
          } else {
            this.$message.success("预约成功");
            this.skipDetailOrForm(res.data.order_sn);
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //完成跳转
    skipDetailOrForm(order_sn) {
      if (this.consultInfo.need_consult_information_collection) {
        //初次表单收集
        this.$router.push({
          path: "/consultplaceorderform",
          query: { order_sn: order_sn },
        });
      } else {
        //订单详情
        this.$router.push({
          path: "/consultorderdetail",
          query: { order_sn: order_sn },
        });
      }
    },
    //咨询订单 - 咨询下单
    postItemOrder() {
      if (!this.pay_type) {
        this.$message.error("请选择支付方式");
        return;
      }
      this.centerDialogVisible = true;
    },
    //微信支付扫码点完成
    wchatPayComplete() {
      this.codeDialogFormVisible = false;
      this.skipDetailOrForm(this.order_sn);
    },
    //生成二维码
    creatQrCode(code_url) {
      let dom = this.$refs.qrCodeUrl;
      console.log(this.$refs);

      while (dom.firstChild) {
        dom.removeChild(dom.firstChild);
      }
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: code_url, // 需要转换为二维码的内容
        width: 150,
        height: 150,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
  },
};
</script>
<style scoped>
.header {
  position: relative;
}
.header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
}
.header_user_info {
  position: absolute;
  display: flex;
  align-items: center;
  right: 20px;
  top: 15px;
}
.header_user_img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #fff;
  overflow: hidden;
}
.header_user_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header_user_name {
  margin-left: 10px;
  font-size: 15px;
  color: #fff;
}
.header_user_center {
  margin-left: 15px;
  font-size: 15px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.header_user_line {
  margin: 0 15px;
  font-size: 15px;
  color: #fff;
}
.header_user_exit {
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}

.header_option {
  position: absolute;
  right: 0px;
  bottom: 15px;
}
.header_option span {
  padding: 0 20px;
  border-right: 1px solid #fff;
  font-size: 17px;
  flex-wrap: 600;
  color: #fff;
  cursor: pointer;
}
.header_option span:nth-child(6) {
  border-right: 0px solid #fff;
}
.title {
  margin-top: 30px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}
.consult_info {
  margin-top: 40px;
  padding: 20px;
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
}
.avatar_image {
  width: 100%;
  height: 180px;
  width: 180px;
  vertical-align: top;
}
.name_info {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: #707070;
}
.consult_price {
  padding: 20px;
  text-align: center;
  font-size: 20px;
  color: rgb(235, 31, 31);
  background-color: #f5f5f5;
}
.consult_price_title {
  color: #707070;
  font-size: 16px;
  font-weight: 600;
}
.submit {
  margin: 50px auto;
  width: 150px;
  height: 40px;
  font-size: 15px;
  line-height: 40px;
  text-align: center;
  border-radius: 3px;
  color: #fff;
  background-color: var(--color-main);
}
.gather_bg {
  margin-top: 20px;
  background-color: #f5f5f5;
  padding: 15px;
}
.gather_title {
  font-size: 17px;
  text-align: center;
  font-weight: 600;
  color: rgb(235, 31, 31);
}
.gather_message {
  font-size: 14px;
  text-align: center;
  color: rgb(235, 31, 31);
}

.form {
  margin: 20px auto;
  width: 600px;
}
.qrcode {
  width: 150px;
  margin: 0 auto;
}
.pay_way {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
}
.pay_way :nth-child(3n) {
  margin-right: 0px;
}
.pay_item {
  margin-bottom: 20px;
  margin-right: 60px;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  background-color: #f5f5f5;
  width: 360px;
  cursor: pointer;
  opacity: 1;
}
.pay_disable {
  cursor: not-allowed;
  opacity: 0.4;
}
.pay_select {
  margin-left: 15px;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  border: 1px solid #aaaaaa;
  background-color: #fff;
}
.pay_select_on {
  background-color: var(--color-main);
}
.pay_icon {
  margin-left: 15px;
  margin-right: 15px;
  width: 35px;
  height: 35px;
}
.pay_way_title_wp {
  display: flex;
  flex-direction: column;
}
.pay_way_title {
  font-size: 15px;
}
.pay_way_balance {
  font-size: 14px;
  color: #ef0c26;
}
.goods_price_bg {
  margin: 30px 0 200px 0;
  padding: 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
}
.goods_total_text {
  font-size: 15px;
}
.goods_total_info {
  margin-left: 15px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.goods_total_price {
  font-size: 20px;
  font-weight: 600;
  color: #ef0c26;
}
.goods_postal {
  margin-left: 10px;
  font-size: 12px;
  color: #646363;
}

.footer {
  margin-top: 100px;
}
:deep(.el-radio__input.is-checked .el-radio__inner) {
  background-color: var(--color-main);
  border-color: var(--color-main);
}
:deep(.el-radio__input.is-checked + .el-radio__label) {
  color: var(--color-main);
}
:deep(.el-checkbox__input.is-checked
    .el-checkbox__inner, .el-checkbox__input.is-indeterminate
    .el-checkbox__inner) {
  background-color: var(--color-main);
  border-color: var(--color-main);
}
:deep(.el-checkbox__input.is-checked + .el-checkbox__label) {
  color: var(--color-main);
}
.coupon {
  margin-top: 20px;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  background-color: #f5f5f5;
}
.coupon_title_num {
  font-size: 14px;
  color: #aaa;
}
.coupon_choose {
  color: #646363;
}

.coupon_list {
  display: flex;
  flex-wrap: wrap;
  background-color: #f5f5f5;
}
.coupon_list_item {
  margin: 20px;
  border-radius: 15px;
  overflow: hidden;
}
.coupon_list_item_wrap6 {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 320px;
  height: 100px;
  background-color: rgb(238, 189, 128);
}
.coupon_list_item_wrap5 {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 320px;
  height: 100px;
  background-color: rgb(150, 195, 245);
}
.coupon_list_item_wrap4 {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 320px;
  height: 100px;
  background-color: rgb(152, 243, 228);
}
.coupon_list_item_wrap0 {
  padding-left: 15px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 320px;
  height: 100px;
  background-color: rgba(233, 212, 212, 0.9);
}
.coupon_scope {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 10px;
  font-size: 10px;
  color: #fff;
  background-color: var(--color-main);
}
.coupon_monry_wrap {
  margin: 0 30px 0 10px;
}
.coupon_money_symbol {
  font-size: 15px;
  font-weight: bold;
  color: rgb(240, 28, 28);
}
.coupon_money4 {
  font-size: 10px;
  font-weight: bold;
  color: rgb(240, 28, 28);
}
.coupon_money {
  font-size: 30px;
  font-weight: bold;
  color: rgb(240, 28, 28);
}
.coupon_all_money {
  font-size: 10px;
  color: rgb(240, 28, 28);
}
.coupon_time_wrap {
  flex: 1;
}
.coupon_time_text {
  font-size: 15px;
  font-weight: bold;
  color: #232323;
}
.coupon_time_date4 {
  font-size: 12px;
  color: rgb(36, 156, 142);
}
.coupon_time_date5 {
  font-size: 12px;
  color: rgb(102, 131, 223);
}
.coupon_time_date6 {
  font-size: 12px;
  color: rgb(171, 105, 25);
}
.coupon_use_wrap {
  margin-right: 10px;
  width: 60px;
  height: 30px;
  line-height: 30px;
  border-radius: 20px;
  font-size: 10px;
  text-align: center;
  background-color: rgb(242, 48, 48);
  color: #fff;
}
</style>