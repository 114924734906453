
<template>
  <div class="consult">
    <div class="header">
      <div class="header_bg">
        <img src="../assets/img/header_bg1.png" alt="" />
      </div>
      <div class="header_logo" @click="goHome"></div>
      <div class="header_user_info">
        <div class="header_user_img">
          <img :src="userInfo.avatar" />
        </div>
        <div class="header_user_name">HI，{{ userInfo.nickname }}</div>
        <div class="header_user_center" @click="userCenterClick">个人中心</div>
        <div class="header_user_line">|</div>
        <div class="header_user_exit" @click="logoutClick">退出登录</div>
      </div>
      <div class="header_option">
        <span @click="headerOptionClick(-1)">首页</span>
        <span @click="headerOptionClick(0)">量表测评</span>
        <span @click="headerOptionClick(1)">心理咨询</span>
        <span @click="headerOptionClick(2)">名师课程</span>
        <span @click="headerOptionClick(3)">在线商城</span>
        <span @click="headerOptionClick(4)">共享咨询室</span>
        <span @click="headerOptionClick(5)">员工关爱方案</span>
      </div>
    </div>
    <div style="width: 1200px; margin: 50px auto; min-height: 500px">
      <div class="order_info">
        <div class="order_info_text">订单编号：{{ detailInfo.order_sn }}</div>
        <div class="order_info_text">
          订单状态：<span style="color: red">{{
            detailInfo.order_status_msg
          }}</span>
        </div>
        <div class="order_info_text">
          创建时间：{{ detailInfo.update_time }}
        </div>
        <div class="order_info_text" v-if="detailInfo.pay_time">
          支付时间：{{ detailInfo.pay_time }}
        </div>
      </div>
      <div class="order_info" style="margin-top: 20px">
        <div class="order_info_text">
          收货人：{{ detailInfo.receiver_name }}
        </div>
        <div class="order_info_text">
          手机号：{{ detailInfo.receiver_phone }}
        </div>
        <div class="order_info_text">
          收货地址：{{ detailInfo.receiver_address }}
        </div>
      </div>
      <div
        v-if="detailInfo.logistics"
        class="order_info"
        style="margin-top: 20px"
      >
        <div class="order_info_text">
          物流单号：{{
            detailInfo.logistics && detailInfo.logistics.tracking_number
          }}
        </div>
        <div class="order_info_text">
          物流公司：{{
            detailInfo.logistics && detailInfo.logistics.logistics_company
          }}
        </div>
        <div class="order_info_text">
          发货时间：{{
            detailInfo.logistics && detailInfo.logistics.created_at
          }}
        </div>
      </div>
      <div
        class="order_info_avatar"
        style="margin-top: 20px"
        @click="consultItemClick"
      >
        <el-image
          class="item_avatar_image"
          :src="detailInfo.item && detailInfo.item.img_url"
          fit="cover"
        ></el-image>
        <div class="item_name_bg">
          <div class="item_name_text">
            {{ detailInfo.item && detailInfo.item.goods_name }}
          </div>
          <div class="item_name_price">
            ￥{{ detailInfo.item && detailInfo.item.price }}
          </div>
        </div>
      </div>
      <div class="order_info" style="margin-top: 20px">
        <div class="order_info_text">
          商品数量：{{ detailInfo.item && detailInfo.item.quantity }}
        </div>
        <div class="order_info_text">
          商品合计：￥{{ detailInfo.goods_amount }}
        </div>
        <div class="order_info_text">
          优惠券：-￥{{ detailInfo.discount_amount }}
        </div>
        <div class="order_info_text">实付款：￥{{ detailInfo.pay_amount }}</div>
        <div class="order_info_text">付款方式：{{ payType }}</div>
      </div>
    </div>
    <copy-footer class="footer" />
  </div>
</template>
<script>
import CopyFooter from "../components/footer/CopyFooter.vue";
import { item_order } from "../network/views-request";

export default {
  name: "consult",
  components: { CopyFooter },
  props: {},
  data() {
    return {
      userInfo: {},
      detailInfo: {},
    };
  },
  computed: {
    payType() {
      if (this.detailInfo.pay_type == "1") {
        return "微信支付";
      } else if (this.detailInfo.pay_type == "2") {
        return "支付宝支付";
      } else if (this.detailInfo.pay_type == "3") {
        return "员工关爱服务";
      } else if (this.detailInfo.pay_type == "4") {
        return "心币支付";
      } else if (this.detailInfo.pay_type == "5") {
        return "企业心币支付";
      } else if (this.detailInfo.pay_type == "6") {
        return "兑换券支付";
      }
    },
  },
  created() {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    if (info) {
      this.userInfo = info;
    }
    this.getInfo();
  },
  methods: {
    //退出登录
    logoutClick() {
      localStorage.clear();
      this.$router.push({ path: "/" });
    },
    //个人中心
    userCenterClick() {
      this.$router.push({ path: "/usercenter" });
    },
    //回主页
    goHome() {
      this.$router.push({ path: "/" });
    },
    headerOptionClick(index) {
      if (index == 0) {
        this.$router.push({ path: "/survey" });
      } else if (index == 1) {
        this.$router.push({ path: "/consult" });
      } else if (index == 2) {
        this.$router.push({ path: "/course" });
      } else if (index == 3) {
        this.$router.push({ path: "/shopping" });
      } else if (index == 4) {
        this.$router.push({ path: "/room" });
      } else if (index == 5) {
        this.$router.push({ path: "/concern" });
      } else {
        this.goHome();
      }
    },
    //获取订单详情
    getInfo() {
      item_order({ order_sn: this.$route.query.order_sn }).then((res) => {
        if (res.code == 200) {
          this.detailInfo = res.data;
        } else {
          this.$message.error(res.message);
        }
        this.loading = false;
      });
    },
    //点击商品
    consultItemClick() {
      this.$router.push({
        path: "/shoppingdetail",
        query: { id: this.detailInfo.item.item_id },
      });
    },
  },
};
</script>
<style scoped>
.header {
  position: relative;
}
.header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
}
.header_user_info {
  position: absolute;
  display: flex;
  align-items: center;
  right: 20px;
  top: 15px;
}
.header_user_img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #fff;
  overflow: hidden;
}
.header_user_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header_user_name {
  margin-left: 10px;
  font-size: 15px;
  color: #fff;
}
.header_user_center {
  margin-left: 15px;
  font-size: 15px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.header_user_line {
  margin: 0 15px;
  font-size: 15px;
  color: #fff;
}
.header_user_exit {
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}

.header_option {
  position: absolute;
  right: 0px;
  bottom: 15px;
}
.header_option span {
  padding: 0 20px;
  border-right: 1px solid #fff;
  font-size: 17px;
  flex-wrap: 600;
  color: #fff;
  cursor: pointer;
}
.header_option span:nth-child(6) {
  border-right: 0px solid #fff;
}
.order_info {
  padding: 15px;
  border-radius: 10px;
  background-color: #f5f5f5;
}

.order_info_text {
  padding: 10px 0;
  font-size: 15px;
  color: #707070;
}
.order_info_avatar {
  display: flex;
  padding: 15px;
  border-radius: 10px;
  background-color: #f5f5f5;
  cursor: pointer;
}
.item_avatar_image {
  width: 100px;
  border-radius: 2px;
}
.item_name_bg {
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 15px;
  color: #646566;
}
.footer {
  margin-top: 100px;
}
</style>