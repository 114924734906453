<template>
  <div class="survey">
    <div class="header">
      <div class="header_bg">
        <img src="../assets/img/header_bg1.png" alt="" />
      </div>
      <div class="header_logo" @click="goHome"></div>
      <div class="header_user_info">
        <div class="header_user_img">
          <img :src="userInfo.avatar" />
        </div>
        <div class="header_user_name">HI，{{ userInfo.nickname }}</div>
        <div class="header_user_center" @click="userCenterClick">个人中心</div>
        <div class="header_user_line">|</div>
        <div class="header_user_exit" @click="logoutClick">退出登录</div>
      </div>
      <div class="header_option">
        <span @click="headerOptionClick(-1)">首页</span>
        <span @click="headerOptionClick(0)">量表测评</span>
        <span @click="headerOptionClick(1)">心理咨询</span>
        <span @click="headerOptionClick(2)">名师课程</span>
        <span @click="headerOptionClick(3)">在线商城</span>
        <span @click="headerOptionClick(4)">共享咨询室</span>
        <span @click="headerOptionClick(5)">员工关爱方案</span>
      </div>
    </div>

    <div class="survey_bg">
      <img src="../assets/img/survey_hedaer.png" />
    </div>
    <div style="width: 1200px; margin: 0 auto">
      <div class="search-wrap">
        <el-button
          v-show="searchViewShow"
          style="margin-right: 10px"
          type="info"
          @click="handleSearchBack"
          plain
          >&lt; 返回</el-button
        >
        <el-input
          v-model="searchText"
          class="search"
          size="large"
          placeholder="输入量表名称"
          :prefix-icon="Search"
        />
        <div class="search-button" @click="handleSearch">搜索</div>
      </div>
      <div v-show="searchViewShow" class="survey_list">
        <ul>
          <li
            class="survey_list_item"
            v-for="i in searchList"
            :key="i.id"
            @click="surveyItemClick(i.id)"
          >
            <el-image
              class="survey_list_item_img"
              :src="i.banner"
              fit="cover"
            ></el-image>
            <div class="survey_list_item_info">
              <div class="survey_list_item_title">
                {{ i.goods_name }}
              </div>
              <div class="survey_list_item_text">{{ i.goods_desc }}</div>
              <div class="survey_list_item_start">开始评测</div>
            </div>
          </li>
        </ul>
        <p v-if="loading" class="load_footer">加载中...</p>
        <p v-if="noMore" class="load_footer">没有更多了</p>
      </div>
      <el-tabs
        v-show="!searchViewShow"
        v-model="tabSelect"
        class="demo-tabs"
        @tab-click="tabHandleClick"
      >
        <el-tab-pane label="护心镜量表评测" name="1">
          <div class="survey_desc">
            心理测评是指通过一系列手段，将人的某些心理特征数量化，来衡量个体心理因素水平和个体心理差异的一种科学的测量方法。量表是其中使用最广泛的方式。京欣医疗采用市面上最成熟、最可靠的心理评测量表，供广大用户主动评估自己的健康状态、人格类型、能力倾向等等，助益生活质量，增进自我了解。
          </div>
          <div class="survey_list">
            <ul>
              <li
                class="survey_list_item"
                v-for="i in surveyList"
                :key="i.id"
                @click="surveyItemClick(i.id)"
              >
                <el-image
                  class="survey_list_item_img"
                  :src="i.banner"
                  fit="cover"
                ></el-image>
                <div class="survey_list_item_info">
                  <div class="survey_list_item_title">
                    {{ i.goods_name }}
                  </div>
                  <div class="survey_list_item_text">{{ i.goods_desc }}</div>
                  <div class="survey_list_item_start">开始评测</div>
                </div>
              </li>
            </ul>
            <p v-if="loading" class="load_footer">加载中...</p>
            <p v-if="noMore" class="load_footer">没有更多了</p>
          </div>
        </el-tab-pane>
        <el-tab-pane label="安心量表评测" name="2">
          <div class="axsurvey_desc">
            <p>
              京欣医疗与北京安定医院深度合作，依托院内心理疾病辅助诊断系统“安心评测”，扩大医疗服务供给、提高医疗资源利用效率，将医疗级别的服务推广到千家万户。
            </p>
            <p>
              安心评测更偏重心理疾病与身心疾病的筛查，每份评测结果均由安定医院精神科医师后台审核，评估出具专业报告。
            </p>
          </div>
          <div class="survey_list">
            <ul>
              <li
                class="survey_list_item"
                v-for="i in axSurveyList"
                :key="i.id"
                @click="surveyItemClick(i.id)"
              >
                <el-image
                  class="survey_list_item_img"
                  :src="i.banner"
                  fit="cover"
                ></el-image>
                <div class="survey_list_item_info">
                  <div class="survey_list_item_title">
                    {{ i.goods_name }}
                  </div>
                  <div class="survey_list_item_text">{{ i.goods_desc }}</div>
                  <div class="survey_list_item_start">开始评测</div>
                </div>
              </li>
            </ul>
            <p v-if="loading" class="load_footer">加载中...</p>
            <p v-if="noMore" class="load_footer">没有更多了</p>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <copy-footer class="footer" />
  </div>
</template>
<script>
import CopyFooter from "../components/footer/CopyFooter.vue";
import { gauge_list } from "../network/views-request";

export default {
  name: "Survey",
  components: { CopyFooter },
  props: {},
  data() {
    return {
      userInfo: {},
      searchText: "",
      searchViewShow: false,
      tabSelect: "1",
      index: 1,
      searchList: [], //搜索
      surveyList: [], //京师量表
      axSurveyList: [], //安心流量表
      loading: false,
      allCount: 1000,
    };
  },
  computed: {
    noMore() {
      return this.allCount <= this.surveyList.length;
    },
    disabled() {
      return this.loading || this.noMore;
    },
  },
  created() {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    console.log(info);
    if (info) {
      this.userInfo = info;
    }
    this.getList();
  },
  mounted() {
    window.addEventListener("scroll", this.getMore); //监听滚动
  },
  destroyed() {
    window.removeEventListener("scroll", this.getMore);
  },
  methods: {
    //退出登录
    logoutClick() {
      this.$router.push({ path: "/" });
    },
    //个人中心
    userCenterClick() {
      this.$router.push({ path: "/usercenter" });
    },
    //回主页
    goHome() {
      this.$router.push({ path: "/" });
    },
    //点击量表
    surveyItemClick(id) {
      this.$router.push({ path: "/surveydetail", query: { id } });
    },
    headerOptionClick(index) {
      if (index == 0) {
        this.$router.push({ path: "/survey" });
      } else if (index == 1) {
        this.$router.push({ path: "/consult" });
      } else if (index == 2) {
        this.$router.push({ path: "/course" });
      } else if (index == 3) {
        this.$router.push({ path: "/shopping" });
      } else if (index == 4) {
        this.$router.push({ path: "/room" });
      } else if (index == 5) {
        this.$router.push({ path: "/concern" });
      } else {
        this.goHome();
      }
    },
    //搜索
    handleSearch() {
      if (this.searchText.length == 0) {
        this.$message.error("请输入搜索内容");
        return;
      }
      this.searchViewShow = true;
      this.searchList = [];
      this.getSearchList();
    },
    //搜索返回
    handleSearchBack() {
      this.searchViewShow = false;
      this.searchText = "";
    },
    //搜索请求
    getSearchList() {
      let params = { keyword: this.searchText };
      gauge_list(params).then((res) => {
        if (res.code == 200) {
          this.searchList = res.data.list;
        } else {
          this.$message.error(res.message);
        }
        this.loading = false;
      });
    },
    //京欣量表和安心量表切换
    tabHandleClick(e) {
      this.index = 1;
      this.getList();
    },
    getList() {
      if (this.tabSelect == "1" && this.index == 1) {
        this.surveyList = [];
      } else if (this.tabSelect == "2" && this.index == 1) {
        this.axSurveyList = [];
      }

      this.loading = true;
      const params = {
        index: this.index,
        count: 10,
        gauge_source: this.tabSelect,
      };
      gauge_list(params).then((res) => {
        console.log(res,88);
        if (res.code == 200) {
          this.allCount = res.data.all_count;
          if (this.tabSelect == "1") {
            this.surveyList = this.surveyList.concat(res.data.list);
          } else {
            this.axSurveyList = this.axSurveyList.concat(res.data.list);
          }
        } else {
          this.$message.error(res.message);
        }
        this.loading = false;
      });
    },
    //滑动到底部
    getMore() {
      let clientHeight = document.documentElement.clientHeight; //获取html的可视高度
      let scrollTop = document.documentElement.scrollTop; //获取html的滚动高度
      let scrollHeight = document.documentElement.scrollHeight; //获取文档的实际高度
      //判断如果html的可视高度加上滚动高度等于文档的实际高度的话,就关闭开关,否则会造成数据混乱
      if (clientHeight + scrollTop >= scrollHeight) {
        this.load();
      }
    },
    //加载更多
    load() {
      if (!this.noMore && !this.loading) {
        this.index += 1;
        this.getList();
      }
    },
  },
};
</script>
<style scoped>
.header {
  position: relative;
}
.header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
}
.header_user_info {
  position: absolute;
  display: flex;
  align-items: center;
  right: 20px;
  top: 15px;
}
.header_user_img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #fff;
  overflow: hidden;
}
.header_user_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header_user_name {
  margin-left: 10px;
  font-size: 15px;
  color: #fff;
}
.header_user_center {
  margin-left: 15px;
  font-size: 15px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.header_user_line {
  margin: 0 15px;
  font-size: 15px;
  color: #fff;
}
.header_user_exit {
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}

.header_option {
  position: absolute;
  right: 0px;
  bottom: 15px;
}
.header_option span {
  padding: 0 20px;
  border-right: 1px solid #fff;
  font-size: 17px;
  flex-wrap: 600;
  color: #fff;
  cursor: pointer;
}
.header_option span:nth-child(6) {
  border-right: 0px solid #fff;
}
.search-wrap {
  margin-top: 30px;
  padding: 10px;
  display: flex;
  align-items: center;
  background-color: #ededed;
}
.search {
  width: 400px;
}
.search-button {
  margin-left: 10px;
  padding: 8px 20px;
  font-size: 15px;
  color: #fff;
  border-radius: 5px;
  background-color: var(--color-main);
  cursor: pointer;
}
.demo-tabs {
  margin-top: 30px;
}

:deep(.demo-tabs .el-tabs__active-bar) {
  background-color: var(--color-main);
}
:deep(.demo-tabs .is-active) {
  color: var(--color-main);
}
:deep(.demo-tabs .el-tabs__item) {
  font-size: 16px;
}

.survey_desc {
  padding: 20px;
  border-radius: 5px;
  font-size: 15px;
  color: #707070;
  background-color: bisque;
}
.axsurvey_desc {
  padding: 20px;
  border-radius: 5px;
  font-size: 15px;
  color: #707070;
  background-color: rgb(194, 220, 245);
}
.survey_list {
  margin-top: 10px;
}
.survey_list_item {
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px dashed #909399;
}
.survey_list_item_img {
  width: 500px;
  height: 186px;
}
.survey_list_item_info {
  margin-left: 20px;
  height: 186px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.survey_list_item_title {
  margin-top: 5px;
  font-size: 17px;
  font-weight: 600;
  color: var(--color-main);
}
.survey_list_item_text {
  font-size: 15px;
}
.survey_list_item_start {
  align-self: flex-end;
  width: 100px;
  height: 30px;
  background-color: var(--color-main);
  border-radius: 3px;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  cursor: pointer;
}
.load_footer {
  margin-top: 30px;
  font-size: 14px;
  text-align: center;
  color: #909399;
}
.footer {
  margin-top: 100px;
}
</style>
