<template>
  <div class="footer" :class="isVip?'vip_bgc':''">
    <div>版权所有：京欣医疗 京 ICP备2021007225号</div>
    <div>Copyright2009 2014 http://www.jingxin-bj.com/ All Rights Reserved</div>
    <div>热线电话：400-827-0020</div>
  </div>
</template>

<script>
export default {
  props: { isVip: Boolean },
};
</script>

<style scoped>
.footer {
  padding: 25px 0;
  text-align: center;
  font-size: 13px;
  background-color: var(--color-main);
  color: #fff;
}
.vip_bgc {
  background-color: #31445D;
}
</style>