<template>
  <div class="consult">
    <div class="header">
      <div class="header_bg">
        <img src="../assets/img/header_bg1.png" alt="" />
      </div>
      <div class="header_logo" @click="goHome"></div>
      <div class="header_user_info">
        <div class="header_user_img">
          <img :src="userInfo.avatar" />
        </div>
        <div class="header_user_name">HI，{{ userInfo.nickname }}</div>
        <div class="header_user_center" @click="userCenterClick">个人中心</div>
        <div class="header_user_line">|</div>
        <div class="header_user_exit" @click="logoutClick">退出登录</div>
      </div>
      <div class="header_option">
        <span @click="headerOptionClick(-1)">首页</span>
        <span @click="headerOptionClick(0)">量表测评</span>
        <span @click="headerOptionClick(1)">心理咨询</span>
        <span @click="headerOptionClick(2)">名师课程</span>
        <span @click="headerOptionClick(3)">在线商城</span>
        <span @click="headerOptionClick(4)">共享咨询室</span>
        <span @click="headerOptionClick(5)">员工关爱方案</span>
      </div>
    </div>
    <div class="content">
      <div class="gauge_name">{{ datas.amount_display_name }}</div>
      <div class="gauge_title">量表介绍</div>
      <div class="gauge_marks">{{ datas.amount_marks }}</div>
      <div class="gauge_title">温馨提示</div>
      <div class="gauge_marks">{{ datas.amount_guidance }}</div>

      <div class="gauge_list">
        <div
          class="gauge_item"
          v-for="(item, index) in datas.tg_list"
          :key="item.SubjectCode"
        >
          <div>{{ item.Sort }}. {{ item.SubjectContent }}</div>
          <el-radio
            class="gauge_item_sub_item"
            v-model="answer_record[index].AnswerCode"
            v-for="item1 in item.tz_List"
            :label="item1.OperationCode"
            :key="item1.OperationCode"
            @change="gauge_change(index, item.SubjectCode, item1.OperationCode)"
            >{{ item1.OperationNum }}. {{ item1.OperationContent }}</el-radio
          >
        </div>
      </div>
      <div class="submit" @click="submit">提交评测</div>
    </div>
    <copy-footer class="footer" />
  </div>
</template>
<script>
import CopyFooter from "../components/footer/CopyFooter.vue";
import {
  get_gauge_order_operation,
  gauge_post_report,
} from "../network/views-request";

export default {
  name: "message",
  components: { CopyFooter },
  props: {},
  data() {
    return {
      userInfo: {},
      datas: {},
      answer_record: [], //选择题目的数组
    };
  },
  computed: {},
  created() {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    if (info) {
      this.userInfo = info;
    }
    this.getGaugeOperation();
  },
  mounted() {},
  methods: {
    //退出登录
    logoutClick() {
      localStorage.clear();
      this.$router.push({ path: "/" });
    },
    //个人中心
    userCenterClick() {
      this.$router.push({ path: "/usercenter" });
    },
    //回主页
    goHome() {
      this.$router.push({ path: "/" });
    },
    headerOptionClick(index) {
      if (index == 0) {
        this.$router.push({ path: "/survey" });
      } else if (index == 1) {
        this.$router.push({ path: "/consult" });
      } else if (index == 2) {
        this.$router.push({ path: "/course" });
      } else if (index == 3) {
        this.$router.push({ path: "/shopping" });
      } else if (index == 4) {
        this.$router.push({ path: "/room" });
      } else if (index == 5) {
        this.$router.push({ path: "/concern" });
      } else {
        this.goHome();
      }
    },
    //单选框改变
    gauge_change(index, subjectCode, operationCode) {
      console.log(index, subjectCode, operationCode);
      let arr = this.answer_record;
      let obj = arr[index];
      obj.AnswerCode = operationCode;
      arr[index] = obj;
      this.answer_record = arr;
    },
    //提交
    submit() {
      //检查是否有未选
      var arr = this.answer_record.filter((item) => {
        return item.AnswerCode == "0";
      });
      if (arr.length !== 0) {
        this.$message.error("有未答项，请检查并全部作答");
        return;
      }
      let params = {
        order_sn: this.$route.query.order_sn,
        answer_record: this.answer_record,
      };
      gauge_post_report(params).then((res) => {
        console.log(params,678)
        if (res.code == 200) {
          this.$router.push({
            path: "/surveyreport",
            query: { order_sn: this.$route.query.order_sn },
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //量表订单 - 获取量表题目信息
    getGaugeOperation() {
      console.log(this.$route.query.order_sn);
      get_gauge_order_operation({ order_sn: this.$route.query.order_sn }).then(
        (res) => {
          if (res.code == 200) {
            this.datas = res.data;
            console.log(res.data,9)
            res.data.tg_list.forEach((item, index) => {
              let obj = { SubjectCode: item.SubjectCode, AnswerCode: "0" };
              this.answer_record.push(obj);
            });
          } else {
            this.$message.error(res.message);
            this.$router.go(-1);
          }
        }
      );
    },
  },
};
</script>
<style scoped>
.header {
  position: relative;
}
.header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
}
.header_user_info {
  position: absolute;
  display: flex;
  align-items: center;
  right: 20px;
  top: 15px;
}
.header_user_img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #fff;
  overflow: hidden;
}
.header_user_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header_user_name {
  margin-left: 10px;
  font-size: 15px;
  color: #fff;
}
.header_user_center {
  margin-left: 15px;
  font-size: 15px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.header_user_line {
  margin: 0 15px;
  font-size: 15px;
  color: #fff;
}
.header_user_exit {
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}

.header_option {
  position: absolute;
  right: 0px;
  bottom: 15px;
}
.header_option span {
  padding: 0 20px;
  border-right: 1px solid #fff;
  font-size: 17px;
  flex-wrap: 600;
  color: #fff;
  cursor: pointer;
}
.header_option span:nth-child(6) {
  border-right: 0px solid #fff;
}
.content {
  width: 1200px;
  margin: 40px auto;
  overflow: auto;
  min-height: 800px;
}
.gauge_name {
  margin-top: 20px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: var(--color-main);
}
.gauge_title {
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
.gauge_marks {
  text-indent: 1.5em;
  margin-top: 5px;
  font-size: 15px;
  color: #606266;
}
.gauge_list {
  margin: 20px;
  padding: 15px;
  border-radius: 10px;
  background-color: #f5f5f5;
}
.gauge_item {
  margin: 30px 0;
}
.gauge_item_sub_item {
  margin-left: 20px;
  margin-top: 10px;
}
.submit {
  margin: 0 auto;
  width: 120px;
  height: 30px;
  border-radius: 5px;
  text-align: center;
  line-height: 30px;

  color: #fff;
  background-color: var(--color-main);
}
</style>
