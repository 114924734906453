<template>
  <div class="shop">
    <div class="header">
      <div class="header_bg">
        <img src="../assets/img/header_bg1.png" alt="" />
      </div>
      <div class="header_logo" @click="goHome"></div>
      <div class="header_user_info">
        <div class="header_user_img">
          <img :src="userInfo.avatar" />
        </div>
        <div class="header_user_name">HI，{{ userInfo.nickname }}</div>
        <div class="header_user_center" @click="userCenterClick">个人中心</div>
        <div class="header_user_line">|</div>
        <div class="header_user_exit" @click="logoutClick">退出登录</div>
      </div>
      <div class="header_option">
        <span @click="headerOptionClick(-1)">首页</span>
        <span @click="headerOptionClick(0)">量表测评</span>
        <span @click="headerOptionClick(1)">心理咨询</span>
        <span @click="headerOptionClick(2)">名师课程</span>
        <span @click="headerOptionClick(3)">在线商城</span>
        <span @click="headerOptionClick(4)">共享咨询室</span>
        <span @click="headerOptionClick(5)">员工关爱方案</span>
      </div>
    </div>
    <div style="width: 1200px; min-height: 500px; margin: 0 auto">
      <!-- 筛选 -->
      <div class="filtrate_wp">
        <el-dropdown @command="handlesortCommand">
          <span class="el-dropdown-link">
            {{ sortString }}<i class="el-icon-arrow-down el-icon--right"></i>
            <el-divider
              style="margin-left: 10px"
              direction="vertical"
            ></el-divider>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="nomore">默认排序</el-dropdown-item>
              <el-dropdown-item command="number">预约最多</el-dropdown-item>
              <el-dropdown-item command="price">低价优先</el-dropdown-item>
              <el-dropdown-item command="evaluate">评价降序</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <el-dropdown @command="handlePriceCommand">
          <span class="el-dropdown-link">
            {{ priceString }}<i class="el-icon-arrow-down el-icon--right"></i>
            <el-divider
              style="margin-left: 10px"
              direction="vertical"
            ></el-divider>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="0">默认</el-dropdown-item>
              <el-dropdown-item command="1">300以下</el-dropdown-item>
              <el-dropdown-item command="2">300-599</el-dropdown-item>
              <el-dropdown-item command="3">600-999</el-dropdown-item>
              <el-dropdown-item command="4">1000+</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <el-input
          v-model="params.keyword"
          class="input-search"
          placeholder="输入咨询室名称"
        />
        <div class="search-button" @click="handleSearch">搜索</div>
      </div>

      <div class="survey_list">
        <ul>
          <li
            class="survey_list_item"
            v-for="i in roomList"
            :key="i.id"
            @click="roomItemClick(i.id)"
          >
            <el-image class="survey_list_item_img" :src="i.img_url"></el-image>
            <div class="survey_list_item_info">
              <div class="survey_list_item_title">
                {{ i.room_name }}
              </div>
              <div class="survey_list_item_text">
                地址：{{ i.province + i.city + i.district + i.detail_address }}
              </div>
              <div class="survey_list_item_price">
                咨询室价格：￥{{ i.price }}
              </div>
              <div class="survey_list_item_start">立即预约</div>
            </div>
          </li>
        </ul>
        <p v-if="loading" class="load_footer">加载中...</p>
        <p v-if="noMore" class="load_footer">没有更多了</p>
      </div>
    </div>

    <copy-footer class="footer" />
  </div>
</template>
<script>
import CopyFooter from "../components/footer/CopyFooter.vue";
import { room_list } from "../network/views-request";

export default {
  name: "shop",
  components: { CopyFooter },
  props: {},
  data() {
    return {
      userInfo: {},
      params: {
        index: 1,
        count: 6,
        sort: "nomore",
        order: "desc",
        keyword: "", //搜索关键字
      },
      price: "0", //价格
      roomList: [],
      loading: false,
      allCount: 1000,
    };
  },
  computed: {
    noMore() {
      return this.allCount <= this.roomList.length;
    },
    disabled() {
      return this.loading || this.noMore;
    },
    sortString() {
      if (this.params.sort == "nomore") {
        return "默认排序";
      } else if (this.params.sort == "number") {
        return "预约最多";
      } else if (this.params.sort == "price") {
        return "低价优先";
      } else if (this.params.sort == "evaluate") {
        return "评价降序";
      }
    },
    priceString() {
      if (this.price == "0") {
        return "价格区间";
      } else if (this.price == "1") {
        return "300以下";
      } else if (this.price == "2") {
        return "300-599";
      } else if (this.price == "3") {
        return "600-999";
      } else if (this.price == "4") {
        return "1000+";
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.getMore); //监听滚动
  },
  created() {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    if (info) {
      this.userInfo = info;
    }
    this.getList();
  },
  methods: {
    //退出登录
    logoutClick() {
      localStorage.clear();
      this.$router.push({ path: "/" });
    },
    //个人中心
    userCenterClick() {
      this.$router.push({ path: "/usercenter" });
    },
    //回主页
    goHome() {
      this.$router.push({ path: "/" });
    },
    headerOptionClick(index) {
      if (index == 0) {
        this.$router.push({ path: "/survey" });
      } else if (index == 1) {
        this.$router.push({ path: "/consult" });
      } else if (index == 2) {
        this.$router.push({ path: "/course" });
      } else if (index == 3) {
        this.$router.push({ path: "/shopping" });
      } else if (index == 4) {
        this.$router.push({ path: "/room" });
      } else if (index == 5) {
        this.$router.push({ path: "/concern" });
      } else {
        this.goHome();
      }
    },
    //点击立即预约
    roomItemClick(id) {
      this.$router.push({ path: "/roomdetail", query: { id } });
    },
    //点击排序
    handlesortCommand(command) {
      this.params.sort = command;
      this.roomList = [];
      this.params.index = 1;
      this.getList();
    },
    //选中筛选价格范围
    handlePriceCommand(command) {
      this.price = command;
      this.roomList = [];
      this.params.index = 1;
      this.getList();
    },
    //搜索
    handleSearch() {
      if (this.params.keyword.length == 0) {
        this.$message.error("请输入搜索内容");
        return;
      }
      this.roomList = [];
      this.params.index = 1;
      this.getList();
      this.params.keyword = "";
    },
    //咨询室列表
    getList() {
      this.loading = true;
      let params = {
        index: this.params.index,
        count: this.params.count,
      };
      if (this.params.sort != "nomore") {
        params.sort = this.params.sort;
        params.order = "desc";

        if (this.params.sort == "price") {
          params.order = "asc";
        }
      }

      if (this.price == "1") {
        params.price = JSON.stringify([0, 299]);
      } else if (this.price == "2") {
        params.price = JSON.stringify([300, 599]);
      } else if (this.price == "3") {
        params.price = JSON.stringify([600, 999]);
      } else if (this.price == "4") {
        params.price = JSON.stringify([1000, 10000]);
      }

      if (this.params.keyword.length > 0) {
        params.keyword = this.params.keyword;
      }
      room_list(this.params).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.allCount = res.data.all_count;
          this.roomList = this.roomList.concat(res.data.list);
        } else {
          this.$message.error(res.message);
        }
        this.loading = false;
      });
    },
    getMore() {
      let clientHeight = document.documentElement.clientHeight; //获取html的可视高度
      let scrollTop = document.documentElement.scrollTop; //获取html的滚动高度
      let scrollHeight = document.documentElement.scrollHeight; //获取文档的实际高度
      //判断如果html的可视高度加上滚动高度等于文档的实际高度的话,就关闭开关,否则会造成数据混乱
      if (clientHeight + scrollTop + 100 >= scrollHeight) {
        this.load();
      }
    },
    load() {
      if (this.noMore == true) {
        return;
      }
      if (this.loading == false) {
        this.params.index += 1;
        this.getList();
      }
    },
  },
};
</script>
<style scoped>
.header {
  position: relative;
}
.header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
}
.header_user_info {
  position: absolute;
  display: flex;
  align-items: center;
  right: 20px;
  top: 15px;
}
.header_user_img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #fff;
  overflow: hidden;
}
.header_user_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header_user_name {
  margin-left: 10px;
  font-size: 15px;
  color: #fff;
}
.header_user_center {
  margin-left: 15px;
  font-size: 15px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.header_user_line {
  margin: 0 15px;
  font-size: 15px;
  color: #fff;
}
.header_user_exit {
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}

.header_option {
  position: absolute;
  right: 0px;
  bottom: 15px;
}
.header_option span {
  padding: 0 20px;
  border-right: 1px solid #fff;
  font-size: 17px;
  flex-wrap: 600;
  color: #fff;
  cursor: pointer;
}
.header_option span:nth-child(7) {
  border-right: 0px solid #fff;
}

.filtrate_wp {
  display: flex;
  align-items: center;
  margin: 50px 20px 0px;
  padding: 20px 15px;
  border-radius: 2px;
  background-color: #ededed;
}
.input-search {
  width: 200px;
}
.search-button {
  margin-left: 10px;
  padding: 5px 10px;
  font-size: 12px;
  color: #fff;
  border-radius: 5px;
  background-color: var(--color-main);
  cursor: pointer;
}
.survey_list {
  margin-top: 10px;
}
.survey_list_item {
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px dashed #909399;
}
.survey_list_item_img {
  width: 186px;
  height: 186px;
}
.survey_list_item_info {
  margin-left: 20px;
  height: 186px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.survey_list_item_title {
  margin-top: 5px;
  font-size: 17px;
  font-weight: 600;
  color: var(--color-main);
}
.survey_list_item_text {
  font-size: 15px;
}
.survey_list_item_price {
  font-size: 15px;
  color: red;
}
.survey_list_item_start {
  align-self: flex-end;
  width: 100px;
  height: 30px;
  background-color: var(--color-main);
  border-radius: 3px;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  cursor: pointer;
}
.load_footer {
  margin-top: 30px;
  font-size: 14px;
  text-align: center;
  color: #909399;
}

.footer {
  margin-top: 100px;
}
</style>