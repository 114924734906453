<template>
  <div class="register">
    <div class="header">
      <div class="header_bg">
        <img src="../assets/img/header_bg1.png" alt="" />
      </div>
      <div class="header_logo" @click="goHome"></div>
    </div>
    <div style="height: 400px">
      <div class="loader">
        <img src="../assets/img/didbind-loader-line.png" alt="" />
      </div>
      <div class="loader_text">登录成功，正在跳转...</div>
    </div>

    <copy-footer class="footer" />
  </div>
</template>
<script>
import CopyFooter from "../components/footer/CopyFooter.vue";
import { users_me } from "../network/views-request";

export default {
  name: "Survey",
  components: { CopyFooter },
  props: {},
  data() {
    return {
      userInfo: {},
    };
  },
  computed: {},
  created() {
    //微信登录有token
    if (this.$route.query.token) {
      localStorage.setItem(
        "userInfo",
        JSON.stringify({ token: this.$route.query.token })
      );
      this.userInfo.token = this.$route.query.token;
    }
    //请求
    this.getUserInfo();
  },
  methods: {
    ////回主页
    goHome() {
      this.$router.replace({ path: "/" });
    },
    //获取个人信息
    getUserInfo() {
      users_me().then((res) => {
        if (res.code == 200) {
          this.userInfo = res.data;
          this.userInfo.token = this.$route.query.token;
          localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
          this.$router.replace({ path: "/home" });
        }
      });
    },
  },
};
</script>
<style scoped>
.header {
  position: relative;
}
.title {
  margin: 20px 0;
  font-size: 17px;
  text-align: center;
}
.header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
}

:deep(.el-checkbox__input.is-checked
    .el-checkbox__inner, .el-checkbox__input.is-indeterminate
    .el-checkbox__inner) {
  background-color: var(--color-main);
  border-color: var(--color-main);
}
.loader {
  margin: 100px auto;
  margin-bottom: 30px;
  width: 100px;
}
.loader_text {
  margin: 0px auto;
  text-align: center;
  font-size: 17px;
  color: #333;
}

.footer {
  margin-top: 100px;
}
</style>