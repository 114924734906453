<template>
  <div class="consult">
    <div class="header">
      <div class="header_bg">
        <img src="../assets/img/header_bg1.png" alt="" />
      </div>
      <div class="header_logo" @click="goHome"></div>
      <div class="header_user_info">
        <div class="header_user_img">
          <img :src="userInfo.avatar" />
        </div>
        <div class="header_user_name">HI，{{ userInfo.nickname }}</div>
        <div class="header_user_center" @click="userCenterClick">个人中心</div>
        <div class="header_user_line">|</div>
        <div class="header_user_exit" @click="logoutClick">退出登录</div>
      </div>
      <div class="header_option">
        <span @click="headerOptionClick(-1)">首页</span>
        <span @click="headerOptionClick(0)">量表测评</span>
        <span @click="headerOptionClick(1)">心理咨询</span>
        <span @click="headerOptionClick(2)">名师课程</span>
        <span @click="headerOptionClick(3)">在线商城</span>
        <span @click="headerOptionClick(4)">共享咨询室</span>
        <span @click="headerOptionClick(5)">员工关爱方案</span>
      </div>
    </div>
    <div class="content">
      <div class="gauge_name">《{{ data.amount_display_name }}》测试报告</div>
      <el-row class="top_right_borfer margin_40px">
        <el-col :span="4" class="bottom_left_borfer"
          ><div>姓名：{{ data.user_name }}</div></el-col
        >
        <el-col :span="5" class="bottom_left_borfer"
          ><div>性别：{{ data.user_sex }}</div></el-col
        >
        <el-col :span="5" class="bottom_left_borfer"
          ><div>年龄：{{ data.user_age }}</div></el-col
        >
        <el-col :span="5" class="bottom_left_borfer"
          ><div>编号：{{ data.amount_code }}</div></el-col
        >
        <el-col :span="5" class="bottom_left_borfer"
          ><div>完成日期：{{ data.complete_time }}</div></el-col
        >
      </el-row>
      <div class="gauge_title">一、 测验简介</div>
      <div class="gauge_marks">{{ data.test_introduce }}</div>
      <div class="gauge_title">二、 测试结果</div>
      <div v-for="item in data.test_result" :key="item.DimensionName">
        <el-row class="top_right_borfer margin_20px">
          <el-col :span="24" class="bottom_left_borfer total_evaluate"
            ><div>
              {{ item.DimensionName }}
            </div></el-col
          >
        </el-row>
        <el-row class="right_borfer">
          <el-col :span="8" class="bottom_left_borfer"
            ><div>原始分：{{ item.OriginalScore }}</div></el-col
          >
          <el-col :span="8" class="bottom_left_borfer"
            ><div>标准分：{{ item.StandardScore }}</div></el-col
          >
          <el-col :span="8" class="bottom_left_borfer"
            ><div>状态：{{ item.DimensionState }}</div></el-col
          >
        </el-row>
        <el-row class="right_borfer">
          <el-col :span="24" class="bottom_left_borfer"
            ><div class="red_color">结果描述</div>
            <div class="red_color description">
              {{ item.ResultsDescribe }}
            </div></el-col
          >
        </el-row>
        <el-row class="right_borfer">
          <el-col :span="24" class="bottom_left_borfer"
            ><div class="red_color">心理建议</div>
            <div class="red_color description">
              {{ item.PsychologicalSuggestion }}
            </div></el-col
          >
        </el-row>
      </div>

      <div class="gauge_title">三、 原始答卷</div>
      <div class="gauge_marks">被试者对每个题目的具体答案如下</div>
      <el-row class="top_right_borfer margin_20px">
        <el-col
          :span="3"
          class="
            bottom_left_borfer
            text_align_center
            total_evaluate
            padding_0px
          "
          v-for="item in data.answer_record_select"
          :key="item.N"
          ><div class="question">{{ item.N }}</div>
          <div class="answer">
            {{ item.S }}
          </div></el-col
        >
      </el-row>
    </div>
    <copy-footer class="footer" />
  </div>
</template>
<script>
import CopyFooter from "../components/footer/CopyFooter.vue";
import { get_gauge_order_report } from "../network/views-request";

export default {
  name: "message",
  components: { CopyFooter },
  props: {},
  data() {
    return {
      userInfo: {},
      data: {},
    };
  },
  computed: {},
  created() {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    if (info) {
      this.userInfo = info;
    }
    this.getGaugeReport();
  },
  mounted() {},
  methods: {
    //退出登录
    logoutClick() {
      localStorage.clear();
      this.$router.push({ path: "/" });
    },
    //个人中心
    userCenterClick() {
      this.$router.push({ path: "/usercenter" });
    },
    //回主页
    goHome() {
      this.$router.push({ path: "/" });
    },
    headerOptionClick(index) {
      if (index == 0) {
        this.$router.push({ path: "/survey" });
      } else if (index == 1) {
        this.$router.push({ path: "/consult" });
      } else if (index == 2) {
        this.$router.push({ path: "/course" });
      } else if (index == 3) {
        this.$router.push({ path: "/shopping" });
      } else if (index == 4) {
        this.$router.push({ path: "/room" });
      } else if (index == 5) {
        this.$router.push({ path: "/concern" });
      } else {
        this.goHome();
      }
    },

    //量表订单 - 获取量表题目信息
    getGaugeReport() {
      get_gauge_order_report({ order_sn: this.$route.query.order_sn }).then(
        (res) => {
          if (res.code == 200) {
            this.data = res.data;
          } else {
            this.$message.error(res.message);
          }
        }
      );
    },
  },
};
</script>
<style scoped>
.header {
  position: relative;
}
.header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
}
.header_user_info {
  position: absolute;
  display: flex;
  align-items: center;
  right: 20px;
  top: 15px;
}
.header_user_img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #fff;
  overflow: hidden;
}
.header_user_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header_user_name {
  margin-left: 10px;
  font-size: 15px;
  color: #fff;
}
.header_user_center {
  margin-left: 15px;
  font-size: 15px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.header_user_line {
  margin: 0 15px;
  font-size: 15px;
  color: #fff;
}
.header_user_exit {
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}

.header_option {
  position: absolute;
  right: 0px;
  bottom: 15px;
}
.header_option span {
  padding: 0 20px;
  border-right: 1px solid #fff;
  font-size: 17px;
  flex-wrap: 600;
  color: #fff;
  cursor: pointer;
}
.header_option span:nth-child(6) {
  border-right: 0px solid #fff;
}
.content {
  width: 1200px;
  margin: 40px auto;
  overflow: auto;
  min-height: 800px;
}
.gauge_name {
  margin-top: 20px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: var(--color-main);
}
.margin_40px {
  margin-top: 40px;
}
.margin_20px {
  margin-top: 20px;
}
.top_right_borfer {
  border: 1px solid #dcdfe6;
  border-bottom-width: 0;
  border-left-width: 0;
}
.bottom_left_borfer {
  padding: 10px;
  border: 1px solid #dcdfe6;
  border-top-width: 0;
  border-right-width: 0;
}
.gauge_title {
  margin-top: 30px;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
.gauge_marks {
  text-indent: 1.5em;
  margin-top: 5px;
  font-size: 15px;
  color: #606266;
}
.total_evaluate {
  background-color: #f3f3f3;
}
.right_borfer {
  border-right: 1px solid #dcdfe6;
}
.red_color {
  color: rgb(212, 6, 6);
  font-size: 14px;
}
.description {
  margin-top: 5px;
  text-indent: 1.5em;
}
.text_align_center {
  text-align: center;
}
.question {
  padding: 10px;
}
.answer {
  padding: 5px;
  background-color: #fff;
}
.padding_0px {
  padding: 0px;
}
</style>