import axios from 'axios'

export function request(config) {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  let headers = {};
  if (userInfo && userInfo.token) {
    headers = {
      'Authorization': 'Bearer ' + userInfo.token,
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }
  } else {
    headers = { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
  }

  const instance = axios.create({
    baseURL: 'https://api.jingxin-inc.com/interface',
    // baseURL: 'https://dev-api.jingxin-inc.com/interface',
    // baseURL: 'http://192.168.0.101/interface',
    headers,
    timeout: 10000,
  })
  // instance.interceptors.request.use(config => {
  //   return config
  // }, err => {
  // })
  instance.interceptors.response.use(res => {
    return res.data
  }, err => {
    // if (err.response.status === 401) {
    //   window.location.href = "https://kpc.cowsms.com/wechat/oauth";
    //   return
    // }

  })
  return instance(config)
}
