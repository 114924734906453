<template>
  <div class="consult">
    <div class="header">
      <div class="header_bg">
        <img src="../assets/img/header_bg1.png" alt="" />
      </div>
      <div class="header_logo" @click="goHome"></div>
      <div class="header_user_info">
        <div class="header_user_img">
          <img :src="userInfo.avatar" />
        </div>
        <div class="header_user_name">HI，{{ userInfo.nickname }}</div>
        <div class="header_user_center" @click="userCenterClick">个人中心</div>
        <div class="header_user_line">|</div>
        <div class="header_user_exit" @click="logoutClick">退出登录</div>
      </div>
      <div class="header_option">
        <span @click="headerOptionClick(-1)">首页</span>
        <span @click="headerOptionClick(0)">量表测评</span>
        <span @click="headerOptionClick(1)">心理咨询</span>
        <span @click="headerOptionClick(2)">名师课程</span>
        <span @click="headerOptionClick(3)">在线商城</span>
        <span @click="headerOptionClick(4)">共享咨询室</span>
        <span @click="headerOptionClick(5)">员工关爱方案</span>
      </div>
    </div>
    <div class="consult_title">我的咨询</div>

    <div style="width: 1200px; margin: 0 auto; min-height: 500px">
      <el-tabs
        class="tabs"
        v-model="activeName"
        type="card"
        @tab-click="handleClick"
      >
        <el-tab-pane :label="consult_stay_pay_count_text" name="0">
        </el-tab-pane>
        <el-tab-pane :label="consult_stay_confrim_count_text" name="1">
        </el-tab-pane>
        <el-tab-pane :label="consult_stay_start_count_text" name="3">
        </el-tab-pane>
        <el-tab-pane :label="consult_stay_evaluate_count_text" name="5">
        </el-tab-pane>
        <el-tab-pane :label="consult_stay_complete_count_text" name="6">
        </el-tab-pane>
        <el-tab-pane label="全部咨询" name="1000"> </el-tab-pane>
      </el-tabs>
      <div>
        <ul class="consult_list">
          <li
            class="consult_list_item"
            v-for="i in consultList"
            :key="i.order_sn"
            @click="consultItemClick(i.order_sn, i.trade_type)"
          >
            <div class="item_avatar_bg">
              <el-image
                class="item_avatar_image"
                :src="i.item.user && i.item.user.avatar"
                fit="cover"
              ></el-image>
              <div class="item_avatar_info">
                <div class="item_avatar_consult_type">
                  预约类型：{{
                    consult_type(i.item.consult_type, i.trade_type)
                  }}
                </div>
                <div class="item_avatar_consult_type">
                  咨询状态：
                  <span style="color: #15bdbb">{{
                    i.item.consult_status_msg
                  }}</span>
                </div>
                <div class="item_avatar_consultant_name">
                  专家名称：{{ i.item.consultant_name }}
                  <span style="color: #15bdbb" v-if="i.item.consult_status_msg1"
                    >({{ i.item.consult_status_msg1 }})</span
                  >
                </div>
                <div class="item_avatar_consultant_time">
                  预约时间：{{
                    i.item.consult_start_time + "-" + i.item.consult_end_time
                  }}
                </div>
                <div class="item_avatar_consultant_duration">
                  合计时长：{{ i.item.duration }}
                </div>
                <div
                  class="item_avatar_consultant_duration"
                  v-if="i.item.consult_cancel_reason"
                >
                  取消原因：{{ i.item.consult_cancel_reason }}
                </div>
              </div>
            </div>
            <div class="order_btn_bg">
              <!-- <el-button plain @click.stop="goToWchat">联系客服</el-button> -->
              <el-button
                plain
                v-if="i.item.cancel_status"
                @click.stop="cancelOrderClick(i.order_sn, i.trade_type)"
                >取消咨询</el-button
              >
              <el-button
                plain
                v-if="i.item.consult_status == '0'"
                @click.stop="goToPay(i.order_sn)"
                >去付款</el-button
              >
              <el-button
                plain
                v-if="i.item.consult_status == '5'"
                @click.stop="evaluateOrderClick(i.order_sn, i.trade_type)"
                >去评价</el-button
              >
              <el-button
                v-if="i.item.consult_status == '4'"
                plain
                @click.stop="intoRoomClick(i)"
                >进入咨询室</el-button
              >
              <el-button
                v-if="i.item.need_consult_information_collection"
                plain
                @click.stop="intoFormClick(i)"
                >填写初次咨询信息</el-button
              >
            </div>
          </li>
        </ul>
        <p v-if="loading" class="load_footer">加载中...</p>
        <p v-if="noMore" class="load_footer">没有更多了</p>
      </div>
    </div>
    <copy-footer class="footer" />
    <!-- 取消预约弹窗 -->
    <el-dialog title="取消咨询" v-model="cancelDialogFormVisible">
      <el-form :model="cancelForm" style="padding: 15px">
        <el-form-item label="取消咨询原因">
          <el-input
            v-model="cancelForm.consult_cancel_reason"
            placeholder="请输入取消原因"
            autocomplete="off"
            type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancelDialogFormVisible = false">否</el-button>
          <el-button
            type="primary"
            @click="cancelDialogFormVisibleClick(this.trade_type)"
            >是</el-button
          >
        </span>
      </template>
    </el-dialog>
    <!-- 评价预约弹窗 -->
    <el-dialog title="评价专家" v-model="evaluateDialogFormVisible">
      <el-form :model="evaluateForm" style="padding: 15px">
        <el-form-item label="总体满意度:">
          <el-radio-group
            v-model="evaluateForm.overall_satisfaction"
            size="mini"
          >
            <el-radio label="非常满意">非常满意</el-radio>
            <el-radio label="比较满意">比较满意</el-radio>
            <el-radio label="一般">一般</el-radio>
            <el-radio label="不满意">不满意</el-radio>
            <el-radio label="非常不满意">非常不满意</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- <el-form-item label="咨询效果:">
          <el-radio-group v-model="evaluateForm.consultation_effect">
            <el-radio label="非常满意">非常满意</el-radio>
            <el-radio label="比较满意">比较满意</el-radio>
            <el-radio label="一般">一般</el-radio>
            <el-radio label="不满意">不满意</el-radio>
            <el-radio label="非常不满意">非常不满意</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="专业度:">
          <el-radio-group v-model="evaluateForm.professionalism">
            <el-radio label="非常满意">非常满意</el-radio>
            <el-radio label="比较满意">比较满意</el-radio>
            <el-radio label="一般">一般</el-radio>
            <el-radio label="不满意">不满意</el-radio>
            <el-radio label="非常不满意">非常不满意</el-radio>
          </el-radio-group>
        </el-form-item> -->
        <el-form-item label="咨询师态度:">
          <el-input
            type="textarea"
            v-model="evaluateForm.consultation_attitude"
            placeholder="请输入文字"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="evaluateDialogFormVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="evaluateDialogFormVisibleClick"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>
    <!-- 重新支付二维码 -->
    <el-dialog title="扫码重新支付" v-model="codeDialogFormVisible" center>
      <div class="qrcode" ref="qrCodeUrl"></div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="codeDialogFormVisible = false"
            >完 成</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import CopyFooter from "../components/footer/CopyFooter.vue";
import QRCode from "qrcodejs2";
import {
  consult_orders_list,
  post_cancel_order,
  post_consult_evaluate,
  post_re_payment,
  accredit_consult_orders,
  accredit_consult_order_post_consult_evaluate,
  accredit_consult_order_post_cancel_order,
} from "../network/views-request";

export default {
  name: "consult",
  components: { CopyFooter },
  props: {},
  data() {
    return {
      userInfo: {},
      activeName: "3",
      params: {
        index: 0,
        count: 20,
        consult_status: 3,
      },
      consultList: [],
      accreditList: [], //驻场数组
      loading: false,
      allCount: 1000,
      consult_stay_start_count: 0, //待开始咨询数
      consult_stay_confrim_count: 0, //待确认咨询数
      consult_stay_evaluate_count: 0, //待评价咨询数
      consult_cancel_count: 0, //已取消咨询数
      consult_stay_pay_count: 0, //待支付
      consult_stay_complete_count: 0, //已完成
      selectOrdersn: "", //选中的订单号
      trade_type: "", //选中的订单线上线下/驻场

      cancelDialogFormVisible: false, //取消咨询弹窗
      cancelForm: {
        //取消咨询表单
        consult_cancel_reason: "",
      },
      evaluateDialogFormVisible: false, //取消咨询弹窗
      evaluateForm: {
        overall_satisfaction: "",
        consultation_effect: "",
        professionalism: "",
        consultation_attitude: "",
      },
      codeDialogFormVisible: false, //重新支付天窗
      code_url: "", //支付二维码
    };
  },
  computed: {
    noMore() {
      return this.allCount <= this.consultList.length;
    },
    disabled() {
      return this.loading || this.noMore;
    },
    consult_type() {
      return (consult_type, trade_type) => {
        if (trade_type == "5") {
          return "驻场咨询";
        } else {
          return consult_type == "1" ? "线上咨询" : "线下咨询";
        }
      };
    },
    consult_stay_pay_count_text() {
      return "待付款（" + this.consult_stay_pay_count + ")";
    },
    consult_stay_confrim_count_text() {
      return "待确认（" + this.consult_stay_confrim_count + ")";
    },
    consult_stay_start_count_text() {
      return "待开始（" + this.consult_stay_start_count + ")";
    },
    consult_stay_evaluate_count_text() {
      return "待评价（" + this.consult_stay_evaluate_count + ")";
    },
    consult_cancel_count_text() {
      return "已取消（" + this.consult_cancel_count + ")";
    },
    consult_stay_complete_count_text() {
      return "已完成（" + this.consult_stay_complete_count + ")";
    },
  },
  created() {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    if (info) {
      this.userInfo = info;
    }
    this.getList();
  },
  mounted() {
    window.addEventListener("scroll", this.getMore); //监听滚动
  },
  destroyed() {
    window.removeEventListener("scroll", this.getMore);
  },
  methods: {
    //退出登录
    logoutClick() {
      localStorage.clear();
      this.$router.push({ path: "/" });
    },
    //个人中心
    userCenterClick() {
      this.$router.push({ path: "/usercenter" });
    },
    //回主页
    goHome() {
      this.$router.push({ path: "/" });
    },
    headerOptionClick(index) {
      if (index == 0) {
        this.$router.push({ path: "/survey" });
      } else if (index == 1) {
        this.$router.push({ path: "/consult" });
      } else if (index == 2) {
        this.$router.push({ path: "/course" });
      } else if (index == 3) {
        this.$router.push({ path: "/shopping" });
      } else if (index == 4) {
        this.$router.push({ path: "/room" });
      } else if (index == 5) {
        this.$router.push({ path: "/concern" });
      } else {
        this.goHome();
      }
    },
    //点击tab
    handleClick(tab, event) {
      this.consultList = [];
      this.accreditList = [];
      this.params.index = 1;
      this.params.consult_status = tab.props.name;
      this.getList();
    },
    //点击订单筛选
    tagOnChange(index) {
      if (this.params.consult_status == index) {
        return;
      }
      this.params.consult_status = index;
      this.params.index = 1;
      this.consultList = [];
      this.accreditList = [];
      this.getList();
    },
    //点击每个订单
    consultItemClick(order_sn, trade_type) {
      if (trade_type == "5") {
        this.$router.push({
          path: "/consultaccreditorderdetail",
          query: { order_sn },
        });
      } else {
        this.$router.push({ path: "/consultorderdetail", query: { order_sn } });
      }
    },
    //填写表单信息
    intoFormClick(item) {
      let order_sn = item.order_sn;
      //初次表单收集
      this.$router.push({
        path: "/consultplaceorderform",
        query: { order_sn: order_sn },
      });
    },
    //进入咨询室
    intoRoomClick(item) {
      let password = item.item.meeting_password
        ? item.item.meeting_password
        : "无";
      this.$confirm("确认进入咨询室吗?房间密码：" + password, "提示", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      }).then(() => {
        if (item.item.meeting_room_url) {
          window.open(item.item.meeting_room_url);
        } else {
          this.$message({
            type: "error",
            message: "咨询室进入失败，请稍后再试",
          });
        }
      });
    },
    //取消咨询
    cancelOrderClick(order_sn, trade_type) {
      this.selectOrdersn = order_sn;
      this.trade_type = trade_type;
      this.cancelDialogFormVisible = true;
    },
    //取消咨询确认
    cancelDialogFormVisibleClick(trade_type) {
      this.cancelDialogFormVisible = false;

      if (this.cancelForm.consult_cancel_reason.length == 0) {
        this.$message.error("请输入取消原因");
        return;
      }
      let data = {
        order_sn: this.selectOrdersn,
        consult_cancel_reason: this.cancelForm.consult_cancel_reason,
      };

      if (trade_type == "5") {
        accredit_consult_order_post_cancel_order(data).then((res) => {
          if (res.code == 200) {
            this.cancelForm.consult_cancel_reason = "";
            this.$message.success("取消成功");
            this.params.index = 1;
            this.consultList = [];
            this.accreditList = [];
            this.getAccreditList();
          } else {
            this.$message.error(res.message);
          }
        });
      } else {
        post_cancel_order(data).then((res) => {
          if (res.code == 200) {
            this.cancelForm.consult_cancel_reason = "";
            this.$message.success("取消成功");
            this.params.index = 1;
            this.consultList = [];
            this.accreditList = [];
            this.getList();
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
    //提示去微信
    goToWchat() {
      this.$alert("请前去微信小程序操作", "提示", {
        confirmButtonText: "确定",
      });
    },
    //重新支付
    goToPay(order_sn) {
      this.$router.push({
        path: "/consultrepayorder",
        query: { order_sn: order_sn },
      });
      // this.codeDialogFormVisible = true;
      // let data = {
      //   order_sn,
      // };
      // post_re_payment(data).then((res) => {
      //   if (res.code == 200) {
      //     this.code_url = res.data.code_url;
      //     this.creatQrCode(res.data.code_url);
      //   } else {
      //     this.$message.error(res.message);
      //   }
      // });
    },
    //评价专家
    evaluateOrderClick(order_sn, trade_type) {
      this.selectOrdersn = order_sn;
      this.trade_type = trade_type;
      this.evaluateDialogFormVisible = true;
    },
    //评价专家
    evaluateDialogFormVisibleClick() {
      this.evaluateDialogFormVisible = false;
      if (this.evaluateForm.overall_satisfaction.length == 0) {
        this.$message.error("请选择总体满意度");
        return;
      }
      // if (this.evaluateForm.consultation_effect.length == 0) {
      //   this.$message.error("请选择咨询效果");
      //   return;
      // }
      // if (this.evaluateForm.professionalism.length == 0) {
      //   this.$message.error("请选择专业度");
      //   return;
      // }
      let data = {
        order_sn: this.selectOrdersn,
        overall_satisfaction: this.evaluateForm.overall_satisfaction,
        consultation_effect: this.evaluateForm.consultation_effect,
        professionalism: this.evaluateForm.professionalism,
        consultation_attitude: this.evaluateForm.consultation_attitude,
      };

      if (this.trade_type == "5") {
        //驻场评价
        accredit_consult_order_post_consult_evaluate(data).then((res) => {
          if (res.code == 200) {
            this.evaluateForm.overall_satisfaction = "";
            this.evaluateForm.consultation_effect = "";
            this.evaluateForm.professionalism = "";
            this.evaluateForm.consultation_attitude = "";
            this.$message.success("评价成功");
            this.params.index = 1;
            this.consultList = [];
            this.accreditList = [];
            this.getAccreditList();
          } else {
            this.$message.error(res.message);
          }
        });
      } else {
        post_consult_evaluate(data).then((res) => {
          if (res.code == 200) {
            this.evaluateForm.overall_satisfaction = "";
            this.evaluateForm.consultation_effect = "";
            this.evaluateForm.professionalism = "";
            this.evaluateForm.consultation_attitude = "";
            this.$message.success("评价成功");
            this.params.index = 1;
            this.consultList = [];
            this.accreditList = [];
            this.getList();
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
    //咨询订单列表
    getList() {
      this.loading = true;
      let params = {};
      if (this.params.consult_status == 1000) {
        params.index = this.params.index;
        params.count = this.params.count;
      } else {
        params = this.params;
      }

      consult_orders_list(params).then((res) => {
        if (res.code == 200) {
          this.allCount = res.data.all_count;
          this.consult_stay_start_count = res.data.consult_stay_start_count;
          this.consult_stay_confrim_count = res.data.consult_stay_confrim_count;
          this.consult_stay_evaluate_count =
            res.data.consult_stay_evaluate_count;
          this.consult_cancel_count = res.data.consult_cancel_count;
          this.consult_stay_pay_count = res.data.consult_stay_pay_count;
          this.consult_stay_complete_count =
            res.data.consult_stay_complete_count;

          this.consultList = this.consultList.concat(res.data.list);
        } else {
          this.$message.error(res.message);
        }
        this.loading = false;
      });
    },
    //驻场订单
    getAccreditList() {
      let params = {};
      if (this.params.consult_status == 1000) {
        params.index = this.params.index;
        params.count = this.params.count;
      } else {
        params.index = this.params.index;
        params.count = this.params.count;
        params.consult_status = this.params.consult_status;
      }

      accredit_consult_orders(params).then((res) => {
        if (res.code == 200) {
          this.allCount = res.data.all_count;
          this.consult_stay_start_count = res.data.consult_stay_start_count;
          this.consult_stay_evaluate_count =
            res.data.consult_stay_evaluate_count;
          this.consult_cancel_count = res.data.consult_cancel_count;
          this.consult_stay_pay_count = res.data.consult_stay_pay_count;
          this.accreditList = this.accreditList.concat(res.data.list);
        } else {
          this.$message.error(res.message);
        }
        this.loading = false;
      });
    },
    //滑动到底部
    getMore() {
      let clientHeight = document.documentElement.clientHeight; //获取html的可视高度
      let scrollTop = document.documentElement.scrollTop; //获取html的滚动高度
      let scrollHeight = document.documentElement.scrollHeight; //获取文档的实际高度
      //判断如果html的可视高度加上滚动高度等于文档的实际高度的话,就关闭开关,否则会造成数据混乱
      if (clientHeight + scrollTop >= scrollHeight) {
        this.load();
      }
    },
    //加载更多
    load() {
      if (!this.noMore && !this.loading) {
        this.params.index += 1;
        this.getList();
      }
    },
    //生成二维码
    creatQrCode(code_url) {
      let dom = this.$refs.qrCodeUrl;
      while (dom.firstChild) {
        dom.removeChild(dom.firstChild);
      }
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: code_url, // 需要转换为二维码的内容
        width: 150,
        height: 150,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
  },
};
</script>
<style scoped>
.header {
  position: relative;
}
.header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
}
.header_user_info {
  position: absolute;
  display: flex;
  align-items: center;
  right: 20px;
  top: 15px;
}
.header_user_img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #fff;
  overflow: hidden;
}
.header_user_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header_user_name {
  margin-left: 10px;
  font-size: 15px;
  color: #fff;
}
.header_user_center {
  margin-left: 15px;
  font-size: 15px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.header_user_line {
  margin: 0 15px;
  font-size: 15px;
  color: #fff;
}
.header_user_exit {
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}

.header_option {
  position: absolute;
  right: 0px;
  bottom: 15px;
}
.header_option span {
  padding: 0 20px;
  border-right: 1px solid #fff;
  font-size: 17px;
  flex-wrap: 600;
  color: #fff;
  cursor: pointer;
}
.header_option span:nth-child(6) {
  border-right: 0px solid #fff;
}
.consult_title {
  margin-top: 50px;
  text-align: center;
  font-size: 19px;
  font-weight: 600;
}
.tabs {
  margin-top: 30px;
}

.consult_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: visible;
}
.consult_list_item {
  margin: 30px;
  padding: 30px;
  width: 45%;
  min-width: 500px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #cecece;
}
.item_avatar_image {
  width: 150px;
  height: 150px;
}
.item_avatar_bg {
  display: flex;
}
.item_avatar_info {
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 14px;
  color: #646464;
}
.order_btn_bg {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
.load_footer {
  margin-top: 20px;
  font-size: 14px;
  text-align: center;
  color: #909399;
}
.qrcode {
  width: 150px;
  margin: 0 auto;
}
.footer {
  margin-top: 100px;
}
:deep(.el-textarea__inner) {
  min-height: 100px !important;
}
:deep(.el-form-item__label) {
  font-size: 15px;
}
:deep(.el-radio__label) {
  font-weight: normal;
}
</style>