<template>
  <div class="home">
    <div class="header">
      <div class="header_bg">
        <img v-if="isVip" src="../assets/img/header_bg_vip.png" alt="" />
        <img v-else src="../assets/img/header_bg.png" alt="" />
      </div>
      <div class="header_user_info" v-show="userInfo.user_id">
        <div class="header_user_img">
          <img :src="userInfo.avatar" />
        </div>
        <div class="header_user_name">HI，{{ userInfo.nickname }}</div>
        <div class="header_user_center" @click="userCenterClick">个人中心</div>
        <div class="header_user_line">|</div>
        <div class="header_user_exit" @click="outloginClick">退出登录</div>
      </div>
      <div class="header_option">
        <span @click="headerOptionClick(0)">量表测评</span>
        <span @click="headerOptionClick(1)">心理咨询</span>
        <span @click="headerOptionClick(2)">名师课程</span>
        <span @click="headerOptionClick(3)">在线商城</span>
        <span @click="headerOptionClick(4)">共享咨询室</span>
        <span @click="headerOptionClick(5)">员工关爱方案</span>
      </div>
    </div>
    <div class="content">
      <div class="content_bg">
        <img v-if="isVip" src="../assets/img/home_content_bg_vip.png" alt="" />
        <img v-else src="../assets/img/home_content_bg.png" alt="" />
      </div>
      <div class="login_bg" v-show="!userInfo.user_id">
        <div class="login_title">用户登录</div>
        <el-row class="account_bg" type="flex" justify="center" align="middle">
          <el-col :span="15">
            <el-input v-model="account" placeholder="请输入手机号"></el-input>
          </el-col>
        </el-row>
        <el-row
          v-show="loginType == 1"
          class="code_bg"
          :gutter="13"
          type="flex"
          justify="center"
          align="middle"
        >
          <el-col :span="9">
            <el-input placeholder="请输入验证码" v-model="code"> </el-input>
          </el-col>
          <el-col :span="6">
            <div
              class="code_btn"
              :class="isVip ? 'vip_bgc' : ''"
              :disabled="sendCodeDisabled"
              @click="sendCodeClick"
            >
              {{ sendCodeTxt }}
            </div>
          </el-col>
        </el-row>
        <el-row
          v-show="loginType == 2"
          class="code_bg"
          type="flex"
          justify="center"
          align="middle"
        >
          <el-col :span="15">
            <el-input placeholder="请输入密码" v-model="password" show-password>
            </el-input>
          </el-col>
        </el-row>
        <div class="login-btn" :class="isVip ? 'vip_bgc' : ''" @click="goLogin">
          登录
        </div>
        <!-- <div class="login-register" @click="registerClick">新用户注册</div> -->
        <div class="forget_wp">
          <!-- <div
            class="forget"
            :class="isVip ? 'vip_color' : ''"
            @click="registerClick"
          >
            新用户注册&nbsp;&nbsp;&nbsp;|
          </div>
          <div
            class="forget"
            :class="isVip ? 'vip_color' : ''"
            @click="forgetClick"
          >
            &nbsp;&nbsp;&nbsp;忘记密码&nbsp;&nbsp;&nbsp;|
          </div>
          <div
            class="password_type"
            :class="isVip ? 'vip_color' : ''"
            @click="loginTypeClick"
          >
            &nbsp;&nbsp;&nbsp;{{ loginTypeShow }}&nbsp;&nbsp;&nbsp;|
          </div> -->
          <div
            class="forget"
            :class="isVip ? 'vip_color' : ''"
            @click="wxLoginClick"
          >
            &nbsp;&nbsp;&nbsp;微信登录&nbsp;&nbsp;&nbsp;
          </div>
        </div>
      </div>

      <!-- 忘记密码 -->
      <div class="forget_bg" v-show="loginType == 3">
        <div
          class="login_back"
          :class="isVip ? 'vip_color' : ''"
          @click="loginBackClick"
        >
          {{ "< 返回" }}
        </div>
        <div class="login_title">找回密码</div>
        <el-row class="account_bg" type="flex" justify="center" align="middle">
          <el-col :span="15">
            <el-input
              v-model="forgetPhone"
              placeholder="请输入手机号"
            ></el-input>
          </el-col>
        </el-row>
        <el-row
          class="code_bg"
          :gutter="13"
          type="flex"
          justify="center"
          align="middle"
        >
          <el-col :span="9">
            <el-input placeholder="请输入验证码" v-model="forgetCode">
            </el-input>
          </el-col>
          <el-col :span="6">
            <div
              class="code_btn"
              :class="isVip ? 'vip_bgc' : ''"
              :disabled="sendCodeDisabled"
              @click="sendForgetCodeClick"
            >
              {{ sendCodeTxt }}
            </div>
          </el-col>
        </el-row>
        <el-row class="code_bg" type="flex" justify="center" align="middle">
          <el-col :span="15">
            <el-input
              placeholder="请输入新密码"
              v-model="forgetPassworld"
              show-password
            >
            </el-input>
          </el-col>
        </el-row>
        <el-row class="code_bg" type="flex" justify="center" align="middle">
          <el-col :span="15">
            <el-input
              placeholder="请再次确认密码"
              v-model="forgetPassworldConfirmation"
              show-password
            >
            </el-input>
          </el-col>
        </el-row>
        <div
          class="login-btn"
          :class="isVip ? 'vip_bgc' : ''"
          @click="goForget"
        >
          重置密码
        </div>
      </div>
      <!-- 微信登录 -->
      <div class="forget_bg" v-show="loginType == 4">
        <div
          class="login_back"
          :class="isVip ? 'vip_color' : ''"
          @click="loginBackClick"
        >
          {{ "< 返回" }}
        </div>
        <div class="login_title">微信登录</div>
        <div id="login_container" class="qrcode" ref="qrCodeUrl"></div>
      </div>

      <!-- 充值 -->
      <div class="content_recharge" v-show="loginType != 3 && loginType != 4">
        <img src="../assets/img/home_recharge.png" />
        <div class="content_recharge_btn" @click="rechargeClick">立即充值</div>
      </div>
      <!-- 用户信息 -->
      <div class="content_user" v-show="userInfo.user_id">
        <div class="content_user_info">
          <div class="bind">
            <!-- <div class="bind_id" @click="bindIDFormVisible = true">
              绑定项目ID
            </div> -->
            <div class="bind_id" @click="myRoomClick">
              我的咨询室({{ homeInfo.consult_room_order_count }})
            </div>
            <div class="message" @click="messageClick">
              <div class="message-icon">
                <img src="../assets/img/new_message.png" alt="" />
              </div>
              ({{ homeInfo.unread_count }}条新消息)
            </div>
          </div>
          <div class="user_con">
            <div class="header_bgc">
              <img
                class="header_bgc_avatar"
                :src="homeInfo.avatar"
                @click="userCenterClick"
              />
              <img
                v-if="homeInfo.project_count"
                class="header_bgc_img"
                src="../assets/img/header_background.png"
                alt=""
              />
            </div>
            <div class="user_info">
              <div class="user_name">{{ homeInfo.nickname }}</div>
              <div class="vip_type">
                会员类型：{{
                  homeInfo.project_count == 0 ? "普通会员" : "企业会员"
                }}
                <span class="user_coupon" @click="couponClick">优惠券</span>
              </div>
              <div class="vip_type">
                心币数量：{{ homeInfo.heart_coin
                }}<span
                  class="heart_coin_look"
                  :class="isVip ? 'vip_color' : ''"
                  @click="heartcoinClick"
                  >查看</span
                >
              </div>

              <div class="vip_type">绑定项目：{{ homeInfo.project_str }}</div>
            </div>
          </div>
          <!-- <div
            class="out_login"
            :class="isVip ? 'vip_color' : ''"
            @click="outloginClick"
          >
            退出登录
          </div> -->

          <!-- <div class="lookat">
            <div class="look_message">
              {{ newer_consult }}
            </div>
            <div class="look_btn">查看详情</div>
          </div> -->
        </div>
        <div
          class="contetn_option"
          :class="{ contetn_option_border_top: isVip }"
        >
          <div class="option_item" @click="contetnOptionClick(1)">
            <img v-if="isVip" src="../assets/img/option_care_vip.png" alt="" />
            <img v-else src="../assets/img/option_care.png" alt="" />
            <div class="option_name">员工关爱</div>
            <div>({{ homeInfo.project_count }})</div>
          </div>
          <div class="option_item" @click="contetnOptionClick(6)">
            <img v-if="isVip" src="../assets/img/option_goods_vip.png" alt="" />
            <img v-else src="../assets/img/option_goods.png" alt="" />
            <div class="option_name">我的商品</div>
            <div>({{ homeInfo.item_order_count }})</div>
          </div>
          <div class="option_item" @click="contetnOptionClick(2)">
            <img
              v-if="isVip"
              src="../assets/img/option_evaluating_vip.png"
              alt=""
            />
            <img v-else src="../assets/img/option_evaluating.png" alt="" />
            <div class="option_name">我的测评</div>
            <div>({{ homeInfo.gauge_order_count }})</div>
          </div>
          <div class="option_item" @click="contetnOptionClick(3)">
            <img v-if="isVip" src="../assets/img/option_play_vip.png" />
            <img v-else src="../assets/img/option_play.png" />
            <div class="option_name">我的课程</div>
            <div>({{ homeInfo.video_order_count }})</div>
          </div>
          <div class="option_item" @click="contetnOptionClick(4)">
            <img
              v-if="isVip"
              src="../assets/img/option_message_vip.png"
              alt=""
            />
            <img v-else src="../assets/img/option_message.png" alt="" />
            <div class="option_name">我的咨询</div>
            <div>({{ homeInfo.consult_order_count }})</div>
          </div>
          <div class="option_item" @click="contetnOptionClick(5)">
            <img
              v-if="isVip"
              src="../assets/img/option_attent_vip.png"
              alt=""
            />
            <img v-else src="../assets/img/option_attent.png" alt="" />
            <div class="option_name">我的关注</div>
            <div>({{ homeInfo.follow_consultant_count }})</div>
          </div>
        </div>
      </div>
      <!-- <div class="content_consulting" @click="contentConsultingClick">
        <img src="../assets/img/home_consulting.png" alt="" />
      </div> -->
    </div>
    <copy-footer class="footer" :isVip="isVip"></copy-footer>
    <!-- 绑定项目id弹窗 -->
    <el-dialog title="绑定项目ID" v-model="bindIDFormVisible">
      <el-form :model="bindForm" label-width="100px" label-position="right">
        <el-form-item label="项目ID">
          <el-input
            style="width: 200px"
            v-model="bindForm.project_id"
            @change="bindIDChange"
          ></el-input>
        </el-form-item>
        <el-form-item label="唯一标识ID">
          <el-input
            style="width: 200px"
            v-model="bindForm.exclusive_code"
          ></el-input>
        </el-form-item>
        <el-form-item label="部门">
          <el-cascader
            placeholder="请选择部门"
            v-model="bindForm.selectName"
            :options="bindForm.departments"
          ></el-cascader>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="bindIDFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="bindIDConfig">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import CopyFooter from "../components/footer/CopyFooter";
import md5 from "js-md5";
import QRCode from "qrcodejs2";
import {
  send_login_sms,
  login_with_mobile,
  auth_login,
  users_me,
  project_departments,
  project_bind,
  send_password_reset_sms,
  auth_passwd_check,
  auth_passwd_reset,
  wechat_qrconnect_config,
} from "../network/views-request";

export default {
  name: "Home",
  components: { CopyFooter },
  props: {},
  data() {
    return {
      isVip: false, //是否是vip
      loginType: "1", //1:验证码 2：密码 3：忘记密码
      account: "",
      password: "",
      code: "",
      sendCodeDisabled: false,
      time: 60,
      sendCodeTxt: "获取验证码",
      userInfo: {},
      homeInfo: {},
      bindIDFormVisible: false, //绑定项目id弹窗展示
      bindForm: {
        project_id: "",
        exclusive_code: "", //唯一标识id
        departments: "",
        selectName: "",
      },
      forgetTime: 60,
      forgetPhone: "",
      forgetCode: "",
      forgetPassworld: "",
      forgetPassworldConfirmation: "",
      wxLoginConfig: null,
    };
  },
  computed: {
    loginTypeShow() {
      if (this.loginType == 1) {
        return "手机密码登录";
      } else {
        return "手机验证码登录";
      }
    },
    newer_consult() {
      if (
        this.homeInfo.newer_consult !== undefined &&
        this.homeInfo.newer_consult !== null &&
        this.homeInfo.newer_consult.length > 0
      ) {
        return "hahah";
      } else {
        return "预约信息：暂无";
      }
    },
  },
  created() {
    const userData = localStorage.getItem("userInfo");
    if (userData) {
      this.userInfo = JSON.parse(userData);
    }
    //type:1 Vip页面风格
    this.$route.query.type == "1" ? (this.isVip = true) : (this.isVip = false);
    //请求
    this.getUserInfo();
    this.wechat_qrconnect_config();
  },
  mounted() {
    const oScript = document.createElement("script");
    oScript.type = "text/javascript";
    oScript.src =
      "http://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js";
    document.body.appendChild(oScript);
  },
  methods: {
    //个人中心
    userCenterClick() {
      this.$router.push({ path: "/usercenter" });
    },
    headerOptionClick(index) {
      if (!(this.userInfo && this.userInfo.user_id)) {
        this.$message.error("请先登录");
        return;
      }
      if (index == 0) {
        this.$router.push({ path: "/survey" });
      } else if (index == 1) {
        this.$router.push({ path: "/consult" });
      } else if (index == 2) {
        this.$router.push({ path: "/course" });
      } else if (index == 3) {
        this.$router.push({ path: "/shopping" });
      } else if (index == 4) {
        this.$router.push({ path: "/room" });
      } else {
        this.$router.push({ path: "/concern" });
      }
    },
    //退出登录
    outloginClick() {
      localStorage.clear();
      location.reload();
      this.$router.push({ path: "/home", replace: true });
    },
    //个人中心
    userCenterClick() {
      this.$router.push({ path: "/usercenter" });
    },
    //点击查看心币
    heartcoinClick() {
      this.$router.push({ path: "/heartcoindetail" });
    },
    //不同订单点击
    contetnOptionClick(index) {
      if (index == 1) {
        //员工关爱
        this.$router.push({ path: "/project" });
      } else if (index == 2) {
        //我的评测
        this.$router.push({ path: "/surveyorderlist" });
      } else if (index == 3) {
        //点播
        this.$router.push({ path: "/courseorderlist" });
      } else if (index == 4) {
        //咨询
        this.$router.push({ path: "/consultorderlist" });
      } else if (index == 5) {
        //关注
        this.$router.push({ path: "/myattention" });
      } else {
        //商品
        this.$router.push({ path: "/shoppingorderlist" });
      }
    },
    //点击消息
    messageClick() {
      this.$router.push({ path: "/message" });
    },
    //点击充值
    rechargeClick() {
      if (!(this.userInfo && this.userInfo.user_id)) {
        this.$message.error("请先登录");
        return;
      }
      this.$router.push({ path: "/recharge" });
    },
    //优惠券点击
    couponClick() {
      this.$router.push({ path: "/coupon" });
    },
    //忘记密码
    forgetClick() {
      this.loginType = 3;
    },
    loginBackClick() {
      this.loginType = 1;
    },
    //微信登录
    wxLoginClick() {
      // this.loginType = 4;
      window.location.href = this.wxLoginConfig.qr_connect_url;
    },
    //登录
    goLogin() {
      if (this.loginType == "1") {
        const data = { mobile: this.account, code: this.code };

        login_with_mobile(data).then((res) => {
          if (res.code == 200) {
            localStorage.setItem("userInfo", JSON.stringify(res.data));
            localStorage.setItem("userInfos", JSON.stringify(res.data));
            this.userInfo = res.data;
            this.getUserInfo();
          } else {
            this.$message.error(res.message);
          }
        });
      } else {
        const data = { mobile: this.account, password: md5(this.password) };

        auth_login(data).then((res) => {
          if (res.code == 200) {
            this.userInfo = res.data;
            localStorage.setItem("userInfo", JSON.stringify(res.data));
            this.getUserInfo();
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
    //找回密码
    goForget() {
      if (!this.forgetPhone) {
        this.$message.error("请输入手机号码");
        return;
      }
      if (!this.forgetCode) {
        this.$message.error("请输入验证码");
        return;
      }
      if (!this.forgetPassworld) {
        this.$message.error("请输入新密码");
        return;
      }
      if (!this.forgetPassworldConfirmation) {
        this.$message.error("请输入再次确认密码");
        return;
      }
      const data = { mobile: this.forgetPhone, code: this.forgetCode };
      auth_passwd_check(data).then((res) => {
        if (res.code == 200) {
          let token = res.data.token;
          this.auth_reset(token);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //接口重置密码
    auth_reset(token) {
      const data = {
        mobile: this.forgetPhone,
        token: token,
        password: md5(this.forgetPassworld),
        password_confirmation: md5(this.forgetPassworldConfirmation),
      };
      auth_passwd_reset(data).then((res) => {
        if (res.code == 200) {
          this.$message.success("重置密码成功");
          this.loginType = 1;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //微信登录
    wechat_qrconnect_config() {
      wechat_qrconnect_config().then((res) => {
        if (res.code == 200) {
          this.wxLoginConfig = res.data.config;
          this.wxLoginConfig.qr_connect_url = res.data.qr_connect_url;
          this.wxLoginConfigInit(res.data);
          // this.creatQrCode(res.data.qr_connect_url);
        } else {
        }
      });
    },
    //配置微信登录参数
    wxLoginConfigInit(data) {
      var obj = new WxLogin({
        self_redirect: true,
        id: "login_container",
        appid: data.appid,
        scope: data.scope,
        redirect_uri: data.redirect_uri,
        state: data.state,
      });
    },
    //生成微信登录二维码
    creatQrCode(code_url) {
      let dom = this.$refs.qrCodeUrl;
      console.log(this.$refs);

      while (dom.firstChild) {
        dom.removeChild(dom.firstChild);
      }
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: code_url, // 需要转换为二维码的内容
        width: 150,
        height: 150,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
    //在线咨询
    contentConsultingClick() {
      if (!(this.userInfo && this.userInfo.user_id)) {
        this.$message.error("请先登录");
        return;
      }
      let url =
        "https://jingxin-inc.s4.udesk.cn/im_client/?web_plugin_id=20759&c_name=" +
        this.homeInfo.nickname +
        "&c_phone=" +
        this.homeInfo.phone +
        "&c_vip=" +
        this.homeInfo.project_count
          ? "vip"
          : "normal";
      window.open(url);
    },
    //注册
    registerClick() {
      this.$router.push("/register");
    },
    //切换登录方式
    loginTypeClick() {
      if (this.loginType == "1") {
        this.loginType = "2";
      } else {
        this.loginType = "1";
      }
    },
    //发送验证码
    sendCodeClick() {
      if (!this.account) {
        this.$message.error("请输入手机号码");
        return;
      }
      const data = { mobile: this.account };
      send_login_sms(data).then((res) => {
        if (res.code == 200) {
          this.time = 60;
          this.timer();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //发送找回密码验证码
    sendForgetCodeClick() {
      if (!this.forgetPhone) {
        this.$message.error("请输入手机号码");
        return;
      }
      const data = { mobile: this.forgetPhone };
      send_password_reset_sms(data).then((res) => {
        if (res.code == 200) {
          this.forgetTime = 60;
          this.forgetTimer();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //发送手机验证码倒计时
    timer() {
      if (this.time > 0) {
        this.sendCodeDisabled = true;
        this.time--;
        this.sendCodeTxt = this.time + "秒";
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.sendCodeTxt = "发送验证码";
        this.sendCodeDisabled = false;
      }
    },
    //发送找回密码验证码倒计时
    forgetTimer() {
      if (this.forgetTime > 0) {
        this.sendCodeDisabled = true;
        this.forgetTime--;
        this.sendCodeTxt = this.time + "秒";
        setTimeout(this.timer, 1000);
      } else {
        this.forgetTime = 0;
        this.sendCodeTxt = "发送验证码";
        this.sendCodeDisabled = false;
      }
    },
    //获取个人信息
    getUserInfo() {
      users_me().then((res) => {
        if (res.code == 200) {
          this.homeInfo = res.data;
          this.homeInfo.token = this.userInfo.token;
          localStorage.setItem("userInfo", JSON.stringify(this.homeInfo));
          this.userInfo = this.homeInfo;
        }
      });
    },
    //绑定项目id改变
    bindIDChange() {
      project_departments({ project_id: this.bindForm.project_id }).then(
        (res) => {
          if (res.code == 200) {
            this.bindForm.departments = res.data.department;
          } else {
            this.$message.error(res.message);
          }
        }
      );
    },
    //确认绑定项目
    bindIDConfig() {
      this.bindIDFormVisible = false;
      if (!this.bindForm.exclusive_code) {
        this.$message.error("请输入唯一标识ID");
        return;
      }
      project_bind({
        project_id: this.bindForm.project_id,
        exclusive_code: this.bindForm.exclusive_code,
      }).then((res) => {
        if (res.code == 200) {
          this.$message.success("绑定成功");
          location.reload();
        } else {
          this.$message.error(res.message);
        }
        this.bindForm.project_id = "";
        this.bindForm.departments = "";
        this.bindForm.exclusive_code = "";
        this.bindForm.selectName = "";
      });
    },
    //点击我的咨询室
    myRoomClick() {
      this.$router.push({ path: "/roomorderlist" });
    },
  },
};
</script>

<style scoped>
.header {
  position: relative;
}

.header_option {
  position: absolute;
  right: 20px;
  bottom: 15px;
}
.header_option span {
  padding: 0 20px;
  border-right: 1px solid #fff;
  font-size: 17px;
  flex-wrap: 600;
  color: #fff;
  cursor: pointer;
}
.header_option span:nth-child(6) {
  border-right: 0px solid #fff;
}

.content {
  position: relative;
}
.content_consulting {
  position: absolute;
  top: -20px;
  right: -20px;
  width: 450px;
  height: 120px;
  cursor: pointer;
}
.content_user {
  position: absolute;
  top: -20px;
  left: 50px;
  width: 400px;
}
.content_user_info {
  padding: 15px;
  background-color: #fff;
  border-radius: 15px;
}
.content_recharge {
  position: absolute;
  top: 270px;
  left: 50px;
  width: 400px;
}
.content_recharge_btn {
  position: absolute;
  top: 60px;
  right: 15px;
  width: 90px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  border-radius: 12px;
  font-size: 14px;
  background-color: #f0e8d4;
  color: #414141;
}
.bind {
  display: flex;
  justify-content: space-between;
}
.bind_id {
  font-size: 13px;
  text-decoration: underline;
  cursor: pointer;
}
.out_login {
  position: absolute;
  top: 150px;
  right: 20px;
  font-size: 13px;
  color: var(--color-main);
  cursor: pointer;
}
.message {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  text-decoration: underline;
  cursor: pointer;
}
.message-icon {
  margin-right: 5px;
  width: 20px;
  height: 20px;
}
.user_con {
  margin: 20px 0 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header_bgc {
  position: relative;
}
.header_bgc_avatar {
  width: 70px;
  height: 70px;
  border-radius: 35px;
}
.header_bgc_img {
  position: absolute;
  left: -5px;
  top: -20px;
  width: 80px;
}
.user_info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 50px;
  font-size: 13px;
}
.user_name {
  font-size: 17px;
  font-weight: 600;
}
.vip_type {
  font-size: 12px;
  color: #242424;
  font-weight: 350;
}
.user_coupon {
  color: var(--color-main);
  cursor: pointer;
}
.heart_coin_look {
  margin-left: 10px;
  color: var(--color-main);
  cursor: pointer;
}
.lookat {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.look_message {
  font-size: 12px;
  color: #ba150c;
}
.look_btn {
  padding: 0 20px;
  font-size: 12px;
  height: 24px;
  background-color: var(--color-main);
  border-radius: 12px;
  line-height: 24px;
  color: #fff;
  cursor: pointer;
}
.contetn_option {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  background-color: #fff;
  border-radius: 15px;
  border-top: 1px dashed var(--color-main);
}
.contetn_option_border_top {
  border-top: 1px dashed #31445d;
}
.option_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: #303133;
  line-height: 1;
  cursor: pointer;
}
.option_item img {
  width: 45px;
  height: 45px;
}
.option_name {
  margin-top: 5px;
}

.login_bg {
  position: absolute;
  top: -20px;
  left: 50px;
  width: 400px;
  border-radius: 10px;
  background-color: #fff;
}
.forget_bg {
  position: absolute;
  top: -20px;
  left: 50px;
  width: 400px;
  border-radius: 10px;
  background-color: #fff;
}
.login_title {
  padding-top: 20px;
  text-align: center;
  font-size: 17px;
}
.login_back {
  margin-top: 20px;
  margin-left: 20px;
  font-size: 15px;
  color: var(--color-main);
  text-decoration: underline;
  cursor: pointer;
}
.account_bg {
  margin-top: 20px;
}
.password_bg {
  margin-top: 10px;
}
.login-btn {
  margin: 30px 100px;
  padding: 10px 0;
  text-align: center;
  background-color: var(--color-main);
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
}
.login-register {
  margin-bottom: 20px;
  font-size: 13px;
  text-align: center;
  color: var(--color-main);
  text-decoration: underline;
  cursor: pointer;
}

.forget_wp {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  font-size: 13px;
  color: var(--color-main);
}
.forget {
  cursor: pointer;
}
.password_type {
  cursor: pointer;
}
.code_bg {
  margin-top: 10px;
}
.code_btn {
  padding: 8px 10px;
  background-color: var(--color-main);
  font-size: 14px;
  color: #fff;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
}
.vip_color {
  color: #31445d;
}
.vip_bgc {
  background-color: #31445d;
}
.header_user_info {
  position: absolute;
  display: flex;
  align-items: center;
  right: 20px;
  top: 15px;
}
.header_user_img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #fff;
  overflow: hidden;
}
.header_user_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header_user_name {
  margin-left: 10px;
  font-size: 15px;
  color: #fff;
}
.header_user_center {
  margin-left: 15px;
  font-size: 15px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.header_user_line {
  margin: 0 15px;
  font-size: 15px;
  color: #fff;
}
.header_user_exit {
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}
.qrcode {
  width: 150px;
  margin: 40px auto;
}
</style>
