<template>
  <div class="survey">
    <div class="header">
      <div class="header_bg">
        <img src="../assets/img/header_bg1.png" alt="" />
      </div>
      <div class="header_logo" @click="goHome"></div>
      <div class="header_user_info">
        <div class="header_user_img">
          <img :src="userInfo.avatar" />
        </div>
        <div class="header_user_name">HI，{{ userInfo.nickname }}</div>
        <div class="header_user_center" @click="userCenterClick">个人中心</div>
        <div class="header_user_line">|</div>
        <div class="header_user_exit" @click="logoutClick">退出登录</div>
      </div>
      <div class="header_option">
       <span @click="headerOptionClick(-1)">首页</span>
        <span @click="headerOptionClick(0)">量表测评</span>
        <span @click="headerOptionClick(1)">心理咨询</span>
        <span @click="headerOptionClick(2)">名师课程</span>
        <span @click="headerOptionClick(3)">在线商城</span>
        <span @click="headerOptionClick(4)">共享咨询室</span>
        <span @click="headerOptionClick(5)">员工关爱方案</span>
      </div>
    </div>

    <div style="width: 1200px; margin: 0 auto; min-height: 500px">
      <div class="survey_title">优惠券适用商品</div>

      <div class="survey_list">
        <ul>
          <li
            class="survey_list_item"
            v-for="i in surveyList"
            :key="i.item_id"
            @click="surveyItemClick(i)"
          >
            <el-image
              class="survey_list_item_img"
              :class="{ consult_item_img: i.type == 4 }"
              :src="i.img_url"
              fit="cover"
            ></el-image>
            <div class="survey_list_item_info">
              <div class="survey_list_item_title">
                <span v-if="i.type == 4" class="consult_tag">咨询师</span
                >{{ i.goods_name }}
              </div>
              <div class="survey_list_item_text">￥{{ i.price }}</div>
              <div class="survey_list_item_start">查看详情</div>
            </div>
          </li>
        </ul>
        <p v-if="loading" class="load_footer">加载中...</p>
        <p v-if="noMore" class="load_footer">没有更多了</p>
      </div>
    </div>

    <copy-footer class="footer" />
  </div>
</template>
<script>
import CopyFooter from "../components/footer/CopyFooter.vue";
import { users_coupon_items } from "../network/views-request";

export default {
  name: "Survey",
  components: { CopyFooter },
  props: {},
  data() {
    return {
      userInfo: {},
      index: 1,
      surveyList: [], //商品列表
      loading: false,
      allCount: 1000,
    };
  },
  computed: {
    noMore() {
      return this.allCount <= this.surveyList.length;
    },
    disabled() {
      return this.loading || this.noMore;
    },
  },
  created() {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    console.log(info);
    if (info) {
      this.userInfo = info;
    }
    this.getList();
  },
  mounted() {
    window.addEventListener("scroll", this.getMore); //监听滚动
  },
  destroyed() {
    window.removeEventListener("scroll", this.getMore);
  },
  methods: {
    //退出登录
    logoutClick() {
      this.$router.push({ path: "/" });
    },
    //个人中心
    userCenterClick() {
      this.$router.push({ path: "/usercenter" });
    },
    //回主页
    goHome() {
      this.$router.push({ path: "/" });
    },

    headerOptionClick(index) {
      if (index == 0) {
        this.$router.push({ path: "/survey" });
      } else if (index == 1) {
        this.$router.push({ path: "/consult" });
      } else if (index == 2) {
        this.$router.push({ path: "/course" });
      } else if (index == 3) {
        this.$router.push({ path: "/shopping" });
      } else if (index == 4) {
        this.$router.push({ path: "/room" });
      } else if (index == 5) {
        this.$router.push({ path: "/concern" });
      } else {
        this.goHome();
      }
    },
    //点击量表
    surveyItemClick(item) {
      if (item.type == "1") {
        //商城商品
        this.$router.push({
          path: "/shoppingdetail",
          query: { id: item.item_id },
        });
      } else if (item.type == "2") {
        //量表
        this.$router.push({
          path: "/surveydetail",
          query: { id: item.item_id },
        });
      } else if (item.type == "3") {
        //课程
        this.$router.push({
          path: "/coursedetail",
          query: { item_id: item.item_id },
        });
      } else if (item.type == "4") {
        //咨询师
        this.$router.push({
          path: "/consultdetail",
          query: { id: item.item_id },
        });
      }
    },

    getList() {
      this.loading = true;
      const params = {
        index: this.index,
        count: 10,
        coupon_id: this.$route.query.coupon_id,
      };
      users_coupon_items(params).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.allCount = res.data.all_count;
          this.surveyList = this.surveyList.concat(res.data.list);
        } else {
          this.$message.error(res.message);
        }
        this.loading = false;
      });
    },
    //滑动到底部
    getMore() {
      let clientHeight = document.documentElement.clientHeight; //获取html的可视高度
      let scrollTop = document.documentElement.scrollTop; //获取html的滚动高度
      let scrollHeight = document.documentElement.scrollHeight; //获取文档的实际高度
      //判断如果html的可视高度加上滚动高度等于文档的实际高度的话,就关闭开关,否则会造成数据混乱
      if (clientHeight + scrollTop >= scrollHeight) {
        // this.load();
      }
    },
    //加载更多
    load() {
      if (!this.noMore && !this.loading) {
        this.index += 1;
        this.getList();
      }
    },
  },
};
</script>
<style scoped>
.header {
  position: relative;
}
.header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
}
.header_user_info {
  position: absolute;
  display: flex;
  align-items: center;
  right: 20px;
  top: 15px;
}
.header_user_img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #fff;
  overflow: hidden;
}
.header_user_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header_user_name {
  margin-left: 10px;
  font-size: 15px;
  color: #fff;
}
.header_user_center {
  margin-left: 15px;
  font-size: 15px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.header_user_line {
  margin: 0 15px;
  font-size: 15px;
  color: #fff;
}
.header_user_exit {
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}

.header_option {
  position: absolute;
  right: 0px;
  bottom: 15px;
}
.header_option span {
  padding: 0 20px;
  border-right: 1px solid #fff;
  font-size: 17px;
  flex-wrap: 600;
  color: #fff;
  cursor: pointer;
}
.header_option span:nth-child(6) {
  border-right: 0px solid #fff;
}
.demo-tabs {
  margin-top: 30px;
}
:deep(.demo-tabs .el-tabs__active-bar) {
  background-color: var(--color-main);
}
:deep(.demo-tabs .is-active) {
  color: var(--color-main);
}
:deep(.demo-tabs .el-tabs__item) {
  font-size: 16px;
}
.survey_desc {
  padding: 20px;
  border-radius: 5px;
  font-size: 15px;
  color: #707070;
  background-color: bisque;
}
.axsurvey_desc {
  padding: 20px;
  border-radius: 5px;
  font-size: 15px;
  color: #707070;
  background-color: rgb(194, 220, 245);
}
.survey_title {
  margin: 30px;
  font-size: 20px;
  color: #707070;
  text-align: center;
}
.survey_list {
  margin-top: 10px;
}
.survey_list_item {
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px dashed #909399;
}
.survey_list_item_img {
  width: 500px;
  height: 186px;
}
.consult_item_img {
  width: 200px;
  height: 200px;
}
.survey_list_item_info {
  margin-left: 20px;
  height: 186px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.survey_list_item_title {
  margin-top: 5px;
  font-size: 17px;
  font-weight: 600;
  color: var(--color-main);
}
.consult_tag {
  margin-right: 10px;
  padding: 5px;
  font-size: 15px;
  border-radius: 3px;
  background-color: rgb(231, 41, 41);
  color: #fff;
}
.survey_list_item_text {
  font-size: 20px;
  color: rgb(241, 13, 13);
}
.survey_list_item_start {
  align-self: flex-end;
  width: 100px;
  height: 30px;
  background-color: var(--color-main);
  border-radius: 3px;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  cursor: pointer;
}
.load_footer {
  margin-top: 30px;
  font-size: 14px;
  text-align: center;
  color: #909399;
}
.footer {
  margin-top: 100px;
}
</style>