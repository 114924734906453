<template>
  <div class="consultdetail">
    <div class="header">
      <div class="header_bg">
        <img src="../assets/img/header_bg1.png" alt="" />
      </div>
      <div class="header_logo" @click="goHome"></div>
      <div class="header_user_info">
        <div class="header_user_img">
          <img :src="userInfo && userInfo.avatar" />
        </div>
        <div class="header_user_name">HI，{{ userInfo.nickname }}</div>
        <div class="header_user_center" @click="userCenterClick">个人中心</div>
        <div class="header_user_line">|</div>
        <div class="header_user_exit" @click="logoutClick">退出登录</div>
      </div>
      <div class="header_option">
        <span @click="headerOptionClick(-1)">首页</span>
        <span @click="headerOptionClick(0)">量表测评</span>
        <span @click="headerOptionClick(1)">心理咨询</span>
        <span @click="headerOptionClick(2)">名师课程</span>
        <span @click="headerOptionClick(3)">在线商城</span>
        <span @click="headerOptionClick(4)">共享咨询室</span>
        <span @click="headerOptionClick(5)">员工关爱方案</span>
      </div>
    </div>
    <div class="consult_bg">
      <img src="../assets/img/consult_hedaer.png" />
    </div>
    <div style="width: 1200px; margin: 0 auto">
      <div class="consult_info">
        <div class="avatar_wp">
          <el-image
            class="avatar_image"
            :src="consultInfo.user && consultInfo.user.avatar"
            fit="cover"
          ></el-image>
          <div class="avatar_text">预约咨询</div>
        </div>
        <div class="name_info">
          <div class="name_wp">
            <div class="name">
              {{ consultInfo && consultInfo.consultant_name }}
            </div>
            <div class="fllow" @click="fllowClick">
              {{ isFollow(consultInfo.follow_status) }}
            </div>
          </div>
          <div class="introduce">
            {{ consultInfo && consultInfo.professional_certificate }}
          </div>
          <div class="price">
            咨询费用：￥{{ consultInfo && consultInfo.consult_price }}元/50分钟
          </div>
        </div>
      </div>
      <div v-show="isUnfold" class="show_content">
        <div class="show_content_title">咨询师寄语</div>
        <div class="show_content_desc">
          {{ consultInfo.consultant_message }}
        </div>
        <div class="show_content_desc">
          线下地址区域：{{ consultInfo.province }}{{ consultInfo.city
          }}{{ consultInfo.district }}
        </div>
        <div class="show_content_title">擅长类别</div>
        <div class="show_content_desc">
          {{
            consultInfo.adept_category && consultInfo.adept_category.toString()
          }}
        </div>

        <div class="show_content_title">从业资质</div>
        <div class="show_content_desc">
          {{ consultInfo.professional_certificate }}
        </div>
        <div class="show_content_title">专业背景</div>
        <div class="show_content_desc">
          {{ consultInfo.professional_background }}
        </div>
        <div class="show_content_title">咨询经验</div>
        <div class="show_content_desc">
          {{ consultInfo.consult_experience }}
        </div>
      </div>
      <div class="unfold" @click="unfoldClick">
        {{ isUnfold ? "-收起更多专家介绍" : "+展开更多专家介绍" }}
      </div>
      <div class="appointment_wp">
        <div class="appointment_price">
          <span class="appointment_price_text"
            >咨询费用：￥{{
              consultInfo && consultInfo.consult_price
            }}元/50分钟</span
          >
          <span class="appointment_can_dot">●</span>
          <span class="appointment_can_blue_dot">●</span>
          <span class="appointment_can">可预约</span>
          <span class="appointment_no_dot">●</span>
          <span class="appointment_no">不可预约</span>
        </div>
      </div>
      <div class="appointment_btn">
        <span class="appointment_btn_up" @click="onlineSelect = true"
          >线上预约</span
        >
        <span class="appointment_btn_down" @click="onlineSelect = flase"
          >线下预约</span
        >
      </div>
      <div v-show="onlineSelect" class="appointment_tab_online">
        <div
          class="appointment_item"
          v-for="item in online_appointment_time_list"
          :key="item.date"
        >
          <div class="appointment_item_title">{{ item.date }}</div>
          <div class="appointment_item_list">
            <div
              class="appointment_item_list_item"
              v-for="item1 in item.time"
              :key="item1.consult_start_time"
              :class="
                item1.status == '0'
                  ? 'appointment_item_can'
                  : 'appointment_item_not'
              "
              @click="appointmentOnLineClick(item1)"
            >
              {{ item1.start_time }}-{{ item1.end_time }}
            </div>
          </div>
        </div>
      </div>
      <div v-show="!onlineSelect" class="appointment_tab_off">
        <div
          class="appointment_item"
          v-for="item in off_appointment_time_list"
          :key="item.date"
        >
          <div class="appointment_item_title">{{ item.date }}</div>
          <div class="appointment_item_list">
            <div
              class="appointment_item_list_item"
              v-for="item1 in item.time"
              :key="item1.consult_start_time"
              :class="
                item1.status == '0'
                  ? 'appointment_item_off_can'
                  : 'appointment_item_not'
              "
              @click="appointmentOffClick(item1)"
            >
              {{ item1.start_time }}-{{ item1.end_time }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <copy-footer class="footer" />
  </div>
</template>
<script>
import CopyFooter from "../components/footer/CopyFooter.vue";
import {
  consultant_detail,
  consultant_appointment_times,
  consultant_follow,
  consultant_unfollow,
} from "../network/views-request";

export default {
  name: "consultDetail",
  components: { CopyFooter },
  props: {},
  data() {
    return {
      userInfo: {},
      consultInfo: {},
      isUnfold: false, //展开
      off_appointment_time_list: [], //线下
      online_appointment_time_list: [], //线上
      onlineSelect: true,
    };
  },
  computed: {
    isFollow() {
      return (status) => {
        return status ? "已关注" : "+关注专家";
      };
    },
  },
  created() {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    if (info) {
      this.userInfo = info;
    }
    this.getConsultantDetail();
    this.getConsultantTime();
  },
  methods: {
    //退出登录
    logoutClick() {
      localStorage.clear();
      this.$router.push({ path: "/" });
    },
    //个人中心
    userCenterClick() {
      this.$router.push({ path: "/usercenter" });
    },
    //回主页
    goHome() {
      this.$router.push({ path: "/home" });
    },
    headerOptionClick(index) {
      if (index == 0) {
        this.$router.push({ path: "/survey" });
      } else if (index == 1) {
        this.$router.push({ path: "/consult" });
      } else if (index == 2) {
        this.$router.push({ path: "/course" });
      } else if (index == 3) {
        this.$router.push({ path: "/shopping" });
      } else if (index == 4) {
        this.$router.push({ path: "/room" });
      } else if (index == 5) {
        this.$router.push({ path: "/concern" });
      } else {
        this.goHome();
      }
    },
    //展开
    unfoldClick() {
      this.isUnfold = !this.isUnfold;
    },
    //关注
    fllowClick() {
      if (this.consultInfo.follow_status) {
        this.$confirm("确定要取消关注该专家吗?", "提示", {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning",
        }).then(() => {
          this.gounFllows();
        });
      } else {
        this.goFllows();
      }
    },
    //线上点击预约
    appointmentOnLineClick(item) {
      if (item.status == "0") {
        let info = {};
        info.consultant_id = this.consultInfo.id;
        info.consult_type = 1;
        info.consult_start_time = item.consult_start_time;
        info.consult_end_time = item.consult_end_time;
        //可预约
        this.$router.push({ path: "/consultplaceorder", query: info });
      } else {
        this.$message.error("该时间段不可预约");
      }
    },
    //线下点击预约
    appointmentOffClick(item) {
      if (item.status == "0") {
        let info = {};
        info.consultant_id = this.consultInfo.id;
        info.consult_type = 2;
        info.consult_start_time = item.consult_start_time;
        info.consult_end_time = item.consult_end_time;
        //可预约
        this.$router.push({ path: "/consultplaceorder", query: info });
      } else {
        this.$message.error("该时间段不可预约");
      }
    },
    //专家详情
    getConsultantDetail() {
      consultant_detail({ consultant_id: this.$route.query.id }).then((res) => {
        if (res.code == 200) {
          this.consultInfo = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    getConsultantTime() {
      consultant_appointment_times({
        consultant_id: this.$route.query.id,
      }).then((res) => {
        if (res.code == 200) {
          this.off_appointment_time_list = res.data.off_appointment_time_list;
          this.online_appointment_time_list =
            res.data.online_appointment_time_list;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //关注
    goFllows() {
      consultant_follow({
        consultant_id: this.$route.query.id,
      }).then((res) => {
        if (res.code == 200) {
          this.consultInfo.follow_status = 1;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //取消关注
    gounFllows() {
      consultant_unfollow({
        consultant_id: this.$route.query.id,
      }).then((res) => {
        if (res.code == 200) {
          this.consultInfo.follow_status = 0;
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>
<style scoped>
.header {
  position: relative;
}
.header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
}
.header_user_info {
  position: absolute;
  display: flex;
  align-items: center;
  right: 20px;
  top: 15px;
}
.header_user_img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #fff;
  overflow: hidden;
}
.header_user_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header_user_name {
  margin-left: 10px;
  font-size: 15px;
  color: #fff;
}
.header_user_center {
  margin-left: 15px;
  font-size: 15px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.header_user_line {
  margin: 0 15px;
  font-size: 15px;
  color: #fff;
}
.header_user_exit {
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}

.header_option {
  position: absolute;
  right: 0px;
  bottom: 15px;
}
.header_option span {
  padding: 0 20px;
  border-right: 1px solid #fff;
  font-size: 17px;
  flex-wrap: 600;
  color: #fff;
  cursor: pointer;
}
.header_option span:nth-child(6) {
  border-right: 0px solid #fff;
}

.consult_info {
  margin-top: 50px;
  display: flex;
}
.avatar_wp {
  width: 230px;
  border: 1px solid #cecece;
}
.avatar_image {
  width: 100%;
  height: 160px;
  vertical-align: top;
}
.avatar_text {
  text-align: center;
  background-color: var(--color-main);
  color: #fff;
}
.name_info {
  margin-left: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.name_wp {
  display: flex;
  justify-content: space-between;
}
.name {
  font-size: 19px;
  color: #000;
}
.fllow {
  font-size: 15px;
  font-weight: 500;
  color: var(--color-main);
  cursor: pointer;
}
.introduce {
  margin-top: 10px;
  flex: 1;
  color: #606266;
}
.price {
  font-size: 15px;
  color: #ce5b39;
}
.show_content {
  margin-top: 50px;
}
.show_content_title {
  margin-top: 40px;
  font-size: 16px;
}
.show_content_desc {
  margin-top: 20px;
  white-space: pre-wrap;
  font-size: 14px;
  color: #606266;
}
.unfold {
  margin-top: 50px;

  font-size: 14px;
  text-align: center;
}
.appointment_wp {
  margin-top: 40px;
  border-top: 1px solid #bebebe;
  padding: 30px 0;
  font-size: 15px;
}
.appointment_can_dot {
  margin-left: 50px;
  color: var(--color-main);
}
.appointment_can {
  margin-left: 10px;
}
.appointment_can_blue_dot {
  margin-left: 10px;
  color: #4389cb;
}
.appointment_no_dot {
  margin-left: 50px;
  color: #bebebe;
}
.appointment_no {
  margin-left: 10px;
}
.appointment_btn {
  margin-top: 30px;
}
.appointment_btn_down {
  padding: 10px 40px;
  font-size: 15px;
  background-color: #4389cb;
  color: #fff;
}
.appointment_btn_up {
  padding: 10px 40px;
  font-size: 15px;
  background-color: var(--color-main);
  color: #fff;
}
.appointment_tab_online {
  padding: 40px 20px;
  border: 1px solid #d5d5d5;
  background-color: #f8f8f8;
}
.appointment_tab_off {
  padding: 40px 20px;
  border: 1px solid #d5d5d5;
  background-color: #f8f8f8;
}
.appointment_item {
  margin-bottom: 30px;
}
.appointment_item_list {
  padding: 15px 20px;
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}
.appointment_item_list_item {
  margin-bottom: 15px;
  margin-left: 15px;
  width: 160px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  cursor: pointer;
}
.appointment_item_can {
  background-color: var(--color-main);
}
.appointment_item_off_can {
  background-color: #4389cb;
}
.appointment_item_not {
  background-color: #bebebe;
}
.footer {
  margin-top: 100px;
}
</style>