<template>
  <div class="register">
    <div class="header">
      <div class="header_bg">
        <img src="../assets/img/header_bg1.png" alt="" />
      </div>
      <div class="header_logo" @click="goHome"></div>
    </div>
    <div class="title">咨询知情同意书</div>
    <div class="content">
      <p>
        1、来访者确认并承诺其为完全民事行为能力人；若来访者为未成年人，则来访者承诺在本同意书前已使其监护人知晓并同意本同意书内容，并已及时通知委托监护人履行咨询知情同意书追认手续，来访者在本同意书签署前已告知咨询师其监护人的联系方式信息，若来访者未及时履行咨询知情同意书追认手续，咨询师有权通知其监护人及时履行，否则在未得到其监护人追认的情况下咨询师有权拒绝或终止咨询。来访者或其监护人在本咨询知情同意书(下称“本同意书”)中应以真实姓名签署，并在咨询申请表单等材料中提示真实、正确信息(包括但不限于联系电话、出生年份、紧急联系人、是否接受过精神科相关的诊断或治疗、是否有过自伤/自杀的想法或行为等)，若来访者或监护人刻意隐瞒或虚构上述信息事实，则来访者自行承担由此导致的全部责任。
      </p>
      <p>
        2、咨询师为来访者主动选择。来访者愿意对自己的选择或行为负责，愿意与咨询师一起努力，制定并达成合理的咨询目标。
      </p>
      <p>
        3、咨询通常有固定频率、时长、收费的设置。比如，频率为每周一次，每次50分钟，收费如平台显示。咨询师可按照来访者的实际情况与来访者讨论确定咨询的时间、频率和收费，并确保双方知晓和同意。
      </p>
      <p>
        4、关于付费。来访者应当在每次咨询开始前至少24小时在护心镜(即北京京欣医疗科技有限责任公司所运营的媒体平台)上预约时间，并向咨询师支付一次咨询费用(例如3月3日下午19:
        00开始咨询，请在3月2日 下午19: 00前通过护心镜进行预约并进行支付)
      </p>
      <p class="sub">
        (1)为保障来访者权益，需要来访者在护心镜完成整个咨询服务，不得未经护心镜预约私下与咨询师达成交易。否则，因此与咨询师发生退款等纠纷，来访者与咨询师自行承担一切后果，双方知悉且同意护心镜的运营者北京京欣医疗科技有限公司(“京欣医疗")不负任何责任。双方知悉且同意京欣医疗有权在发现来访者和咨询师存在私下交易的行为后，即时采取注销账户并拒绝提供服务措施，如给京欣医疗及其相关方造成损失的，来访者和咨询师还应承担相应赔偿责任。
      </p>
      <p class="sub">
        (2)如果来访者未提前24小时通过护心镜完成心理咨询预约和付费，咨询师有权单方面停止本次咨询。双方均可根据实际情况提出调整付费方式为提前多次付费或延后付费，但须双方协商同意。任何一方因故终止咨询，相关付费事宜须双方协商解决。
      </p>
      <p class="sub">
        (3)如果来访者首次咨询前需要协调时间，需要先填写预约申请表、提供协调时间的信息，并提前支付第一-次的咨询费用，咨询师接受预约后会与来访者商讨确定具体的咨询时间。未付费的情况下，来访者无法提前联系咨询师进行时间协调。
      </p>
      <p>
        5、关于保密性。咨询中所涉及的来访者个人隐私及相关资料，都将受到严格保护，不会在任何场合公开，但下列情况除外:
      </p>
      <p class="sub">(1)可能对来访者自身或他人的生命安全构成严重危害的情况;</p>
      <p class="sub">
        (2)不具备完全民事行为能力的未成年人等遭受或者疑似遭受不法侵害以及面临不法侵害危险的情况;
      </p>
      <p class="sub">(3)法律规定需要披露的其他情况;</p>
      <p class="sub">
        (4)咨询师与督导、朋辈督导在严格的专业保密设置的环境下讨论个案，须隐去来访者可识别信息;
      </p>
      <p class="sub">(5)其他经过来访者书面同意的情况;</p>
      <p>
        6、无特别约定或者另签订同意书，咨询师与来访者皆不得对咨询过程进行录音或者录像。
      </p>
      <p>7、关于时间。请按时到达咨询地点或网络地点。</p>
      <p class="sub">
        (1)关于取消或更改预约:
        来访者或者咨询师需要取消或更改预约(包括修改咨询时间、咨询方式等)，需在本次预约的咨询开始前至少24小时联系对方商议，双方确认后由咨询师操作。如:原定周六晚上20:
        00开始的咨询，请在周五晚20: 00之前提出更改。
        -若咨询师在24小时之内提出无法出席，需要取消或更改预约，来访者有权要求咨询师免费提供一次咨询。
        -若来访者在24小时之内提出无法出席，需要取消或更改预约，咨询师有权要求来访者照常缴纳本次咨询费用。
        双方另有约定或协商的情况以双方的约定或协商为准。
      </p>
      <p class="sub">
        (2)关于取消协调时间的申请:
        -若咨询师和来访者在协调时间时，没有谈妥合适的时间，或来访者有意取消协调时间的申请，咨询师应当拒绝当前申请。申请取消后，来访者申请协调时间支付的费用会被退款。
        -若咨询师在收到来访者协调时间的申请后，24小时内没有确认申请，则该申请自动取消，来访者申请协调时间支付的费用会被退款。
      </p>
      <p class="sub">
        (3)关于迟到或缺席:
        -若咨询师迟到或缺席，来访者有权要求咨询师补齐原定咨询时间，退还迟到或缺席时段所对应的咨询费用。
        -若来访者迟到或缺席，咨询将仍在原定时间结束，并且咨询师有权不退还迟到或缺席时段所对应的咨询费用。
        双方另有约定或协商的情况以双方的约定或协商为准。
      </p>
      <p>
        8、来访者在咨询过程中，可以单方面提出终止咨询。但建议在咨询中与咨询师充分讨论后，由双方协商决定。咨询师在优先考虑来访者利益后，也可提出终止咨询，并根据来访者意愿尽力妥善安排后续转介事宜。
      </p>
      <p>
        9、关于咨询纠纷调处。
        重点提示：京欣医疗仅为来访者和咨询师提供商务辅助服务和居间服务，搭建心理咨询的合作桥梁。来访者可通过护心镜平台了解咨询师公开对外展示的信息，并使用平台进行心理咨询服务的预约等。当且仅当来访者在护心镜平台付费预约及续约的心理咨询服务进行中，如来访者与心理咨询师产生任何纠纷，京欣医疗有权但无义务介入调解，即，京欣医疗提供信息沟通及相应调处方案，但该调处结果无法律效力，由纠纷双方(来访者与咨询师)自愿作出，京欣医疗不对纠纷以及调处承担法律责任。
      </p>
      <p>
        10、咨询师与来访者双方应互相有基本的尊重。若咨询师有理由相信来访者有肢体伤害咨询师的倾向，咨询师有权终止咨询，必要时可以报警保护自身安全。
      </p>
      <p>
        11、紧急情况联系人。来访者需提供除本人之外的一位联系人的联系方式备用，并保证所提供的信息的有效性;如信息不实，咨询师有权拒绝咨询，并不退还咨询费用，由此导致的一切后果由提供者承担。
      </p>
      <p>
        12、
        关于私信。平台的私信功能，仅用于来访者与咨询师沟通预约时间更改等事务性内容，因咨询师工作时间所限，私信留言不能确保被及时阅读和回复，咨询师对非事务性留言不保证在私信中给予回复和干预。若遇到危机情况，即正处于危机(自杀、自伤、伤人)风险之中，请拨打110或当地心理援助热线。
      </p>
      <p>
        13、来访者知晓，咨询师提供的仅为心理咨询的服务。如需精神科诊断或心理治疗服务须去相关医疗机构就诊。
      </p>
      <p>
        14、咨询知情同意书由来访者点击同意后生效。如来访者是未成年人，须提前取得监护人同意。
      </p>
      <p>
        15、对咨询知情同意书、方案所涉及的任何有关以上内容调整，均应在双方认可的情况下，通过电子邮件或者其他文字方式确认，方产生效力。
      </p>
    </div>
    <copy-footer class="footer" />
  </div>
</template>
<script>
import CopyFooter from "../components/footer/CopyFooter.vue";

export default {
  name: "Survey",
  components: { CopyFooter },
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {
    ////回主页
    goHome() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>
<style scoped>
.header {
  position: relative;
}
.title {
  margin: 20px 0;
  font-size: 17px;
  text-align: center;
}
.header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
}
.content {
  margin: 0 auto;
  width: 1200px;
  font-size: 16px;
  color: #3c3c3c;
  line-height: 2;
}
h4 {
  margin-top: 20px;
}
.detailtext {
  text-indent: 1.5em;
  margin: 5px 0px;
  font-size: 14px;
  color: #606266;
}
.sub {
  text-indent: 1.5em;
  font-size: 14px;
  color: #707070;
}
.footer {
  margin-top: 100px;
}
</style>