<template>
  <div class="consultdetail">
    <div class="header">
      <div class="header_bg">
        <img src="../assets/img/header_bg1.png" alt="" />
      </div>
      <div class="header_logo" @click="goHome"></div>
      <div class="header_user_info">
        <div class="header_user_img">
          <img :src="userInfo && userInfo.avatar" />
        </div>
        <div class="header_user_name">HI，{{ userInfo.nickname }}</div>
        <div class="header_user_center" @click="userCenterClick">个人中心</div>
        <div class="header_user_line">|</div>
        <div class="header_user_exit" @click="logoutClick">退出登录</div>
      </div>
      <div class="header_option">
        <span @click="headerOptionClick(-1)">首页</span>
        <span @click="headerOptionClick(0)">量表测评</span>
        <span @click="headerOptionClick(1)">心理咨询</span>
        <span @click="headerOptionClick(2)">名师课程</span>
        <span @click="headerOptionClick(3)">在线商城</span>
        <span @click="headerOptionClick(4)">共享咨询室</span>
        <span @click="headerOptionClick(5)">员工关爱方案</span>
      </div>
    </div>
    <div style="width: 1200px; margin: 0 auto; min-height: 500px">
      <div class="title">预约信息</div>
      <div class="consult_info">
        <el-image
          class="avatar_image"
          :src="consultInfo.consultant && consultInfo.consultant.user.avatar"
          fit="cover"
        ></el-image>
        <div class="name_info">
          <div class="consult_type">预约类型：驻场预约</div>
          <div class="consult_type">
            咨询地点：{{
              consultInfo.accredit_consult_activity &&
              consultInfo.accredit_consult_activity.accredit_locale
            }}
          </div>
          <div class="consult_name">
            预约专家：{{
              consultInfo.consultant && consultInfo.consultant.consultant_name
            }}
          </div>
          <div class="consult_date">
            预约时间：{{
              consultInfo.consult_start_time +
              "-" +
              consultInfo.consult_end_time
            }}
          </div>
          <div class="consult_time">合计时长：50分钟</div>
        </div>
      </div>

      <div
        class="gather_bg"
        v-if="consultInfo.need_consult_information_collection"
      >
        <div class="gather_title">初次咨询信息收集表</div>
        <div class="gather_message">
          您还需要回答几个问题，即可完成第一次咨询预约（带*号为必填项）。
        </div>
        <el-form
          class="form"
          ref="form"
          :model="form"
          label-width="300px"
          label-position="left"
        >
          <el-form-item
            label="之前是否做过心理咨询"
            :rules="[{ required: true }]"
          >
            <el-radio-group v-model="form.done_consult">
              <el-radio label="1">是</el-radio>
              <el-radio label="2">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="之前是否去医院心理科或精神科看过该问题"
            :rules="[{ required: true }]"
          >
            <el-radio-group v-model="form.whether_go_hospital">
              <el-radio label="1">是</el-radio>
              <el-radio label="2">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="如果有的话医生是否开药"
            :rules="[{ required: true }]"
          >
            <el-radio-group v-model="form.whether_prescribe">
              <el-radio label="1">是</el-radio>
              <el-radio label="2">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否有服药" :rules="[{ required: true }]">
            <el-radio-group v-model="form.whether_medication">
              <el-radio label="1">正在服用</el-radio>
              <el-radio label="2">曾用过</el-radio>
              <el-radio label="3">没吃过</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="曾去心理科或精神科看过其他问题"
            :rules="[{ required: true }]"
          >
            <el-radio-group v-model="form.whether_go_hospital_other">
              <el-radio label="1">是</el-radio>
              <el-radio label="2">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="紧急联系人">
            <el-input v-model="form.emergency_contact_name"></el-input>
          </el-form-item>
          <el-form-item label="紧急联系方式">
            <el-input v-model.number="form.emergency_contact_mobile"></el-input>
          </el-form-item>
          <el-form-item label="您想让咨询师了解的">
            <el-input
              type="textarea"
              v-model="form.know_information"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="submit" @click="postItemOrder">立即预约</div>
    </div>
    <copy-footer class="footer" />
  </div>
</template>
<script>
import CopyFooter from "../components/footer/CopyFooter.vue";
import {
  accredit_consult_order_post_item_order,
  accredit_consult_order_preview_post_item_order,
} from "../network/views-request";

export default {
  name: "consultDetail",
  components: { CopyFooter },
  props: {},
  data() {
    return {
      userInfo: {},
      consultInfo: {},
      form: {
        done_consult: "", //是否做过心理咨询
        whether_go_hospital: "", //是否去医院 1:是 2:否
        whether_prescribe: "", //是否开药 1:是 2:否
        whether_medication: "", //是否服药 1.正在服用 2.曾用过 3.没吃过
        whether_go_hospital_other: "", //曾去心理科或精神科看过其他问题 1:是 2:否
        emergency_contact_name: "", //紧急联系人
        emergency_contact_mobile: "", //紧急联系电话
        know_information: "", //咨询师提前知晓的信息
      },
    };
  },
  computed: {},
  created() {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    if (info) {
      this.userInfo = info;
    }
    this.getPreviewOrder();
  },
  methods: {
    //退出登录
    logoutClick() {
      localStorage.clear();
      this.$router.push({ path: "/" });
    },
    //个人中心
    userCenterClick() {
      this.$router.push({ path: "/usercenter" });
    },
    //回主页
    goHome() {
      this.$router.push({ path: "/home" });
    },
    headerOptionClick(index) {
      if (index == 0) {
        this.$router.push({ path: "/survey" });
      } else if (index == 1) {
        this.$router.push({ path: "/consult" });
      } else if (index == 2) {
        this.$router.push({ path: "/course" });
      } else if (index == 3) {
        this.$router.push({ path: "/shopping" });
      } else if (index == 4) {
        this.$router.push({ path: "/room" });
      } else if (index == 5) {
        this.$router.push({ path: "/concern" });
      } else {
        this.goHome();
      }
    },

    //咨询订单 - 咨询下单预览
    getPreviewOrder() {
      let data = this.$route.query;
      accredit_consult_order_preview_post_item_order(data).then((res) => {
        if (res.code == 200) {
          this.consultInfo = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //咨询订单 - 咨询下单
    postItemOrder() {
      if (this.consultInfo.need_consult_information_collection) {
        if (!this.form.done_consult) {
          this.$message.error("请选择是否做过心理咨询");
          return;
        }
        if (!this.form.whether_go_hospital) {
          this.$message.error("请选择之前是否去医院心理科或精神科看过该问题 ");
          return;
        }
        if (!this.form.whether_prescribe) {
          this.$message.error("请选择是否开药 ");
          return;
        }
        if (!this.form.whether_medication) {
          this.$message.error("请选择是否服药 ");
          return;
        }
        if (!this.form.whether_go_hospital_other) {
          this.$message.error("请选择曾去心理科或精神科看过其他问题 ");
          return;
        }
      }
      let data = {
        consultant_id: this.$route.query.consultant_id,
        accredit_id: this.$route.query.accredit_id,
        consult_start_time: this.$route.query.consult_start_time,
        consult_end_time: this.$route.query.consult_end_time,
        consult_information_collection: this.form,
      };

      accredit_consult_order_post_item_order(data).then((res) => {
        if (res.code == 200) {
          this.$message.success("预约成功");
          //订单详情
          this.$router.push({
            path: "/consultaccreditorderdetail",
            query: { order_sn: res.data.order_sn },
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>
<style scoped>
.header {
  position: relative;
}
.header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
}
.header_user_info {
  position: absolute;
  display: flex;
  align-items: center;
  right: 20px;
  top: 15px;
}
.header_user_img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #fff;
  overflow: hidden;
}
.header_user_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header_user_name {
  margin-left: 10px;
  font-size: 15px;
  color: #fff;
}
.header_user_center {
  margin-left: 15px;
  font-size: 15px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.header_user_line {
  margin: 0 15px;
  font-size: 15px;
  color: #fff;
}
.header_user_exit {
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}

.header_option {
  position: absolute;
  right: 0px;
  bottom: 15px;
}
.header_option span {
  padding: 0 20px;
  border-right: 1px solid #fff;
  font-size: 17px;
  flex-wrap: 600;
  color: #fff;
  cursor: pointer;
}
.header_option span:nth-child(6) {
  border-right: 0px solid #fff;
}
.title {
  margin-top: 30px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}
.consult_info {
  margin-top: 40px;
  padding: 20px;
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
}
.avatar_image {
  width: 100%;
  height: 150px;
  width: 230px;
  vertical-align: top;
}
.name_info {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: #707070;
}
.consult_price {
  padding: 20px;
  text-align: center;
  font-size: 20px;
  color: rgb(235, 31, 31);
  background-color: #f5f5f5;
}
.consult_price_title {
  color: #707070;
  font-size: 16px;
  font-weight: 600;
}
.submit {
  margin: 50px auto;
  width: 150px;
  height: 40px;
  font-size: 15px;
  line-height: 40px;
  text-align: center;
  border-radius: 3px;
  color: #fff;
  background-color: var(--color-main);
}
.gather_bg {
  margin-top: 20px;
  background-color: #f5f5f5;
  padding: 15px;
}
.gather_title {
  font-size: 17px;
  text-align: center;
  font-weight: 600;
  color: rgb(235, 31, 31);
}
.gather_message {
  font-size: 14px;
  text-align: center;
  color: rgb(235, 31, 31);
}
.pay_type {
  margin-top: 20px;
  padding: 15px;
  background-color: #f5f5f5;
}
.pay_title {
  font-size: 17px;
  text-align: center;
}
.pay_ch {
  margin: 10px auto;
  width: 600px;
}
.form {
  margin: 20px auto;
  width: 600px;
}
.qrcode {
  width: 150px;
  margin: 0 auto;
}
.footer {
  margin-top: 100px;
}
:deep(.el-radio__input.is-checked .el-radio__inner) {
  background-color: var(--color-main);
  border-color: var(--color-main);
}
:deep(.el-radio__input.is-checked + .el-radio__label) {
  color: var(--color-main);
}
:deep(.el-checkbox__input.is-checked
    .el-checkbox__inner, .el-checkbox__input.is-indeterminate
    .el-checkbox__inner) {
  background-color: var(--color-main);
  border-color: var(--color-main);
}
</style>