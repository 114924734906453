<template>
  <div class="consult">
    <div class="header">
      <div class="header_bg">
        <img src="../assets/img/header_bg1.png" alt="" />
      </div>
      <div class="header_logo" @click="goHome"></div>
      <div class="header_user_info">
        <div class="header_user_img">
          <img :src="userInfo.avatar" />
        </div>
        <div class="header_user_name">HI，{{ userInfo.nickname }}</div>
        <div class="header_user_center" @click="userCenterClick">个人中心</div>
        <div class="header_user_line">|</div>
        <div class="header_user_exit" @click="logoutClick">退出登录</div>
      </div>
      <div class="header_option">
        <span @click="headerOptionClick(-1)">首页</span>
        <span @click="headerOptionClick(0)">量表测评</span>
        <span @click="headerOptionClick(1)">心理咨询</span>
        <span @click="headerOptionClick(2)">名师课程</span>
        <span @click="headerOptionClick(3)">在线商城</span>
        <span @click="headerOptionClick(4)">共享咨询室</span>
        <span @click="headerOptionClick(5)">员工关爱方案</span>
      </div>
    </div>
    <div class="consult_title">预约信息</div>

    <div style="width: 900px; margin: 0 auto" v-if="detailInfo">
      <div class="info_bg">
        <div class="item_avatar_bg">
          <el-image
            class="item_avatar_image"
            :src="detailInfo.item && detailInfo.item.img_url"
            :fit="cover"
          ></el-image>
          <div class="item_avatar_info">
            <div class="item_avatar_consult_type">
              咨询室名称：{{ detailInfo.item.room_name }}
            </div>
            <div class="item_avatar_consult_type">
              预约时间：{{
                detailInfo.item.consult_start_time +
                "-" +
                detailInfo.item.consult_end_time
              }}
            </div>
            <div class="item_avatar_consultant_duration">
              合计时长：{{ detailInfo.item.duration }}
            </div>
            <div class="item_avatar_consultant_name">
              地址：{{
                detailInfo.item.province +
                detailInfo.item.city +
                detailInfo.item.district +
                detailInfo.item.detail_address
              }}
            </div>
            <div
              class="item_avatar_consultant_duration"
              v-if="detailInfo.item.consult_cancel_reason"
            >
              取消原因：{{ detailInfo.item.consult_cancel_reason }}
            </div>
          </div>
        </div>
        <div class="order_btn_bg">
          <!-- <el-button plain @click.stop="goToWchat">联系客服</el-button> -->
          <el-button
            plain
            v-if="detailInfo.item.cancel_status"
            @click.stop="cancelOrderClick"
            >取消咨询</el-button
          >
          <el-button
            plain
            v-if="detailInfo.item.consult_status == '0'"
            @click.stop="goToPay"
            >去付款</el-button
          >
        </div>
        <div class="consult_status">
          <div class="consult_status_info">
            <div>咨询价格</div>
            <div>￥{{ detailInfo.goods_amount }}</div>
          </div>
          <div class="consult_status_info">
            <div>优惠券</div>
            <div>-￥{{ detailInfo.discount_amount }}</div>
          </div>
          <div class="consult_status_info">
            <div>支付金额</div>
            <div>￥{{ detailInfo.pay_amount }}</div>
          </div>
          <div class="consult_status_info">
            <div>支付方式</div>
            <div>
              {{ payType }}
            </div>
          </div>
          <div class="consult_status_text">
            {{ detailInfo.item.consult_status_msg }}
          </div>
        </div>
        <div class="room_info_wp">
          <div class="consult_info_title">咨询室介绍</div>
          <div
            class="room_profile"
            v-for="item in detailInfo.item.room_profile"
            :key="item.url"
          >
            <el-image v-if="item.type == 'image'" :src="item.url"></el-image>
            <video
              v-if="item.type == 'video'"
              ref="video"
              id="video"
              :src="videolist.src"
              :autoplay="videolist.autoplay"
              :controls="videolist.controls"
              :controlslist="videolist.controlslist"
              :webkit-playsinline="true"
              style="width: 100%; height: 100%"
            ></video>
          </div>
        </div>
        <div class="room_details" v-html="detailInfo.item.room_detail"></div>
      </div>

      <!-- 取消预约弹窗 -->
      <el-dialog title="取消咨询" v-model="cancelDialogFormVisible">
        <el-form :model="cancelForm" style="padding: 15px">
          <el-form-item label="取消咨询原因">
            <el-input
              v-model="cancelForm.consult_cancel_reason"
              placeholder="请输入取消原因"
              autocomplete="off"
              type="textarea"
            ></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="cancelDialogFormVisible = false">否</el-button>
            <el-button type="primary" @click="cancelDialogFormVisibleClick"
              >是</el-button
            >
          </span>
        </template>
      </el-dialog>
    </div>
    <copy-footer class="footer" />
  </div>
</template>
<script>
import CopyFooter from "../components/footer/CopyFooter.vue";
import { room_order, room_post_cancel_order } from "../network/views-request";

export default {
  name: "consult",
  components: { CopyFooter },
  props: {},
  data() {
    return {
      userInfo: {},
      detailInfo: null,
      selectOrdersn: "", //选中的订单号

      cancelDialogFormVisible: false, //取消咨询弹窗
      cancelForm: {
        //取消咨询表单
        consult_cancel_reason: "",
      },
      videoPlayer: null,
      videolist: {
        autoplay: false, // 自动播放
        controls: "controls", //操作
        controlslist: "nodownload  noplaybackrate",
        src: "",
        /*
        1、nodownload: 不要下载按钮
        2、nofullscreen: 不要全屏按钮
        3、noremoteplayback: 不要远程回放
        4、disablePictureInPicture 不要画中画按钮
        5、noplaybackrate 不要播放速度按钮
        */
      },
    };
  },
  computed: {
    payType() {
      if (this.detailInfo.pay_type == "1") {
        return "微信支付";
      } else if (this.detailInfo.pay_type == "2") {
        return "支付宝支付";
      } else if (this.detailInfo.pay_type == "3") {
        return "员工关爱服务";
      } else if (this.detailInfo.pay_type == "4") {
        return "心币支付";
      } else if (this.detailInfo.pay_type == "5") {
        return "企业心币支付";
      } else if (this.detailInfo.pay_type == "6") {
        return "兑换券支付";
      } else if (this.detailInfo.pay_type == "7") {
        return "项目次数支付";
      }
    },
  },
  mounted() {},
  created() {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    if (info) {
      this.userInfo = info;
    }
    this.getDetail();
  },
  methods: {
    //退出登录
    logoutClick() {
      localStorage.clear();
      this.$router.push({ path: "/" });
    },
    //个人中心
    userCenterClick() {
      this.$router.push({ path: "/usercenter" });
    },
    //回主页
    goHome() {
      this.$router.push({ path: "/" });
    },
    headerOptionClick(index) {
      if (index == 0) {
        this.$router.push({ path: "/survey" });
      } else if (index == 1) {
        this.$router.push({ path: "/consult" });
      } else if (index == 2) {
        this.$router.push({ path: "/course" });
      } else if (index == 3) {
        this.$router.push({ path: "/shopping" });
      } else if (index == 4) {
        this.$router.push({ path: "/room" });
      } else if (index == 5) {
        this.$router.push({ path: "/concern" });
      } else {
        this.goHome();
      }
    },
    //订单详情
    getDetail() {
      room_order({ order_sn: this.$route.query.order_sn }).then((res) => {
        if (res.code == 200) {
          this.detailInfo = res.data;
          res.data.item.room_profile.forEach((item) => {
            if (item.type == "video") {
              this.videolist.src = item.url;
            }
          });
          this.$nextTick(() => {
            this.initVideoplayer();
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //取消咨询
    cancelOrderClick() {
      this.cancelDialogFormVisible = true;
    },
    //取消咨询确认
    cancelDialogFormVisibleClick() {
      this.cancelDialogFormVisible = false;

      if (this.cancelForm.consult_cancel_reason.length == 0) {
        this.$message.error("请输入取消原因");
        return;
      }
      let data = {
        order_sn: this.$route.query.order_sn,
        consult_cancel_reason: this.cancelForm.consult_cancel_reason,
      };
      room_post_cancel_order(data).then((res) => {
        if (res.code == 200) {
          this.cancelForm.consult_cancel_reason = "";
          this.$message.success("取消成功");
          this.getDetail();
        } else {
          this.$message.error(res.message);
        }
      });
    },

    //重新支付
    goToPay() {
      this.$router.push({
        path: "/roomrepayorder",
        query: { order_sn: order_sn },
      });
    },

    //生成二维码
    creatQrCode(code_url) {
      let dom = this.$refs.qrCodeUrl;
      while (dom.firstChild) {
        dom.removeChild(dom.firstChild);
      }
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: code_url, // 需要转换为二维码的内容
        width: 150,
        height: 150,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
    initVideoplayer() {
      if (!this.videoPlayer && this.$refs.video) {
        this.videoPlayer = this.$refs.video;

        // 视频进度改变 -- 播放即触发
        this.videoPlayer.addEventListener("timeupdate", (e) => {
          // console.log(this.videoPlayer.currentTime,'currentTime') //当前播放进度
          // console.log(this.videoPlayer.duration,'duration') //当前播放进度
          sessionStorage.setItem("currentTime", this.videoPlayer.currentTime);
          sessionStorage.setItem("duration", this.videoPlayer.duration);
        });

        //视频暂停 --  点击暂停（退出也会触发并记录时间）
        this.videoPlayer.addEventListener("pause", (e) => {
          // this.fileTimelengthRecord(this.videoPlayer.currentTime,this.videoPlayer.duration)
        });

        // // 进度拖动
        // this.videoPlayer.addEventListener('seeking',(e) => {
        //   console.log(this.videoPlayer.currentTime,'seeking进行拖动')
        // })

        // // 进度拖动（进度条拖动会先 pause --> seeked--->play）
        // this.videoPlayer.addEventListener('seeked',(e) => {
        //   console.log(this.videoPlayer.currentTime,'seeked进行拖动')
        // })

        // 视频播放 -- 点击播放
        this.videoPlayer.addEventListener("play", (e) => {});

        // 视频播放 -- 播放结束
        this.videoPlayer.addEventListener("ended", (e) => {
          console.log(this.videoPlayer.currentTime, "ended 播放完成");
        });

        //   // 禁止快进
        // let sym
        // setInterval(() => {
        //     let time = this.videoPlayer.currentTime
        //     if(time-sym>1){
        //         this.videoPlayer.currentTime=sym
        //     }
        //     sym=this.videoPlayer.currentTime
        // },500)
      }
    },
  },
};
</script>
<style scoped>
.header {
  position: relative;
}
.header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
}
.header_user_info {
  position: absolute;
  display: flex;
  align-items: center;
  right: 20px;
  top: 15px;
}
.header_user_img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #fff;
  overflow: hidden;
}
.header_user_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header_user_name {
  margin-left: 10px;
  font-size: 15px;
  color: #fff;
}
.header_user_center {
  margin-left: 15px;
  font-size: 15px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.header_user_line {
  margin: 0 15px;
  font-size: 15px;
  color: #fff;
}
.header_user_exit {
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}

.header_option {
  position: absolute;
  right: 0px;
  bottom: 15px;
}
.header_option span {
  padding: 0 20px;
  border-right: 1px solid #fff;
  font-size: 17px;
  flex-wrap: 600;
  color: #fff;
  cursor: pointer;
}
.header_option span:nth-child(6) {
  border-right: 0px solid #fff;
}
.consult_title {
  margin-top: 50px;
  text-align: center;
  font-size: 19px;
  font-weight: 600;
}
.info_bg {
  margin: 50px auto;
  width: 900px;
}
.item_avatar_bg {
  display: flex;
  justify-content: center;
}
.item_avatar_image {
  width: 200px;
  height: 200px;
}
.item_avatar_info {
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 14px;
  color: #646464;
}
.order_btn_bg {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
.load_footer {
  font-size: 14px;
  text-align: center;
  color: #909399;
}
.qrcode {
  width: 150px;
  margin: 0 auto;
}
.consult_status {
  margin: 50px auto;
  padding: 20px 0;
  width: 500px;
  border-top: 1px dotted #707070;
  border-bottom: 1px dotted #707070;
}
.consult_status_info {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #646464;
}
.consult_status_text {
  margin-top: 20px;
  text-align: center;
  font-weight: 500;
  color: rgb(241, 22, 22);
}

.consult_introduce {
  width: 500px;
  margin: 50px auto;
}
.consult_introduce_title {
  font-size: 17px;
  font-weight: 600;
  text-align: center;
}
.consult_introduce_subtitle {
  margin-top: 30px;
  font-size: 15px;
  font-weight: 500;
}
.consult_introduce_detail {
  margin-top: 10px;
  font-size: 14px;
  color: #646464;
}
.consult_info_title {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}
.room_info_wp {
  margin: 0px auto;
  width: 500px;
}
.room_profile {
  margin-top: 30px;
}
.room_details {
  margin: 20px auto;
  padding: 20px 0;
  width: 500px;
}
.footer {
  margin-top: 100px;
}
:deep(.el-textarea__inner) {
  min-height: 100px !important;
}
:deep(.el-form-item__label) {
  font-size: 15px;
}
:deep(.el-radio__label) {
  font-weight: normal;
}
</style>