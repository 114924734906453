<template>
  <div class="consult">
    <div class="header">
      <div class="header_bg">
        <img src="../assets/img/header_bg1.png" alt="" />
      </div>
      <div class="header_logo" @click="goHome"></div>
      <div class="header_user_info">
        <div class="header_user_img">
          <img :src="userInfo.avatar" />
        </div>
        <div class="header_user_name">HI，{{ userInfo.nickname }}</div>
        <div class="header_user_center" @click="userCenterClick">个人中心</div>
        <div class="header_user_line">|</div>
        <div class="header_user_exit" @click="logoutClick">退出登录</div>
      </div>
      <div class="header_option">
        <span @click="headerOptionClick(-1)">首页</span>
        <span @click="headerOptionClick(0)">量表测评</span>
        <span @click="headerOptionClick(1)">心理咨询</span>
        <span @click="headerOptionClick(2)">名师课程</span>
        <span @click="headerOptionClick(3)">在线商城</span>
        <span @click="headerOptionClick(4)">共享咨询室</span>
        <span @click="headerOptionClick(5)">员工关爱方案</span>
      </div>
    </div>
    <div class="content">
      <div class="balance_wp">
        <div class="balance_tit">账户余额</div>
        <div class="balance_text">{{ homeInfo.heart_coin }}</div>
      </div>
      <div class="balance_detail">明细</div>
      <div class="balance_list">
        <div
          class="balance_list_item"
          v-for="item in logsList"
          :key="item.created_at"
        >
          <div class="item_top">
            <div class="item_title">{{ item.title }}</div>
            <div
              class="item_heart_coin"
              :class="
                item.type == '1'
                  ? 'item_heart_coin_blue'
                  : 'item_heart_coin_yellow'
              "
            >
              {{ addOrMinus(item.type) }}{{ item.heart_coin }}
            </div>
          </div>
          <div class="item_bottom">
            <div class="item_date">{{ item.created_at }}</div>
            <div class="item_balance">余额 {{ item.balance }}</div>
          </div>
        </div>
      </div>
    </div>
    <copy-footer class="footer" />
  </div>
</template>
<script>
import CopyFooter from "../components/footer/CopyFooter.vue";
import { heart_coin_logs, users_me } from "../network/views-request";

export default {
  name: "message",
  components: { CopyFooter },
  props: {},
  data() {
    return {
      userInfo: {},
      homeInfo: {},
      logsList: [],
      page: 1,
    };
  },
  computed: {
    addOrMinus() {
      return (type) => {
        if (type == "1") {
          return "-";
        } else {
          return "+";
        }
      };
    },
  },

  created() {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    if (info) {
      this.userInfo = info;
    }
    this.getHeartCoinLogs();
    this.getUserInfo();
  },
  mounted() {
    window.addEventListener("scroll", this.getMore); //监听滚动
  },
  destroyed() {
    window.removeEventListener("scroll", this.getMore);
  },
  methods: {
    //退出登录
    logoutClick() {
      localStorage.clear();
      this.$router.push({ path: "/" });
    },
    //个人中心
    userCenterClick() {
      this.$router.push({ path: "/usercenter" });
    },
    //回主页
    goHome() {
      this.$router.push({ path: "/" });
    },
    headerOptionClick(index) {
      if (index == 0) {
        this.$router.push({ path: "/survey" });
      } else if (index == 1) {
        this.$router.push({ path: "/consult" });
      } else if (index == 2) {
        this.$router.push({ path: "/course" });
      } else if (index == 3) {
        this.$router.push({ path: "/shopping" });
      } else if (index == 4) {
        this.$router.push({ path: "/room" });
      } else if (index == 5) {
        this.$router.push({ path: "/concern" });
      } else {
        this.goHome();
      }
    },

    //心币使用明细
    getHeartCoinLogs() {
      heart_coin_logs({ index: this.page, count: "20" }).then((res) => {
        if (res.code == 200) {
          this.logsList = [...this.logsList, ...res.data.list];
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //获取个人信息
    getUserInfo() {
      users_me().then((res) => {
        if (res.code == 200) {
          this.homeInfo = res.data;
        }
      });
    },
    //滑动到底部
    getMore() {
      let clientHeight = document.documentElement.clientHeight; //获取html的可视高度
      let scrollTop = document.documentElement.scrollTop; //获取html的滚动高度
      let scrollHeight = document.documentElement.scrollHeight; //获取文档的实际高度
      //判断如果html的可视高度加上滚动高度等于文档的实际高度的话,就关闭开关,否则会造成数据混乱
      if (clientHeight + scrollTop >= scrollHeight) {
        this.load();
      }
    },
    //加载更多
    load() {
      this.page += 1;
      this.getHeartCoinLogs();
    },
  },
};
</script>
<style scoped>
.header {
  position: relative;
}
.header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
}
.header_user_info {
  position: absolute;
  display: flex;
  align-items: center;
  right: 20px;
  top: 15px;
}
.header_user_img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #fff;
  overflow: hidden;
}
.header_user_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header_user_name {
  margin-left: 10px;
  font-size: 15px;
  color: #fff;
}
.header_user_center {
  margin-left: 15px;
  font-size: 15px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.header_user_line {
  margin: 0 15px;
  font-size: 15px;
  color: #fff;
}
.header_user_exit {
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}

.header_option {
  position: absolute;
  right: 0px;
  bottom: 15px;
}
.header_option span {
  padding: 0 20px;
  border-right: 1px solid #fff;
  font-size: 17px;
  flex-wrap: 600;
  color: #fff;
  cursor: pointer;
}
.header_option span:nth-child(6) {
  border-right: 0px solid #fff;
}
.content {
  width: 1200px;
  margin: 40px auto;
  min-height: 900px;
  overflow: auto;
  background-color: #f5f5f5;
  padding: 10px;
}
.balance_wp {
  padding: 30px;
  border-radius: 5px;
  background-color: #fff;
}
.balance_tit {
  text-align: center;
  font-size: 15px;
}
.balance_text {
  text-align: center;
  margin-top: 10px;
  font-weight: 600;
  font-size: 25px;
}
.balance_detail {
  margin-top: 40px;
  margin-left: 10px;
  font-size: 16px;
}
.balance_list {
  border-radius: 5px;
  background-color: #fff;
}
.balance_list_item {
  margin-top: 10px;
  padding: 20px;
}
.item_top {
  display: flex;
  justify-content: space-between;
}
.item_bottom {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
.item_title {
  color: #646566;
}
.item_heart_coin {
  font-size: 20px;
}
.item_heart_coin_yellow {
  color: #c39f52;
}
.item_heart_coin_blue {
  color: hsl(140, 48%, 54%);
}
.item_date {
  font-size: 15px;
  color: #969799;
}
.item_balance {
  font-size: 14px;
  color: #969799;
}
</style>