<template>
  <div class="consult">
    <div class="header">
      <div class="header_bg">
        <img src="../assets/img/header_bg1.png" alt="" />
      </div>
      <div class="header_logo" @click="goHome"></div>
      <div class="header_user_info">
        <div class="header_user_img">
          <img :src="userInfo.avatar" />
        </div>
        <div class="header_user_name">HI，{{ userInfo.nickname }}</div>
        <div class="header_user_center" @click="userCenterClick">个人中心</div>
        <div class="header_user_line">|</div>
        <div class="header_user_exit" @click="logoutClick">退出登录</div>
      </div>
      <div class="header_option">
        <span @click="headerOptionClick(-1)">首页</span>
        <span @click="headerOptionClick(0)">量表测评</span>
        <span @click="headerOptionClick(1)">心理咨询</span>
        <span @click="headerOptionClick(2)">名师课程</span>
        <span @click="headerOptionClick(3)">在线商城</span>
        <span @click="headerOptionClick(4)">咨询室</span>
        <span @click="headerOptionClick(5)">员工关爱方案</span>
      </div>
    </div>
    <div class="consult_title">预约信息</div>

    <div style="width: 900px; margin: 0 auto" v-if="detailInfo">
      <div class="info_bg">
        <div class="item_avatar_bg">
          <el-image
            class="item_avatar_image"
            :src="detailInfo.item && detailInfo.item.user.avatar"
            :fit="cover"
          ></el-image>
          <div class="item_avatar_info">
            <div class="item_avatar_consult_type">
              预约类型：{{
                detailInfo.item.consult_type == "1" ? "线上咨询" : "线下咨询"
              }}
            </div>
            <div class="item_avatar_consultant_name">
              专家名称：{{ detailInfo.item.consultant_name }}
              <span
                style="color: #15bdbb"
                v-if="detailInfo.item.consult_status_msg1"
                >({{ detailInfo.item.consult_status_msg1 }})</span
              >
            </div>
            <div class="item_avatar_consultant_time">
              预约时间：{{
                detailInfo.item.consult_start_time +
                "-" +
                detailInfo.item.consult_end_time
              }}
            </div>
            <div class="item_avatar_consultant_duration">
              合计时长：{{ detailInfo.item.duration }}
            </div>
            <div
              class="item_avatar_consultant_duration"
              v-if="detailInfo.item.consult_cancel_reason"
            >
              取消原因：{{ detailInfo.item.consult_cancel_reason }}
            </div>
          </div>
        </div>
        <div class="order_btn_bg">
          <!-- <el-button plain @click.stop="goToWchat">联系客服</el-button> -->
          <el-button
            plain
            v-if="detailInfo.item.cancel_status"
            @click.stop="cancelOrderClick"
            >取消咨询</el-button
          >
          <el-button
            plain
            v-if="detailInfo.item.consult_status == '0'"
            @click.stop="goToPay"
            >去付款</el-button
          >
          <el-button
            plain
            v-if="detailInfo.item.consult_status == '5'"
            @click.stop="evaluateOrderClick"
            >去评价</el-button
          >
          <el-button
            plain
            v-if="detailInfo.item.need_consult_information_collection"
            @click.stop="formOrderClick"
            >填写初次咨询信息</el-button
          >
        </div>
        <div class="consult_status">
          <div class="consult_status_info">
            <div>咨询价格</div>
            <div>￥{{ detailInfo.goods_amount }}</div>
          </div>
          <div class="consult_status_info">
            <div>优惠券</div>
            <div>-￥{{ detailInfo.discount_amount }}</div>
          </div>
          <div class="consult_status_info">
            <div>支付金额</div>
            <div>￥{{ detailInfo.pay_amount }}</div>
          </div>
          <div class="consult_status_info">
            <div>支付方式</div>
            <div>
              {{ payType }}
            </div>
          </div>
          <div class="consult_status_text">
            {{ detailInfo.item.consult_status_msg }}
          </div>
        </div>
        <div class="consult_introduce">
          <div class="consult_introduce_title">专家介绍</div>
          <div class="consult_introduce_subtitle">咨询师寄语</div>
          <div class="consult_introduce_detail">
            {{ detailInfo.item.consultant_message }}
          </div>
          <div class="consult_introduce_subtitle">擅长领域</div>
          <div class="consult_introduce_detail">
            {{ detailInfo.item.adept_category.toString() }}
          </div>

          <div class="consult_introduce_subtitle">从业资质</div>
          <div class="consult_introduce_detail">
            {{ detailInfo.item.professional_certificate }}
          </div>
        </div>
      </div>
      <!-- 取消预约弹窗 -->
      <el-dialog title="取消咨询" v-model="cancelDialogFormVisible">
        <el-form :model="cancelForm" style="padding: 15px">
          <el-form-item label="取消咨询原因">
            <el-input
              v-model="cancelForm.consult_cancel_reason"
              placeholder="请输入取消原因"
              autocomplete="off"
              type="textarea"
            ></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="cancelDialogFormVisible = false">否</el-button>
            <el-button type="primary" @click="cancelDialogFormVisibleClick"
              >是</el-button
            >
          </span>
        </template>
      </el-dialog>
      <!-- 评价预约弹窗 -->
      <el-dialog title="评价专家" v-model="evaluateDialogFormVisible">
        <el-form :model="evaluateForm" style="padding: 15px">
          <el-form-item label="总体满意度:">
            <el-radio-group v-model="evaluateForm.overall_satisfaction">
              <el-radio label="非常满意">非常满意</el-radio>
              <el-radio label="比较满意">比较满意</el-radio>
              <el-radio label="一般">一般</el-radio>
              <el-radio label="不满意">不满意</el-radio>
              <el-radio label="非常不满意">非常不满意</el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- <el-form-item label="咨询效果:">
            <el-radio-group v-model="evaluateForm.consultation_effect">
              <el-radio label="非常满意">非常满意</el-radio>
              <el-radio label="比较满意">比较满意</el-radio>
              <el-radio label="一般">一般</el-radio>
              <el-radio label="不满意">不满意</el-radio>
              <el-radio label="非常不满意">非常不满意</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="专业度:">
            <el-radio-group v-model="evaluateForm.professionalism">
              <el-radio label="非常满意">非常满意</el-radio>
              <el-radio label="比较满意">比较满意</el-radio>
              <el-radio label="一般">一般</el-radio>
              <el-radio label="不满意">不满意</el-radio>
              <el-radio label="非常不满意">非常不满意</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="咨询态度:">
            <el-input
              type="textarea"
              v-model="evaluateForm.consultation_attitude"
              placeholder="请输入文字"
            ></el-input>
          </el-form-item> -->
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="evaluateDialogFormVisible = false"
              >取 消</el-button
            >
            <el-button type="primary" @click="evaluateDialogFormVisibleClick"
              >确 定</el-button
            >
          </span>
        </template>
      </el-dialog>
      <!-- 重新支付二维码 -->
      <el-dialog title="扫码重新支付" v-model="codeDialogFormVisible" center>
        <div class="qrcode" ref="qrCodeUrl"></div>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="codeDialogFormVisible = false"
              >完 成</el-button
            >
          </span>
        </template>
      </el-dialog>
    </div>
    <copy-footer class="footer" />
  </div>
</template>
<script>
import CopyFooter from "../components/footer/CopyFooter.vue";
import {
  consult_order,
  post_consult_evaluate,
  post_cancel_order,
  post_re_payment,
  users_message_read,
} from "../network/views-request";

export default {
  name: "consult",
  components: { CopyFooter },
  props: {},
  data() {
    return {
      userInfo: {},
      detailInfo: null,
      selectOrdersn: "", //选中的订单号

      cancelDialogFormVisible: false, //取消咨询弹窗
      cancelForm: {
        //取消咨询表单
        consult_cancel_reason: "",
      },
      evaluateDialogFormVisible: false, //取消咨询弹窗
      evaluateForm: {
        overall_satisfaction: "",
        consultation_effect: "",
        professionalism: "",
        consultation_attitude: "",
      },
      codeDialogFormVisible: false, //重新支付天窗
      code_url: "", //支付二维码
    };
  },
  computed: {
    payType() {
      if (this.detailInfo.pay_type == "1") {
        return "微信支付";
      } else if (this.detailInfo.pay_type == "2") {
        return "支付宝支付";
      } else if (this.detailInfo.pay_type == "3") {
        return "员工关爱服务";
      } else if (this.detailInfo.pay_type == "4") {
        return "心币支付";
      } else if (this.detailInfo.pay_type == "5") {
        return "企业心币支付";
      } else if (this.detailInfo.pay_type == "6") {
        return "兑换券支付";
      } else if (this.detailInfo.pay_type == "7") {
        return "项目次数支付";
      }
    },
  },
  created() {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    if (info) {
      this.userInfo = info;
    }
    this.getDetail();
    this.readMessage();
  },
  methods: {
    //退出登录
    logoutClick() {
      localStorage.clear();
      this.$router.push({ path: "/" });
    },
    //个人中心
    userCenterClick() {
      this.$router.push({ path: "/usercenter" });
    },
    //回主页
    goHome() {
      this.$router.push({ path: "/" });
    },
    headerOptionClick(index) {
      if (index == 0) {
        this.$router.push({ path: "/survey" });
      } else if (index == 1) {
        this.$router.push({ path: "/consult" });
      } else if (index == 2) {
        this.$router.push({ path: "/course" });
      } else if (index == 3) {
        this.$router.push({ path: "/shopping" });
      } else if (index == 4) {
        this.$router.push({ path: "/room" });
      } else if (index == 5) {
        this.$router.push({ path: "/concern" });
      } else {
        this.goHome();
      }
    },
    //订单详情
    getDetail() {
      consult_order({ order_sn: this.$route.query.order_sn }).then((res) => {
        if (res.code == 200) {
          this.detailInfo = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //消息阅读
    readMessage() {
      if (this.$route.query.message_id) {
        users_message_read({ message_id: this.$route.query.message_id }).then(
          (res) => {
            if (res.code == 200) {
            } else {
            }
          }
        );
      }
    },
    //取消咨询
    cancelOrderClick() {
      this.cancelDialogFormVisible = true;
    },
    //取消咨询确认
    cancelDialogFormVisibleClick() {
      this.cancelDialogFormVisible = false;

      if (this.cancelForm.consult_cancel_reason.length == 0) {
        this.$message.error("请输入取消原因");
        return;
      }
      let data = {
        order_sn: this.$route.query.order_sn,
        consult_cancel_reason: this.cancelForm.consult_cancel_reason,
      };
      post_cancel_order(data).then((res) => {
        if (res.code == 200) {
          this.cancelForm.consult_cancel_reason = "";
          this.$message.success("取消成功");
          this.getDetail();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //提示去微信
    goToWchat() {
      this.$alert("请前去微信小程序操作", "提示", {
        confirmButtonText: "确定",
      });
    },
    //重新支付
    goToPay() {
      this.codeDialogFormVisible = true;
      let data = {
        order_sn: this.$route.query.order_sn,
      };
      post_re_payment(data).then((res) => {
        if (res.code == 200) {
          this.code_url = res.data.code_url;
          this.creatQrCode(res.data.code_url);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //评价专家
    evaluateOrderClick() {
      this.evaluateDialogFormVisible = true;
    },
    //评价专家
    evaluateDialogFormVisibleClick() {
      this.evaluateDialogFormVisible = false;
      if (this.evaluateForm.overall_satisfaction.length == 0) {
        this.$message.error("请选择总体满意度");
        return;
      }
      if (this.evaluateForm.consultation_effect.length == 0) {
        this.$message.error("请选择咨询效果");
        return;
      }
      if (this.evaluateForm.professionalism.length == 0) {
        this.$message.error("请选择专业度");
        return;
      }
      let data = {
        order_sn: this.$route.query.order_sn,
        overall_satisfaction: this.evaluateForm.overall_satisfaction,
        consultation_effect: this.evaluateForm.consultation_effect,
        professionalism: this.evaluateForm.professionalism,
        consultation_attitude: this.evaluateForm.consultation_attitude,
      };
      post_consult_evaluate(data).then((res) => {
        if (res.code == 200) {
          this.evaluateForm.overall_satisfaction = "";
          this.evaluateForm.consultation_effect = "";
          this.evaluateForm.professionalism = "";
          this.evaluateForm.consultation_attitude = "";
          this.$message.success("评价成功");

          this.getDetail();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //填写初次表单
    formOrderClick() {
      this.$router.push({
        path: "/consultplaceorderform",
        query: { order_sn: this.$route.query.order_sn },
      });
    },
    //生成二维码
    creatQrCode(code_url) {
      let dom = this.$refs.qrCodeUrl;
      while (dom.firstChild) {
        dom.removeChild(dom.firstChild);
      }
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: code_url, // 需要转换为二维码的内容
        width: 150,
        height: 150,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
  },
};
</script>
<style scoped>
.header {
  position: relative;
}
.header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
}
.header_user_info {
  position: absolute;
  display: flex;
  align-items: center;
  right: 20px;
  top: 15px;
}
.header_user_img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #fff;
  overflow: hidden;
}
.header_user_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header_user_name {
  margin-left: 10px;
  font-size: 15px;
  color: #fff;
}
.header_user_center {
  margin-left: 15px;
  font-size: 15px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.header_user_line {
  margin: 0 15px;
  font-size: 15px;
  color: #fff;
}
.header_user_exit {
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}

.header_option {
  position: absolute;
  right: 0px;
  bottom: 15px;
}
.header_option span {
  padding: 0 20px;
  border-right: 1px solid #fff;
  font-size: 17px;
  flex-wrap: 600;
  color: #fff;
  cursor: pointer;
}
.header_option span:nth-child(6) {
  border-right: 0px solid #fff;
}
.consult_title {
  margin-top: 50px;
  text-align: center;
  font-size: 19px;
  font-weight: 600;
}
.info_bg {
  margin: 50px auto;
  width: 900px;
}
.item_avatar_bg {
  display: flex;
  justify-content: center;
}
.item_avatar_image {
  width: 200px;
  height: 200px;
}
.item_avatar_info {
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 14px;
  color: #646464;
}
.order_btn_bg {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
.load_footer {
  font-size: 14px;
  text-align: center;
  color: #909399;
}
.qrcode {
  width: 150px;
  margin: 0 auto;
}
.consult_status {
  margin: 50px auto;
  padding: 20px 0;
  width: 500px;
  border-top: 1px dotted #707070;
  border-bottom: 1px dotted #707070;
}
.consult_status_info {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #646464;
}
.consult_status_text {
  margin-top: 20px;
  text-align: center;
  font-weight: 500;
  color: rgb(241, 22, 22);
}

.consult_introduce {
  width: 500px;
  margin: 50px auto;
}
.consult_introduce_title {
  font-size: 17px;
  font-weight: 600;
  text-align: center;
}
.consult_introduce_subtitle {
  margin-top: 30px;
  font-size: 15px;
  font-weight: 500;
}
.consult_introduce_detail {
  margin-top: 10px;
  font-size: 14px;
  color: #646464;
}
.footer {
  margin-top: 100px;
}
:deep(.el-textarea__inner) {
  min-height: 100px !important;
}
:deep(.el-form-item__label) {
  font-size: 15px;
}
:deep(.el-radio__label) {
  font-weight: normal;
}
</style>