<template>
  <div class="concern">
    <div class="header">
      <div class="header_bg">
        <img src="../assets/img/header_bg1.png" alt="" />
      </div>
      <div class="header_logo" @click="goHome"></div>
      <div class="header_user_info">
        <div class="header_user_img">
          <img :src="userInfo.avatar" />
        </div>
        <div class="header_user_name">HI，{{ userInfo.nickname }}</div>
        <div class="header_user_center" @click="userCenterClick">个人中心</div>
        <div class="header_user_line">|</div>
        <div class="header_user_exit" @click="logoutClick">退出登录</div>
      </div>
      <div class="header_option">
        <span @click="headerOptionClick(-1)">首页</span>
        <span @click="headerOptionClick(0)">量表测评</span>
        <span @click="headerOptionClick(1)">心理咨询</span>
        <span @click="headerOptionClick(2)">名师课程</span>
        <span @click="headerOptionClick(3)">在线商城</span>
        <span @click="headerOptionClick(4)">共享咨询室</span>
        <span @click="headerOptionClick(5)">员工关爱方案</span>
      </div>
    </div>
    <div class="concern_bg">
      <img src="../assets/img/concern_header.png" />
    </div>
    <div class="content_bg">
      <div class="content_title">企业定制信息</div>
      <div class="content_subtitle">
        您好，感谢关注！请填写必要信息，我们将尽快与您联系。
      </div>
      <div class="form">
        <div class="form_label">
          <span style="color: red">*</span>
          <span>您的姓名</span>
          <el-input class="form_value" v-model="form.name"></el-input>
        </div>
        <div class="form_label">
          <span style="color: red">*</span>
          <span>您的手机</span>
          <el-input class="form_value" v-model="form.mobile"></el-input>
        </div>
        <div class="form_label">
          <span style="color: red">*</span>
          <span>您的公司</span>
          <el-input class="form_value" v-model="form.company"></el-input>
        </div>
        <div class="form_label">
          <span style="color: red">*</span>
          <span>公司邮箱（请确保是公司邮箱）</span>
          <el-input class="form_value" v-model="form.email"></el-input>
        </div>
        <div class="form_label">
          <span style="color: red">*</span>
          <span>公司所在地区(海外地区请在其他信息中备注)</span>
          <div class="form_value">
            <el-cascader
              ref="myCascader"
              v-model="selectArea"
              :options="areas"
              placeholder="请选择公司所在地区"
              separator="-"
              @change="handleChangeArea"
            ></el-cascader>
          </div>
        </div>
        <div class="form_label">
          <span>其他信息</span>
          <el-input
            class="form_value"
            type="textarea"
            v-model="form.remark"
          ></el-input>
        </div>
        <el-button
          type="primary"
          style="margin: 20px 330px; width: 120px"
          @click="submitClick"
          >提交</el-button
        >
      </div>
    </div>

    <copy-footer class="footer" />
  </div>
</template>
<script>
import CopyFooter from "../components/footer/CopyFooter.vue";

import { information_submit, get_area } from "../network/views-request";
export default {
  name: "concern",
  components: { CopyFooter },
  props: {},
  data() {
    return {
      userInfo: {},
      selectArea: "",
      form: {
        name: "",
        mobile: "",
        company: "",
        email: "",
        area: "",
        remark: "", //其它信息
      },
      areas: [], //省市区信息
    };
  },
  computed: {},
  created() {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    if (info) {
      this.userInfo = info;
    }
    this.getArea();
  },
  methods: {
    //退出登录
    logoutClick() {
      localStorage.clear();
      this.$router.push({ path: "/" });
    },
    //个人中心
    userCenterClick() {
      this.$router.push({ path: "/usercenter" });
    },
    //回主页
    goHome() {
      this.$router.push({ path: "/" });
    },
    headerOptionClick(index) {
      if (index == 0) {
        this.$router.push({ path: "/survey" });
      } else if (index == 1) {
        this.$router.push({ path: "/consult" });
      } else if (index == 2) {
        this.$router.push({ path: "/course" });
      } else if (index == 3) {
        this.$router.push({ path: "/shopping" });
      } else if (index == 4) {
        this.$router.push({ path: "/room" });
      } else if (index == 5) {
        this.$router.push({ path: "/concern" });
      } else {
        this.goHome();
      }
    },
    //获取省市区信息
    getArea() {
      get_area().then((res) => {
        if (res.code == 200) {
          this.areas = res.data.area_list;
        }
      });
    },
    //新建地址所在地选择完成
    handleChangeArea(item) {
      let pathLabels = this.$refs.myCascader.getCheckedNodes()[0].pathLabels;
      const province = pathLabels[0];
      const city = pathLabels[1];
      const county = pathLabels[2];
      this.form.area = province + "/" + city + "/" + county;
    },
    //提交
    submitClick() {
      if (this.form.name.length == 0) {
        this.$message.error("请填写姓名");
        return;
      }
      if (this.form.mobile.length == 0) {
        this.$message.error("请填写手机号码");
        return;
      }
      if (this.form.company.length == 0) {
        this.$message.error("请填写公司名称");
        return;
      }
      if (this.form.email.length == 0) {
        this.$message.error("请填写公司邮箱");
        return;
      }
      if (this.form.area.length == 0) {
        this.$message.error("请选择公司所在地区");
        return;
      }
      information_submit(this.form).then((res) => {
        if (res.code == 200) {
          this.$message.success("提交成功");
          //清空值
          for (let key in this.form) {
            this.form[key] = "";
          }
          this.selectArea = "";
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>
<style scoped>
.header {
  position: relative;
}
.header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
}
.header_user_info {
  position: absolute;
  display: flex;
  align-items: center;
  right: 20px;
  top: 15px;
}
.header_user_img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #fff;
  overflow: hidden;
}
.header_user_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header_user_name {
  margin-left: 10px;
  font-size: 15px;
  color: #fff;
}
.header_user_center {
  margin-left: 15px;
  font-size: 15px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.header_user_line {
  margin: 0 15px;
  font-size: 15px;
  color: #fff;
}
.header_user_exit {
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}

.header_option {
  position: absolute;
  right: 0px;
  bottom: 15px;
}
.header_option span {
  padding: 0 20px;
  border-right: 1px solid #fff;
  font-size: 17px;
  flex-wrap: 600;
  color: #fff;
  cursor: pointer;
}
.header_option span:nth-child(6) {
  border-right: 0px solid #fff;
}
.content_bg {
  padding: 30px 15px;
  width: 1000px;
  margin: 30px auto;
  min-height: 500px;
  border-radius: 5px;
  background-color: #f5f5f5;
}
.content_title {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}
.content_subtitle {
  margin: 20px 0;
  font-size: 13px;
  text-align: center;
}
.form {
  width: 800px;
  margin: 0 auto;
}
.form_label {
  margin-top: 20px;
  font-size: 15px;
  font-weight: 600;
}
.form_value {
  margin-top: 10px;
}
.footer {
  margin-top: 100px;
}
</style>