import { request } from './request'

//注册
export function register(data) {
  return request({
    url: '/auth/register',
    method: "post",
    data
  })
}
//发送注册验证码
export function send_register_sms(data) {
  return request({
    url: '/send_register_sms',
    method: "post",
    data
  })
}

//发送登录验证码
export function send_login_sms(data) {
  return request({
    url: '/send_login_sms',
    method: "post",
    data
  })
}
//用户手机密码登录
export function auth_login(data) {
  return request({
    url: '/auth/login',
    method: "post",
    data
  })
}
//用户手机验证码登录
export function login_with_mobile(data) {
  return request({
    url: '/auth/login_with_mobile',
    method: "post",
    data
  })
}
//发送绑定手机验证码
export function send_bind_sms(data) {
  return request({
    url: '/send_bind_sms',
    method: "post",
    data
  })
}
//绑定手机号并登录
export function bind_login_with_mobile(data) {
  return request({
    url: '/auth/bind_login_with_mobile',
    method: "post",
    data
  })
}

//用户的站内信
export function users_messages(params) {
  return request({
    url: '/users/messages',
    method: "get",
    params
  })
}
//用户 - 消息阅读
export function users_message_read(data) {
  return request({
    url: '/users/message/read',
    method: "post",
    data
  })
}
//用户 - 获得我的项目
export function project_list(params) {
  return request({
    url: '/project/list',
    method: "get",
    params
  })
}
//用户 - 解绑项目ID
export function project_unbind(data) {
  return request({
    url: '/project/unbind',
    method: "post",
    data
  })
}
//用户 - 获得项目详情
export function project_detail(params) {
  return request({
    url: '/project/detail',
    method: "get",
    params
  })
}
//用户 - 获取项目的部门列表
export function project_departments(params) {
  return request({
    url: '/project/departments',
    method: "get",
    params
  })
}
//用户 - 绑定项目ID
export function project_bind(data) {
  return request({
    url: '/project/bind',
    method: "post",
    data
  })
}
//用户 - 提交企业的定制信息
export function information_submit(data) {
  return request({
    url: '/users/enterprise/customization/information/submit',
    method: "post",
    data
  })
}
//用户 - 一键已读
export function users_message_all_read(data) {
  return request({
    url: '/users/message/all_read',
    method: "post",
    data
  })
}
//优惠券内的商品列表
export function users_coupon_items(params) {
  return request({
    url: '/users/coupon/items',
    method: "get",
    params
  })
}
//我的优惠券
export function users_coupons(params) {
  return request({
    url: '/users/coupons',
    method: "get",
    params
  })
}

//获取用户信息
export function users_me(params) {
  return request({
    url: '/users/me',
    method: "get",
    params
  })
}
//更新用户信息
export function users_update(data) {
  return request({
    url: '/users/update',
    method: "post",
    data
  })
}

//用户 - 发送密码重置验证码
export function send_password_reset_sms(data) {
  return request({
    url: '/send_password_reset_sms',
    method: "post",
    data
  })
}
//用户 - 重置密码（短信验证码验证）
export function auth_passwd_check(data) {
  return request({
    url: '/auth/passwd/check',
    method: "post",
    data
  })
}
//用户 - 重置密码
export function auth_passwd_reset(data) {
  return request({
    url: '/auth/passwd/reset',
    method: "post",
    data
  })
}
//用户 - 心币使用明细
export function heart_coin_logs(params) {
  return request({
    url: '/heart/coin/logs',
    method: "get",
    params
  })
}

//量表 - 商城商品列表
export function gauge_list(params) {
  return request({
    url: '/gauge/list',
    method: "get",
    params
  })
}
//量表 -商城商品详情
export function gauge_detail(params) {
  return request({
    url: '/gauge/detail',
    method: "get",
    params
  })
}
//量表订单 - 量表订单列表
export function gauge_orders(params) {
  return request({
    url: '/gauge/orders',
    method: "get",
    params
  })
}
//量表订单 - 量表订单详情
export function gauge_order(params) {
  return request({
    url: '/gauge/order',
    method: "get",
    params
  })
}
//量表订单 - 量表下单预览
export function gauge_preview_post_item_order(data) {
  return request({
    url: '/gauge/order/preview_post_item_order',
    method: "post",
    data
  })
}
//量表订单 - 量表商品下单
export function gauge_post_item_order(data) {
  return request({
    url: '/gauge/order/post_item_order',
    method: "post",
    data
  })
}
//量表订单 - 重新支付
export function gauge_post_re_payment(data) {
  return request({
    url: '/gauge/order/post_re_payment',
    method: "post",
    data
  })
}
//量表订单 - 获取量表题目信息
export function get_gauge_order_operation(params) {
  return request({
    url: '/gauge/order/get_gauge_order_subject_operation',
    method: "get",
    params
  })
}
//量表订单 - 量表报告信息接口
export function gauge_post_report(data) {
  return request({
    url: '/gauge/order/post_gauge_order_report',
    method: "post",
    data
  })
}
//量表订单 - 获取量表报告
export function get_gauge_order_report(params) {
  return request({
    url: '/gauge/order/get_gauge_order_report',
    method: "get",
    params
  })
}


//咨询订单 - 咨询订单列表
export function consult_orders(params) {
  return request({
    url: '/consult/orders',
    method: "get",
    params
  })
}
//咨询订单 - 咨询下单预览
export function preview_post_item_order(data) {
  return request({
    url: '/consult/order/preview_post_item_order',
    method: "post",
    data
  })
}
//咨询订单 - 咨询下单
export function post_item_order(data) {
  return request({
    url: '/consult/order/post_item_order',
    method: "post",
    data
  })
}
//咨询订单 - 咨询订单列表
export function consult_orders_list(params) {
  return request({
    url: '/consult/orders',
    method: "get",
    params
  })
}
//咨询订单 - 取消咨询订单
export function post_cancel_order(data) {
  return request({
    url: '/consult/order/post_cancel_order',
    method: "post",
    data
  })
}
//咨询订单 - 评论咨询师
export function post_consult_evaluate(data) {
  return request({
    url: '/consult/order/post_consult_evaluate',
    method: "post",
    data
  })
}

//咨询订单 - 咨询订单详情
export function consult_order(params) {
  return request({
    url: '/consult/order',
    method: "get",
    params
  })
}
//咨询订单 - 待付款咨询订单预览
export function preview_re_payment_consult_order(params) {
  return request({
    url: '/consult/order/preview_post_re_payment_consult_order',
    method: "post",
    params
  })
}
//咨询订单 - 待付款咨询订单付款
export function post_re_payment(params) {
  return request({
    url: '/consult/order/post_re_payment',
    method: "post",
    params
  })
}

//专家 - 专家列表
export function consultant_list(params) {
  return request({
    url: '/consultant/list',
    method: "get",
    params
  })
}
//专家 - 专家详情
export function consultant_detail(params) {
  return request({
    url: '/consultant/detail',
    method: "get",
    params
  })
}
//专家的咨询时间段
export function consultant_appointment_times(params) {
  return request({
    url: '/consultant/appointment_times',
    method: "get",
    params
  })
}
// 关注专家
export function consultant_follow(data) {
  return request({
    url: '/consultant/follow',
    method: "post",
    data
  })
}
//专家 - 取消关注专家
export function consultant_unfollow(data) {
  return request({
    url: '/consultant/unfollow',
    method: "post",
    data
  })
}
//我关注的专家列表
export function consultant_follow_list(params) {
  return request({
    url: '/consultant/follow_list',
    method: "get",
    params
  })
}
//咨询订单 - 填写初次咨询信息
export function consult_information_collection(data) {
  return request({
    url: '/consult/order/post_consult_information_collection',
    method: "post",
    data
  })
}
//微信登录配置
export function wechat_qrconnect_config(params) {
  return request({
    url: '/wechat/qrconnect/config',
    method: "get",
    params
  })
}

//视频课程 - 视频课程列表
export function video_list(params) {
  return request({
    url: '/video/list',
    method: "get",
    params
  })
}
//视频课程 - 视频课程详情
export function video_detail(params) {
  return request({
    url: '/video/detail',
    method: "get",
    params
  })
}
//视频订单 - 视频订单列表
export function video_orders(params) {
  return request({
    url: '/video/orders',
    method: "get",
    params
  })
}
//视频订单 - 重新支付
export function video_order_post_re_payment(data) {
  return request({
    url: '/video/order/post_re_payment',
    method: "post",
    data
  })
}
//视频订单 - 视频订单详情
export function video_order(params) {
  return request({
    url: '/video/order',
    method: "get",
    params
  })
}
//视频订单 - 取消订单
export function video_post_cancel_order(data) {
  return request({
    url: '/video/order/post_cancel_order',
    method: "post",
    data
  })
}
//视频订单 - 视频下单预览
export function video_preview_post_item_order(data) {
  return request({
    url: '/video/order/preview_post_item_order',
    method: "post",
    data
  })
}
//视频订单 - 视频商品下单
export function video_order_post_item_order(data) {
  return request({
    url: '/video/order/post_item_order',
    method: "post",
    data
  })
}
//视频课程 - 提交视频日志
export function video_journal(params) {
  return request({
    url: '/video/journal',
    method: "post",
    params
  })
}


//在线商城 - 商城商品列表
export function item_list(params) {
  return request({
    url: '/item/list',
    method: "get",
    params
  })
}
//在线商城 - 商城商品详情
export function item_detail(params) {
  return request({
    url: '/item/detail',
    method: "get",
    params
  })
}
//商城订单 - 商品详情页下单预览
export function item_preview_post_item_order(data) {
  return request({
    url: '/item/order/preview_post_item_order',
    method: "post",
    data
  })
}
//商城订单 - 商品下单
export function item_order_post_item_order(data) {
  return request({
    url: '/item/order/post_item_order',
    method: "post",
    data
  })
}
//商城订单 - 商品订单列表
export function item_orders(params) {
  return request({
    url: '/item/orders',
    method: "get",
    params
  })
}
//商城订单 - 重新支付
export function item_order_post_re_payment(data) {
  return request({
    url: '/item/order/post_re_payment',
    method: "post",
    data
  })
}
//商城订单 - 取消订单
export function item_order_post_cancel_order(data) {
  return request({
    url: '/item/order/post_cancel_order',
    method: "post",
    data
  })
}
//商城订单 - 商品订单详情
export function item_order(params) {
  return request({
    url: '/item/order',
    method: "get",
    params
  })
}
//商城订单 - 确认收货
export function item_order_confirm_receipt(data) {
  return request({
    url: '/item/order/confirm_receipt',
    method: "post",
    data
  })
}

//收获地址 - 获取地址列表
export function get_addresses() {
  return request({
    url: '/addresses',
    method: "get",
  })
}
//收获地址 - 新建地址
export function new_address(data) {
  return request({
    url: '/address',
    method: "post",
    data
  })
}
//收获地址 - 编辑地址
export function edit_address(data) {
  return request({
    url: '/address',
    method: "patch",
    data
  })
}
//收获地址 - 删除地址
export function delete_address(data) {
  return request({
    url: '/address',
    method: "delete",
    data
  })
}
//Area - 获取省市区列表
export function get_area() {
  return request({
    url: '/area/area.json',
    method: "get",
  })
}
//驻场 - 驻场列表
export function accredit_consult_activity_list(params) {
  return request({
    url: '/accredit/consult/activity/list',
    method: "get",
    params
  })
}
//驻场 - 驻场详情
export function accredit_consult_activity_detail(params) {
  return request({
    url: '/accredit/consult/activity/detail',
    method: "get",
    params
  })
}
//驻场 - 驻场咨询师列表
export function accredit_consult_activity_consultant_list(params) {
  return request({
    url: '/accredit/consult/activity/consultant/list',
    method: "get",
    params
  })
}
//驻场 - 驻场咨询预约预览
export function accredit_consult_order_preview_post_item_order(data) {
  return request({
    url: '/accredit/consult/order/preview_post_item_order',
    method: "post",
    data
  })
}
//驻场 - 驻场咨询预约
export function accredit_consult_order_post_item_order(data) {
  return request({
    url: '/accredit/consult/order/post_item_order',
    method: "post",
    data
  })
}
//驻场 - 驻场订单列表
export function accredit_consult_orders(params) {
  return request({
    url: '/accredit/consult/orders',
    method: "get",
    params
  })
}
//驻场 - 驻场订单详情
export function accredit_consult_order(params) {
  return request({
    url: '/accredit/consult/order',
    method: "get",
    params
  })
}
//驻场 - 评论驻场咨询订单
export function accredit_consult_order_post_consult_evaluate(data) {
  return request({
    url: '/accredit/consult/order/post_consult_evaluate',
    method: "post",
    data
  })
}
//驻场 - 取消驻场咨询订单
export function accredit_consult_order_post_cancel_order(data) {
  return request({
    url: '/accredit/consult/order/post_cancel_order',
    method: "post",
    data
  })
}
//充值管理 - 获取充值设置
export function recharge_setting(params) {
  return request({
    url: '/recharge/setting',
    method: "get",
    params
  })
}
//充值管理 - 充值订单提交
export function recharge_order_submit(data) {
  return request({
    url: '/recharge/order/submit',
    method: "post",
    data
  })
}
//充值管理 - 充值记录
export function recharge_orders(params) {
  return request({
    url: '/recharge/orders',
    method: "get",
    params
  })
}
//咨询室列表
export function room_list(params) {
  return request({
    url: '/consult/room/list',
    method: "get",
    params
  })
}
//咨询室 - 咨询室详情
export function room_detail(params) {
  return request({
    url: '/consult/room/detail',
    method: "get",
    params
  })
}
//咨询室 - 咨询室预约时间段
export function room_appointment_times(params) {
  return request({
    url: '/consult/room/appointment_times',
    method: "get",
    params
  })
}
//咨询师认证 - 咨询师认证详情
export function consultant_verify_detail(params) {
  return request({
    url: '/consultant/verify/detail',
    method: "get",
    params
  })
}
//咨询师认证 - 发送咨询师认证验证码
export function consultant_verify_send_sms(data) {
  return request({
    url: '/consultant/verify/send_sms',
    method: "post",
    data
  })
}
//咨询师认证 - 提交咨询师认证
export function consultant_verify_submit(data) {
  return request({
    url: 'consultant/verify/submit',
    method: "post",
    data
  })
}
//咨询室订单 - 咨询室下单预览
export function room_preview_post_item_order(data) {
  return request({
    url: '/consult/room/order/preview_post_item_order',
    method: "post",
    data
  })
}
//咨询室订单 - 咨询室下单
export function room_post_item_order(data) {
  return request({
    url: '/consult/room/order/post_item_order',
    method: "post",
    data
  })
}
//咨询室订单 - 咨询室订单列表
export function room_orders(params) {
  return request({
    url: '/consult/room/orders',
    method: "get",
    params
  })
}
//咨询室订单 - 咨询室订单取消预约
export function room_post_cancel_order(data) {
  return request({
    url: '/consult/room/order/post_cancel_order',
    method: "post",
    data
  })
}
//咨询室订单 - 待付款咨询室订单预览
export function room_preview_post_re_payment(data) {
  return request({
    url: '/consult/room/order/preview_post_re_payment',
    method: "post",
    data
  })
}
//咨询室订单 - 咨询室下单
export function room_post_re_payment(data) {
  return request({
    url: '/consult/room/order/post_re_payment',
    method: "post",
    data
  })
}
//咨询室订单 - 咨询室订单详情
export function room_order(params) {
  return request({
    url: '/consult/room/order',
    method: "get",
    params
  })
}


//用户 - 图片上传
export function auth_upload(data) {
  return request({
    url: '/auth/upload',
    method: "post",
    data
  })
}

