<template>
  <div class="register">
    <div class="header">
      <div class="header_bg">
        <img src="../assets/img/header_bg1.png" alt="" />
      </div>
      <div class="header_logo" @click="goHome"></div>
    </div>
    <div class="title">个人中心</div>
    <div>
      <el-form class="form" :model="form" label-width="80px" :rules="rules">
        <el-form-item label="头像" prop="avatar">
          <ImgCutter v-on:cutDown="cutDown" sizeChange="false" fileType="jpeg">
            <template #open>
              <el-image
                class="avatar"
                v-if="form.avatar"
                :src="form.avatar"
                fit="cover"
              ></el-image>
              <div v-else class="upload-avatar"></div>
            </template>
          </ImgCutter>
        </el-form-item>
        <el-form-item label="昵称" prop="nickname">
          <el-input placeholder="请输入昵称" v-model="form.nickname"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="mobile">
          <el-input
            placeholder="请输入手机号"
            v-model="form.mobile"
            type="tel"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="电子邮箱" prop="email">
          <el-input
            placeholder="请输入电子邮箱"
            v-model="form.email"
            type="email"
          ></el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-radio-group v-model="form.gender">
            <el-radio :label="1">男</el-radio>
            <el-radio :label="2">女</el-radio>
            <el-radio :label="3">保密</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="出生日期">
          <el-date-picker
            v-model="form.birthday"
            type="date"
            placeholder="选择日期"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
          />
        </el-form-item>
        <el-form-item label="年龄">
          <el-input
            placeholder="请输入年龄"
            v-model="form.age"
            type="number"
          ></el-input>
        </el-form-item>
        <el-form-item label="学历程度">
          <el-select v-model="form.education" placeholder="请选择学历">
            <el-option label="初中及以下" value="1"></el-option>
            <el-option label="高中" value="2"></el-option>
            <el-option label="中专" value="3"></el-option>
            <el-option label="大专" value="4"></el-option>
            <el-option label="大学本科" value="5"></el-option>
            <el-option label="硕士" value="6"></el-option>
            <el-option label="博士" value="7"></el-option>
          </el-select>
        </el-form-item>
        <div class="submit" @click="submitClick">确认修改</div>
      </el-form>
      <div v-if="dialogVisible" class="cropper">
        <vueCropper
          ref="cropper"
          :img="option.img"
          :outputSize="option.size"
          :outputType="option.outputType"
          :info="true"
          :full="option.full"
          :canMove="option.canMove"
          :canMoveBox="option.canMoveBox"
          :original="option.original"
          :autoCrop="option.autoCrop"
          :fixed="option.fixed"
          :fixedNumber="option.fixedNumber"
          :centerBox="option.centerBox"
          :infoTrue="option.infoTrue"
          :fixedBox="option.fixedBox"
        ></vueCropper>
      </div>
    </div>
    <copy-footer class="footer" />
  </div>
</template>

<script>
import CopyFooter from "../components/footer/CopyFooter.vue";
import { users_me, users_update, auth_upload } from "../network/views-request";
import ImgCutter from "vue-img-cutter";

export default {
  name: "Center",
  components: { CopyFooter, ImgCutter },
  props: {},
  data() {
    return {
      form: {
        mobile: "",
        nickname: "",
        email: "",
        avatar: "",
        gender: "",
        age: "",
        education: "",
        birthday: "",
      },
    };
  },
  computed: {},
  created() {
    this.getUserInfo();
  },
  methods: {
    //回主页
    goHome() {
      this.$router.push({ path: "/" });
    },
    //修改资料
    submitClick() {
      if (!this.form.mobile) {
        this.$message.error("请输入手机号码");
        return;
      }

      if (!this.form.nickname) {
        this.$message.error("请输入昵称");
        return;
      }
      if (!this.form.avatar) {
        this.$message.error("请选择头像");
        return;
      }
      if (!this.form.email) {
        this.$message.error("请输入邮箱");
        return;
      }

      const data = this.form;
      users_update(data).then((res) => {
        console.log(res.data,123);
        if (res.code == 200) {
          this.$message.success("修改成功");
          localStorage.setItem("userInfos", JSON.stringify(res.data));
          this.getUserInfo()
          this.$router.go(-1);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //选择图片回调
    cutDown(e) {
      let formData = new FormData();
      formData.append("file", e.file);
      auth_upload(formData).then((res) => {
        if (res.code == 200) {
          this.form.avatar = res.data.img_url;
        } else {
          this.$message.error(res.message);
        }
      });
      console.log(e.file);
    },
    //获取个人信息
    getUserInfo() {
      users_me().then((res) => {
        if (res.code == 200) {
          this.form.avatar = res.data.avatar;
          this.form.nickname = res.data.nickname;
          this.form.mobile = res.data.mobile;
          this.form.email = res.data.email;
          this.form.gender = res.data.gender;
          this.form.age = res.data.age;
          this.form.education = res.data.education;
          this.form.birthday = res.data.birthday;
        }
      });
    },
  },
};
</script>
<style scoped>
.header {
  position: relative;
}
.header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
}
.title {
  margin: 20px 0;
  font-size: 17px;
  text-align: center;
}
.form {
  margin: 0 auto;
  width: 500px;
}
.upload-avatar {
  padding: 20px;
  width: 80px;
  height: 80px;
  background-color: #f5f5f5;
  border: 1px solid #cccccc;
}
.avatar {
  width: 80px;
  height: 80px;
}
:deep .el-radio__input.is-checked .el-radio__inner {
  background-color: var(--color-main);
  border-color: var(--color-main);
}
:deep .el-radio__input.is-checked + .el-radio__label {
  color: var(--color-main);
}
.submit {
  margin: 15px 30px;
  padding: 10px 0;
  background-color: var(--color-main);
  color: #fff;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}
.agreement {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-size: 13px;
}
.agreement_we {
  margin-left: 15px;
}
.agreement_title {
  color: var(--color-main);
  cursor: pointer;
}
:deep(.el-checkbox__input.is-checked) :deep(.el-checkbox__inner),
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: var(--color-main);
  border-color: var(--color-main);
}

.cropper {
  width: 600px;
  height: 300px;
}
.footer {
  margin-top: 100px;
}
</style>
