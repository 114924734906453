<template>
  <div class="consult">
    <div class="header">
      <div class="header_bg">
        <img src="../assets/img/header_bg1.png" alt="" />
      </div>
      <div class="header_logo" @click="goHome"></div>
      <div class="header_user_info">
        <div class="header_user_img">
          <img :src="userInfo.avatar" />
        </div>
        <div class="header_user_name">HI，{{ userInfo.nickname }}</div>
        <div class="header_user_center" @click="userCenterClick">个人中心</div>
        <div class="header_user_line">|</div>
        <div class="header_user_exit" @click="logoutClick">退出登录</div>
      </div>
      <div class="header_option">
        <span @click="headerOptionClick(-1)">首页</span>
        <span @click="headerOptionClick(0)">量表测评</span>
        <span @click="headerOptionClick(1)">心理咨询</span>
        <span @click="headerOptionClick(2)">名师课程</span>
        <span @click="headerOptionClick(3)">在线商城</span>
        <span @click="headerOptionClick(4)">共享咨询室</span>
        <span @click="headerOptionClick(5)">员工关爱方案</span>
      </div>
    </div>
    <div class="consult_title">站内消息</div>
    <div
      style="width: 1200px; margin: 0 auto; overflow: auto"
      class="infinite-list-wrapper"
    >
      <div class="all_read" @click="allRead">一键已读</div>
      <div class="box">
        <ul class="consult_list">
          <li
            class="consult_list_item"
            v-for="i in messageList"
            :key="i.id"
            @click="itemClick(i)"
          >
            <div class="item_read" v-if="i.status == '0'"></div>
            <span class="item_title">{{ i.title }}</span>
            <div class="item_content">{{ i.content }}</div>
            <div class="item_time">{{ i.created_at }}</div>
          </li>
        </ul>
        <p v-if="loading" class="load_footer">加载中...</p>
        <p v-if="noMore" class="load_footer">没有更多了</p>
      </div>
    </div>
    <copy-footer class="footer" />
  </div>
</template>
<script>
import CopyFooter from "../components/footer/CopyFooter.vue";
import {
  users_messages,
  users_message_all_read,
  users_message_read,
} from "../network/views-request";

export default {
  name: "message",
  components: { CopyFooter },
  props: {},
  data() {
    return {
      userInfo: {},
      params: {
        index: 1,
        count: 10,
      },
      messageList: [],
      loading: false,
      allCount: 1000,
    };
  },
  computed: {
    noMore() {
      return this.allCount <= this.messageList.length;
    },
    disabled() {
      return this.loading || this.noMore;
    },
  },
  created() {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    if (info) {
      this.userInfo = info;
    }
    this.getMessages();
  },
  mounted() {
    window.addEventListener("scroll", this.getMore); //监听滚动
  },
  methods: {
    //退出登录
    logoutClick() {
      localStorage.clear();
      this.$router.push({ path: "/" });
    },
    //个人中心
    userCenterClick() {
      this.$router.push({ path: "/usercenter" });
    },
    //回主页
    goHome() {
      this.$router.push({ path: "/" });
    },
    headerOptionClick(index) {
      if (index == 0) {
        this.$router.push({ path: "/survey" });
      } else if (index == 1) {
        this.$router.push({ path: "/consult" });
      } else if (index == 2) {
        this.$router.push({ path: "/course" });
      } else if (index == 3) {
        this.$router.push({ path: "/shopping" });
      } else if (index == 4) {
        this.$router.push({ path: "/room" });
      } else if (index == 5) {
        this.$router.push({ path: "/concern" });
      } else {
        this.goHome();
      }
    },
    getMore() {
      let clientHeight = document.documentElement.clientHeight; //获取html的可视高度
      let scrollTop = document.documentElement.scrollTop; //获取html的滚动高度
      let scrollHeight = document.documentElement.scrollHeight; //获取文档的实际高度
      //判断如果html的可视高度加上滚动高度等于文档的实际高度的话,就关闭开关,否则会造成数据混乱
      if (clientHeight + scrollTop + 100 >= scrollHeight) {
        this.load();
      }
    },
    //加载更多
    load() {
      if (this.noMore == true) {
        return;
      }
      if (this.loading == false) {
        this.params.index += 1;
        this.getMessages();
      }
    },
    //获取消息
    getMessages() {
      this.loading = true;
      users_messages(this.params).then((res) => {
        if (res.code == 200) {
          this.allCount = res.data.all_count;
          this.messageList = this.messageList.concat(res.data.list);
        } else {
          this.$message.error(res.message);
        }
        this.loading = false;
      });
    },
    //点击消息
    itemClick(item) {
      if (item.status == "0") {
        users_message_read({ message_id: item.id }).then((res) => {
          if (res.code == 200) {
          } else {
          }
        });
      }
      //trade_type = 1:商城 2:量表 3:视频 4:咨询
      if (item.order_item.trade_type == "1") {
        this.$router.push({
          path: "/shoppingorderdetail",
          query: { order_sn: item.order_item.order_sn, message_id: item.id },
        });
      } else if (item.order_item.trade_type == "2") {
        this.$router.push({
          path: "/surveyorderdetail",
          query: { order_sn: item.order_item.order_sn, message_id: item.id },
        });
      } else if (item.order_item.trade_type == "3") {
        this.$router.push({
          path: "/courseorderdetail",
          query: { order_sn: item.order_item.order_sn, message_id: item.id },
        });
      } else if (item.order_item.trade_type == "4") {
        this.$router.push({
          path: "/consultorderdetail",
          query: { order_sn: item.order_item.order_sn, message_id: item.id },
        });
      }
    },
    //一键已读
    allRead() {
      users_message_all_read().then((res) => {
        if (res.code == 200) {
          this.messageList = [];
          this.params.index = 1;
          this.getMessages();
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>
<style scoped>
.header {
  position: relative;
}
.header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
}
.header_user_info {
  position: absolute;
  display: flex;
  align-items: center;
  right: 20px;
  top: 15px;
}
.header_user_img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #fff;
  overflow: hidden;
}
.header_user_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header_user_name {
  margin-left: 10px;
  font-size: 15px;
  color: #fff;
}
.header_user_center {
  margin-left: 15px;
  font-size: 15px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.header_user_line {
  margin: 0 15px;
  font-size: 15px;
  color: #fff;
}
.header_user_exit {
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}

.header_option {
  position: absolute;
  right: 0px;
  bottom: 15px;
}
.header_option span {
  padding: 0 20px;
  border-right: 1px solid #fff;
  font-size: 17px;
  flex-wrap: 600;
  color: #fff;
  cursor: pointer;
}
.header_option span:nth-child(6) {
  border-right: 0px solid #fff;
}
.consult_title {
  margin-top: 50px;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
}
.all_read {
  margin-right: 15px;
  font-size: 15px;
  font-weight: 700;
  text-decoration: underline;
  color: var(--color-main);
  text-align: right;
  cursor: pointer;
}
.box {
  width: 100%;
  margin: 0 auto;
  overflow: auto;
}

.consult_list_item {
  margin-top: 30px;
  padding: 30px;
  background-color: #f5f5f5;
  border-radius: 15px;
}
.item_read {
  display: inline-block;
  margin-right: 10px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: rgb(238, 13, 13);
}
.item_content {
  margin-top: 10px;
  text-indent: 1.5em;
  font-size: 15px;
}
.item_time {
  margin-top: 10px;
  text-align: right;
  font-size: 13px;
  color: #646566;
}
.load_footer {
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
  color: #909399;
}
.footer {
  margin-top: 100px;
}
</style>