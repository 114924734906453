<template>
  <div id="pro">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "Pro",
  components: {},
};
</script>

<style>
@import "./assets/css/base.css";
</style>
