<template>
  <div class="consult">
    <div class="header">
      <div class="header_bg">
        <img src="../assets/img/header_bg1.png" alt="" />
      </div>
      <div class="header_logo" @click="goHome"></div>
      <div class="header_user_info">
        <div class="header_user_img">
          <img :src="userInfo.avatar" />
        </div>
        <div class="header_user_name">HI，{{ userInfo.nickname }}</div>
        <div class="header_user_center" @click="userCenterClick">个人中心</div>
        <div class="header_user_line">|</div>
        <div class="header_user_exit" @click="logoutClick">退出登录</div>
      </div>
      <div class="header_option">
        <span @click="headerOptionClick(-1)">首页</span>
        <span @click="headerOptionClick(0)">量表测评</span>
        <span @click="headerOptionClick(1)">心理咨询</span>
        <span @click="headerOptionClick(2)">名师课程</span>
        <span @click="headerOptionClick(3)">在线商城</span>
        <span @click="headerOptionClick(4)">共享咨询室</span>
        <span @click="headerOptionClick(5)">员工关爱方案</span>
      </div>
    </div>
    <div class="content">
      <div class="content_title_bg">
        <img src="../assets/img/recharge_title_bg.png" />
      </div>
      <div class="content_user_wp">
        <div class="content_user_avatar">
          <img :src="userInfo.avatar" />
        </div>
        <div class="content_user_info">
          <div class="content_user_name">
            {{ userInfo.nickname }} 欢迎您的到来
          </div>
          <div class="content_user_heart">
            心币余额：{{ userInfo.heart_coin }}
          </div>
        </div>
        <div class="content_recharge" @click="handelSkipRecharge">充值记录</div>
      </div>
      <div class="content_price_bg">
        <div class="content_price_wp">
          <div
            class="content_price_item"
            v-for="item in settings"
            :key="item.recharge_gear_id"
            @click="handelRecharge(item.recharge_gear_id)"
          >
            <div class="content_price_coin">
              {{ item.give_heart_coin + item.recharge_amount }}
              <span style="font-size: 12px">心币</span>
            </div>
            <div class="content_price_rmb">
              充值￥{{ item.recharge_amount }}
            </div>
          </div>
        </div>
        <div class="content_price_input">
          <el-input
            v-model="rechargeInput"
            placeholder="请输入金额"
            type="number"
            @input="onPriceInput"
          ></el-input>
          <div class="recharge_btn" @click="rechargeSubmit">立即充值</div>
        </div>
        <div class="content_price_give">赠送心币：{{ givePeice }}心币</div>
      </div>
      <div class="content_recharge_tips">
        <img src="../assets/img/recharge_tips.png" alt="" />
        <div class="content_recharge_tips_wp">
          <div class="content_recharge_tips_text">
            1.用户可按照档位说明充值心币，也可自行填写任意金额充值。
          </div>
          <div class="content_recharge_tips_text">
            2.在平台优惠活动期间，将根据充值心币的数量赠送一定数额的赠送心币。绑定项目的企业员工用户，同期享受更高的赠币比例。
          </div>
          <div class="content_recharge_tips_text">
            3.心币可用于护心镜平台的商品消费，1心币=1元人民币。
          </div>
          <div class="content_recharge_tips_text">
            4.消费时将优先使用充值心币支付，最后消费赠送心币。
          </div>
          <div class="content_recharge_tips_text">
            5.更多问题请咨询护心镜分诊台。
          </div>
        </div>
      </div>
    </div>
    <copy-footer class="footer" />
    <!-- 支付二维码 -->
    <el-dialog title="微信扫码支付" v-model="codeDialogFormVisible" center>
      <div class="qrcode" ref="qrCodeUrl"></div>
      <div style="text-align: center; margin-top: 20px">
        扫码成功后点击完成查看订单
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="wchatPayComplete">完 成</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import CopyFooter from "../components/footer/CopyFooter.vue";
import {
  users_me,
  recharge_setting,
  recharge_order_submit,
} from "../network/views-request";
import QRCode from "qrcodejs2";

export default {
  name: "message",
  components: { CopyFooter },
  props: {},
  data() {
    return {
      userInfo: {},
      settings: [],
      sectionList: [],
      givePeice: 0,
      rechargeInput: "",
      rechargeChoose: "",
      codeDialogFormVisible: false,
    };
  },
  computed: {},
  created() {
    this.getUserInfo();
    this.getRechargeSetting();
  },
  mounted() {},
  methods: {
    //退出登录
    logoutClick() {
      localStorage.clear();
      this.$router.push({ path: "/" });
    },
    //个人中心
    userCenterClick() {
      this.$router.push({ path: "/usercenter" });
    },
    //回主页
    goHome() {
      this.$router.push({ path: "/" });
    },
    headerOptionClick(index) {
      if (index == 0) {
        this.$router.push({ path: "/survey" });
      } else if (index == 1) {
        this.$router.push({ path: "/consult" });
      } else if (index == 2) {
        this.$router.push({ path: "/course" });
      } else if (index == 3) {
        this.$router.push({ path: "/shopping" });
      } else if (index == 4) {
        this.$router.push({ path: "/room" });
      } else if (index == 5) {
        this.$router.push({ path: "/concern" });
      } else {
        this.goHome();
      }
    },
    //跳转充值记录
    handelSkipRecharge() {
      this.$router.push({
        path: "/rechargeorder",
      });
    },
    //自定义充值
    rechargeSubmit() {
      if (parseFloat(this.rechargeInput) <= 0) {
        this.$message.error("请输入正确充值金额");
      }
      recharge_order_submit({ recharge_amount: this.rechargeInput }).then(
        (res) => {
          if (res.code == 200) {
            //微信支付
            this.codeDialogFormVisible = true;
            //解决二维码refs找不到的问题
            setTimeout(() => {
              this.code_url = res.data.code_url;
              this.order_sn = res.data.order_sn;
              this.creatQrCode(res.data.code_url);
            }, 500);
          } else {
            this.$message.error(res.message);
          }
        }
      );
    },
    //点击充值
    handelRecharge(gear_id) {
      this.rechargeChoose = gear_id;
      recharge_order_submit({ recharge_gear_id: gear_id }).then((res) => {
        if (res.code == 200) {
          //微信支付
          this.codeDialogFormVisible = true;
          //解决二维码refs找不到的问题
          setTimeout(() => {
            this.code_url = res.data.code_url;
            this.order_sn = res.data.order_sn;
            this.creatQrCode(res.data.code_url);
          }, 500);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //生成二维码
    creatQrCode(code_url) {
      let dom = this.$refs.qrCodeUrl;
      console.log(this.$refs);

      while (dom.firstChild) {
        dom.removeChild(dom.firstChild);
      }
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: code_url, // 需要转换为二维码的内容
        width: 150,
        height: 150,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
    //自定义输入金额赠送
    onPriceInput() {
      if (this.rechargeInput.length == 0) {
        this.givePeice = 0;
      }
      for (let index = 0; index < this.sectionList.length; index++) {
        const item = this.sectionList[index];
        if (
          this.rechargeInput <= item.recharge_amount_end_section &&
          this.rechargeInput >= item.recharge_amount_start_section
        ) {
          let give__coin =
            parseFloat(this.rechargeInput) *
            parseFloat(item.give_heart_coin_rate);
          this.givePeice = parseFloat(give__coin).toFixed(2);
          break;
        } else {
          this.givePeice = 0;
        }
      }
    },
    //微信支付扫码点完成
    wchatPayComplete() {
      this.codeDialogFormVisible = false;
      this.$router.push({
        path: "/rechargeorder",
      });
    },
    //请求充值设置
    getRechargeSetting() {
      recharge_setting().then((res) => {
        if (res.code == 200) {
          this.settings = res.data.recharge_gear_list;
          this.sectionList = res.data.recharge_custom_section_list;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //获取个人信息
    getUserInfo() {
      users_me().then((res) => {
        if (res.code == 200) {
          this.userInfo = res.data;
        }
      });
    },
  },
};
</script>
<style scoped>
.header {
  position: relative;
}
.header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
}
.header_user_info {
  position: absolute;
  display: flex;
  align-items: center;
  right: 20px;
  top: 15px;
}
.header_user_img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #fff;
  overflow: hidden;
}
.header_user_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header_user_name {
  margin-left: 10px;
  font-size: 15px;
  color: #fff;
}
.header_user_center {
  margin-left: 15px;
  font-size: 15px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.header_user_line {
  margin: 0 15px;
  font-size: 15px;
  color: #fff;
}
.header_user_exit {
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}

.header_option {
  position: absolute;
  right: 0px;
  bottom: 15px;
}
.header_option span {
  padding: 0 20px;
  border-right: 1px solid #fff;
  font-size: 17px;
  flex-wrap: 600;
  color: #fff;
  cursor: pointer;
}
.header_option span:nth-child(6) {
  border-right: 0px solid #fff;
}
.content {
  width: 1200px;
  margin: 40px auto;
  overflow: auto;
  min-height: 800px;
  background-color: #3b3b3b;
}
.content_user_wp {
  display: flex;
  align-items: center;
  padding: 10px 30px;
}
.content_user_avatar {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  overflow: hidden;
}
.content_user_avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.content_user_info {
  flex: 1;
  margin-left: 20px;
  color: #fff3d1;
}
.content_user_name {
  font-size: 15px;
}
.content_user_heart {
  font-size: 13px;
}
.content_recharge {
  width: 100px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  border-radius: 12px;
  font-size: 13px;
  background-color: #c39f52;
  color: #fff3d1;
}
.content_price_bg {
  margin: 0px 30px;
  background-color: #ebebeb;
  border-radius: 5px;
  overflow: hidden;
}
.content_price_wp {
  padding: 40px 50px 30px 170px;
  display: flex;
  flex-wrap: wrap;
  background-color: #ebebeb;
}
.content_price_item {
  margin-right: 90px;
  margin-bottom: 30px;
  border-radius: 5px;
  overflow: hidden;
}
.content_price_coin {
  width: 200px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 17px;
  background-color: #fff;
  color: #575757;
}
.content_price_rmb {
  width: 200px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 13px;
  color: #fff;
  background-color: #c39f52;
}
.content_price_input {
  padding: 0 300px 0px;
  display: flex;
  justify-content: center;
}
.recharge_btn {
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 5px;
  font-size: 14px;
  color: #f0d8a5;
  background-color: #575757;
}
.content_price_give {
  margin: 10px 0 50px;
  text-align: center;
  color: #c39f52;
}
.content_recharge_tips {
  margin: 50px 30px;
  border-radius: 5px;
  overflow: hidden;
}
.content_recharge_tips_wp {
  padding: 40px 100px;
  background-color: #ebebeb;
}
.content_recharge_tips_text {
  margin-bottom: 20px;
  font-size: 14px;
  color: #575757;
}
.qrcode {
  width: 150px;
  margin: 0 auto;
}
</style>