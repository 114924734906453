import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Survey from '../views/Survey.vue'
import Consult from '../views/Consult.vue'
import Register from '../views/Register.vue'
import Course from '../views/Course.vue'
import Shopping from '../views/Shopping.vue'
import Concern from '../views/Concern.vue'
import ConsultDetail from '../views/ConsultDetail.vue'
import UserCenter from '../views/UserCenter.vue'
import MyAttention from '../views/MyAttention.vue'
import SurveyDetail from '../views/SurveyDetail.vue'
import ShoppingDetail from '../views/ShoppingDetail.vue'
import SurveyPlaceOrders from '../views/SurveyPlaceOrders.vue'
import ShoppingPlaceOrder from '../views/ShoppingPlaceOrder.vue'
import ConsultPlaceOrder from '../views/ConsultPlaceOrder.vue'
import ConsultOrderList from '../views/ConsultOrderList.vue'
import ConsultOrderDetail from '../views/ConsultOrderDetail.vue'
import ConsultRepayOrder from '../views/ConsultRepayOrder.vue'
import Message from '../views/Message.vue'
import Project from '../views/Project.vue'
import ProjectDetail from '../views/ProjectDetail.vue'
import SurveyOrderList from '../views/SurveyOrderList.vue'
import SurveyReview from '../views/SurveyReview.vue'
import SurveyOrderDetail from '../views/SurveyOrderDetail.vue'
import SurveyReport from '../views/SurveyReport.vue'
import CourseDetail from '../views/CourseDetail.vue'
import CourseOrderList from '../views/CourseOrderList.vue'
import CourseOrderDetail from '../views/CourseOrderDetail.vue'
import CoursePlaceOrder from '../views/CoursePlaceOrder.vue'
import ConsultPlaceOrderForm from '../views/ConsultPlaceOrderForm.vue'

import ShoppingOrderList from '../views/ShoppingOrderList.vue'
import ShoppingOrderDetail from '../views/ShoppingOrderDetail.vue'
import ConcernDetail from '../views/ConcernDetail.vue'
import Contactus from '../views/Contactus.vue'
import ConsultAccreditDetail from '../views/ConsultAccreditDetail.vue'
import ConsultAccreditPlaceOrder from '../views/ConsultAccreditPlaceOrder.vue'
import ConsultAccreditOrderDetail from '../views/ConsultAccreditOrderDetail.vue'

import Recharge from '../views/Recharge.vue'
import RechargeOrder from '../views/RechargeOrder.vue'
import HeartCoinDetail from '../views/HeartCoinDetail.vue'
import UserAgreement from '../views/UserAgreement.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import InformedConsent from '../views/InformedConsent.vue'
import BindPhone from '../views/BindPhone.vue'
import DidBindPhone from '../views/DidBindPhone.vue'
import Coupon from '../views/Coupon.vue'
import CouponGoods from '../views/CouponGoods.vue'

import Room from '../views/Room.vue'
import RoomDetail from '../views/RoomDetail.vue'
import ConsultantVerify from '../views/ConsultantVerify.vue'
import RoomPlaceOrder from '../views/RoomPlaceOrder.vue'
import RoomOrderList from '../views/RoomOrderList.vue'
import RoomRepayOrder from '../views/RoomRepayOrder.vue'
import RoomOrderDetail from '../views/RoomOrderDetail.vue'

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/survey',
    name: 'Survey',
    component: Survey
  },
  {
    path: '/consult',
    name: 'Consult',
    component: Consult
  },
  {
    path: '/register',
    name: 'Register',
    component: Register

  },
  {
    path: '/course',
    name: 'Course',
    component: Course
  },
  {
    path: '/shopping',
    name: 'Shopping',
    component: Shopping
  },
  {
    path: '/concern',
    name: 'Concern',
    component: Concern
  },
  {
    path: '/consultdetail',
    name: 'ConsultDetail',
    component: ConsultDetail
  },
  {
    path: '/usercenter',
    name: 'UserCenter',
    component: UserCenter
  },
  {
    path: '/myattention',
    name: 'MyAttention',
    component: MyAttention
  },
  {
    path: '/surveydetail',
    name: 'SurveyDetail',
    component: SurveyDetail
  },
  {
    path: '/shoppingdetail',
    name: 'ShoppingDetail',
    component: ShoppingDetail
  },
  {
    path: '/surveyplaceorders',
    name: 'SurveyPlaceOrders',
    component: SurveyPlaceOrders
  },
  {
    path: '/shoppingplaceorder',
    name: 'ShoppingPlaceOrder',
    component: ShoppingPlaceOrder
  },
  {
    path: '/consultplaceorder',
    name: 'ConsultPlaceOrder',
    component: ConsultPlaceOrder
  },
  {
    path: '/consultorderlist',
    name: 'ConsultOrderList',
    component: ConsultOrderList
  },
  {
    path: '/consultorderdetail',
    name: 'ConsultOrderDetail',
    component: ConsultOrderDetail
  },
  {
    path: '/message',
    name: 'Message',
    component: Message
  },
  {
    path: '/project',
    name: 'Project',
    component: Project
  },
  {
    path: '/projectdetail',
    name: 'ProjectDetail',
    component: ProjectDetail
  },
  {
    path: '/surveyorderlist',
    name: 'SurveyOrderList',
    component: SurveyOrderList
  },
  {
    path: '/surveyorderdetail',
    name: 'SurveyOrderDetail',
    component: SurveyOrderDetail
  },
  {
    path: '/coursedetail',
    name: 'CourseDetail',
    component: CourseDetail
  },
  {
    path: '/courseorderlist',
    name: 'CourseOrderList',
    component: CourseOrderList
  },
  {
    path: '/courseorderdetail',
    name: 'CourseOrderDetail',
    component: CourseOrderDetail
  },
  {
    path: '/courseplaceorder',
    name: 'CoursePlaceOrder',
    component: CoursePlaceOrder
  },
  {
    path: '/shoppingorderlist',
    name: 'ShoppingOrderList',
    component: ShoppingOrderList
  },
  {
    path: '/shoppingorderdetail',
    name: 'ShoppingOrderDetail',
    component: ShoppingOrderDetail
  },
  {
    path: '/concerndetail',
    name: 'ConcernDetail',
    component: ConcernDetail
  },
  {
    path: '/contactus',
    name: 'Contactus',
    component: Contactus
  },
  {
    path: '/consultaccreditdetail',
    name: 'ConsultAccreditDetail',
    component: ConsultAccreditDetail
  },
  {
    path: '/consultaccreditplaceorder',
    name: 'ConsultAccreditPlaceOrder',
    component: ConsultAccreditPlaceOrder
  },
  {
    path: '/consultaccreditorderdetail',
    name: 'ConsultAccreditOrderDetail',
    component: ConsultAccreditOrderDetail
  },
  {
    path: '/recharge',
    name: 'Recharge',
    component: Recharge
  },
  {
    path: '/rechargeorder',
    name: 'RechargeOrder',
    component: RechargeOrder
  },
  {
    path: '/heartcoindetail',
    name: 'HeartCoinDetail',
    component: HeartCoinDetail
  },
  {
    path: '/surveyreview',
    name: 'SurveyReview',
    component: SurveyReview
  },
  {
    path: '/surveyreport',
    name: 'SurveyReport',
    component: SurveyReport
  },
  {
    path: '/useragreement',
    name: 'UserAgreement',
    component: UserAgreement
  },
  {
    path: '/privacypolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/informedConsent',
    name: 'InformedConsent',
    component: InformedConsent
  },
  {
    path: '/consultrepayorder',
    name: 'ConsultRepayOrder',
    component: ConsultRepayOrder
  },
  {
    path: '/consultplaceorderform',
    name: 'ConsultPlaceOrderForm',
    component: ConsultPlaceOrderForm
  },
  {
    path: '/bindphone',
    name: 'BindPhone',
    component: BindPhone
  },
  {
    path: '/didbindphone',
    name: 'DidBindPhone',
    component: DidBindPhone
  },
  {
    path: '/coupon',
    name: 'Coupon',
    component: Coupon
  },
  {
    path: '/coupongoods',
    name: 'CouponGoods',
    component: CouponGoods
  },
  {
    path: '/room',
    name: 'Room',
    component: Room
  },
  {
    path: '/roomdetail',
    name: 'RoomDetail',
    component: RoomDetail
  },
  {
    path: '/consultantverify',
    name: 'ConsultantVerify',
    component: ConsultantVerify
  },
  {
    path: '/roomplaceorder',
    name: 'RoomPlaceOrder',
    component: RoomPlaceOrder
  },
  {
    path: '/roomorderlist',
    name: 'RoomOrderList',
    component: RoomOrderList
  },
  {
    path: '/roomrepayorder',
    name: 'RoomRepayOrder',
    component: RoomRepayOrder
  },
  {
    path: '/roomorderdetail',
    name: 'RoomOrderDetail',
    component: RoomOrderDetail
  },

]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
