<template>
  <div class="register">
    <div class="header">
      <div class="header_bg">
        <img src="../assets/img/header_bg1.png" alt="" />
      </div>
      <div class="header_logo" @click="goHome"></div>
    </div>
    <div class="title">新用户注册</div>
    <div>
      <el-form
        class="form"
        :model="form"
        label-width="80px"
        :rules="rules"
        ref="ruleForm"
      >
        <el-form-item label="手机号码" prop="mobile">
          <el-input
            placeholder="请输入手机号"
            v-model="form.mobile"
            type="tel"
          ></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="code">
          <el-row :gutter="20">
            <el-col :span="20">
              <el-input
                placeholder="请输入验证码"
                v-model="form.code"
                type="tel"
              ></el-input>
            </el-col>
            <el-col :span="4">
              <el-button :disabled="sendCodeDisabled" @click="sendCodeClick">{{
                sendCodeTxt
              }}</el-button>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="昵称" prop="nick_name">
          <el-input
            placeholder="请输入昵称"
            v-model="form.nick_name"
          ></el-input>
        </el-form-item>
        <el-form-item label="设置密码" prop="password">
          <el-input
            placeholder="请输入设置密码"
            v-model="form.password"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="password_confirmation">
          <el-input
            placeholder="请输入确认密码"
            v-model="form.password_confirmation"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="电子邮箱" prop="email">
          <el-input
            placeholder="请输入电子邮箱"
            v-model="form.email"
            type="email"
          ></el-input>
        </el-form-item>
        <el-form-item label="头像" prop="avatar">
          <ImgCutter v-on:cutDown="cutDown" sizeChange="false" fileType="jpeg">
            <template #open>
              <el-image
                class="avatar"
                v-if="form.avatar"
                :src="form.avatar"
                fit="cover"
              ></el-image>
              <div v-else class="upload-avatar"></div>
            </template>
          </ImgCutter>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-radio-group v-model="form.gender">
            <el-radio label="1">男</el-radio>
            <el-radio label="2">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="年龄" prop="age">
          <el-input
            placeholder="请输入年龄"
            v-model="form.age"
            type="number"
          ></el-input>
        </el-form-item>
        <el-form-item label="学历程度">
          <el-select v-model="form.education" placeholder="请选择学历">
            <el-option label="初中及以下" value="初中及以下"></el-option>
            <el-option label="高中" value="高中"></el-option>
            <el-option label="中专" value="中专"></el-option>
            <el-option label="大专" value="大专"></el-option>
            <el-option label="大学本科" value="大学本科"></el-option>
            <el-option label="硕士" value="硕士"></el-option>
            <el-option label="博士" value="博士"></el-option>
          </el-select>
        </el-form-item>
        <div class="submit" @click="submitClick">确认注册</div>
        <div class="agreement">
          <el-checkbox
            v-model="agreementChecked"
            @click="agreementClick"
          ></el-checkbox>
          <span class="agreement_we">我已经仔细阅读并同意</span>
          <span class="agreement_title" @click="userAgreementClick"
            >用户协议</span
          >
          <span>和</span>
          <span class="agreement_title" @click="PricacyPolicyClick"
            >隐私政策</span
          >
        </div>
      </el-form>
    </div>
    <copy-footer class="footer" />
  </div>
</template>
<script>
import CopyFooter from "../components/footer/CopyFooter.vue";
import {
  register,
  send_register_sms,
  auth_upload,
} from "../network/views-request";
import md5 from "js-md5";
import ImgCutter from "vue-img-cutter";

export default {
  name: "Survey",
  components: { CopyFooter, ImgCutter },
  props: {},
  data() {
    return {
      form: {
        mobile: "",
        code: "",
        nick_name: "",
        password: "",
        password_confirmation: "",
        email: "",
        avatar: "",
        gender: "",
        age: "",
        education: "",
      },
      rules: {
        mobile: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { min: 11, max: 11, message: "输入正确的手机号", trigger: "blur" },
        ],
        code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 6, max: 6, message: "输入6位的验证码", trigger: "blur" },
        ],
        nick_name: [{ required: true, message: "请输入昵称", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        password_confirmation: [
          { required: true, message: "请再次确认密码", trigger: "blur" },
        ],
        email: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
        sex: [{ required: true, message: "请选择性别", trigger: "blur" }],
        age: [{ required: true, message: "请输入年龄", trigger: "blur" }],
      },
      agreementChecked: false,
      sendCodeDisabled: false,
      time: 60,
      sendCodeTxt: "获取验证码",
    };
  },
  computed: {},
  created() {},
  methods: {
    ////回主页
    goHome() {
      this.$router.push({ path: "/" });
    },
    //用户协议
    userAgreementClick() {
      this.$router.push({ path: "/useragreement" });
    },
    //隐私政策
    PricacyPolicyClick() {
      this.$router.push({ path: "/privacypolicy" });
    },
    //注册
    submitClick() {
      if (!this.form.mobile) {
        this.$message.error("请输入手机号码");
        return;
      }
      if (!this.form.code) {
        this.$message.error("请输入验证码");
        return;
      }
      if (!this.form.nick_name) {
        this.$message.error("请输入昵称");
        return;
      }
      if (!this.form.password) {
        this.$message.error("请输入密码");
        return;
      }
      if (!this.form.password) {
        this.$message.error("请输入密码");
        return;
      }
      if (!this.form.password_confirmation) {
        this.$message.error("请再次确认密码");
        return;
      }
      if (!this.form.email) {
        this.$message.error("请输入邮箱地址");
        return;
      }
      if (!this.form.gender) {
        this.$message.error("请选择性别");
        return;
      }
      if (!this.form.age) {
        this.$message.error("请选择年龄");
        return;
      }
      if (this.form.password != this.form.password_confirmation) {
        this.$message.error("两次输入密码不一致");
        return;
      }
      if (!this.agreementChecked) {
        this.$message.error("请阅读协议并同意");
        return;
      }
      console.log(this.form);
      const data = JSON.parse(JSON.stringify(this.form));
      data.password = md5(data.password);
      data.password_confirmation = md5(data.password_confirmation);
      register(data).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message.success("注册成功");
          this.$router.go(-1);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //发送验证码
    sendCodeClick() {
      if (!this.form.mobile) {
        this.$message.error("请输入手机号码");
        return;
      }
      const data = { mobile: this.form.mobile };
      send_register_sms(data).then((res) => {
        if (res.code == 200) {
          this.time = 60;
          this.timer();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //发送手机验证码倒计时
    timer() {
      if (this.time > 0) {
        this.sendCodeDisabled = true;
        this.time--;
        this.sendCodeTxt = this.time + "秒";
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.sendCodeTxt = "发送验证码";
        this.sendCodeDisabled = false;
      }
    },
    //选择图片回调
    cutDown(e) {
      let formData = new FormData();
      formData.append("file", e.file);
      auth_upload(formData).then((res) => {
        if (res.code == 200) {
          this.form.avatar = res.data.img_url;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //同意协议
    agreementClick() {
      // this.agreementChecked = !this.agreementChecked;
    },
    load() {},
  },
};
</script>
<style scoped>
.header {
  position: relative;
}
.title {
  margin: 20px 0;
  font-size: 17px;
  text-align: center;
}
.header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
}
.form {
  margin: 0 auto;
  width: 500px;
}
.upload-avatar {
  padding: 20px;
  width: 80px;
  height: 80px;
  background-color: #f5f5f5;
  border: 1px solid #cccccc;
}
.avatar {
  width: 80px;
  height: 80px;
}
:deep(.el-radio__input.is-checked) :deep(.el-radio__inner) {
  background-color: var(--color-main);
  border-color: var(--color-main);
}
:deep(.el-radio__input.is-checked +) :deep(.el-radio__label) {
  color: var(--color-main);
}
.submit {
  margin: 15px 30px;
  padding: 10px 0;
  background-color: var(--color-main);
  color: #fff;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}
.agreement {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-size: 13px;
}
.agreement_we {
  margin-left: 15px;
}
.agreement_title {
  color: var(--color-main);
  cursor: pointer;
}
:deep(.el-checkbox__input.is-checked
    .el-checkbox__inner, .el-checkbox__input.is-indeterminate
    .el-checkbox__inner) {
  background-color: var(--color-main);
  border-color: var(--color-main);
}

.footer {
  margin-top: 100px;
}
</style>