<template>
  <div class="register">
    <div class="header">
      <div class="header_bg">
        <img src="../assets/img/header_bg1.png" alt="" />
      </div>
      <div class="header_logo" @click="goHome"></div>
    </div>
    <div class="title">绑定手机号</div>
    <div>
      <el-form
        class="form"
        :model="form"
        label-width="80px"
        :rules="rules"
        ref="ruleForm"
      >
        <el-form-item label="手机号码" prop="mobile">
          <el-input
            placeholder="请输入手机号"
            v-model="form.mobile"
            type="tel"
          ></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="code">
          <el-row :gutter="20">
            <el-col :span="20">
              <el-input
                placeholder="请输入验证码"
                v-model="form.code"
                type="tel"
              ></el-input>
            </el-col>
            <el-col :span="4">
              <el-button :disabled="sendCodeDisabled" @click="sendCodeClick">{{
                sendCodeTxt
              }}</el-button>
            </el-col>
          </el-row>
        </el-form-item>

        <div class="submit" @click="submitClick">确认</div>
        <!-- <div class="agreement">
          <el-checkbox
            v-model="agreementChecked"
            @click="agreementClick"
          ></el-checkbox>
          <span class="agreement_we">我已经仔细阅读并同意</span>
          <span class="agreement_title" @click="userAgreementClick"
            >用户协议</span
          >
          <span>和</span>
          <span class="agreement_title" @click="PricacyPolicyClick"
            >隐私政策</span
          >
        </div> -->
      </el-form>
    </div>
    <copy-footer class="footer" />
  </div>
</template>
<script>
import CopyFooter from "../components/footer/CopyFooter.vue";
import {
  bind_login_with_mobile,
  send_bind_sms,
} from "../network/views-request";
import md5 from "js-md5";
import ImgCutter from "vue-img-cutter";

export default {
  name: "Survey",
  components: { CopyFooter, ImgCutter },
  props: {},
  data() {
    return {
      form: {
        mobile: "",
        code: "",
        unionid: "",
      },
      rules: {
        mobile: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { min: 11, max: 11, message: "输入正确的手机号", trigger: "blur" },
        ],
        code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 6, max: 6, message: "输入6位的验证码", trigger: "blur" },
        ],
      },
      sendCodeDisabled: false,
      time: 60,
      sendCodeTxt: "获取验证码",
    };
  },
  computed: {},
  created() {},
  methods: {
    ////回主页
    goHome() {
      this.$router.push({ path: "/" });
    },
    //绑定
    submitClick() {
      if (!this.form.mobile) {
        this.$message.error("请输入手机号码");
        return;
      }
      if (!this.form.code) {
        this.$message.error("请输入验证码");
        return;
      }

      const data = JSON.parse(JSON.stringify(this.form));
      data.code = data.code;
      data.mobile = data.mobile;
      data.unionid = this.$route.query.unionid;
      bind_login_with_mobile(data).then((res) => {
        console.log(res);
        if (res.code == 200) {
          localStorage.setItem("userInfo", JSON.stringify(res.data));
          this.$message.success("绑定成功");
          this.$router.replace({ path: "/home" });
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //发送验证码
    sendCodeClick() {
      if (!this.form.mobile) {
        this.$message.error("请输入手机号码");
        return;
      }
      const data = { mobile: this.form.mobile };
      send_bind_sms(data).then((res) => {
        if (res.code == 200) {
          this.time = 60;
          this.timer();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //发送手机验证码倒计时
    timer() {
      if (this.time > 0) {
        this.sendCodeDisabled = true;
        this.time--;
        this.sendCodeTxt = this.time + "秒";
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.sendCodeTxt = "发送验证码";
        this.sendCodeDisabled = false;
      }
    },
  },
};
</script>
<style scoped>
.header {
  position: relative;
}
.title {
  margin: 20px 0;
  font-size: 17px;
  text-align: center;
}
.header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
}
.form {
  margin: 0 auto;
  width: 500px;
  height: 400px;
}
.upload-avatar {
  padding: 20px;
  width: 80px;
  height: 80px;
  background-color: #f5f5f5;
  border: 1px solid #cccccc;
}
.avatar {
  width: 80px;
  height: 80px;
}
:deep(.el-radio__input.is-checked) :deep(.el-radio__inner) {
  background-color: var(--color-main);
  border-color: var(--color-main);
}
:deep(.el-radio__input.is-checked +) :deep(.el-radio__label) {
  color: var(--color-main);
}
.submit {
  margin: 15px 30px;
  padding: 10px 0;
  background-color: var(--color-main);
  color: #fff;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}
.agreement {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-size: 13px;
}
.agreement_we {
  margin-left: 15px;
}
.agreement_title {
  color: var(--color-main);
  cursor: pointer;
}
:deep(.el-checkbox__input.is-checked
    .el-checkbox__inner, .el-checkbox__input.is-indeterminate
    .el-checkbox__inner) {
  background-color: var(--color-main);
  border-color: var(--color-main);
}

.footer {
  margin-top: 100px;
}
</style>